import { BaseTouroFiltro } from '../../models/configuracoes/base-touros-filtro.model';
import { Injectable } from '@angular/core';
import { Lote } from 'src/models/configuracoes/lote.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { Inseminador } from 'src/models/configuracoes/inseminador.model';
import { Protocolo } from 'src/models/configuracoes/protocolo.model';
import { ComposicaoRacial } from 'src/models/configuracoes/composicao-racial.model';
import { Raca } from 'src/models/configuracoes/raca.model';
import { Reprodutor } from 'src/models/configuracoes/reprodutor.model';
import { BaseTouro } from 'src/models/configuracoes/base-touros.model';
import { SemenTouro } from 'src/models/configuracoes/semen-touro.model';
import { AltaGestaoService } from './alta-gestao.service';
import { ProtocoloDetalhe } from 'src/models/configuracoes/protocolo-detalhes.model';

@Injectable({
    providedIn: 'root'
})

export class ConfiguracoesService {

  private apiUrl = `${environment.apiBaseUrl}/configuracoes`;
  private NUMERO_TENTATIVAS_REQUEST = 0;

  constructor(
    private http: HttpClient,
    private altaGestaoService: AltaGestaoService) {
  }

  getCdFazenda() {
      return this.altaGestaoService.getCdFazendaSelecionada();
  }

  getLotes(): Observable<Lote[]> {
    return this.http
      .get<Lote[]>(`${this.apiUrl}/lote/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaLote(value: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/lote/atualiza`, value)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeLote(value: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/lote/remove`, value)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereLote(lote: Lote) {
    return this.http
      .post<any>(`${this.apiUrl}`, lote)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getInseminadores(): Observable<Inseminador[]> {
    return this.http
      .get<Inseminador[]>(`${this.apiUrl}/inseminador/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(3), catchError(this.handleError));
  }

  atualizaInseminador(value: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/inseminador/atualiza`, value)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeInseminador(value: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/inseminador/remove`, value)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereInseminador(inseminador: Inseminador) {
    return this.http
      .post<any>(`${this.apiUrl}/inseminador/insere`, inseminador)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getProtocolos(): Observable<Protocolo[]> {
    return this.http
      .get<Protocolo[]>(`${this.apiUrl}/protocolo/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaProtocolo(value: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/protocolo/atualiza`, value)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeProtocolo(value: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/protocolo/remove`, value)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereProtocolo(protocolo: Protocolo) {
    return this.http
      .post<any>(`${this.apiUrl}/protocolo/insere`, protocolo)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getProtocoloDetalhes(cdProtocolo: number): Observable<ProtocoloDetalhe[]> {
    return this.http
      .get<ProtocoloDetalhe[]>(`${this.apiUrl}/detalhes-protocolo/${this.altaGestaoService.getCdFazendaSelecionada()}/${cdProtocolo}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaProtocoloDetalhes(protocoloDetalhes: ProtocoloDetalhe[]): Observable<ProtocoloDetalhe[]> {
    return this.http
      .put<ProtocoloDetalhe[]>(`${this.apiUrl}/detalhes-protocolo/atualiza`, protocoloDetalhes)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereProtocoloDetalhes(protocoloDetalhes: ProtocoloDetalhe[]): Observable<ProtocoloDetalhe[]> {
    return this.http
      .post<ProtocoloDetalhe[]>(`${this.apiUrl}/detalhes-protocolo/insere`, protocoloDetalhes)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getComposicoesRaciais(): Observable<ComposicaoRacial[]> {
    return this.http
      .get<ComposicaoRacial[]>(`${this.apiUrl}/composicao-racial/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaComposicaoRacial(composicaoRacial: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/composicao-racial/atualiza`, composicaoRacial)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeComposicaoRacial(composicaoRacial: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/composicao-racial/remove`, composicaoRacial)
      .pipe(retry(3), catchError(this.handleError));
  }

  insereComposicaoRacial(composicaoRacial: ComposicaoRacial) {
    return this.http
      .post<any>(`${this.apiUrl}/composicao-racial/insere`, composicaoRacial)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getRacas(): Observable<Raca[]> {
    return this.http
      .get<Raca[]>(`${this.apiUrl}/raca`)
      .pipe(retry(3), catchError(this.handleError));
  }

  getReprodutores(): Observable<Reprodutor[]> {
    return this.http
      .get<Reprodutor[]>(`${this.apiUrl}/reprodutor/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaReprodutor(reprodutor: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/reprodutor/atualiza`, reprodutor)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeReprodutor(valor: any): Observable<object> {
    return this.http
      .put(`${this.apiUrl}/reprodutor/remove`, valor)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereReprodutor(reprodutor: Reprodutor) {
    return this.http
      .post<any>(`${this.apiUrl}/reprodutor/insere`, reprodutor)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getBaseTouros(): Observable<BaseTouro[]> {
    return this.http
      .get<BaseTouro[]>(`${this.apiUrl}/base-touros`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getBaseTourosPorFiltro(filtro: BaseTouroFiltro): Observable<BaseTouro[]> {
    return this.http
      .post<BaseTouro[]>(`${this.apiUrl}/base-touros`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getSemenTouros(): Observable<SemenTouro[]> {
    return this.http
      .get<SemenTouro[]>(`${this.apiUrl}/semen-touros/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(3), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }
}
