import { Component, OnInit } from '@angular/core';
import { apiConfig } from '../_authorization/auth.config';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading = true;

  constructor(
    private authService: MsalService,
    private router: Router) { }

  ngOnInit() {
    this.acquireTokenSilent();
  }

  acquireTokenSilent() {
    this.authService.acquireTokenSilent({ scopes: apiConfig.b2cScopes })
      .then((tokenResponse) => {
        this.router.navigate(['/inicio']);
      })
      .catch((error) => {
        // este método é um paleativo para o problema de login no Azure 
        // ClientAuthError: Token renewal operation failed due to timeout.
        this.acquireTokenPopup();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  acquireTokenPopup() {
    this.authService.acquireTokenPopup({ scopes: apiConfig.b2cScopes })
      .then((tokenResponse) => {
        this.router.navigate(['/inicio']);
      })
      .catch((error) => {})
      .finally(() => {
        this.loading = false;
      });
  }

  login() {
    this.authService.loginRedirect();
  }
}
