export class SemenTouro {
    public cdreprodutor: number;
    public tipo: string;
    public numero: string;
    public nome: string;
    public registro: string;
    public codigoBaseunif: number;
    public regInternacional: string;
    public codigoInternacional: string;
    public cdfazenda: number;
    public removido: number;
    public bullid: number;
    public bullid1: number;
}
