import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ControleLeiteiro } from 'src/models/producao/controle-leiteiro.model';
import { AltaGestaoService } from './alta-gestao.service';
import { Secagem } from 'src/models/producao/secagem.model';
import { ListagemProducaoFiltro } from 'src/models/listagem/listagem-producao-filtro.model';
import { ListagemProducao } from 'src/models/listagem/listagem-producao.model';

@Injectable({
  providedIn: 'root'
})
export class ProducaoService {

  private apiUrlProducao = `${environment.apiBaseUrl}/producao`;
  private apiUrlListagem = `${environment.apiBaseUrl}/listagem`;
  private NUMERO_TENTATIVAS = 0;

  constructor(
    private http: HttpClient,
    private altaGestaoService: AltaGestaoService) {
  }

  getControlesLeiteirosLancados(): Observable<any[]> {
    return this.http
      .get<ControleLeiteiro[]>(`${this.apiUrlProducao}/controle-leiteiro/lancados/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  getControlesLeiteirosParaLancamento(): Observable<any[]> {
    return this.http
      .get<ControleLeiteiro[]>(`${this.apiUrlProducao}/controle-leiteiro/lancamento/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  getControlesLeiteirosParaExportacao(filtro: ListagemProducaoFiltro): Observable<ListagemProducao[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<ListagemProducao[]>(`${this.apiUrlListagem}/listagem-producao`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  insereControleLeiteiro(controlesLeiteiro: ControleLeiteiro): Observable<ControleLeiteiro> {
    return this.http
      .post<ControleLeiteiro>(`${this.apiUrlProducao}/controle-leiteiro/novo`, controlesLeiteiro)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  insereControlesLeiteiros(controlesLeiteiros: Array<ControleLeiteiro>): Observable<Array<ControleLeiteiro>> {
    return this.http
      .post<Array<ControleLeiteiro>>(`${this.apiUrlProducao}/controle-leiteiro/novos`, controlesLeiteiros)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  atualizaControlesLeiteiros(controlesLeiteiros: Array<ControleLeiteiro>): Observable<Array<ControleLeiteiro>> {
    return this.http
      .put<Array<ControleLeiteiro>>(`${this.apiUrlProducao}/controle-leiteiro/atualiza`, controlesLeiteiros)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  removeControleLeiteiro(cdLeite: number): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.apiUrlProducao}/controle-leiteiro/remove`, cdLeite)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  getSecagensLancadas(): Observable<Secagem[]> {
    return this.http.get<Secagem[]>(`${this.apiUrlProducao}/secagem/lancadas/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  getSecagensParaLancamento(): Observable<Secagem[]> {
    return this.http
      .get<Secagem[]>(`${this.apiUrlProducao}/secagem/lancamento/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  insereSecagem(secagens: Secagem): Observable<Secagem> {
    return this.http
      .post<Secagem>(`${this.apiUrlProducao}/secagem/nova`, secagens)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  getUltimoControleLeiteiro(Secagem: Secagem): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrlProducao}/controle-leiteiro/ultimo`, Secagem)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  insereSecagens(secagens: Array<Secagem>): Observable<Array<Secagem>> {
    return this.http
      .post<Array<Secagem>>(`${this.apiUrlProducao}/secagem/novas`, secagens)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  atualizaSecagens(secagens: Array<Secagem>): Observable<Array<Secagem>> {
    return this.http
      .put<Array<Secagem>>(`${this.apiUrlProducao}/secagem/atualiza`, secagens)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  removeSecagem(secagem: Secagem): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.apiUrlProducao}/secagem/remove`, secagem)
      .pipe(retry(this.NUMERO_TENTATIVAS), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }
}
