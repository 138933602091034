import { Injectable } from '@angular/core';
import { Matriz } from 'src/models/matriz/matriz.model';
import { ResultadoDiagnosticoEnum } from '../_enums/resultado-diagnostico.enum';

@Injectable({
  providedIn: 'root'
})
export class ValidacaoDiagnosticoReproducaoService {

  private reproducao: any;
  private matriz: Matriz;
  private erro: string;

  constructor() { }

  validaLancamentoDiagnostico(reproducao: any, matriz: Matriz): boolean {
    this.reproducao = reproducao;
    this.matriz = matriz;
    this.erro = ``;

    if (!this.existeDataDiagnosticos())                 { return false; }
    if (!this.existeResultadoDiagnosticos())            { return false; }
    if (!this.validaLancamentoResultadoDiagnostico2())  { return false; }
    if (!this.validaLancamentoResultadoDiagnostico3())  { return false; }
    if (!this.validaLancamentoResultadoDiagnostico4())  { return false; }
    if (!this.validaDataDiagnostico1())                 { return false; }
    if (!this.validaDataDiagnostico3())                 { return false; }
    if (!this.validaDataDiagnostico4())                 { return false; }
    if (!this.ehResultadoDiagnostico2Valido())          { return false; }
    if (!this.ehResultadoDiagnostico3Valido())          { return false; }
    if (!this.ehResultadoDiagnostico4Valido())          { return false; }
    if (!this.ehDataDiagnostico1Valida())               { return false; }
    if (!this.ehDataDiagnostico2Valida())               { return false; }
    if (!this.ehDataDiagnostico3Valida())               { return false; }
    if (!this.ehDataDiagnostico4Valida())               { return false; }

    return true;
  }

  private existeDataDiagnosticos(): boolean {
    if ((this.existeResultadoDiagnostico1() && !this.existaDataDiagnostico1())
      || (this.existeResultadoDiagnostico2() && !this.existaDataDiagnostico2())
      || (this.existeResultadoDiagnostico3() && !this.existaDataDiagnostico3())
      || (this.existeResultadoDiagnostico4() && !this.existaDataDiagnostico4())) {
        this.erro = `
          Não é permitido informar o resultado de algum toque
            sem informar sua data. Matriz: ${ this.matriz.numero }.`;
        return false;
     }
    return true;
  }

  private existeResultadoDiagnosticos(): boolean {
    if ((!this.existeResultadoDiagnostico1() && this.existaDataDiagnostico1())
      || (!this.existeResultadoDiagnostico2() && this.existaDataDiagnostico2())
      || (!this.existeResultadoDiagnostico3() && this.existaDataDiagnostico3())
      || (!this.existeResultadoDiagnostico4() && this.existaDataDiagnostico4())) {
        this.erro = `
          Não é permitido informar a data de algum toque sem informar
            o seu resultado. Matriz: ${ this.matriz.numero }.`;
        return false;
     }
    return true;
  }

  private validaLancamentoResultadoDiagnostico2(): boolean {
    if (this.existeResultadoDiagnostico2() && !this.existeResultadoDiagnostico1()) {
      this.erro = `
        Não é permitido informar o segundo toque sem informar
          o primeiro toque. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private validaLancamentoResultadoDiagnostico3(): boolean {
    if (this.existeResultadoDiagnostico3() && !this.existeResultadoDiagnostico2()) {
      this.erro = `
        Não é permitido informar o terceiro toque sem informar
          os toques anteriores. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private validaLancamentoResultadoDiagnostico4(): boolean {
    if (this.existeResultadoDiagnostico4() && !this.existeResultadoDiagnostico3()) {
      this.erro = `
        Não é permitido informar o quarto toque sem informar
          os toques anteriores. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private validaDataDiagnostico1(): boolean {
    if (this.existaDataDiagnostico2() && !this.existaDataDiagnostico1()) {
      this.erro = `
        Não é permitido informar a data do segundo toque sem informar
          a data do primeiro toque. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private validaDataDiagnostico3(): boolean {
    if (this.existaDataDiagnostico3() && !this.existaDataDiagnostico2()) {
      this.erro = `
        Não é permitido informar a data do terceiro toque sem informar
          a data do segundo toque. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private validaDataDiagnostico4(): boolean {
    if (this.existaDataDiagnostico4() && !this.existaDataDiagnostico3()) {
      this.erro = `
        Não é permitido informar a data do quarto toque sem informar
          a data do terceiro toque. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private ehResultadoDiagnostico2Valido(): boolean {
    let ehResultadoDiagnostico2Valido = true;

    if (this.existeResultadoDiagnostico1() && this.existeResultadoDiagnostico2()) {
      const ehResultadoDiagnostico1Positivo = this.reproducao.cdresultadodiagnostico1 !== ResultadoDiagnosticoEnum.N;
      const ehResultadoDiagnostico2Positivo = this.reproducao.cdresultadodiagnostico2 === ResultadoDiagnosticoEnum.P;

      if (!ehResultadoDiagnostico1Positivo && ehResultadoDiagnostico2Positivo) {
        this.erro = `
          Não é permitido informar o segundo toque como positivo com
            o primeiro toque negativo. Matriz: ${ this.matriz.numero }.`;
        ehResultadoDiagnostico2Valido = false;
        return ehResultadoDiagnostico2Valido;
      }
    }
    return ehResultadoDiagnostico2Valido;
  }

  private ehResultadoDiagnostico3Valido(): boolean {
    if (this.existeResultadoDiagnostico2() && this.existeResultadoDiagnostico3()) {
      const ehResultadoDiagnostico2Positivo = this.reproducao.cdresultadodiagnostico2 !== ResultadoDiagnosticoEnum.N;
      const ehResultadoDiagnostico3Positivo = this.reproducao.cdresultadodiagnostico3 === ResultadoDiagnosticoEnum.P;

      if (!ehResultadoDiagnostico2Positivo && ehResultadoDiagnostico3Positivo) {
        this.erro = `
          Não é permitido informar o terceiro toque como positivo com
            o segundo toque negativo. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  private ehResultadoDiagnostico4Valido(): boolean {
    if (this.existeResultadoDiagnostico4() && this.existeResultadoDiagnostico4()) {
      const ehResultadoDiagnostico3Positivo = this.reproducao.cdresultadodiagnostico3 !== ResultadoDiagnosticoEnum.N;
      const ehResultadoDiagnostico4Positivo = this.reproducao.cdresultadodiagnostico4 === ResultadoDiagnosticoEnum.P;

      if (!ehResultadoDiagnostico3Positivo && ehResultadoDiagnostico4Positivo) {
        this.erro = `
          Não é permitido informar o quarto toque como positivo com
            o terceiro toque negativo. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  private ehDataDiagnostico1Valida(): boolean {
    let ehDataDiagnostico1Valida = true;

    if (this.existaDataDiagnostico1()) {
      const dataAtual = new Date();
      const dataIaCobTe = new Date(this.reproducao.data);
      const dataDiagnostico1 = new Date(this.reproducao.dtdiagnostico1);

      ehDataDiagnostico1Valida = dataDiagnostico1 > dataIaCobTe && dataDiagnostico1 <= dataAtual;

      if (!ehDataDiagnostico1Valida) {
        this.erro = `
          A data do primeiro toque tem que ser superior à data da inseminação
            e inferior a data atual. Matriz: ${ this.matriz.numero }.`;
        return ehDataDiagnostico1Valida;
      }
    }
    return ehDataDiagnostico1Valida;
  }

  private ehDataDiagnostico2Valida(): boolean {
    let ehDataDiagnostico2Valida = true;

    if (this.existaDataDiagnostico2()) {
      const dataAtual = new Date();
      const dataIaCobTe = new Date(this.reproducao.data);
      const dataDiagnostico1 = new Date(this.reproducao.dtdiagnostico1);
      const dataDiagnostico2 = new Date(this.reproducao.dtdiagnostico2);

      ehDataDiagnostico2Valida =
        dataDiagnostico2 > dataIaCobTe
          && dataDiagnostico2 > dataDiagnostico1
          && dataDiagnostico2 <= dataAtual;

      if (!ehDataDiagnostico2Valida) {
        this.erro = `
          A data do segundo toque tem que ser superior à data da inseminação,
            a data do primeiro toque e inferior a data atual. Matriz: ${ this.matriz.numero }.`;
        return ehDataDiagnostico2Valida;
      }
    }
    return ehDataDiagnostico2Valida;
  }

  private ehDataDiagnostico3Valida(): boolean {
    let ehDataDiagnostico3Valida = true;

    if (this.existaDataDiagnostico3()) {
      const dataAtual = new Date();
      const dataIaCobTe = new Date(this.reproducao.data);
      const dataDiagnostico2 = new Date(this.reproducao.dtdiagnostico2);
      const dataDiagnostico3 = new Date(this.reproducao.dtdiagnostico3);

      ehDataDiagnostico3Valida =
        dataDiagnostico3 > dataIaCobTe
          && dataDiagnostico3 > dataDiagnostico2
          && dataDiagnostico3 <= dataAtual;

      if (!ehDataDiagnostico3Valida) {
        this.erro = `
          A data do terceiro toque tem que ser superior à data da inseminação,
            a data do segundo toque e inferior a data atual. Matriz: ${ this.matriz.numero }.`;
        return ehDataDiagnostico3Valida;
      }
    }
    return ehDataDiagnostico3Valida;
  }

  private ehDataDiagnostico4Valida(): boolean {
    let ehDataDiagnostico4Valida = true;

    if (this.existaDataDiagnostico4()) {
      const dataAtual = new Date();
      const dataIaCobTe = new Date(this.reproducao.data);
      const dataDiagnostico3 = new Date(this.reproducao.dtdiagnostico3);
      const dataDiagnostico4 = new Date(this.reproducao.dtdiagnostico4);

      ehDataDiagnostico4Valida =
        dataDiagnostico4 > dataIaCobTe
          && dataDiagnostico4 > dataDiagnostico3
          && dataDiagnostico4 <= dataAtual;

      if (!ehDataDiagnostico4Valida) {
        this.erro = `
          A data do quarto toque tem que ser superior à data da inseminação,
            a data do terceiro toque e inferior a data atual. Matriz: ${ this.matriz.numero }.`;
        return ehDataDiagnostico4Valida;
      }
    }
    return ehDataDiagnostico4Valida;
  }

  private existaDataDiagnostico1(): boolean {
    const existaDataDiagnostico1 =
      this.reproducao.dtdiagnostico1 !== undefined
      && this.reproducao.dtdiagnostico1 !== null;
    return existaDataDiagnostico1;
  }

  private existaDataDiagnostico2(): boolean {
    const existaDataDiagnostico2 =
      this.reproducao.dtdiagnostico2 !== undefined
      && this.reproducao.dtdiagnostico2 !== null;
    return existaDataDiagnostico2;
  }

  private existaDataDiagnostico3(): boolean {
    const existaDataDiagnostico3 =
      this.reproducao.dtdiagnostico3 !== undefined
      && this.reproducao.dtdiagnostico3 !== null;
    return existaDataDiagnostico3;
  }

  private existaDataDiagnostico4(): boolean {
    const existaDataDiagnostico4 =
      this.reproducao.dtdiagnostico4 !== undefined
      && this.reproducao.dtdiagnostico4 !== null;
    return existaDataDiagnostico4;
  }

  private existeResultadoDiagnostico1(): boolean {
    const hasDiagnosticResult1 =
      this.reproducao.cdresultadodiagnostico1 !== null
      && this.reproducao.cdresultadodiagnostico1 !== undefined;
    return hasDiagnosticResult1;
  }

  private existeResultadoDiagnostico2(): boolean {
    const existeResultadoDiagnostico2 =
      this.reproducao.cdresultadodiagnostico2 !== null
      && this.reproducao.cdresultadodiagnostico2 !== undefined;
    return existeResultadoDiagnostico2;
  }

  private existeResultadoDiagnostico3(): boolean {
    const hasDiagnosticResult3 =
      this.reproducao.cdresultadodiagnostico3 !== null
      && this.reproducao.cdresultadodiagnostico3 !== undefined;
    return hasDiagnosticResult3;
  }

  private existeResultadoDiagnostico4(): boolean {
    const hasDiagnosticResult4 =
      this.reproducao.cdresultadodiagnostico4 !== null
      && this.reproducao.cdresultadodiagnostico4 !== undefined;
    return hasDiagnosticResult4;
  }

  getErro(): string {
    return this.erro;
  }

}
