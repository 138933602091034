export class ProtocoloDetalhe {
    public cddetalhe: number;
    public cdprotocolo: number;
    public fase: string;
    public dias?: number;
    public produto: string;
    public doseprod?: number;
    public produto1: string;
    public doseprod1?: number;
    public produto2: string;
    public doseprod2?: number;
    public ordem?: number;
}
