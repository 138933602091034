import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatInput, MatRadioButton, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { ConfiguracoesService } from 'src/app/_services/configuracoes.service';
import { MatrizService } from 'src/app/_services/matriz.service';
import { Raca } from 'src/models/configuracoes/raca.model';
import { Reprodutor } from 'src/models/configuracoes/reprodutor.model';
import { SemenTouro } from 'src/models/configuracoes/semen-touro.model';
import { ComposicaoRacial } from 'src/models/matriz/composicao-racial.model';
import { Genealogia } from 'src/models/matriz/genealogia.model';
import { Lote } from 'src/models/matriz/lote.model';
import { Matriz } from 'src/models/matriz/matriz.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';

@Component({
  selector: 'app-matriz-mae-cadastro',
  templateUrl: './matriz-mae-cadastro.component.html',
  styleUrls: ['./matriz-mae-cadastro.component.scss']
})
export class MatrizMaeCadastroComponent implements OnInit {

  @Input() matrixId: any;
  @Output() closed = new EventEmitter();
  @Output() motherId = new EventEmitter();

  @ViewChild('tipo0', { static: false }) tipo0: MatRadioButton;
  @ViewChild('tipo1', { static: false }) tipo1: MatRadioButton;
  @ViewChild('ativo0', { static: false }) ativo0: MatRadioButton;
  @ViewChild('ativo1', { static: false }) ativo1: MatRadioButton;
  @ViewChild('desc', { static: false }) private desc: MatInput;

  hasChangesComp = false;
  hasChangesLot = false;
  hasChanges = false;
  hasChangesPai = false;
  hasError = false;
  desabilita = false;
  loading = false;
  editComp = false;
  editLote = false;
  editMother = true;
  edited = false;

  raca: Array<Raca>;
  racaList: Array<Raca>;
  compositionList: Array<ComposicaoRacial>;
  compositionListAll: Array<ComposicaoRacial>;
  lotListAll: Array<Lote>;
  racas = [];
  reprodutorListAll: Array<Reprodutor>;
  reprodutorList: Array<Reprodutor>;
  semenTourosListAll: Array<SemenTouro>;
  semenTourosList: Array<SemenTouro>;
  racaListAll: Array<Raca>;
  composicao: Array<ComposicaoRacial>;
  reprodutor: Array<Reprodutor>;
  lote: Array<Lote>;
  genealogia: Array<Genealogia>;
  reproducao: Array<Reproducao>;
  matrixListAll: Array<Matriz>;

  clickedRowItem: any;
  racialCompositionId: any;
  validComposition: any;
  lotId: any;
  validLot: any;
  validReprodutor: any;
  semenTouroId: any;
  reprodutorId: any;
  validMatrix: any;

  message = '';
  perc1: string;
  perc2: string;
  perc3: string;
  perc4: string;
  perc5: string;
  rac1: string;
  rac2: string;
  rac3: string;
  rac4: string;
  rac5: string;
  percTotal = 0;
  msg = '';
  msgPercentual = '';
  msgTotal: string;
  msgPreenchimento = '';
  msgValidFill = '';
  msgRaca = '';

  gridView: GridDataResult;
  matrixSort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];
  gridSelection: any[] = [];

  avoSelecionado: number;
  paiSelecionado: number;
  semenCodePai: string;
  totalPartos = 0;
  numPartos = 0;
  total = 0;

  F4 = `F4`;

  snackBarConfig: MatSnackBarConfig = {
    panelClass: ['mat-snack-bar-container'],
    duration: 2500
  };

  selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private matrizService: MatrizService,
    private reprodutorService: ConfiguracoesService
  ) { }

  ngOnInit() {
      this.racaList = [];
      this.compositionList = [];
      this.totalPartos = this.matrixId.numPartos !== undefined ? this.matrixId.numPartos : this.totalPartos;
      this.numPartos = this.totalPartos;
      this.onPartosTotais(this.matrixId.numpartoinicio);
      this.getAllRaca();
      this.getAllComposition();
      this.getAllLot();
      this.getAllReprodutor();
      this.getSemenTouro();
      this.getRaca();
      this.getAllGenealogia();
      this.getAllComposicaoRacial();
      this.getReprodutor();
      this.getAllLote();
      this.getAllMatrix(false);
  }

  getAllMatrix(isInsert: boolean) {
    if (!isInsert) {
      this.loading = true;
      this.matrizService.getMatrizes().subscribe((response) => {
        this.matrixListAll = response;
        this.loading = false;
      });
    } else {
      this.loading = true;
      this.matrizService.getMatrizes().subscribe((response) => {
        this.matrixListAll = response;
        this.matrixId.cdmatriz = this.matrixListAll.sort((a, b) => b.cdmatriz - a.cdmatriz)[0].cdmatriz;
        this.motherId.emit(this.matrixId.cdmatriz);
        this.loading = false;
      });
    }
  }

  getAllComposicaoRacial() {
    this.loading = true;
    this.matrizService.getComposicaoRacial().subscribe((response) => {
      this.composicao = response;
      this.loading = false;
    });
  }

  getReprodutor() {
    this.loading = true;
    this.matrizService.getReprodutor().subscribe((response) => {
      this.reprodutor = response;
      this.loading = false;
    });
  }

  getAllLote() {
    this.loading = true;
    this.matrizService.getLote().subscribe((response) => {
      this.lote = response;
      this.loading = false;
    });
  }

  getAllGenealogia() {
    this.loading = true;
    this.matrizService.getGenealogia().subscribe((response) => {
      this.genealogia = response;
      this.loading = false;
    });
  }

  getAllRaca() {
    this.loading = true;
    this.matrizService.getRaca().subscribe((response) => {
        this.raca = response;
        this.racaList = response;
        this.loading = false;
    });
  }

  getAllComposition() {
    this.loading = true;
    this.matrizService.getConfiguracoesComposicaoRacial().subscribe((response) => {
        this.compositionListAll = response;
        this.loading = false;
    });
  }

  getAllLot() {
    this.loading = true;
    this.matrizService.getLot().subscribe((response) => {
        this.lotListAll = response;
        this.loading = false;
    });
  }

  getAllReprodutor() {
    this.loading = true;
    this.reprodutorService.getReprodutores().subscribe((response) => {
        this.reprodutorListAll = response;
        this.reprodutorList = [];
        this.reprodutorList = this.reprodutorListAll;
        this.loading = false;
    });
  }

  getSemenTouro() {
    this.loading = true;
    this.reprodutorService.getSemenTouros().subscribe((response) => {
      this.semenTourosListAll = response;
      this.semenTourosList = response;
      const hasCdPai = this.matrixId !== undefined && this.matrixId.cdpai !== undefined;
      if (hasCdPai) {
        this.onBuscaSemenCode(this.matrixId.cdpai);
      }
      this.loading = false;
    });
  }

  getRaca() {
      this.loading = true;
      this.reprodutorService.getRacas().subscribe((response) => {
      this.racaListAll = response;
      this.loading = false;
    });
  }

  onPartosTotais(obj: any) {
    const sel = obj;
    if (sel !== null && sel !== undefined) {
      this.totalPartos = parseFloat(sel) + this.numPartos;
    } else {
      this.totalPartos = this.numPartos;
    }
  }

  onBuscaAvo(ob: any) {
    const hasOb = ob !== undefined && ob !== null;
    if (hasOb) {
      const selectedBook = ob.value;
      this.avoSelecionado = ob.value;
    }
  }

  onBuscaPai(ob: any) {
    const hasOb = ob !== undefined && ob !== null;
    if (hasOb) {
      const selectedBook = ob.value;
      this.paiSelecionado = ob.value;
    }
  }

  onBuscaSemenCode(ob: any) {
    const hasOb = ob !== undefined && ob !== null;
    if (hasOb) {
      const semenCode = this.semenTourosListAll.filter(x => (x.cdreprodutor === ob.value));
      for (const item of semenCode) {
        this.semenCodePai = item.codigoInternacional;
      }
    } else {
      const semenCode = this.semenTourosListAll.filter(x => (x.cdreprodutor === ob));
      for (const item of semenCode) {
        this.semenCodePai = item.codigoInternacional;
      }
    }
  }

  hasValue(statusValue: any) {
    const hasNumber =
      this.matrixId.numero !== null && this.matrixId.numero !== undefined && this.matrixId.numero !== '';

    if (hasNumber) {
      if (statusValue !== '' && statusValue !== undefined) {
        this.hasChanges = true;
      }
    } else {
      this.hasChanges = false;
    }
  }

  // cadastro/alteracao composicao racial
  openNewRacialComposition() {
    this.racialCompositionId = new ComposicaoRacial();
    this.editComp = true;
    this.edited = true;
  }

  closeRacialComposition() {
    this.clearAllFields();
    this.editComp = false;
    this.edited = false;
  }

  saveNewComposition() {
    this.msgValidFill = '';

    let validacao = this.validFill();

    if (validacao) {
        validacao = this.validateComposition();
    }

    if (validacao) {
      this.saveComp(this.tipo0.checked, this.ativo0.checked);
      this.hasChangesComp = false;
      this.editComp = false;
      this.edited = false;
    } else {
        this.openConfirmationDialog();
    }
  }

  openConfirmationDialog() {
    const dialogRef1 = this.dialog.open(DialogoConfirmacao, {
    data: {
        // message: 'Existem campos para serem preenchidos! ',
        message: this.msgValidFill !== '' ? this.msgValidFill : 'Existem campos para serem preenchidos! ',
        buttonText: {
            ok: 'Cancelar Tudo',
            cancel: 'Corrigir'
        }
    }
    });
    dialogRef1.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
            this.clearAllFields();
            this.editComp = false;
            this.edited = false;
        }
    });

  }

  hasValueComp(statusValueComp) {
    if ( statusValueComp.trim() !== '' && statusValueComp !== undefined ) {
        this.hasChangesComp = true;
    } else {
        this.hasChangesComp = false;
    }
  }

  saveComp(tipo0: boolean, ativo0: boolean) {
    this.racialCompositionId.tipo = tipo0 === true ? true : false;
    this.racialCompositionId.ativo = ativo0 === true ? true : false;

    this.racialCompositionId.padrao = this.racialCompositionId.PADRAO > 0 ? this.racialCompositionId.PADRAO : null;
    this.racialCompositionId.cdraca1 = this.racialCompositionId.cdraca1 === undefined ? null : this.racialCompositionId.cdraca1;
    this.racialCompositionId.percentual1 = this.racialCompositionId.percentual1 === undefined
          ? null : this.racialCompositionId.percentual1;
    this.racialCompositionId.cdraca2 = this.racialCompositionId.cdraca2 === undefined ? null : this.racialCompositionId.cdraca2;
    this.racialCompositionId.percentual2 = this.racialCompositionId.percentual2 === undefined
          ? null : this.racialCompositionId.percentual2;
    this.racialCompositionId.cdraca3 = this.racialCompositionId.cdraca3 === undefined ? null : this.racialCompositionId.cdraca3;
    this.racialCompositionId.percentual3 = this.racialCompositionId.percentual3 === undefined
          ? null : this.racialCompositionId.percentual3;
    this.racialCompositionId.cdraca4 = this.racialCompositionId.cdraca4 === undefined ? null : this.racialCompositionId.cdraca4;
    this.racialCompositionId.percentual4 = this.racialCompositionId.percentual4 === undefined
          ? null : this.racialCompositionId.percentual4;
    this.racialCompositionId.cdraca5 = this.racialCompositionId.cdraca5 === undefined ? null : this.racialCompositionId.cdraca5;
    this.racialCompositionId.percentual5 = this.racialCompositionId.percentual5 === undefined
          ? null : this.racialCompositionId.percentual5;
    this.racialCompositionId.removido = this.racialCompositionId.removido === undefined ? false : this.racialCompositionId.removido;

    this.validateComposition();

    this.racialCompositionId.cdfazenda = this.racialCompositionId.cdfazenda !== null && this.racialCompositionId.cdfazenda !== undefined
                           ? this.racialCompositionId.cdfazenda : this.matrizService.getCdFazenda();

    if ( this.message !== '' ) {
      this.showSnackBar(this.message);
      this.compositionList = [];
      this.atualizaListagemComp(false);
      this.message = '';
    } else {
      this.matrizService.insertComposition(this.racialCompositionId).subscribe((response) => {
        this.compositionList = [];
        this.atualizaListagemComp(true);
        this.hasChanges = true;
        this.showSnackBar('Registros armazenados com sucesso.');
      }, (error) => this.showSnackBar(error));
    }
  }

  validTotalPerc() {
    let percItem1 = 0;
    let percItem2 = 0;
    let percItem3 = 0;
    let percItem4 = 0;
    let percItem5 = 0;
    let percItem = 0;

    percItem1 = parseFloat(this.racialCompositionId.percentual1 !== null && this.racialCompositionId.percentual1 !== undefined
                && this.racialCompositionId.percentual1 !== '' ? this.racialCompositionId.percentual1 : 0);
    percItem2 = parseFloat(this.racialCompositionId.percentual2 !== null && this.racialCompositionId.percentual2 !== undefined
                && this.racialCompositionId.percentual2 !== '' ? this.racialCompositionId.percentual2 : 0);
    percItem3 = parseFloat(this.racialCompositionId.percentual3 !== null && this.racialCompositionId.percentual3 !== undefined
                && this.racialCompositionId.percentual3 !== '' ? this.racialCompositionId.percentual3 : 0);
    percItem4 = parseFloat(this.racialCompositionId.percentual4 !== null && this.racialCompositionId.percentual4 !== undefined
                && this.racialCompositionId.percentual4 !== '' ? this.racialCompositionId.percentual4 : 0);
    percItem5 = parseFloat(this.racialCompositionId.percentual5 !== null && this.racialCompositionId.percentual5 !== undefined
                && this.racialCompositionId.percentual5 !== '' ? this.racialCompositionId.percentual5 : 0);

    if (percItem1 > 0) { this.validPerc(percItem1, 'Percentual1'); }
    if (percItem2 > 0) { this.validPerc(percItem2, 'Percentual2'); }
    if (percItem3 > 0) { this.validPerc(percItem3, 'Percentual3'); }
    if (percItem4 > 0) { this.validPerc(percItem4, 'Percentual4'); }
    if (percItem5 > 0) { this.validPerc(percItem5, 'Percentual5'); }

    this.validBreed();

    percItem = (percItem1 + percItem2 + percItem3 + percItem4 + percItem5);

    if (percItem > 100) {
        this.msgTotal = 'Erro! Soma dos Percentuais acima de 100. ';
        if (!this.hasError) { this.hasError = true; }
        return false;
    } else {
        this.msgTotal = '';
        return true;
    }
  }

  validateComposition() {
    if (this.racialCompositionId.descricao !== null && this.racialCompositionId.descricao !== undefined) {

      this.validComposition = this.racialCompositionId;

      const description = this.racialCompositionId.descricao.toUpperCase();
      const array = new Array();
      const raca = description.split(', ');

      for (let i = 0; i < 4; i++) {
        if (raca[i] !== undefined) {
          array.push(raca[i]);
        }
      }

      let total = 0;
      let tem = false;

      for (const item of this.compositionListAll) {
          if ( item.cdcomposicaoracial !== this.racialCompositionId.cdcomposicaoracial
              && item.descricao.toUpperCase() === this.racialCompositionId.descricao.toUpperCase() ) {
                  this.validComposition = [];
                  this.message = 'Esta descrição já existe no cadastro.';
                  return false;
          }

          const desc = item.descricao.toUpperCase();
          const arrayLista = new Array();
          const racas = desc.split(', ');
          if (racas.length === raca.length) {
            for (let i = 0; i < 4; i++) {
              tem = item.descricao.toUpperCase().includes(array[i], i) ? true : false;
              if (tem) {
                total = total + 1;
              }

              if (total === raca.length) {
                if ( item.cdcomposicaoracial === this.racialCompositionId.cdcomposicaoracial) {
                      return true;
                  } else {
                    this.validComposition = [];
                    this.message = 'A composição racial informada já existe! Escolha outra.';
                    return false;
                  }
              }
            }
          }
      }
      return true;
    }
  }

  atualizaListagemComp(isInsert: boolean) {
    if (isInsert) {
      this.loading = true;
      this.matrizService.getConfiguracoesComposicaoRacial().subscribe((response) => {
        this.compositionListAll = response;
        this.compositionList = response;
        this.composicao = response;
        this.matrixId.cdcomposicaoracial =
          this.compositionListAll.sort((a, b) => b.cdcomposicaoracial - a.cdcomposicaoracial)[0].cdcomposicaoracial;
        this.loading = false;
      });
    } else {
      this.loading = true;
      this.matrizService.getConfiguracoesComposicaoRacial().subscribe((response) => {
        this.compositionListAll = response;
        this.compositionList = response;
        this.composicao = response;
        this.loading = false;
      });
    }
  }

  validPerc(value: number, field: string) {
    const num = value / 0.390625;
    const setField = field;

    // Parte inteira:
    const int = Math.trunc(num);

    // Parte decimal ('0' por padrão).
    const decimal = num - int;

    if (decimal > 0) {
        this.hasError = true;
        const indice = this.msgPercentual.indexOf(setField);
        if (indice === 0 && this.msgPercentual.length > 43) { this.msgPercentual = this.msgPercentual.substring(43); }
        if (indice === 0 && this.msgPercentual.length === 43) { this.msgPercentual = ''; }
        if (indice >= 43) { this.msgPercentual = this.msgPercentual.substring(indice - 1, 0)
            + this.msgPercentual.substring(this.msgPercentual.length, indice + 43); }
        this.msgPercentual += setField + ' está com valor lançado errado! ';

        return false;
    } else {
        const indice = this.msgPercentual.indexOf(setField);
        if (indice === 0 ) { this.msgPercentual = this.msgPercentual.substring(43); }
        if (indice >= 43) { this.msgPercentual = this.msgPercentual.substring(indice - 1, 0)
                    + this.msgPercentual.substring(this.msgPercentual.length, indice + 43); }
        if (this.msgPercentual === '') { this.hasError = false; }

        this.getDescription();

        return true;
    }
  }

  validBreed() {
    const erro = this.hasError;
    this.racas = [];

    if ( this.racialCompositionId.cdraca1 !== null && this.racialCompositionId.cdraca1 !== undefined ) {
        const tem = this.racas.includes(this.racialCompositionId.cdraca1);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            this.racas.push(this.racialCompositionId.cdraca1);
        }
    }

    if ( this.racialCompositionId.cdraca2 !== null && this.racialCompositionId.cdraca2 !== undefined ) {
        const tem = this.racas.includes(this.racialCompositionId.cdraca2);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            this.racas.push(this.racialCompositionId.cdraca2);
        }
    }

    if ( this.racialCompositionId.cdraca3 !== null && this.racialCompositionId.cdraca3 !== undefined ) {
        const tem = this.racas.includes(this.racialCompositionId.cdraca3);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            this.racas.push(this.racialCompositionId.cdraca3);
        }
    }

    if ( this.racialCompositionId.cdraca4 !== null && this.racialCompositionId.cdraca4 !== undefined ) {
        const tem = this.racas.includes(this.racialCompositionId.cdraca4);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            this.racas.push(this.racialCompositionId.cdraca4);
        }
    }

    if ( this.racialCompositionId.cdraca5 !== null && this.racialCompositionId.cdraca5 !== undefined ) {
        const tem = this.racas.includes(this.racialCompositionId.cdraca5);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            this.racas.push(this.racialCompositionId.cdraca5);
        }
    }

    this.msgRaca = '';
    if (!erro) { this.hasError = false; }
    return true;
  }

  public getDescription() {
    const sigla1 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca1);
    const sigla2 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca2);
    const sigla3 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca3);
    const sigla4 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca4);
    const sigla5 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca5);


    let total = 0;
    total += parseFloat(this.racialCompositionId.cdraca1) > 0 ? 1 : 0;
    total += parseFloat(this.racialCompositionId.cdraca2) > 0 ? 1 : 0;
    total += parseFloat(this.racialCompositionId.cdraca3) > 0 ? 1 : 0;
    total += parseFloat(this.racialCompositionId.cdraca4) > 0 ? 1 : 0;
    total += parseFloat(this.racialCompositionId.cdraca5) > 0 ? 1 : 0;

    const fracao1 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual1));
    const fracao2 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual2));
    const fracao3 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual3));
    const fracao4 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual4));
    const fracao5 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual5));

    let nome = '';

    if ( total === 1 ) {
        nome = sigla1.descricao;
    } else {
        if ( total >= 2 ) {
            nome = fracao1 +  ' ' + sigla1.sigla;
            nome = nome + ', ' + fracao2 + ' ' + sigla2.sigla;
            if ( total >= 3 ) {
                nome = nome + ', ' + fracao3 + ' ' + sigla3.sigla;
                if ( total >= 4 ) {
                    nome = nome + ', ' + fracao4 + ' ' + sigla4.sigla;
                    if ( total === 5 ) {
                        nome = nome + ', ' + fracao5 + ' ' + sigla5.sigla;
                    }
                }
            }
        }
    }
    this.racialCompositionId.descricao = nome;
  }

  // converte o valor percentual em fracao para incluir na descricao
  public fracaoRaca(APercentual: number) {
    let num: number;
    let den: number;

    den = 1;

    while (den <= 256) {
        num = (APercentual * den) / 100;
        if ( (num < den) && (Math.trunc(num) === num) ) {
            return num + '/' + den;
        }
        den = den * 2;
    }
  }

  public clearAllFields() {
    this.racialCompositionId.cdraca1 = null;
    this.racialCompositionId.cdraca2 = null;
    this.racialCompositionId.cdraca3 = null;
    this.racialCompositionId.cdraca4 = null;
    this.racialCompositionId.cdraca5 = null;

    this.racialCompositionId.percentual1 = null;
    this.racialCompositionId.percentual2 = null;
    this.racialCompositionId.percentual3 = null;
    this.racialCompositionId.percentual4 = null;
    this.racialCompositionId.percentual5 = null;

    this.racialCompositionId.descricao = '';

    this.clearMessages();

    this.hasChangesComp = false;
  }

  clearMessages() {
    this.msgTotal = '';
    this.msgPercentual = '';
    this.hasError = false;
  }

  validFill() {
    if ( this.tipo1.checked === true ) {
        if (this.racialCompositionId.cdraca1 !== null
            && this.racialCompositionId.cdraca1 !== ''
            && this.racialCompositionId.cdraca1 !== undefined) {
                if (this.racialCompositionId.cdraca2 !== null
                    && this.racialCompositionId.cdraca2 !== ''
                    && this.racialCompositionId.cdraca2 !== undefined) {
                        if (this.racialCompositionId.percentual1 !== null
                            && this.racialCompositionId.percentual1 !== ''
                            && this.racialCompositionId.percentual1 !== undefined) {
                                if (this.racialCompositionId.percentual2 !== null
                                    && this.racialCompositionId.percentual2 !== ''
                                    && this.racialCompositionId.percentual2 !== undefined) {
                                        return this.validFillOthers();
                                    } else {
                                        this.msgValidFill = 'Preencha o campo Percentual 2.';
                                        return false;
                                    }
                            } else {
                                this.msgValidFill = 'Preencha o campo Percentual 1.';
                                return false;
                            }
                    } else {
                        this.msgValidFill = 'Preencha o campo Raca 2.';
                        return false;
                    }
            } else {
                this.msgValidFill = 'Preecha o campo Raca 1.';
                return false;
            }
    } else {
        if ( this.tipo1.checked === false ) {
            if (this.racialCompositionId.cdraca1 !== null
                && this.racialCompositionId.cdraca1 !== ''
                && this.racialCompositionId.cdraca1 !== undefined) {
                    if (this.racialCompositionId.percentual1 !== null
                        && this.racialCompositionId.percentual1 !== ''
                        && this.racialCompositionId.percentual1 !== undefined) {
                            if (this.racialCompositionId.percentual1 === 100) {
                                return true;
                            } else {
                                this.msgValidFill = 'O percentual da Raça deve ser 100.';
                                return false;
                            }
                        } else {
                            this.msgValidFill = 'Preencha o campo percentual da Raça.';
                            return false;
                        }
                } else {
                    this.msgValidFill = 'Escolha uma raça e preencha o campo Percentual 1.';
                    return false;
                }
        } else { return false; }
    }
  }

  validFillOthers() {
    if ( this.tipo1.checked === true ) {
        if (this.racialCompositionId.cdraca3 !== null
            && this.racialCompositionId.cdraca3 !== ''
            && this.racialCompositionId.cdraca3 !== undefined) {
                if (this.racialCompositionId.percentual3 === null
                    || this.racialCompositionId.percentual3 === ''
                    || this.racialCompositionId.percentual3 === undefined) {
                        this.msgValidFill = 'O percentual da Raça 3 deve ser preenchido.';
                        return false;
                    }
            }
        if (this.racialCompositionId.cdraca4 !== null
            && this.racialCompositionId.cdraca4 !== ''
            && this.racialCompositionId.cdraca4 !== undefined) {
                if (this.racialCompositionId.percentual4 === null
                    || this.racialCompositionId.percentual4 === ''
                    || this.racialCompositionId.percentual4 === undefined) {
                        this.msgValidFill = 'O percentual da Raça 4 deve ser preenchido.';
                        return false;
                    }
            }

        if (this.racialCompositionId.cdraca5 !== null
            && this.racialCompositionId.cdraca5 !== ''
            && this.racialCompositionId.cdraca5 !== undefined) {
                if (this.racialCompositionId.percentual5 === null
                    || this.racialCompositionId.percentual5 === ''
                    || this.racialCompositionId.percentual5 === undefined) {
                        this.msgValidFill = 'O percentual da Raça 3 deve ser preenchido.';
                        return false;
                    }
            }

        if (this.racialCompositionId.percentual3 !== null
            && this.racialCompositionId.percentual3 !== ''
            && this.racialCompositionId.percentual3 !== undefined) {
                if (this.racialCompositionId.cdraca3 === null
                    || this.racialCompositionId.cdraca3 === ''
                    || this.racialCompositionId.cdraca3 === undefined) {
                        this.msgValidFill = 'O campo Raca 3 deve ser preenchido.';
                        return false;
                    }
            }

        if (this.racialCompositionId.percentual4 !== null
            && this.racialCompositionId.percentual4 !== ''
            && this.racialCompositionId.percentual4 !== undefined) {
                if (this.racialCompositionId.cdraca4 === null
                    || this.racialCompositionId.cdraca4 === ''
                    || this.racialCompositionId.cdraca4 === undefined) {
                        this.msgValidFill = 'O campo Raca 4 deve ser preenchido.';
                        return false;
                    }
            }

        if (this.racialCompositionId.percentual5 !== null
            && this.racialCompositionId.percentual5 !== ''
            && this.racialCompositionId.percentual5 !== undefined) {
                if (this.racialCompositionId.cdraca5 === null
                    || this.racialCompositionId.cdraca5 === ''
                    || this.racialCompositionId.cdraca5 === undefined) {
                        this.msgValidFill = 'O campo Raca 5 deve ser preenchido.';
                        return false;
                    }
            }

        // verifica totais percentuais
        let percItem1 = 0;
        let percItem2 = 0;
        let percItem3 = 0;
        let percItem4 = 0;
        let percItem5 = 0;
        let percItem = 0;

        percItem1 = parseFloat(this.racialCompositionId.percentual1 !== null
                    && this.racialCompositionId.percentual1 !== undefined
                    && this.racialCompositionId.percentual1 !== '' ? this.racialCompositionId.percentual1 : 0);
        percItem2 = parseFloat(this.racialCompositionId.percentual2 !== null
                    && this.racialCompositionId.percentual2 !== undefined
                    && this.racialCompositionId.percentual2 !== '' ? this.racialCompositionId.percentual2 : 0);
        percItem3 = parseFloat(this.racialCompositionId.percentual3 !== null
                    && this.racialCompositionId.percentual3 !== undefined
                    && this.racialCompositionId.percentual3 !== '' ? this.racialCompositionId.percentual3 : 0);
        percItem4 = parseFloat(this.racialCompositionId.percentual4 !== null
                    && this.racialCompositionId.percentual4 !== undefined
                    && this.racialCompositionId.percentual4 !== '' ? this.racialCompositionId.percentual4 : 0);
        percItem5 = parseFloat(this.racialCompositionId.percentual5 !== null
                    && this.racialCompositionId.percentual5 !== undefined
                    && this.racialCompositionId.percentual5 !== '' ? this.racialCompositionId.percentual5 : 0);

        percItem = (percItem1 + percItem2 + percItem3 + percItem4 + percItem5);

        if (percItem < 100) {
            this.msgValidFill = 'A soma dos percentuais não Totalizam 100.';
            return false;
        } else {
            return true;
        }
    }
  }

  validacaoTipos() {
    if (this.racialCompositionId.padrao === 0
        || this.racialCompositionId.padrao === undefined
        || this.racialCompositionId.padrao === null
       ) {

        // verifica se o tipo é PURA
        if ( this.tipo0 === undefined ) {
            this.validGeral();
        } else {
            if ( this.tipo0.checked === true ) {
                this.getValuesFields();
                this.clearFields();
                this.desabilita = true;
                this.clearMessages();
            } else {
                this.clearMessages();
                this.setValuesFields();
                this.desabilita = false;
            }
        }
    }
  }

  validGeral() {
    // valida percentuais - se o valor lançado como percentual é válido
    this.perc1 = this.racialCompositionId.percentual1;
    this.perc2 = this.racialCompositionId.percentual2;
    this.perc3 = this.racialCompositionId.percentual3;
    this.perc4 = this.racialCompositionId.percentual4;
    this.perc5 = this.racialCompositionId.percentual5;

    this.validPerc(parseFloat(this.perc1), 'Percentual1');
    this.validPerc(parseFloat(this.perc2), 'Percentual2');
    this.validPerc(parseFloat(this.perc3), 'Percentual3');
    this.validPerc(parseFloat(this.perc4), 'Percentual4');
    this.validPerc(parseFloat(this.perc5), 'Percentual5');

    // preenche o campo descrição
    this.getDescription();

    // verifica se total percentuais não ultrapassou 100%
    this.validTotalPerc();
  }

  getValuesFields() {
    this.desc = this.racialCompositionId.descricao;

    this.rac1 = this.racialCompositionId.cdraca1;
    this.rac2 = this.racialCompositionId.cdraca2;
    this.rac3 = this.racialCompositionId.cdraca3;
    this.rac4 = this.racialCompositionId.cdraca4;
    this.rac5 = this.racialCompositionId.cdraca5;

    this.perc1 = this.racialCompositionId.percentual1;
    this.perc2 = this.racialCompositionId.percentual2;
    this.perc3 = this.racialCompositionId.percentual3;
    this.perc4 = this.racialCompositionId.percentual4;
    this.perc5 = this.racialCompositionId.percentual5;
  }

  setValuesFields() {
    this.racialCompositionId.descricao   = this.desc;
    this.racialCompositionId.cdraca1 = this.rac1 !== '' ? this.rac1 : '';
    this.racialCompositionId.cdraca2 = this.rac2 !== '' ? this.rac2 : '';
    this.racialCompositionId.cdraca3 = this.rac3 !== '' ? this.rac3 : '';
    this.racialCompositionId.cdraca4 = this.rac4 !== '' ? this.rac4 : '';
    this.racialCompositionId.cdraca5 = this.rac5 !== '' ? this.rac5 : '';

    if (this.perc1 !== '') {
        this.racialCompositionId.percentual1 = this.perc1;
        this.validPerc(parseFloat(this.perc1), 'Percentual1');
    }
    if (this.perc2 !== '') {
        this.racialCompositionId.percentual2 = this.perc2;
        this.validPerc(parseFloat(this.perc2), 'Percentual2');
    }
    if (this.perc3 !== '') {
        this.racialCompositionId.percentual3 = this.perc3;
        this.validPerc(parseFloat(this.perc3), 'Percentual3');
    }
    if (this.perc4 !== '') {
        this.racialCompositionId.percentual4 = this.perc4;
        this.validPerc(parseFloat(this.perc4), 'Percentual4');
    }
    if (this.perc5 !== '') {
        this.racialCompositionId.percentual5 = this.perc5;
        this.validPerc(parseFloat(this.perc5), 'Percentual5');
    }
  }

  clearFields() {
    this.racialCompositionId.cdraca2 = null;
    this.racialCompositionId.cdraca3 = null;
    this.racialCompositionId.cdraca4 = null;
    this.racialCompositionId.cdraca5 = null;
    this.racialCompositionId.percentual1 = null;
    this.racialCompositionId.percentual2 = null;
    this.racialCompositionId.percentual3 = null;
    this.racialCompositionId.percentual4 = null;
    this.racialCompositionId.percentual5 = null;
    this.racialCompositionId.descricao = '';
    this.getDescription();
  }

  openNewLote() {
    this.lotId = new Lote();
    this.editLote = true;
    this.edited = true;
  }

  closeLote() {
    this.editLote = false;
    this.edited = false;
  }

  saveLot() {
    this.hasChangesLot = false;
    this.editLote = false;
    this.edited = false;
    this.saveLotAll();
  }

  saveLotAll() {
    this.validateLot();

    this.lotId.cdfazenda = this.lotId.cdfazenda !== null && this.lotId.cdfazenda !== undefined
                            ? this.lotId.cdfazenda : this.matrizService.getCdFazenda();

    if (this.message !== '' ) {
      this.showSnackBar(this.message);
      this.atualizaListagemLot(false);
      this.message = '';
    } else {
      this.matrizService.insertLot(this.lotId).subscribe((response) => {
        this.atualizaListagemLot(true);
        this.hasChanges = true;
        this.showSnackBar('Registros armazenados com sucesso.');
      }, (error) => this.showSnackBar(error));
    }
  }

  validateLot() {
    if (this.lotId.descricao !== null && this.lotId.descricao !== undefined) {
      this.validLot = this.lotId;
      for (const item of this.lotListAll) {
        if ( item.cdlote !== this.lotId.cdlote
            && item.descricao.toUpperCase() === this.lotId.descricao.toUpperCase() ) {
                this.validLot = [];
                this.message = 'Esta descrição já existe no cadastro.';
        }
      }
    }
  }

  atualizaListagemLot(isInsert: boolean) {
    if (isInsert) {
      this.loading = true;
      this.matrizService.getLot().subscribe((response) => {
        this.lotListAll = response;
        this.matrixId.cdlote = this.lotListAll.sort((a, b) => b.cdlote - a.cdlote)[0].cdlote;
        this.loading = false;
      });
    } else {
      this.loading = true;
      this.matrizService.getLot().subscribe((response) => {
        this.lotListAll = response;
        this.matrixId.cdlote = this.lotListAll.sort((a, b) => b.cdlote - a.cdlote)[0].cdlote;
        this.loading = false;
      });
    }
  }

  hasValueLot(statusValueLot) {
    if ( statusValueLot.trim() !== '' && statusValueLot !== undefined ) {
        this.hasChangesLot = true;
    } else {
        this.hasChangesLot = false;
    }
  }

  hasValuePai(statusValuePai) {
    if ( statusValuePai.trim() !== '' && statusValuePai !== undefined ) {
        this.hasChangesPai = true;
    } else {
        this.hasChangesPai = false;
    }
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'X', this.snackBarConfig);
  }

  save() {
    const hasValidMatrix =
      this.validMatrix.cdmatriz !== null
      && this.validMatrix.cdmatriz !== undefined
      && this.validMatrix.cdmatriz !== 0;

    if (hasValidMatrix) {
      this.matrizService.atualizaMatriz(this.matrixId).subscribe((response: Array<Matriz>) => {
        this.motherId.emit(this.matrixId.cdmatriz);
        this.showSnackBar('Alterações salvas com sucesso.');
      }, (error) => this.showSnackBar(error));
    } else {
      this.matrizService.insereMatriz(this.matrixId).subscribe((response) => {
        this.getAllMatrix(true);
        this.showSnackBar('Alterações salvas com sucesso.');
      }, (error) => this.showSnackBar(error));
    }
  }

  validateMatrix(): boolean {
    let isValid = true;
    const hasMatrixNumber = this.matrixId.numero !== null && this.matrixId.numero !== undefined;
    if (hasMatrixNumber) {
        this.validMatrix = this.matrixId;
    } else {
      isValid = false;
    }
    return isValid;
  }

  close() {
    this.editMother = false;
    this.edited = false;
    this.hasChanges = false;
    this.closed.emit(true);
  }

  saveMother() {
    if (this.validateMatrix()) {
      this.save();
    }
  }

}
