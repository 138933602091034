import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Protocolo } from 'src/models/configuracoes/protocolo.model';

@Component({
    selector: 'app-protocolo-cadastro',
    templateUrl: './protocolo-cadastro.component.html',
    styleUrls: ['./protocolo-cadastro.component.scss'],
  })

export class ProtocoloCadastroComponent implements OnInit {

    validProtocol: any;
    protocolListAll: Array<Protocolo>;
    message: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ProtocoloCadastroComponent>) { }

    public hasChanges = false;

    ngOnInit() { this.protocolListAll = this.data.protocolList; }

    cancel() {
        this.dialogRef.close({ data: 'cancelar' });
    }

    confirm() {
        if (this.isValid()) {
            this.dialogRef.close({ lotId: this.data.protocolId });
        }
    }

    hasValue(statusValue) {
        if ( statusValue.trim() !== '' && statusValue !== undefined ) {
            this.hasChanges = true;
        } else {
            this.hasChanges = false;
        }
    }

    isValid(): boolean {
        this.message = ``;
        this.validProtocol = this.data.protocolId;

        const hasDescription = this.data.protocolId.descricao !== null
            && this.data.protocolId.descricao !== undefined;

        if (!this.validateImplantDate()) { return false; }
        if (!this.validateIatfDate()) { return false; }

        if (hasDescription) {
            const existingProtocol =
                this.protocolListAll.find(protocol =>
                        protocol.descricao.toUpperCase() === this.data.protocolId.descricao.toUpperCase());
            const existsProtocol =
                existingProtocol !== undefined && existingProtocol.cdprotocolo > 0
                    && existingProtocol.cdprotocolo !== this.data.protocolId.cdprotocolo;
            if (existsProtocol) {
                this.validProtocol = [];
                this.message = 'Esta descrição de protocolo já está cadastrada.';
                return false;
            }
        } else {
            this.message = 'Informe a descrição do protocolo';
            return false;
        }
        return true;
    }

    validateImplantDate(): boolean {
        const hasImplantDays =
            this.validProtocol.implante !== undefined && this.validProtocol.implante !== null;
        if (!hasImplantDays) {
            this.message = 'Informe a quantidade de dias do implante';
            return false;
        }
        return true;
    }

    validateIatfDate(): boolean {
        const hasIatfDays =
            this.validProtocol.iatf !== undefined && this.validProtocol.iatf !== null;
        if (!hasIatfDays) {
            this.message = 'Informe a quantidade de dias da iatf';
            return false;
        }
        return true;
    }
}
