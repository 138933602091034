import { GrupoFazenda } from './cadastro/grupo-fazenda.model';
import { Municipio } from './municipio.model';

export class Fazenda {
    public cdfazenda: number;
    public nome: string;
    public cdmunicipio: number;
    public telefone: string;
    public codigo: number;
    public cdperfilracialrebanho: number;
    public cdsistemaproducao: number;
    public proprietario: string;
    public municipio: Municipio;
    public grupoFazenda: GrupoFazenda[];
    public cidade: string;
    public cdpessoa: number;
    public cdgrupo: number;
    public cdpais: number;

    // utilização temporária para testes
    public cdmesorregiao: number;
    public cduf: number;
}
