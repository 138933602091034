export class OcorrenciaDeReproducao {
    public cdOcorrencia: number;
    public cdMatriz: number;
    public cdFazenda: number;
    public numeroMatriz: string;
    public nomeMatriz: string;
    public CdReproducao: number;
    public dataReproducao: Date;
    public tipoTentativa: string;
    public cdReprodutor: number;
    public numeroReprodutor: string;
    public nomeReprodutor: string;
    public dataDiagnostico1: Date;
    public diagnostico1: string;
    public dataDiagnostico2: Date;
    public diagnostico2: string;
    public dataOcorrencia: Date;
    public descricao: string;
    public isValid = true;
    public isRemoved = false;
    public descricaoCompl: string;
}
