import { Reproducao } from 'src/models/matriz/reproducao.models';
import { Matriz } from 'src/models/matriz/matriz.model';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { GridComponent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { AltaGestaoService } from 'src/app/_services/alta-gestao.service';
import { ReproducaoService } from 'src/app/_services/reproducao.service';
import { HistoricoReproducaoFiltro } from 'src/models/reproducao/historico-reproducao-filtro.model';
import { HistoricoReproducao } from 'src/models/reproducao/historico-reproducao.model';
import { OcorrenciaReproducaoCadastroComponent } from '../ocorrencia-reproducao-cadastro/ocorrencia-reproducao-cadastro.component';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';

@Component({
  selector: 'app-historico-reproducao-cadastro',
  templateUrl: './historico-reproducao-cadastro.component.html',
  styleUrls: ['./historico-reproducao-cadastro.component.scss']
})
export class HistoricoReproducaoCadastroComponent implements OnInit {

  @ViewChild('eventsGrid', { static: false }) grid: GridComponent;

  hasChanges = false;
  loading = false;
  isValid = true;

  itemsToUpdate: HistoricoReproducao[] = [];
  itemsToInsert: HistoricoReproducao[] = [];
  removedItems: HistoricoReproducao[] = [];
  itemsSource: HistoricoReproducao[] = [];
  invalidItems: HistoricoReproducao[] = [];

  formGroup: FormGroup;
  previousEdit: any;
  editedRowIndex: number;
  itemToRemove: HistoricoReproducao;
  matrix: Matriz;
  reproduction: Reproducao;

  gridView: GridDataResult;
  itemsSort: SortDescriptor[] = [{ field: 'data', dir: 'asc' }];
  gridSelection: any[] = [];
  addIcon = faPlus;
  editIcon = faEdit;
  rowIndex: number;

  selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private reproducaoService: ReproducaoService,
    private dialogRef: MatDialogRef<OcorrenciaReproducaoCadastroComponent>,
    private altaGestaoService: AltaGestaoService) {
    this.matrix = data.matrix;
    this.reproduction = data.reproduction;
  }

  ngOnInit() {
    this.invalidItems = new Array<HistoricoReproducao>();
    this.getHistory();
  }

  onCellClick({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    this.editHandler({ sender, rowIndex, columnIndex, dataItem, isEdited });
  }

  validateItem(context: RowClassArgs) {
    const data: Date = new Date(context.dataItem.data);

    context.dataItem.isValid = true;

    const hasDateAndDescription =
      data !== undefined
      && context.dataItem.descricao !== undefined
      && context.dataItem.descricao !== '';

    const reproductionDate = new Date(context.dataItem.dataReproducao);
    const hasValidDate = data > reproductionDate && data < new Date();

    if (!hasDateAndDescription || !hasValidDate) {
      context.dataItem.isValid = false;
    }

    return {
      invalid: !context.dataItem.isValid
    };
  }

  createFormGroup(dataItem: HistoricoReproducao) {
    this.formGroup = new FormGroup({
      data: new FormControl(new Date(dataItem.data), Validators.required),
      descricao: new FormControl(dataItem.descricao, Validators.required)
    },
    {
      updateOn: 'blur',
    });

    this.formGroup.valueChanges.subscribe(value => this.updateObjectHandler(dataItem));
  }

  updateObjectHandler(dataItem: HistoricoReproducao) {
    dataItem.descricao = this.formGroup.controls[`descricao`].value;
    dataItem.data = this.formGroup.controls[`data`].value;

    const itemsToUpdate = this.itemsToUpdate.filter(cond => cond === dataItem);
    if (itemsToUpdate.length === 0) {
      this.itemsToUpdate.push(dataItem);
    }

    this.hasChanges = true;
  }

  closeRow({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  onRemoveClick({ dataItem }) {
    this.itemToRemove = dataItem;
    this.itemToRemove.isRemoved = true;
    this.removeHandler();
  }

  removeHandler() {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: `Deseja realmente remover o registro
          ${ new Date (this.itemToRemove.data).toLocaleDateString() } 
            - ${ this.itemToRemove.descricao ?  this.itemToRemove.descricao : `Não informado`} ?`,
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.removeItem(dialogRef);
      } else {
        this.itemToRemove.isRemoved = false;
      }
    });
  }

  removeItem(dialogRef) {
    const hasCdHistoricoReproducao =
      this.itemToRemove.cdHistoricoReproducao !== undefined && this.itemToRemove.cdHistoricoReproducao > 0;

    if (hasCdHistoricoReproducao) {
      this.itemsSource = this.itemsSource.filter(hm => hm.cdHistoricoReproducao !== this.itemToRemove.cdHistoricoReproducao);
      this.itemsToUpdate = this.itemsToUpdate.filter(hm => hm.cdHistoricoReproducao !== this.itemToRemove.cdHistoricoReproducao);

      this.reproducaoService
        .removeHistoricosDeReproducao([this.itemToRemove.cdHistoricoReproducao]).subscribe((response: Array<HistoricoReproducao>) => {
        this.snackBar.open('Registro removido com sucesso.', 'X', {
          duration: 2500,
        });
      });
    } else {
      this.itemsSource = this.itemsSource.filter(hm => hm.isRemoved === false || hm.isRemoved === undefined);
      this.itemsToUpdate = this.itemsToUpdate.filter(hm => hm.isRemoved === false || hm.isRemoved === undefined);

      if (this.itemsToUpdate.length === 0) {
        this.hasChanges = false;
      }
    }

    dialogRef.close();
  }

  saveClick() {
    this.saveHandler(false);
  }

  getHistory() {
    this.loading = true;
    const filter = this.getFilter();
    this.reproducaoService.getHistoricosDeReproducao(filter).subscribe(response => {
      this.itemsSource = response;
      this.itemsSource.forEach((item) => {
        item.dataReproducao = this.reproduction.data;
      });
      this.loading = false;
    });
  }

  getFilter(): HistoricoReproducaoFiltro {
    const filter = new HistoricoReproducaoFiltro();
    filter.cdMatriz = this.matrix.cdmatriz;
    filter.cdReproducao = this.reproduction.cdreproducao;
    return filter;
  }

  saveHandler(closeDialog: boolean) {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja salvar as alterações?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const item = this.itemsToUpdate.find(cond => cond.isValid === false);
        const hasInvalidItem = item !== null && item !== undefined;
        if (hasInvalidItem) {
          this.dialog.open(DialogoAlertaComponent, {
            data: {
              message: `Não foi possível salvar. Existem registros inválidos`,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          this.saveChanges(dialogRef, closeDialog);
        }
      } else if (closeDialog) {
        this.closeHandler();
      }
    });
  }

  saveChanges(dialogRef, closeDialog: boolean) {
    this.itemsToInsert = this.itemsToUpdate.filter(item => item.cdHistoricoReproducao === 0 || item.cdHistoricoReproducao === undefined);
    this.itemsToUpdate = this.itemsToUpdate.filter(item => item.cdHistoricoReproducao > 0);

    if (this.itemsToInsert.length > 0) {
      this.insert(closeDialog);
    }
    if (this.itemsToUpdate.length > 0) {
      this.update(closeDialog);
    }

    this.hasChanges = false;

    dialogRef.close();
  }

  insert(closeDialog: boolean) {
    this.reproducaoService.insereHistoricosDeReproducao(this.itemsToInsert).subscribe((response: Array<HistoricoReproducao>) => {
      if (response != null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'X', {
          duration: 2500,
        });
        this.itemsToInsert = [];
        this.getHistory();
        if (closeDialog) {
          this.closeHandler();
        }
      }
    });
  }

  update(closeDialog: boolean) {
    this.reproducaoService.atualizaHistoricosDeReproducao(this.itemsToUpdate).subscribe((response: Array<HistoricoReproducao>) => {
      if (response != null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'X', {
          duration: 2500,
        });
        this.itemsToUpdate = [];
        this.getHistory();
        if (closeDialog) {
          this.closeHandler();
        }
      }
    });
  }

  closeClick() {
    if (this.hasChanges === true) {
      this.saveHandler(true);
    } else {
      this.closeHandler();
    }
  }

  closeHandler() {
    this.dialogRef.close();
  }

  addClick() {
    this.addHandler();
  }

  addHandler() {
    const newItem: HistoricoReproducao = new HistoricoReproducao();
    newItem.cdMatriz = this.matrix.cdmatriz;
    newItem.cdReproducao = this.reproduction.cdreproducao;
    newItem.dataReproducao = this.reproduction.data;
    newItem.data = new Date();
    newItem.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();

    this.itemsSource.push(newItem);
    this.itemsToUpdate.push(newItem);

    this.editHandler({ sender: this.grid, rowIndex: this.itemsSource.length - 1, columnIndex: 0, dataItem: newItem, isEdited: false });

    this.hasChanges = true;
  }

  editHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (this.previousEdit != null) {
      this.closeRow(this.previousEdit);
    }

    this.createFormGroup(dataItem);
    sender.editCell(rowIndex, columnIndex, this.formGroup);
    this.previousEdit = { dataItem, sender, rowIndex };
  }

}
