import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Fazenda } from 'src/models/fazenda.model';
import { Pessoa } from 'src/models/pessoa.model';

@Injectable({
  providedIn: 'root'
})
export class AltaGestaoService {

  private numeroMatriz = new BehaviorSubject('');
  numeroMatrizSelecionado = this.numeroMatriz.asObservable();

  constructor() { }

  alteraNumeroMatriz(numeroMatriz: string) {
    this.numeroMatriz.next(numeroMatriz);
  }

  getCdFazendaSelecionada(): number {
    const fazendaSelecionada = JSON.parse(sessionStorage.getItem(`currentFarm`));
    const existeFazenda = fazendaSelecionada !== undefined && fazendaSelecionada !== null && fazendaSelecionada.cdfazenda > 0;
    if (existeFazenda) {
      const cdFazendaSelecionada = Number.parseInt(fazendaSelecionada.cdfazenda);
      if (cdFazendaSelecionada > 0) {
       return cdFazendaSelecionada;
      }
      return 0;
    }
    return 0;
  }

  getFazendaSelecionada(): Fazenda {
    const fazendaSelecionada = JSON.parse(sessionStorage.getItem(`currentFarm`));
    return fazendaSelecionada;
  }

  getUsuarioLogado(): Pessoa {
    const usuarioLogado = JSON.parse(sessionStorage.getItem(`currentUser`));
    return usuarioLogado;
  }

  ehUsuarioAdministrador(): boolean {
    let ehAdministrador = false;
    const usuarioLogado = JSON.parse(sessionStorage.getItem(`currentUser`));
    const existeUsuario =
        usuarioLogado !== undefined
        && usuarioLogado !== null
        && usuarioLogado.usuario !== undefined
        && usuarioLogado.usuario !== null;
    if (existeUsuario) {
      ehAdministrador = usuarioLogado.usuario.isAdmin;
      return ehAdministrador;
    }
    return ehAdministrador;
  }

  ehUsuarioAdministradorTecnico(): boolean {
    let ehAdministradorTecnico = false;
    const usuarioLogado = JSON.parse(sessionStorage.getItem(`currentUser`));
    const existeUsuario =
        usuarioLogado !== undefined
        && usuarioLogado !== null
        && usuarioLogado.usuario !== undefined
        && usuarioLogado.usuario !== null;
    if (existeUsuario) {
      ehAdministradorTecnico = usuarioLogado.usuario.isAdminTecnico;
      return ehAdministradorTecnico;
    }
    return ehAdministradorTecnico;
  }

  calculaDiferencaEntreDatas(dataInicial: Date, dataFinal: Date): number {
    return Math.floor
    (
      (
        Date.UTC
        (
          dataFinal.getFullYear(),
          dataFinal.getMonth(),
          dataFinal.getDate()
        )
        -
        Date.UTC
        (
          dataInicial.getFullYear(),
          dataInicial.getMonth(),
          dataInicial.getDate()
        )
      ) / (1000 * 60 * 60 * 24)
    );
  }
}
