import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ConfiguracoesService } from 'src/app/_services/configuracoes.service';
import { Inseminador } from 'src/models/configuracoes/inseminador.model';

@Component({
    selector: 'app-inseminador-cadastro',
    templateUrl: './inseminador-cadastro.component.html',
    styleUrls: ['./inseminador-cadastro.component.scss'],
  })

export class InseminadorCadastroComponent implements OnInit {

    validInseminator: any;
    message = '';
    hasChanges = false;

    inseminatorListAll: Array<Inseminador>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<InseminadorCadastroComponent>,
        private inseminatorService: ConfiguracoesService) { }

    ngOnInit() {
        this.inseminatorListAll = [];
        this.getAllInseminator();
    }

    getAllInseminator() {
        this.inseminatorService.getInseminadores().subscribe((response) => {
            this.inseminatorListAll = response;
        });
    }

    cancel() {
        this.dialogRef.close({ data: 'cancelar' });
    }

    confirm() {
        if (this.validateInseminator()) {
            this.save();
            this.dialogRef.close({ inseminatorId: this.data.inseminatorId });
        }
    }

    hasValue(statusValue) {
        if ( statusValue.trim() !== '' && statusValue !== undefined ) {
            this.hasChanges = true;
        } else {
            this.hasChanges = false;
        }
    }

    save() {
        const hasCdFarm =
            this.data.inseminatorId.cdfazenda !== null
            && this.data.inseminatorId.cdfazenda !== undefined;

        const hasValidInseminator =
            this.validInseminator.cdinseminador !== null
            && this.validInseminator.cdinseminador !== undefined
             && this.validInseminator.cdinseminador !== 0;

        this.data.inseminatorId.cdfazenda = hasCdFarm ? this.data.inseminatorId.cdfazenda : this.inseminatorService.getCdFazenda();

        if (hasValidInseminator) {
            this.inseminatorService.atualizaInseminador(this.data.inseminatorId).subscribe((response: Array<Inseminador>) => {
                if (response != null) {
                    // this.data.inseminators = response;
                }
            });
        } else {
            if (this.message !== '') {
                this.message = '';
            } else {
                this.inseminatorService.insereInseminador(this.data.inseminatorId).subscribe((response) => {
                    // this.data.inseminators = response;
                });
            }
        }
    }

    validateInseminator(): boolean {
        let isValid = true;

        const hasIseminatorName =
            this.data.inseminatorId.nome !== undefined
            && this.data.inseminatorId.nome !== null
            && this.data.inseminatorId.nome !== ``;

        if (hasIseminatorName) {
            this.validInseminator = this.data.inseminatorId;
            for (const item of this.inseminatorListAll) {
                if (item.cdinseminador !== this.data.inseminatorId.cdinseminador
                    && item.nome.toUpperCase() === this.data.inseminatorId.nome.toUpperCase() ) {
                        this.validInseminator = [];
                        isValid = false;
                        this.message = 'Nome de inseminador já cadastrado.';
                }
            }
        }

        return isValid;
    }
}
