import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { AltaGestaoService } from 'src/app/_services/alta-gestao.service';
import { EmailService } from 'src/app/_services/email.service';
import { FazendaService } from 'src/app/_services/fazenda.service';
import { Email } from 'src/models/email.model';
import { Fazenda } from 'src/models/fazenda.model';

@Component({
  selector: 'app-dialog-limpar-fazenda',
  templateUrl: './dialog-limpar-fazenda.component.html',
  styleUrls: ['./dialog-limpar-fazenda.component.scss']
})
export class DialogLimparFazendaComponent implements OnInit {

  public itemsSource: Fazenda;
  public email: Email = new Email;
  codigo: string;
  codigoEmail: string;
  btnClearDisabled: boolean = false;
  btnSendDisabled: boolean = false;
  private usuarioLogado: any;
  isCurrentAdminUser = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public fazendaService: FazendaService,
              public emailService: EmailService,
              public dialogRef: MatDialogRef<DialogLimparFazendaComponent>,
              private altaGestaService: AltaGestaoService
              ) {

    this.itemsSource = data.farm;
    this.isCurrentAdminUser = this.altaGestaService.ehUsuarioAdministrador();
    this.usuarioLogado = JSON.parse(sessionStorage.getItem(`currentUser`));
    if (this.usuarioLogado && this.usuarioLogado.cdpessoa) {
      this.itemsSource.cdpessoa = this.usuarioLogado.cdpessoa;
    }

  }

  ngOnInit() {
  }

  closeClick() {
    this.dialogRef.close();
  }

  clearClick() {
    if (this.codigo != undefined && this.codigo.trim().length > 0) {
      if (this.codigo.trim() == this.codigoEmail) {
        this.btnClearDisabled = true;
        this.fazendaService.limpaFazenda(this.itemsSource).subscribe(response => {
          this.closeClick();
          this.snackBar.open('Limpeza da fazenda foi realizado com sucesso!', 'Fechar', {
            duration: 2000,
          });
        }, error => {
          this.snackBar.open('Erro inesperado.', 'Fechar', {
            duration: 2000,
          });
        })
      } else {
        this.snackBar.open('Código inválido!', 'Fechar', {
          duration: 2000,
        });
      }
    } else {
      this.snackBar.open('Insira o código de confirmação!', 'Fechar', {
        duration: 2000,
      });
    }
  }

  enviarCodigoConfirmacaoEmail() {
    this.btnSendDisabled = true;
    this.email.email = this.usuarioLogado.email;
    this.email.codigo = this.codigoEmail = this.generateRandomNumber();
    this.email.cdfazenda = this.itemsSource.cdfazenda;
    this.email.fazenda = this.itemsSource.nome;
    this.email.usuario = this.usuarioLogado.usuario.apelido;
    this.email.admin = this.isCurrentAdminUser;
    this.emailService.enviaEmailConfirmacaoLimpezaFazenda(this.email).subscribe(response => {
      this.snackBar.open('Código enviado para o e-mail.', 'Fechar', {
        duration: 4000,
      });
    })
  }

  generateRandomNumber(): string {
    const min = 1000;
    const max = 9999;

    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNumber.toString();
  }

}
