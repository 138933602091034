import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent, MAT_DATE_FORMATS } from '@angular/material';
import { ReproducaoService } from 'src/app/_services/reproducao.service';
import { Protocolo } from 'src/models/protocolo.model';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';

@Component({
  selector: 'app-iatf-cadastro',
  templateUrl: './iatf-cadastro.component.html',
  styleUrls: ['./iatf-cadastro.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class IatfCadastroComponent implements OnInit {

  protocolsList: Array<Protocolo>;

  implantDate: Date;
  luteolise1Date: Date;
  luteolise2Date: Date;
  edfDate: Date;
  stimulusDate: Date;
  removalDate: Date;
  iatfDate: Date;

  applyErrorValidation = '';

  loading = false;
  isScheduleIatf = false;

  constructor(
    public dialogRef: MatDialogRef<IatfCadastroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reproducaoService: ReproducaoService) {
      this.isScheduleIatf = data.isScheduleIatf ? data.isScheduleIatf : false;
    }

  ngOnInit() {
    this.protocolsList = [];
    this.getProtocols();
    this.setIatfValues();
    this.updateIatfEvents();
  }

  getProtocols() {
    this.loading = true;
    this.reproducaoService.getProtocolosIatf().subscribe((response) => {
      this.protocolsList = response;
      this.loading = false;
    });
  }

  setIatfValues() {
    this.setImplantDate();
    this.setLuteolise1Date();
    this.setLuteolise2Date();
    this.setEdfDate();
    this.setDtEstimulo();
    this.setRemovalDate();
    this.setIatfDate();
  }

  setImplantDate() {
    const hasImplantDate = this.data.iatf.dtImplante !== undefined && this.data.iatf.dtImplante !== null;
    this.implantDate = !hasImplantDate ? new Date() : this.data.iatf.dtImplante;
  }

  setLuteolise1Date() {
    const hasLuteolise1Date = this.data.iatf.dtLuteolise1 !== undefined && this.data.iatf.dtLuteolise1 !== null;
    this.luteolise1Date = !hasLuteolise1Date ? new Date() : this.data.iatf.dtLuteolise1;
  }

  setLuteolise2Date() {
    const hasLuteolise2Date = this.data.iatf.dtLuteolise2 !== undefined && this.data.iatf.dtLuteolise2 !== null;
    this.luteolise2Date = !hasLuteolise2Date ? new Date() : this.data.iatf.dtLuteolise2;
  }

  setEdfDate() {
    const hasEdfDate = this.data.iatf.dtEdf !== undefined && this.data.iatf.dtEdf !== null;
    this.edfDate = !hasEdfDate ? new Date() : this.data.iatf.dtEdf;
  }

  setDtEstimulo() {
    const hasDtEstimulo = this.data.iatf.dtEstimulo !== undefined && this.data.iatf.dtEstimulo !== null;
    this.stimulusDate = !hasDtEstimulo ? new Date() : this.data.iatf.dtEstimulo;
  }

  setRemovalDate() {
    const hasRemovalDate = this.data.iatf.dtRetirada !== undefined && this.data.iatf.dtRetirada !== null;
    this.removalDate = !hasRemovalDate ? new Date() : this.data.iatf.dtRetirada;
  }

  setIatfDate() {
    const hasIatfDate = this.data.iatf.dtIatf !== undefined && this.data.iatf.dtIatf !== null;
    this.iatfDate = !hasIatfDate ? new Date() : this.data.iatf.dtIatf;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.calculateIatfEventDates(event);
  }

  calculateIatfEventDates(implantDate: any) {
    const hasCdProtocol = this.data.iatf.cdProtocolo !== undefined && this.data.iatf.cdProtocolo > 0;

    if (hasCdProtocol) {
      const protocol = this.protocolsList.find(p => p.cdprotocolo === this.data.iatf.cdProtocolo);
      const hasProtocol = protocol !== undefined && protocol.cdprotocolo > 0;

      if (hasProtocol) {
        this.data.iatf.dtImplante = new Date(implantDate);
        this.calculateLuteolise1EventDate(protocol);
        this.calculateLuteolise2EventDate(protocol);
        this.calculateEdfEventDate(protocol);
        this.calculateStimulusEventDate(protocol);
        this.calculateRemovalEventDate(protocol);
        this.calculateIatfEventDate(protocol);
      }
    }
  }

  calculateLuteolise1EventDate(protocol: any) {
    const hasLuteolise1 = protocol.luteolise1 !== undefined && protocol.luteolise1 !== null;
    if (hasLuteolise1) {
      this.data.iatf.dtLuteolise1 = new Date(this.data.iatf.dtImplante);
      this.data.iatf.dtLuteolise1.setDate(this.data.iatf.dtImplante.getDate() + protocol.luteolise1);
      this.luteolise1Date = this.data.iatf.dtLuteolise1;
    }
  }

  calculateLuteolise2EventDate(protocol: any) {
    const hasLuteolise2 = protocol.luteolise2 !== undefined && protocol.luteolise2 !== null;
    if (hasLuteolise2) {
      this.data.iatf.dtLuteolise2 = new Date(this.data.iatf.dtImplante);
      this.data.iatf.dtLuteolise2.setDate(this.data.iatf.dtImplante.getDate() + protocol.luteolise2);
      this.luteolise2Date = this.data.iatf.dtLuteolise2;
    }
  }

  calculateEdfEventDate(protocol: any) {
    const hasEdf = protocol.edf !== undefined && protocol.edf !== null;
    if (hasEdf) {
      this.data.iatf.dtEdf = new Date(this.data.iatf.dtImplante);
      this.data.iatf.dtEdf.setDate(this.data.iatf.dtImplante.getDate() + protocol.edf);
      this.edfDate = this.data.iatf.dtEdf;
    }
  }

  calculateStimulusEventDate(protocol: any) {
    const hasStimulus = protocol.estimulo !== undefined && protocol.estimulo !== null;
    if (hasStimulus) {
      this.data.iatf.dtEstimulo = new Date(this.data.iatf.dtImplante);
      this.data.iatf.dtEstimulo.setDate(this.data.iatf.dtImplante.getDate() + protocol.estimulo);
      this.stimulusDate = this.data.iatf.dtEstimulo;
    }
  }

  calculateRemovalEventDate(protocol: any) {
    const hasRemoval = protocol.retirada !== undefined && protocol.retirada !== null;
    if (hasRemoval) {
      this.data.iatf.dtRetirada = new Date(this.data.iatf.dtImplante);
      this.data.iatf.dtRetirada.setDate(this.data.iatf.dtImplante.getDate() + protocol.retirada);
      this.removalDate = this.data.iatf.dtRetirada;
    }
  }

  calculateIatfEventDate(protocol: any) {
    const hasRemoval = protocol.iatf !== undefined && protocol.iatf !== null;
    if (hasRemoval) {
      this.data.iatf.dtIatf = new Date(this.data.iatf.dtImplante);
      this.data.iatf.dtIatf.setDate(this.data.iatf.dtImplante.getDate() + protocol.iatf);
      this.iatfDate = this.data.iatf.dtIatf;
    }
  }

  applyIatfEvents() {
    if (this.data.iatf.cdProtocolo === undefined && this.data.iatf.cdProtocolo === 0) {
      this.applyErrorValidation = 'Informe um protocolo para aplicar';
    } else {
      this.applyErrorValidation = '';
    }
  }

  updateIatfEvents() {
    this.calculateIatfEventDates(new Date(this.implantDate));
  }
}
