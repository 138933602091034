import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './_services/auth-guard.service';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', canActivate: [AuthGuardService], runGuardsAndResolvers: 'always',
    children: [
      { path: '', redirectTo: 'inicio', pathMatch: 'full' },
      { path: 'inicio', component: HomeComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'agenda' },
          { path: 'agenda',               loadChildren: './schedule/_agenda-module/agenda.module#AgendaModule' },
          { path: 'matriz',               loadChildren: './matrix/_matriz-module/matriz.module#MatrizModule' },
          { path: 'reproducao',           loadChildren: './reproduction/_reproducao-module/reproducao.module#ReproducaoModule' },
          { path: 'dashboard',            loadChildren: './dashboard/_dashboard-module/dashboard.module#DashboardModule' },
          { path: 'analises',             loadChildren: './analises/_analises-module/analises.module#AnalisesModule' },
          { path: 'taxa-concepcao',       loadChildren: './taxa-concepcao/_taxa-concepcao-module/taxa-concepcao.module#TaxaConcepcaoModule' },
          { path: 'configuracao',         loadChildren: './configurations/_configuracoes-module/configuracoes.module#ConfiguracoesModule' },
          { path: 'listagem',             loadChildren: './listing/_listagem-module/listagem.module#ListagemModule' },
          { path: 'alta-cria',            loadChildren: './alta-cria/_alta-cria-module/alta-cria.module#AltaCriaModule' },
          { path: 'producao',             loadChildren: './production/_producao-module/producao.module#ProducaoModule' },
          { path: 'ficha-individual',     loadChildren: './ficha-individual/_ficha-individual-module/ficha-individual.module#FichaIndividualModule' },
          { path: 'simulador-reproducao', loadChildren: './simulador-reproducao/_simulador-reproducao-module/simulador-reproducao.module#SimuladorReproducaoModule' },
          { path: 'benchmarking',         loadChildren: './benchmarking/_benchmarking-module/benchmarking.module#BenchmarkingModule' },
          { path: 'cadastro',             loadChildren: './cadastro/_cadastro-module/cadastro.module#CadastroModule' },
          { path: 'relatorio',            loadChildren: './relatorios/_relatorios-module/relatorios.module#RelatoriosModule' },
          { path: 'importacao',           loadChildren: './importacoes/_importacoes-module/importacoes.module#ImportacoesModule' }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
