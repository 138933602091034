import { ProtocoloDetalhe } from '../../../models/configuracoes/protocolo-detalhes.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ConfiguracoesService } from 'src/app/_services/configuracoes.service';
import { Protocolo } from 'src/models/configuracoes/protocolo.model';

@Component({
  selector: 'app-protocolo-detelhe-cadastro',
  templateUrl: './protocolo-detelhe-cadastro.component.html',
  styleUrls: ['./protocolo-detelhe-cadastro.component.scss']
})
export class ProtocoloDetalheCadastroComponent implements OnInit {

  hasChanges = false;
  protocolDetail: ProtocoloDetalhe;
  protocol: Protocolo;
  protocolDetailsList: ProtocoloDetalhe[];
  loading = false;

  INSERCAO_IMPLANTE = `Inserção do implante`;
  LUTEOLISE1 = `Luteólise 1`;
  LUTEOLISE2 = `Luteólise 2`;
  EDF = `Estímulo Desenv. Folicular Final`;
  EOV = `Estímulo Ovulatório`;
  RETIRADA_IMPLANTE = `Retirada do implante`;
  IATF = `IATF`;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProtocoloDetalheCadastroComponent>,
    private snackBar: MatSnackBar,
    private configurationsService: ConfiguracoesService) {
      this.protocol = this.data.protocolId;
     }

  ngOnInit() {
    this.protocolDetailsList = [];
    this.getProtocolDetails();
   }

  getProtocolDetails() {
    this.loading = true;
    this.configurationsService.getProtocoloDetalhes(this.protocol.cdprotocolo).subscribe((response) => {
      this.protocolDetailsList =
        response.filter(protocolDetail => protocolDetail.cdprotocolo === this.protocol.cdprotocolo);

      const hasProtocolDetails =
        this.protocolDetailsList !== undefined && this.protocolDetailsList.length > 0;

      if (!hasProtocolDetails) {
        this.createProtocolDetails();
      }

      this.setFasesDays();

      this.loading = false;
    });
  }

  createProtocolDetails() {
    let protocolDetail: ProtocoloDetalhe;

    protocolDetail = new ProtocoloDetalhe();
    protocolDetail.fase = this.INSERCAO_IMPLANTE;
    protocolDetail.dias = 0;
    this.protocolDetailsList.push(protocolDetail);

    protocolDetail = new ProtocoloDetalhe();
    protocolDetail.fase = this.LUTEOLISE1;
    protocolDetail.dias = 0;
    this.protocolDetailsList.push(protocolDetail);

    protocolDetail = new ProtocoloDetalhe();
    protocolDetail.fase = this.LUTEOLISE2;
    protocolDetail.dias = 0;
    this.protocolDetailsList.push(protocolDetail);

    protocolDetail = new ProtocoloDetalhe();
    protocolDetail.fase = this.EDF;
    protocolDetail.dias = 0;
    this.protocolDetailsList.push(protocolDetail);

    protocolDetail = new ProtocoloDetalhe();
    protocolDetail.fase = this.EOV;
    protocolDetail.dias = 0;
    this.protocolDetailsList.push(protocolDetail);

    protocolDetail = new ProtocoloDetalhe();
    protocolDetail.fase = this.RETIRADA_IMPLANTE;
    protocolDetail.dias = 0;
    this.protocolDetailsList.push(protocolDetail);

    protocolDetail = new ProtocoloDetalhe();
    protocolDetail.fase = this.IATF;
    protocolDetail.dias = 0;
    this.protocolDetailsList.push(protocolDetail);
  }

  setFasesDays() {
    this.protocolDetailsList.forEach((protocolDetail) => {
      const hasCdDetail = protocolDetail.cddetalhe > 0
                            || protocolDetail.cddetalhe !== undefined;

      if (!hasCdDetail) {
        if (protocolDetail.fase === this.INSERCAO_IMPLANTE) {
          protocolDetail.dias = this.protocol.implante ? this.protocol.implante : 0;
        }
        if (protocolDetail.fase === this.LUTEOLISE1) {
          protocolDetail.dias = this.protocol.luteolise1 ? this.protocol.luteolise1 : 0;
        }
        if (protocolDetail.fase === this.LUTEOLISE2) {
          protocolDetail.dias = this.protocol.luteolise2 ? this.protocol.luteolise2 : 0;
        }
        if (protocolDetail.fase === this.EDF) {
          protocolDetail.dias = this.protocol.edf ? this.protocol.edf : 0;
        }
        if (protocolDetail.fase === this.EOV) {
          protocolDetail.dias = this.protocol.estimulo ? this.protocol.estimulo : 0;
        }
        if (protocolDetail.fase === this.RETIRADA_IMPLANTE) {
          protocolDetail.dias = this.protocol.retirada ? this.protocol.retirada : 0;
        }
        if (protocolDetail.fase === this.IATF) {
          protocolDetail.dias = this.protocol.iatf ? this.protocol.iatf : 0;
        }
      }
    });
  }

  cancel() {
    this.dialogRef.close({ data: 'cancelar' });
  }

  hasValue(statusValue) {
    if ( statusValue.trim() !== '' && statusValue !== undefined ) {
        this.hasChanges = true;
    } else {
        this.hasChanges = false;
    }
  }

  save() {
    const cdDetailsList =
      this.protocolDetailsList.filter(
          protocolDetail => protocolDetail.cddetalhe !== undefined || protocolDetail.cddetalhe > 0);

    const hasCdDetails = cdDetailsList !== undefined && cdDetailsList.length > 0;

    this.updateProtocolFasesDays();

    if (hasCdDetails) {
      this.update();
    } else {
      this.setProtocol();
      this.insert();
    }
    this.dialogRef.close({ protocol: this.protocol });
  }

  insert() {
    this.configurationsService.insereProtocoloDetalhes(this.protocolDetailsList).subscribe((response) => {
      this.showSnackBar('Registros armazenados com sucesso.');
    }, (error) => this.showSnackBar(error));
  }

  update() {
    this.configurationsService.atualizaProtocoloDetalhes(this.protocolDetailsList).subscribe((response: ProtocoloDetalhe[]) => {
      if (response != null) {
        this.showSnackBar('Alterações salvas com sucesso.');
      }
    });
  }

  setProtocol() {
    this.protocolDetailsList.forEach((protocolDetail) => {
      protocolDetail.cdprotocolo = this.protocol.cdprotocolo;
    });
  }

  updateProtocolFasesDays() {
    this.protocolDetailsList.forEach((protocolDetail) => {
      if (protocolDetail.fase === `Inserção do implante`) {
        this.protocol.implante = protocolDetail.dias;
      }
      if (protocolDetail.fase === `Luteólise 1`) {
        this.protocol.luteolise1 = protocolDetail.dias;
      }
      if (protocolDetail.fase === `Luteólise 2`) {
        this.protocol.luteolise2 = protocolDetail.dias;
      }
      if (protocolDetail.fase === `Estímulo Desenv. Folicular Final`) {
        this.protocol.edf = protocolDetail.dias;
      }
      if (protocolDetail.fase === `Estímulo Ovulatório`) {
        this.protocol.estimulo = protocolDetail.dias;
      }
      if (protocolDetail.fase === `Retirada do implante`) {
        this.protocol.retirada = protocolDetail.dias;
      }
      if (protocolDetail.fase === `IATF`) {
        this.protocol.iatf = protocolDetail.dias;
      }
    });
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'X', {
      duration: 2500,
    });
  }

}
