export class HistoricoReproducao {
    cdHistoricoReproducao: number;
    cdFazenda: number;
    cdMatriz: number;
    cdReproducao: number;
    descricao: string;
    data: Date;
    removido: boolean;

    // utilizado somente para inserir e validar novos registros de histório de reprodução no grid
    dataReproducao: Date;
    isValid: boolean;
    isRemoved: boolean;
}
