export class Matriz {
    public cdmatriz: number;
    public numero: string;
    public nome: string;
    public registro: string;
    public cdcomposicaoracial: number;
    public dtnascimento?: Date;
    public dtultparto?: Date;
    public numpartoinicio: number;
    public cdmae: number;
    public cdpai: number;
    public observacao: string;
    public cdlote: number;
    public dtbaixa: Date;
    public descarte: boolean;
    public doadora: boolean;
    public cdreproducao: number;
    public cdmotivobaixa: number;
    public cdfazenda: number;
    public dtsecagemanterior: Date;
    public pesonascer: number;
    public inforealnascer: number;
    public dtdesaleitamento: Date;
    public pesodesaleitamento: number;
    public inforealdesaleitamento: number;
    public dtdescarte: Date;

    public historico: string;
    public dataHistorico: Date;
  }
