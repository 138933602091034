import { FazendaParametro } from 'src/models/fazenda-parametro.model';
import { Reprodutor } from 'src/models/configuracoes/reprodutor.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { ReproducaoService } from 'src/app/_services/reproducao.service';
import { Matriz } from 'src/models/matriz/matriz.model';
import { ValidacaoReproducaoService } from 'src/app/_services/validacao-reproducao.service';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';
import { ConfiguracoesService } from 'src/app/_services/configuracoes.service';
import { MatrizService } from 'src/app/_services/matriz.service';
import { ParametroFiltro } from 'src/models/parametro-filtro.model';
import { ParametrosEditaveisService } from 'src/app/_services/parametros-editaveis.service';

@Component({
  selector: 'app-consulta-cadastro',
  templateUrl: './consulta-cadastro.component.html',
  styleUrls: ['./consulta-cadastro.component.scss']
})
export class ConsultaCadastroComponent implements OnInit {

  matrixPanelOpenState = true;
  iaCobTePanelOpenState = true;
  touchPanelOpenState = false;
  ocurrencesPanelOpenState = false;
  parturitionPanelOpenState = false;
  hasChanges = false;
  reload = false;
  loading = false;

  matrix: Matriz;
  reproductionsList: Reproducao[];
  breedersList: Reprodutor[];
  matrixList: Matriz[];
  parametersList: FazendaParametro[];
  numPartos: Reproducao[];
  item: any;
  oldItem: any;

  error = ``;

  gridSelection: any[] = [];

  selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private reproducaoService: ReproducaoService,
              public dialogRef: MatDialogRef<ConsultaCadastroComponent>,
              private reproducaoValidacaoService: ValidacaoReproducaoService,
              private configurationsService: ConfiguracoesService,
              private matrizService: MatrizService,
              private parameterService: ParametrosEditaveisService) {
    this.setItems(data);
  }

  ngOnInit() {
    if (!this.hasReproductions()) {
      this.getReproductionsList();
    }
    this.getBreeders();
    this.getMatrix();
    this.getParametersByFarm();
  }

  hasReproductions(): boolean {
    const hasReproductions =
      this.data.reproductions !== undefined
      && this.data.reproductions !== null
      && this.data.reproductions.length > 0;
    return hasReproductions;
  }

  setItems(data: any) {
    this.oldItem = data.item;
    this.item = data.item;
    this.matrix = data.matrix;
    this.reproductionsList = data.reproductions;
  }

  setTentativasForScheduleIaCobTe() {
    if (this.item.tentativas === undefined) {
      const reproductions = this.reproductionsList.filter(rep => rep.cdmatriz === this.item.cdmatriz);
      const hasReproduction = reproductions !== undefined && reproductions.length > 0;
      if (hasReproduction) {
        this.item.tentativas = reproductions.sort((a, b) => (new Date(b.data).getTime() - new Date(a.data).getTime()))[0].tentativas;
      } else {
        this.item.tentativas = 0;
      }
    }
  }

  getReproductionsList() {
    this.loading = true;
    this.reproducaoService.getReproducoes().subscribe((response: Reproducao[]) => {
      this.reproductionsList = response;
      this.setTentativasForScheduleIaCobTe();
      this.loading = false;
    }, (error) => console.error(error));
  }

  getBreeders() {
    this.loading = true;
    this.configurationsService.getReprodutores().subscribe((response: Reprodutor[]) => {
    this.breedersList = response;
    this.loading = false;
    }, (error) => console.error(error));
  }

  getMatrix() {
    this.loading = true;
    this.matrizService.getMatrizes().subscribe((response: Matriz[]) => {
      this.matrixList = response;
      this.loading = false;
    }, (error) => console.error(error));
  }

  getParametersByFarm() {
    this.loading = true;
    const parameterFilter = new ParametroFiltro();
    parameterFilter.editavelWeb = true;
    this.parameterService.fetch(parameterFilter).subscribe((response: FazendaParametro[]) => {
      this.parametersList = response;
      this.loading = false;
    }, (error) => console.error(error));
  }

  onSaveClick() {
    this.saveHandler(false, false);
  }

  onCloseClick() {
    if (this.hasChanges) {
      this.saveHandler(true, true);
    } else {
      this.closeHandler(false);
    }
  }

  saveHandler(closeDialog: boolean, isOnCloseClick: boolean) {
    if (isOnCloseClick) {
      this.showConfirmationDialog(closeDialog);
    } else {
      if (this.validateToSave()) {
        this.saveChanges(closeDialog);
        this.closeHandler(true);
      }
    }
  }

  showConfirmationDialog(closeDialog: boolean) {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja salvar as alterações?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
          if (this.validateToSave()) {
            this.saveChanges(closeDialog);
            this.closeHandler(true);
          }
      } else if (closeDialog) {
        this.closeHandler(false);
      }
    });
  }

  saveChanges(closeDialog: boolean) {
    if (this.hasCdReproduction()) {
      this.updateIaCobTe(closeDialog);
    } else {
      this.insertIaCobTe(closeDialog);
    }
  }

  hasCdReproduction(): boolean {
    const hasCdReproduction =
      this.item.cdreproducao !== undefined
      && this.item.cdreproducao !== null
      && this.item.cdreproducao > 0;
    return hasCdReproduction;
  }

  insertIaCobTe(closeDialog: boolean) {
    this.reproducaoService.insereIaCobTes([this.item]).subscribe((response: Reproducao[]) => {
      this.showSnackBar('Alterações salvas com sucesso.');
      this.hasChanges = false;
      if (closeDialog) {
        this.closeHandler(true);
      }
    }, (error) => console.error(error));
  }

  updateIaCobTe(closeDialog: boolean) {
    this.reproducaoService.atualizaReproducoes([this.item]).subscribe((response: Reproducao[]) => {
      if (response != null) {
        this.showSnackBar('Alterações salvas com sucesso.');
        this.hasChanges = false;
        if (closeDialog) {
          this.closeHandler(true);
        }
      }
    }, (error) => console.error(error));
  }

  closeHandler(reload: boolean) {
    this.dialogRef.close(reload);
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'X', {duration: 2500});
  }

  validateToSave(): boolean {
    let isValid = true;
    this.error = ``;
    const reproduction = this.item;
    const isScheduleScreen = this.data.isScheduleTouch || this.data.isScheduleIaCobTe;

    if (isScheduleScreen) {
      if (!this.reproducaoValidacaoService.validaLancamentoIaCobTe(this.reproductionsList, this.breedersList,
            reproduction, this.matrix, this.parametersList)
          || !this.reproducaoValidacaoService.validaLancamentoDiagnostico(reproduction, this.matrix)
          || !this.reproducaoValidacaoService.validaLancamentoDatas(this.reproductionsList, reproduction, this.oldItem, this.matrix)) {
        isValid = false;
        this.error = this.reproducaoValidacaoService.getErro();
        this.showErrorAlert();
        return isValid;
      }
    } else {
      if (!this.reproducaoValidacaoService.validaLancamentoIaCobTe(this.reproductionsList, this.breedersList,
            reproduction, this.matrix, this.parametersList)
          || !this.reproducaoValidacaoService.validaLancamentoDiagnostico(reproduction, this.matrix)
          || !this.reproducaoValidacaoService.validaLancamentoParto(reproduction, this.matrix, this.matrixList, this.parametersList)
          || !this.reproducaoValidacaoService.validaLancamentoDatas(this.reproductionsList, reproduction, this.oldItem, this.matrix)) {
        isValid = false;
        this.error = this.reproducaoValidacaoService.getErro();
        this.showErrorAlert();
        return isValid;
      }
    }

    return isValid;
  }

  itemHasChanged(value: string) {
    const hasChanges = value !== undefined && value === `true`;
    if (hasChanges) {
      this.hasChanges = true;
    }
  }

  showErrorAlert() {
    const dialogRef = this.dialog.open(DialogoAlertaComponent, {
      data: {
        message: `${this.error}`,
        buttonText: {
          cancel: 'OK'
        }
      }
    });
  }
}
