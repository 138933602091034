export class Pesagem {
    public cdPesagem: number;
    public cdMatriz: number;
    public cdFazenda: number;
    public dataPesagem: Date;
    public peso: number;

    // utilizados somente para validações na tela
    public isValid: boolean;
    public dtNascimento: Date;
    public isRemoved: boolean;
}
