import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatInput, MatRadioButton, MatSelect } from '@angular/material';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';

@Component({
    selector: 'app-composicao-racial-cadastro',
    templateUrl: './composicao-racial-cadastro.component.html',
    styleUrls: ['./composicao-racial-cadastro.component.scss'],
})
export class ComposicaoRacialCadastroComponent implements OnInit {

    @ViewChild('tipo0', { static: false }) tipo0: MatRadioButton;
    @ViewChild('tipo1', { static: false }) tipo1: MatRadioButton;
    @ViewChild('ativo0', { static: false }) ativo0: MatRadioButton;
    @ViewChild('ativo1', { static: false }) ativo1: MatRadioButton;

    @ViewChild('percentual1', { static: false }) percentual1: MatInput;
    @ViewChild('percentual2', { static: false }) percentual2: MatInput;
    @ViewChild('percentual3', { static: false }) percentual3: MatInput;
    @ViewChild('percentual4', { static: false }) percentual4: MatInput;
    @ViewChild('percentual5', { static: false }) percentual5: MatInput;

    @ViewChild('raca1', { static: false }) private raca1: MatSelect;
    @ViewChild('raca2', { static: false }) private raca2: MatSelect;
    @ViewChild('raca3', { static: false }) private raca3: MatSelect;
    @ViewChild('raca4', { static: false }) private raca4: MatSelect;
    @ViewChild('raca5', { static: false }) private raca5: MatSelect;

    @ViewChild('desc', { static: false }) private desc: MatInput;

    hasChanges = false;
    desabilita = false;
    hasError = false;
    opcaoTipoPura = false;
    desc1: string;
    perc1: string;
    perc2: string;
    perc3: string;
    perc4: string;
    perc5: string;
    rac1: string;
    rac2: string;
    rac3: string;
    rac4: string;
    rac5: string;

    percTotal = 0;
    msg = '';
    msgPercentual = '';
    msgTotal: string;
    msgPreenchimento = '';
    msgValidFill = '';

    msgRaca = '';
    racas = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ComposicaoRacialCadastroComponent>,
        public dialog: MatDialog) { }

    ngOnInit() { }

    cancel() {
        this.dialogRef.close({ data: 'cancelar' });
    }

    confirm() {
        this.msgValidFill = '';

        let validacao = this.validFill();

        if (validacao) {
            validacao = this.validateComposition();
        }

        if (validacao) {
            this.dialogRef.close({ tipo0: this.tipo0.checked
                , ativo0: this.ativo0.checked
                , racialCompositionId: this.data.racialCompositionId
            });
        } else {
            this.openConfirmationDialog();
        }
    }

    public openConfirmationDialog() {

        const dialogRef1 = this.dialog.open(DialogoConfirmacao, {
        data: {
            // message: 'Existem campos para serem preenchidos! ',
            message: this.msgValidFill !== '' ? this.msgValidFill : 'Existem campos para serem preenchidos! ',
            buttonText: {
                ok: 'Cancelar Tudo',
                cancel: 'Corrigir'
            }
        }
        });
        dialogRef1.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.dialogRef.close({ data: 'cancelar' });
            }
        });

    }

    public validFill() {
        if ( this.tipo1.checked === true ) {
            if (this.data.racialCompositionId.cdraca1 !== null
                && this.data.racialCompositionId.cdraca1 !== ''
                && this.data.racialCompositionId.cdraca1 !== undefined) {
                    if (this.data.racialCompositionId.cdraca2 !== null
                        && this.data.racialCompositionId.cdraca2 !== ''
                        && this.data.racialCompositionId.cdraca2 !== undefined) {
                            if (this.data.racialCompositionId.percentual1 !== null
                                && this.data.racialCompositionId.percentual1 !== ''
                                && this.data.racialCompositionId.percentual1 !== undefined) {
                                    if (this.data.racialCompositionId.percentual2 !== null
                                        && this.data.racialCompositionId.percentual2 !== ''
                                        && this.data.racialCompositionId.percentual2 !== undefined) {
                                            return this.validFillOthers();
                                        } else {
                                            this.msgValidFill = 'Preencha o campo Percentual 2.';
                                            return false;
                                        }
                                } else {
                                    this.msgValidFill = 'Preencha o campo Percentual 1.';
                                    return false;
                                }
                        } else {
                            this.msgValidFill = 'Preencha o campo Raca 2.';
                            return false;
                        }
                } else {
                    this.msgValidFill = 'Preecha o campo Raca 1.';
                    return false;
                }
        } else {
            if ( this.tipo1.checked === false ) {
                if (this.data.racialCompositionId.cdraca1 !== null
                    && this.data.racialCompositionId.cdraca1 !== ''
                    && this.data.racialCompositionId.cdraca1 !== undefined) {
                        if (this.data.racialCompositionId.percentual1 !== null
                            && this.data.racialCompositionId.percentual1 !== ''
                            && this.data.racialCompositionId.percentual1 !== undefined) {
                                if (this.data.racialCompositionId.percentual1 === 100) {
                                    return true;
                                } else {
                                    this.msgValidFill = 'O percentual da Raça deve ser 100.';
                                    return false;
                                }
                            } else {
                                this.msgValidFill = 'Preencha o campo percentual da Raça.';
                                return false;
                            }
                    } else {
                        this.msgValidFill = 'Escolha uma raça e preencha o campo Percentual 1.';
                        return false;
                    }
            } else { return false; }
        }
    }

    public validFillOthers() {
        if ( this.tipo1.checked === true ) {
            if (this.data.racialCompositionId.cdraca3 !== null
                && this.data.racialCompositionId.cdraca3 !== ''
                && this.data.racialCompositionId.cdraca3 !== undefined) {
                    if (this.data.racialCompositionId.percentual3 === null
                        || this.data.racialCompositionId.percentual3 === ''
                        || this.data.racialCompositionId.percentual3 === undefined) {
                            this.msgValidFill = 'O percentual da Raça 3 deve ser preenchido.';
                            return false;
                        }
                }
            if (this.data.racialCompositionId.cdraca4 !== null
                && this.data.racialCompositionId.cdraca4 !== ''
                && this.data.racialCompositionId.cdraca4 !== undefined) {
                    if (this.data.racialCompositionId.percentual4 === null
                        || this.data.racialCompositionId.percentual4 === ''
                        || this.data.racialCompositionId.percentual4 === undefined) {
                            this.msgValidFill = 'O percentual da Raça 4 deve ser preenchido.';
                            return false;
                        }
                }

            if (this.data.racialCompositionId.cdraca5 !== null
                && this.data.racialCompositionId.cdraca5 !== ''
                && this.data.racialCompositionId.cdraca5 !== undefined) {
                    if (this.data.racialCompositionId.percentual5 === null
                        || this.data.racialCompositionId.percentual5 === ''
                        || this.data.racialCompositionId.percentual5 === undefined) {
                            this.msgValidFill = 'O percentual da Raça 3 deve ser preenchido.';
                            return false;
                        }
                }

            if (this.data.racialCompositionId.percentual3 !== null
                && this.data.racialCompositionId.percentual3 !== ''
                && this.data.racialCompositionId.percentual3 !== undefined) {
                    if (this.data.racialCompositionId.cdraca3 === null
                        || this.data.racialCompositionId.cdraca3 === ''
                        || this.data.racialCompositionId.cdraca3 === undefined) {
                            this.msgValidFill = 'O campo Raca 3 deve ser preenchido.';
                            return false;
                        }
                }

            if (this.data.racialCompositionId.percentual4 !== null
                && this.data.racialCompositionId.percentual4 !== ''
                && this.data.racialCompositionId.percentual4 !== undefined) {
                    if (this.data.racialCompositionId.cdraca4 === null
                        || this.data.racialCompositionId.cdraca4 === ''
                        || this.data.racialCompositionId.cdraca4 === undefined) {
                            this.msgValidFill = 'O campo Raca 4 deve ser preenchido.';
                            return false;
                        }
                }

            if (this.data.racialCompositionId.percentual5 !== null
                && this.data.racialCompositionId.percentual5 !== ''
                && this.data.racialCompositionId.percentual5 !== undefined) {
                    if (this.data.racialCompositionId.cdraca5 === null
                        || this.data.racialCompositionId.cdraca5 === ''
                        || this.data.racialCompositionId.cdraca5 === undefined) {
                            this.msgValidFill = 'O campo Raca 5 deve ser preenchido.';
                            return false;
                        }
                }


            // verifica totais percentuais
            let percItem1 = 0;
            let percItem2 = 0;
            let percItem3 = 0;
            let percItem4 = 0;
            let percItem5 = 0;
            let percItem = 0;

            percItem1 = parseFloat(this.data.racialCompositionId.percentual1 !== null
                        && this.data.racialCompositionId.percentual1 !== undefined
                        && this.data.racialCompositionId.percentual1 !== '' ? this.data.racialCompositionId.percentual1 : 0);
            percItem2 = parseFloat(this.data.racialCompositionId.percentual2 !== null
                        && this.data.racialCompositionId.percentual2 !== undefined
                        && this.data.racialCompositionId.percentual2 !== '' ? this.data.racialCompositionId.percentual2 : 0);
            percItem3 = parseFloat(this.data.racialCompositionId.percentual3 !== null
                        && this.data.racialCompositionId.percentual3 !== undefined
                        && this.data.racialCompositionId.percentual3 !== '' ? this.data.racialCompositionId.percentual3 : 0);
            percItem4 = parseFloat(this.data.racialCompositionId.percentual4 !== null
                        && this.data.racialCompositionId.percentual4 !== undefined
                        && this.data.racialCompositionId.percentual4 !== '' ? this.data.racialCompositionId.percentual4 : 0);
            percItem5 = parseFloat(this.data.racialCompositionId.percentual5 !== null
                        && this.data.racialCompositionId.percentual5 !== undefined
                        && this.data.racialCompositionId.percentual5 !== '' ? this.data.racialCompositionId.percentual5 : 0);

            percItem = (percItem1 + percItem2 + percItem3 + percItem4 + percItem5);

            if (percItem < 100) {
                this.msgValidFill = 'A soma dos percentuais não Totalizam 100.';
                return false;
            } else {
                return true;
            }
        }
    }

    validacaoTipos() {
        if (this.data.racialCompositionId.padrao === 0
            || this.data.racialCompositionId.padrao === undefined
            || this.data.racialCompositionId.padrao === null
           ) {

            // verifica se o tipo é PURA
            if ( this.tipo0 === undefined ) {
                this.validGeral();
            } else {
                if ( this.tipo0.checked === true ) {
                    this.getValuesFields();
                    this.clearFields();
                    this.desabilita = true;
                    this.clearMessages();
                } else {
                    this.clearMessages();
                    this.setValuesFields();
                    this.desabilita = false;
                }
            }
        }
    }

    validGeral() {
        // valida percentuais - se o valor lançado como percentual é válido
        this.perc1 = this.data.racialCompositionId.percentual1;
        this.perc2 = this.data.racialCompositionId.percentual2;
        this.perc3 = this.data.racialCompositionId.percentual3;
        this.perc4 = this.data.racialCompositionId.percentual4;
        this.perc5 = this.data.racialCompositionId.percentual5;

        this.validPerc(parseFloat(this.perc1), 'Percentual1');
        this.validPerc(parseFloat(this.perc2), 'Percentual2');
        this.validPerc(parseFloat(this.perc3), 'Percentual3');
        this.validPerc(parseFloat(this.perc4), 'Percentual4');
        this.validPerc(parseFloat(this.perc5), 'Percentual5');

        // preenche o campo descrição
        this.getDescription();

        // verifica se total percentuais não ultrapassou 100%
        this.validTotalPerc();
    }

    hasValue(statusValue) {
        if ( statusValue.trim() !== '' && statusValue !== undefined ) {
            this.hasChanges = true;
        } else {
            this.hasChanges = false;
        }
    }

    public getValuesFields() {
        this.desc = this.data.racialCompositionId.descricao;

        this.rac1 = this.data.racialCompositionId.cdraca1;
        this.rac2 = this.data.racialCompositionId.cdraca2;
        this.rac3 = this.data.racialCompositionId.cdraca3;
        this.rac4 = this.data.racialCompositionId.cdraca4;
        this.rac5 = this.data.racialCompositionId.cdraca5;

        this.perc1 = this.data.racialCompositionId.percentual1;
        this.perc2 = this.data.racialCompositionId.percentual2;
        this.perc3 = this.data.racialCompositionId.percentual3;
        this.perc4 = this.data.racialCompositionId.percentual4;
        this.perc5 = this.data.racialCompositionId.percentual5;
    }

    setValuesFields() {

        this.data.racialCompositionId.descricao   = this.desc;

        this.data.racialCompositionId.cdraca1 = this.rac1 !== '' ? this.rac1 : '';
        this.data.racialCompositionId.cdraca2 = this.rac2 !== '' ? this.rac2 : '';
        this.data.racialCompositionId.cdraca3 = this.rac3 !== '' ? this.rac3 : '';
        this.data.racialCompositionId.cdraca4 = this.rac4 !== '' ? this.rac4 : '';
        this.data.racialCompositionId.cdraca5 = this.rac5 !== '' ? this.rac5 : '';

        if (this.perc1 !== '') {
            this.data.racialCompositionId.percentual1 = this.perc1;
            this.validPerc(parseFloat(this.perc1), 'Percentual1');
        }
        if (this.perc2 !== '') {
            this.data.racialCompositionId.percentual2 = this.perc2;
            this.validPerc(parseFloat(this.perc2), 'Percentual2');
        }
        if (this.perc3 !== '') {
            this.data.racialCompositionId.percentual3 = this.perc3;
            this.validPerc(parseFloat(this.perc3), 'Percentual3');
        }
        if (this.perc4 !== '') {
            this.data.racialCompositionId.percentual4 = this.perc4;
            this.validPerc(parseFloat(this.perc4), 'Percentual4');
        }
        if (this.perc5 !== '') {
            this.data.racialCompositionId.percentual5 = this.perc5;
            this.validPerc(parseFloat(this.perc5), 'Percentual5');
        }
    }

    public clearAllFields() {

        this.data.racialCompositionId.cdraca1 = null;
        this.data.racialCompositionId.cdraca2 = null;
        this.data.racialCompositionId.cdraca3 = null;
        this.data.racialCompositionId.cdraca4 = null;
        this.data.racialCompositionId.cdraca5 = null;

        this.data.racialCompositionId.percentual1 = null;
        this.data.racialCompositionId.percentual2 = null;
        this.data.racialCompositionId.percentual3 = null;
        this.data.racialCompositionId.percentual4 = null;
        this.data.racialCompositionId.percentual5 = null;

        this.data.racialCompositionId.descricao = '';

        this.clearMessages();
    }

    public clearFields() {

        this.data.racialCompositionId.cdraca2 = null;
        this.data.racialCompositionId.cdraca3 = null;
        this.data.racialCompositionId.cdraca4 = null;
        this.data.racialCompositionId.cdraca5 = null;

        this.data.racialCompositionId.percentual1 = null;
        this.data.racialCompositionId.percentual2 = null;
        this.data.racialCompositionId.percentual3 = null;
        this.data.racialCompositionId.percentual4 = null;
        this.data.racialCompositionId.percentual5 = null;

        this.data.racialCompositionId.descricao = '';

        this.getDescription();
    }

    public getDescription() {
        const sigla1 = this.data.raca.find(x => x.cdraca === this.data.racialCompositionId.cdraca1);
        const sigla2 = this.data.raca.find(x => x.cdraca === this.data.racialCompositionId.cdraca2);
        const sigla3 = this.data.raca.find(x => x.cdraca === this.data.racialCompositionId.cdraca3);
        const sigla4 = this.data.raca.find(x => x.cdraca === this.data.racialCompositionId.cdraca4);
        const sigla5 = this.data.raca.find(x => x.cdraca === this.data.racialCompositionId.cdraca5);


        let total = 0;
        total += parseFloat(this.data.racialCompositionId.cdraca1) > 0 ? 1 : 0;
        total += parseFloat(this.data.racialCompositionId.cdraca2) > 0 ? 1 : 0;
        total += parseFloat(this.data.racialCompositionId.cdraca3) > 0 ? 1 : 0;
        total += parseFloat(this.data.racialCompositionId.cdraca4) > 0 ? 1 : 0;
        total += parseFloat(this.data.racialCompositionId.cdraca5) > 0 ? 1 : 0;

        const fracao1 = this.fracaoRaca(parseFloat(this.data.racialCompositionId.percentual1));
        const fracao2 = this.fracaoRaca(parseFloat(this.data.racialCompositionId.percentual2));
        const fracao3 = this.fracaoRaca(parseFloat(this.data.racialCompositionId.percentual3));
        const fracao4 = this.fracaoRaca(parseFloat(this.data.racialCompositionId.percentual4));
        const fracao5 = this.fracaoRaca(parseFloat(this.data.racialCompositionId.percentual5));

        let nome = '';

        if ( total === 1 ) {
            nome = sigla1.descricao;
        } else {
            if ( total >= 2 ) {
                nome = fracao1 +  ' ' + sigla1.sigla;
                nome = nome + ', ' + fracao2 + ' ' + sigla2.sigla;
                if ( total >= 3 ) {
                    nome = nome + ', ' + fracao3 + ' ' + sigla3.sigla;
                    if ( total >= 4 ) {
                        nome = nome + ', ' + fracao4 + ' ' + sigla4.sigla;
                        if ( total === 5 ) {
                            nome = nome + ', ' + fracao5 + ' ' + sigla5.sigla;
                        }
                    }
                }
            }
        }
        this.data.racialCompositionId.descricao = nome;
    }

    public validPerc(value: number, field: string) {
        const num = value / 0.390625;
        const setField = field;

        // Parte inteira:
        const int = Math.trunc(num);

        // Parte decimal ('0' por padrão).
        const decimal = num - int;

        if (decimal > 0) {
            this.hasError = true;
            const indice = this.msgPercentual.indexOf(setField);
            if (indice === 0 && this.msgPercentual.length > 43) { this.msgPercentual = this.msgPercentual.substring(43); }
            if (indice === 0 && this.msgPercentual.length === 43) { this.msgPercentual = ''; }
            if (indice >= 43) { this.msgPercentual = this.msgPercentual.substring(indice - 1, 0)
                + this.msgPercentual.substring(this.msgPercentual.length, indice + 43); }
            this.msgPercentual += setField + ' está com valor lançado errado! ';

            return false;
        } else {
            const indice = this.msgPercentual.indexOf(setField);
            if (indice === 0 ) { this.msgPercentual = this.msgPercentual.substring(43); }
            if (indice >= 43) { this.msgPercentual = this.msgPercentual.substring(indice - 1, 0)
                        + this.msgPercentual.substring(this.msgPercentual.length, indice + 43); }
            if (this.msgPercentual === '') { this.hasError = false; }

            this.getDescription();

            return true;
        }
    }

    public validTotalPerc() {
        let percItem1 = 0;
        let percItem2 = 0;
        let percItem3 = 0;
        let percItem4 = 0;
        let percItem5 = 0;
        let percItem = 0;

        percItem1 = parseFloat(this.data.racialCompositionId.percentual1 !== null && this.data.racialCompositionId.percentual1 !== undefined
                    && this.data.racialCompositionId.percentual1 !== '' ? this.data.racialCompositionId.percentual1 : 0);
        percItem2 = parseFloat(this.data.racialCompositionId.percentual2 !== null && this.data.racialCompositionId.percentual2 !== undefined
                    && this.data.racialCompositionId.percentual2 !== '' ? this.data.racialCompositionId.percentual2 : 0);
        percItem3 = parseFloat(this.data.racialCompositionId.percentual3 !== null && this.data.racialCompositionId.percentual3 !== undefined
                    && this.data.racialCompositionId.percentual3 !== '' ? this.data.racialCompositionId.percentual3 : 0);
        percItem4 = parseFloat(this.data.racialCompositionId.percentual4 !== null && this.data.racialCompositionId.percentual4 !== undefined
                    && this.data.racialCompositionId.percentual4 !== '' ? this.data.racialCompositionId.percentual4 : 0);
        percItem5 = parseFloat(this.data.racialCompositionId.percentual5 !== null && this.data.racialCompositionId.percentual5 !== undefined
                    && this.data.racialCompositionId.percentual5 !== '' ? this.data.racialCompositionId.percentual5 : 0);

        if (percItem1 > 0) { this.validPerc(percItem1, 'Percentual1'); }
        if (percItem2 > 0) { this.validPerc(percItem2, 'Percentual2'); }
        if (percItem3 > 0) { this.validPerc(percItem3, 'Percentual3'); }
        if (percItem4 > 0) { this.validPerc(percItem4, 'Percentual4'); }
        if (percItem5 > 0) { this.validPerc(percItem5, 'Percentual5'); }

        this.validBreed();

        percItem = (percItem1 + percItem2 + percItem3 + percItem4 + percItem5);

        if (percItem > 100) {
            this.msgTotal = 'Erro! Soma dos Percentuais acima de 100. ';
            if (!this.hasError) { this.hasError = true; }
            return false;
        } else {
            this.msgTotal = '';
            return true;
        }
    }

    public clearMessages() {
        this.msgTotal = '';
        this.msgPercentual = '';
        this.hasError = false;
    }

    public validBreed() {
        const erro = this.hasError;
        this.racas = [];

        if ( this.data.racialCompositionId.cdraca1 !== null && this.data.racialCompositionId.cdraca1 !== undefined ) {
            const tem = this.racas.includes(this.data.racialCompositionId.cdraca1);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                this.hasError = true;
                return false;
            } else {
                this.racas.push(this.data.racialCompositionId.cdraca1);
            }
        }

        if ( this.data.racialCompositionId.cdraca2 !== null && this.data.racialCompositionId.cdraca2 !== undefined ) {
            const tem = this.racas.includes(this.data.racialCompositionId.cdraca2);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                this.hasError = true;
                return false;
            } else {
                this.racas.push(this.data.racialCompositionId.cdraca2);
            }
        }

        if ( this.data.racialCompositionId.cdraca3 !== null && this.data.racialCompositionId.cdraca3 !== undefined ) {
            const tem = this.racas.includes(this.data.racialCompositionId.cdraca3);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                this.hasError = true;
                return false;
            } else {
                this.racas.push(this.data.racialCompositionId.cdraca3);
            }
        }

        if ( this.data.racialCompositionId.cdraca4 !== null && this.data.racialCompositionId.cdraca4 !== undefined ) {
            const tem = this.racas.includes(this.data.racialCompositionId.cdraca4);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                this.hasError = true;
                return false;
            } else {
                this.racas.push(this.data.racialCompositionId.cdraca4);
            }
        }

        if ( this.data.racialCompositionId.cdraca5 !== null && this.data.racialCompositionId.cdraca5 !== undefined ) {
            const tem = this.racas.includes(this.data.racialCompositionId.cdraca5);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                this.hasError = true;
                return false;
            } else {
                this.racas.push(this.data.racialCompositionId.cdraca5);
            }
        }

        this.msgRaca = '';

        if (!erro) { this.hasError = false; }

        return true;
    }

    validateComposition() {
        if (this.data.racialCompositionId.descricao !== null && this.data.racialCompositionId.descricao !== undefined) {

            const description = this.data.racialCompositionId.descricao.toUpperCase();
            const array = new Array();
            const raca = description.split(', ');

            for (let i = 0; i < 4; i++) {
              if (raca[i] !== undefined) {
                array.push(raca[i]);
              }
            }

            let total = 0;
            let tem = false;

            for (const item of this.data.compositionListAll) {
                if ( item.cdcomposicaoracial !== this.data.racialCompositionId.cdcomposicaoracial
                    && item.descricao.toUpperCase() === this.data.racialCompositionId.descricao.toUpperCase() ) {
                        this.msgValidFill = 'Esta descrição já existe no cadastro.';
                        return false;
                }

                const desc = item.descricao.toUpperCase();
                const racas = desc.split(', ');

                if (racas.length === raca.length) {
                  for (let i = 0; i < 4; i++) {
                    tem = item.descricao.toUpperCase().includes(array[i]) ? true : false;
                    if (tem) {
                      total = total + 1;
                    }

                    if (total === raca.length) {
                        if ( item.cdcomposicaoracial === this.data.racialCompositionId.cdcomposicaoracial) {
                                return true;
                            } else {
                                this.msgValidFill = 'A composição racial informada já existe! Escolha outra.';
                                return false;
                            }
                    }
                  }
                }
            }

            return true;
        }
    }

    public validaRacas() {

        this.racas = [];

        if (this.raca1.value !== '' && this.raca1.value !== undefined) {
            this.racas.push(this.raca1.value);
        }

        if (this.raca2.value !== '' && this.raca2.value !== undefined) {
            const tem = this.racas.includes(this.raca2.value);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                return false;
            } else {
                this.racas.push(this.raca2.value);
            }
        }
        if (this.raca3.value !== '' && this.raca3.value !== undefined) {
            const tem = this.racas.includes(this.raca3.value);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                return false;
            } else {
                this.racas.push(this.raca3.value);
            }
        }
        if (this.raca4.value !== '' && this.raca4.value !== undefined) {
            const tem = this.racas.includes(this.raca4.value);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                return false;
            } else {
                this.racas.push(this.raca4.value);
            }
        }
        if (this.raca5.value !== '' && this.raca5.value !== undefined) {
            const tem = this.racas.includes(this.raca5.value);
            if (tem) {
                this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
                return false;
            } else {
                this.racas.push(this.raca5.value);
            }
        }

        this.msgRaca = '';

        return true;
    }

    // converte o valor percentual em fracao para incluir na descricao
    public fracaoRaca(APercentual: number) {
        let num: number;
        let den: number;

        den = 1;

        while (den <= 256) {
            num = (APercentual * den) / 100;
            if ( (num < den) && (Math.trunc(num) === num) ) {
                return num + '/' + den;
            }
            den = den * 2;
        }
    }
}
