export class ControleLeiteiro {
    public cdFazenda: number;
    public cdMatriz: number;
    public nomeMatriz: string;
    public numeroMatriz: string;
    public cdLeite: number;
    public cdReproducao: number;
    public dataControle: Date;
    public dataLactacao: Date;
    public ccs: number;
    public ordenha1 = 0;
    public ordenha2 = 0;
    public ordenha3 = 0;
    public pesoTotal = this.ordenha1 + this.ordenha2 + this.ordenha3;
    public checked: boolean;
}
