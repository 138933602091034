import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material';
import { TipoPartoEnum } from 'src/app/_enums/tipo-parto.enum';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';
import { DificuldadeDeParto } from 'src/models/reproducao/dificuldade-de-parto.model';
import { TipoDeParto } from 'src/models/reproducao/tipo-de-parto.model';
import { ReproducaoService } from '../../_services/reproducao.service';

@Component({
  selector: 'app-parto-detalhe',
  templateUrl: './parto-detalhe.component.html',
  styleUrls: ['./parto-detalhe.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class PartoDetalheComponent implements OnInit {

  @Input() item: any;
  @Output() hasChanges = new EventEmitter<string>();

  types: TipoDeParto[];
  difficulties: DificuldadeDeParto[];

  isGemelar = false;
  isSexoCria1Female = false;
  isSexoCria2Female = false;
  isNatimortoCria1 = false;
  isNatimortoCria2 = false;
  isRetencaoPlacenta = false;
  isDoencasMetabolicas = false;
  isDeslocamentoAbomaso = false;

  constructor(private reproducaoService: ReproducaoService) { }

  ngOnInit() {
    this.getTiposDeParto();
    this.getDificuldadesDeParto();
    this.setDetalhesDoParto();
  }

  setDetalhesDoParto() {
    this.isGemelar =
      this.item.gemelar !== undefined && this.item.gemelar !== null && this.item.gemelar === 1;
    this.isSexoCria1Female =
      this.item.sexocria1 !== undefined && this.item.sexocria1 !== null && this.item.sexocria1 === `F`;
    this.isSexoCria2Female =
      this.item.sexocria2 !== undefined && this.item.sexocria2 !== null && this.item.sexocria2 === `F`;
    this.isNatimortoCria1 =
      this.item.natimortocria1 !== undefined && this.item.natimortocria1 !== null && this.item.natimortocria1 === 1;
    this.isNatimortoCria2 =
      this.item.natimortocria2 !== undefined && this.item.natimortocria2 !== null && this.item.natimortocria2 === 1;
    this.isDeslocamentoAbomaso =
      this.item.deslocamentoabomaso !== undefined && this.item.deslocamentoabomaso !== null && this.item.deslocamentoabomaso === 1;
    this.isDoencasMetabolicas =
      this.item.doencasmetabolicas !== undefined && this.item.doencasmetabolicas !== null && this.item.doencasmetabolicas === 1;
    this.isRetencaoPlacenta =
      this.item.retencaoplacenta !== undefined && this.item.retencaoplacenta !== null && this.item.retencaoplacenta === 1;
    this.item.cdtipoparto =
      this.item.cdtipoparto === undefined || this.item.cdtipoparto === null ? 1 : this.item.cdtipoparto;
    this.item.cddificuldadeparto =
      this.item.cddificuldadeparto === undefined || this.item.cddificuldadeparto === null ? 1 : this.item.cddificuldadeparto;
    this.item.gemelar = this.isGemelar ? 1 : 0;
  }

  getTiposDeParto() {
    this.reproducaoService.getTiposDeParto().subscribe((response: any) => {
      this.types = response;
    }, (error) => console.error(error));
  }

  getDificuldadesDeParto() {
    this.reproducaoService.getDificuldadesDeParto().subscribe((response: any) => {
      this.difficulties = response;
    }, (error) => console.error(error));
  }

  onRetencaoPlacentaChange() {
    this.item.retencaoplacenta = this.isRetencaoPlacenta ? 1 : 0;
  }

  onDoencasMetabolicasChange() {
    this.item.doencasmetabolicas = this.isDoencasMetabolicas ? 1 : 0;
  }

  onDeslocamentoAbomasoChange() {
    this.item.deslocamentoabomaso = this.isDeslocamentoAbomaso ? 1 : 0;
  }

  onNatimortoCria1Change() {
    this.item.natimortocria1 = this.isNatimortoCria1 ? 1 : 0;
    this.item.numeroCria1 = null;
  }

  onNatimortoCria2Change() {
    this.item.natimortocria2 = this.isNatimortoCria2 ? 1 : 0;
    this.item.numeroCria2 = null;
  }

  onSexoCria1Change(e: any) {
    this.item.sexocria1 = this.isSexoCria1Female ? `F` : `M`;
    const isMale = e.value === `M`;
    if (isMale) {
      this.item.numeroCria1 = ``;
    }
  }

  onSexoCria2Change(e: any) {
    this.item.sexocria2 = this.isSexoCria2Female ? `F` : `M`;
    const isMale = e.value === `M`;
    if (isMale) {
      this.item.numeroCria2 = ``;
    }
  }

  onCriaChange(e: any) {
    this.isGemelar = e.value === 1;
    this.item.gemelar = this.isGemelar;
    if (!this.isGemelar) {
      this.item.sexocria2 = null;
      this.item.natimortocria2 = null;
      this.item.numeroCria2 = null;
      this.isNatimortoCria2 = false;
    }
  }

  itemChanged(e: any) {
    this.itemHasChanged();
  }

  itemHasChanged() {
    this.hasChanges.emit(`true`);
  }

  onParturitionDateChange() {
    const hasParturitionDate = this.item.dtparto !== null && this.item.dtparto !== undefined;
    if (hasParturitionDate) {
      const hasNotParturitionType = this.item.cdtipoparto === undefined || this.item.cdtipoparto === null;
      if (hasNotParturitionType) {
        this.item.cdtipoparto = 1;
      }
      const hasNotParturitionDificulty = this.item.cddificuldadeparto === undefined || this.item.cddificuldadeparto === null;
      if (hasNotParturitionDificulty) {
        this.item.cddificuldadeparto = 1;
      }
      const hasNotGemelar = this.item.gemelar === undefined || this.item.gemelar === null;
      if (hasNotGemelar) {
        this.item.gemelar = false;
      }
    }
  }

  ehTipoDePartoAbortoComLactacao(): boolean {
    return this.item.cdtipoparto === TipoPartoEnum.ABORTO_COM_LACTACAO;
  }

}
