import { CadastroService } from 'src/app/_services/cadastro.service';
import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { DialogoConfirmacao } from '../_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { Pessoa } from 'src/models/pessoa.model';
import { UsuarioService } from '../_services/usuario.service';
import { PopUpAlteracaoFazendaComponent } from '../_utils/popup-alteracao-fazenda/popup-alteracao-fazendacomponent';
import { Fazenda } from 'src/models/fazenda.model';
import { AltaGestaoService } from '../_services/alta-gestao.service';
import { UsuarioFazenda } from 'src/models/usuario-fazenda.model';
import { Router } from '@angular/router';
import { Usuario } from 'src/models/usuario.model';
import { faList, faTachometerAlt, faChartBar, faIndustry, faCalculator, faListOl, faRetweet,
        faChartArea, faCalendarAlt, faPercent, faChartLine, faCalendarPlus, faUsers, faCubes, faSortAmountDownAlt,
        faTimes, faEye, faHourglassEnd, faMarsDouble, faThList, faLayerGroup, faLandmark,
        faDotCircle, faNeuter, faCogs, faFileArchive, faDna, faSortAmountDown, faChartPie,
        faSearchPlus, faCloud, faHashtag, faUserPlus, faObjectGroup, faFileMedical, faStepForward,
        faMapMarker, faMedkit, faSearch, faCalendar, faHistory, faCalendarCheck, faPlus, faMars,
        faWineBottle, faCompressArrowsAlt, faEdit, faUpload, faFileUpload, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { EmailService } from '../_services/email.service';
import { Email } from 'src/models/email.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isMenuOpen = true;
  carregandoFazenda = false;
  anoAtual: string;
  pessoa: Pessoa;
  emailUsuario: string;
  nomeUsuario: string;
  loginUsuario: string;
  nomeFazenda: string;
  usuariosFazenda: UsuarioFazenda[];
  claims: any;
  cdFazendaSelecionada = 0;
  erro = ``;
  fazendaSelecionada: Fazenda;
  existeFazendaSelecionada = true;
  erroCadastroAzure: boolean = false;
  email: Email;

  // icons
  faList = faList;
  faCircle = faDotCircle;
  faSchedule = faCalendarAlt;
  faProduction = faIndustry;
  faReproduction = faDna;
  faConfigurations = faCogs;
  faDashboard = faTachometerAlt;
  faAnalysis = faChartBar;
  faPregnacyDelDistribution = faChartArea;
  faServicesDelDitribution = faIndustry;
  faPregnancyLost = faTimes;
  faLactationCowsExpected = faEye;
  faServicesInterval = faHourglassEnd;
  faWeeklyServices = faCalendarAlt;
  faPregnancyServiceRate = faPercent;
  faConceptionRate = faNeuter;
  faConceptionRateByDEl = faChartLine;
  faConceptionRateByBreeder = faMarsDouble;
  faConceptionRateByProtocol = faCalendarPlus;
  faConceptionRateByMonth = faCalculator;
  faConceptionRateByParturitionOrder = faListOl;
  faConceptionRateByServiceInteraval = faRetweet;
  faConceptionRateByInseminator = faUsers;
  faConceptionRateByBloodGrade = faCubes;
  faConceptionRateByServiceNumber = faSortAmountDownAlt;
  faConceptionRateByServiceType = faThList;
  faConceptionRateConcatenated = faLayerGroup;
  faRegister = faFileMedical;
  faAltaCria = faChartLine;
  faColostragemMortalidade = faChartPie;
  faGpdQtdDoencas = faChartBar;
  faAgeDeseases = faChartArea;
  faDeseasesNumberMonths = faChartLine;
  faReproductionSimulator = faCalculator;
  faFarm = faLandmark;
  faGanhoPesoMeta = faIndustry;
  faSearchPlus = faSearchPlus;
  faBenchmarking = faCloud;
  faBenchmarkingGlobal = faDotCircle;
  faBenchmarkingEstado = faDotCircle;
  faBenchmarkingMesorregiao = faDotCircle;
  faBenchmarkingPerfilRebanho = faDotCircle;
  faBenchmarkingSistemaProducao = faDotCircle;
  faRelatorios = faFileArchive;
  faRelatorioDown = faSortAmountDown;
  faFarmCrud = faHashtag;
  faUserCrud = faUserPlus;
  faGroupCrud = faObjectGroup;
  faParameterCrud = faCogs;
  faInseminators = faUsers;
  faDryings = faStepForward;
  faLots = faMapMarker;
  faProtocols = faMedkit;
  faQuery = faSearch;
  faReproductionOcurrence = faCalendarCheck;
  faIATF = faCalendar;
  faMatrixHistory = faHistory;
  faMatrixRegister = faPlus;
  faBreeders = faMars;
  faDairyControl = faWineBottle;
  faRacialComposition = faCompressArrowsAlt;
  faReleases = faEdit;
  faQueries = faSearch;
  faImports = faUpload;
  faImportMatrix = faFileUpload;
  faTouch = faDesktop;

  snackBarConfig: MatSnackBarConfig = {
    panelClass: ['mat-snack-bar-container'],
    duration: 2500
  };

  constructor(
    private authService: MsalService,
    public dialog: MatDialog,
    private emailService: EmailService,
    private usuarioService: UsuarioService,
    private cadastroService: CadastroService,
    public altaGestaoService: AltaGestaoService,
    private snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.usuariosFazenda = [];
    this.setAnoAtual();
    if (this.getClaims()) {
      this.setEmailUsuarioSessao();
      this.setLoginUsuarioSessao();
      this.getPessoa();
      this.fazendaSelecionada = this.altaGestaoService.getFazendaSelecionada();
      this.existeFazendaSelecionada = this.fazendaSelecionada !== undefined && this.fazendaSelecionada !== null;
    }
  }

  enviaEmailNovoCadastro() {
    this.email = new Email();
    this.email.usuario = this.claims.given_name + ' ' + this.claims.family_name;
    this.email.email = this.claims.emails[0];
    this.email.fazenda = this.claims.extension_Farm ? this.claims.extension_Farm : this.nomeFazenda;
    this.email.dataCadastro = new Date();
    this.emailService.enviaEmailNovoCadastro(this.email).subscribe((response) => {
    }, (error) => console.error(error));
  }

  enviaEmailErroCadastro() {
    this.email = new Email();
    this.email.email = this.claims.emails[0];
    this.email.dataCadastro = new Date();
    this.emailService.enviaEmailErroCadastro(this.email).subscribe((response) => {
    }, (error) => console.error(error));
  }

  public onSidenavClick(): void {
    // this.isMenuOpen = this.isMenuOpen ? false : true;
  }

  getPessoa() {
    const usuarioSessao = this.getUsuarioSessao();
    const existeUsuarioSessao = usuarioSessao !== null && usuarioSessao !== undefined && usuarioSessao.cdpessoa > 0;
    if (!existeUsuarioSessao) {
      this.usuarioService.getUsuariLogadoPorEmail(this.emailUsuario).subscribe((response) => {
        this.pessoa = response;
        const existePessoaCadastrada =
          this.pessoa.cdpessoa !== undefined && this.pessoa.cdpessoa > 0;
        if (!existePessoaCadastrada) {
          this.insereUsuarioCadastradoNaAzure();
        } else if (!this.ehUsuarioBloqueado() && !this.ehUsuarioPendente()) {
          this.usuariosFazenda = this.pessoa.usuario.usuariofazenda;
          this.setUsuarioSessao();
          if (this.ehUsuarioVeterinario()) {
            this.abrePopupAlteracaoFazenda();
          }
          this.setFazendaSessao();
        }
        this.setNomeUsuarioSessao();
        const isNewUser = this.claims.newUser;
        if (isNewUser) {
          this.enviaEmailNovoCadastro();
        }
      }, (error) => console.error(error));
    } else {
      if (!this.ehUsuarioBloqueado() && !this.ehUsuarioPendente()) {
        this.pessoa = usuarioSessao;
        this.usuariosFazenda = usuarioSessao.usuario.usuariofazenda;
        this.setNomeUsuarioSessao();
        this.setFazendaSessao();
      }
    }
  }

  getUsuarioInserido() {
    this.usuarioService.getUsuariLogadoPorEmail(this.emailUsuario).subscribe((response) => {
      this.pessoa = response;
      if (!this.ehUsuarioBloqueado() && !this.ehUsuarioPendente()) {
        this.usuariosFazenda = this.pessoa.usuario.usuariofazenda;
        this.setUsuarioSessao();
        if (this.ehUsuarioVeterinario()) {
          this.abrePopupAlteracaoFazenda();
        }
        this.setNomeUsuarioSessao();
        this.setFazendaSessao();
      }
    }, (error) => console.error(error));
  }

  insereUsuarioCadastradoNaAzure() {
    this.cadastroService.insereUsuarioCadastradoNaAzure(this.criaUsuario()).subscribe((response) => {
      const hasResponse = response !== undefined && response !== null;
      if (hasResponse) {
        const isError = response[`isError`] !== undefined && response[`isError`] !== null;
        if (isError) {
          const hasError = response[`isError`] === true;
          console.log(response[`isError`]);
          if (hasError) {
            this.erro = response[`mensagem`];
            console.log(response[`mensagem`]);
          }
        }
      } else {
        this.getUsuarioInserido();
      }
    }, (error) => console.error(error));
  }

  criaUsuario(): Usuario {
    const usuario = new Usuario();
    usuario.apelido = this.claims.name;
    usuario.bloqueado = false;
    usuario.pendente = true;
    usuario.indicacao = this.claims.extension_WhoReferredTheSystem;
    usuario.cdpessoa = 0;
    usuario.nome = this.claims.given_name + ' ' + this.claims.family_name;
    usuario.email = this.claims.emails[0];
    usuario.cnpj = this.claims.extension_Identification;
    // user.login = this.claims.extension_Login;
    usuario.login = this.claims.emails[0];
    usuario.perfilAcesso = 2;
    usuario.usuariofazenda = [];
    usuario.usuariofazenda.push(this.criaUsuarioFazenda());
    usuario.pessoa = new Pessoa();
    return usuario;
  }

  criaFazenda(): Fazenda {
    const fazenda = new Fazenda();
    fazenda.nome = this.claims.extension_Farm;
    fazenda.proprietario = this.claims.extension_FarmOwner;
    fazenda.cdperfilracialrebanho = this.claims.extension_HerdProfile;
    fazenda.cdsistemaproducao = this.claims.extension_ProductionSystem;
    fazenda.cdpais = this.claims.extension_FarmCountry;
    fazenda.cduf = this.claims.extension_FarmState;
    fazenda.cdmunicipio = this.claims.extension_FarmCity;
    // farm.cidade = this.claims.extension_FarmCity;
    fazenda.telefone = this.claims.extension_Phone;
    return fazenda;
  }

  criaUsuarioFazenda(): UsuarioFazenda {
    const usuarioFazenda = new UsuarioFazenda();
    usuarioFazenda.fazenda = this.criaFazenda();
    return usuarioFazenda;
  }

  ehUsuarioVeterinario(): boolean {
    return (this.usuariosFazenda.length > 1);
  }

  getUsuarioSessao(): Pessoa {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    return currentUser;
  }

  setUsuarioSessao() {
    sessionStorage.setItem('currentUser', JSON.stringify(this.pessoa));
  }

  setNomeUsuarioSessao() {
    // this.nomeUsuario = this.claims.given_name;
    this.nomeUsuario = this.pessoa.nome;
  }

  setEmailUsuarioSessao() {
    this.emailUsuario = this.claims.emails[0];
  }

  setLoginUsuarioSessao() {
    this.loginUsuario = this.claims.extension_Login;
  }

  getFazendaSessao(): Fazenda {
    const currentFarm = JSON.parse(sessionStorage.getItem(`currentFarm`));
    return currentFarm;
  }

  setFazendaSessao() {
    let fazendaSessao = this.getFazendaSessao();
    if (fazendaSessao === null || fazendaSessao === undefined || fazendaSessao.cdfazenda === 0) {
      const existeFazendaLogada = this.usuariosFazenda !== undefined && this.usuariosFazenda.length > 0;
      if (existeFazendaLogada) {
        fazendaSessao = this.usuariosFazenda[0].fazenda;
        this.nomeFazenda = fazendaSessao.nome;
        sessionStorage.setItem(`currentFarm`, JSON.stringify(fazendaSessao));
        this.cdFazendaSelecionada = fazendaSessao.cdfazenda;
      }
    } else {
      this.nomeFazenda = fazendaSessao.nome;
      this.cdFazendaSelecionada = fazendaSessao.cdfazenda;
    }
  }

  getClaims() {
    const claims = this.authService.getAccount().idTokenClaims;
    this.claims = claims;
    const isAdmin = claims['name'] === 'Admin';

    if (!isAdmin) {
      const requiredClaims = ['extension_Farm', 'extension_FarmCity', 'extension_FarmOwner', 'extension_FarmState'];
      const missingRequiredClaim = requiredClaims.some(claim => !claims[claim]);

      if (missingRequiredClaim) {
        this.erroCadastroAzure = true;
        this.enviaEmailErroCadastro();
        return false;
      }
    }

    for (const claim in this.claims) {
      if (claim !== 'extension_WhoReferredTheSystem' &&
        this.claims.hasOwnProperty(claim) && (this.claims[claim] === '' || this.claims[claim] === null || typeof this.claims[claim] === 'undefined'
        || (claim === 'extension_FarmCity' && this.claims[claim] === "0") || (claim === 'name' && this.claims[claim] === "unknown")
        || (claim === 'extension_HerdProfile' && this.claims[claim] === "0"))
      ) {
        this.erroCadastroAzure = true;
        this.enviaEmailErroCadastro();
        return false;
      }
    }

    return true;
  }

  abrePopupAlteracaoFazenda() {
    const dialogRef = this.dialog.open(PopUpAlteracaoFazendaComponent, {
      disableClose: true,
      height: '10px',
      width: '500px',
      position: { top: 100 + 'px' }
    });

    dialogRef.afterClosed().subscribe(result => {
      const hasResult =
        result !== ''
        && result !== undefined
        && result !== null;

      if (hasResult) {
        if (result === `nenhumaFazendaSelecionada`) {
          this.nomeFazenda = ``;
        } else if (result.cdfazenda !== undefined && result.cdfazenda > 0) {
          this.nomeFazenda = result.nome;
          const message = `A fazenda ${ this.nomeFazenda } foi selecionada. Aguarde enquanto os dados são carregados.`;
          this.showSnackBar(message);
          this.recarregaTela();
        }
      } else {
        this.nomeFazenda = ``;
      }
    });
  }

  recarregaTela() {
    this.carregandoFazenda = true;
    const currentUrl = this.router.url;
    this.router.navigate([currentUrl])
      .then(() => {
        window.location.reload();
        this.carregandoFazenda = false;
      });
  }

  logout(): void {
    this.removeUsuarioSessao();
    this.removeFazendaSessao();
    this.authService.logout();
  }

  openLogoutDialog() {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja realmente sair do Alta Gestão ?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.logout();
      }
    });
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'X', this.snackBarConfig);
  }

  removeUsuarioSessao() {
    sessionStorage.removeItem('currentUser');
  }

  removeFazendaSessao() {
    sessionStorage.removeItem('currentFarm');
  }

  setAnoAtual() {
    this.anoAtual = new Date().getFullYear().toString();
  }

  ehUsuarioBloqueado(): boolean {
    if (this.pessoa && this.pessoa.usuario) {
      return (this.pessoa.usuario.bloqueado);
    }
    return false;
  }

  ehUsuarioPendente(): boolean {
    if (this.pessoa && this.pessoa.usuario) {
      return (this.pessoa.usuario.pendente);
    }
    return false;
  }

  ehUsuarioAdministrador(): boolean {
    return this.altaGestaoService.ehUsuarioAdministrador();
  }
}
