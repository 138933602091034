import { ColostragemFiltro } from '../../../models/alta-cria/colostragem-filtro.model';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DATE_FORMATS, MAT_DIALOG_DATA } from '@angular/material';
import { AltaCriaService } from 'src/app/_services/alta-cria.service';
import { Colostragem } from 'src/models/alta-cria/colostragem.model';
import { Matriz } from 'src/models/matriz/matriz.model';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';

@Component({
  selector: 'app-colostragem-cadastro',
  templateUrl: './colostragem-cadastro.component.html',
  styleUrls: ['./colostragem-cadastro.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ColostragemCadastroComponent implements OnInit {

  @ViewChild('errorAlert', { static: false })
  errorAlert: ElementRef;

  matrixPanelOpenState = true;
  colostragemPanelOpenState = true;
  hasChanges = false;
  loading = false;

  matrix: Matriz;
  colostragem: Colostragem;
  tipoFornecimento: string[];
  tipoColostro: string[];
  qualidade: string[];
  avaliacaoProteina: string[];

  error = ``;

  gridSelection: any[] = [];
  selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<ColostragemCadastroComponent>,
              private altaCriaService: AltaCriaService) {
    this.setItems(data);
  }

  ngOnInit() {
    this.colostragem = new Colostragem();
    this.colostragem.cdMatriz = this.matrix.cdmatriz;
    this.colostragem.cdFazenda = this.matrix.cdfazenda;

    this.tipoColostro = [];
    this.tipoFornecimento = [];
    this.qualidade = [];
    this.avaliacaoProteina = [];

    this.tipoColostro.push(`FRESCO`, `DESCONGELADO`, `FRESCO + DESCONGELADO`, `EM PO`, `EM PO + FRESCO`, `EM PO + DESCONGELADO` );
    this.tipoFornecimento.push(`MAE`, `BALDE`, `BALDE COM BICO`, `MAMADEIRA`, `SONDA`);
    this.qualidade.push(`COLOSTROMETRO`, `REFRATOMETRO`, `NENHUMA`);
    this.avaliacaoProteina.push(`BRIX (%)`, `G/DL`);

    this.getColostragem();
  }

  getColostragem() {
    this.loading = true;
    const filter = this.getFilter();
    this.altaCriaService.getColostragem(filter).subscribe(response => {
      const hasResponse = response !== undefined && response.length > 0;
      if (hasResponse) {
        this.colostragem = response[0];
      }
      this.loading = false;
    });
  }

  getFilter(): ColostragemFiltro {
    const filter = new ColostragemFiltro();
    filter.cdMatriz = this.matrix.cdmatriz;
    filter.cdFazenda = this.matrix.cdfazenda;
    return filter;
  }

  setItems(data: any) {
    this.matrix = data.matrix;
    const hasBirthDate =
      this.matrix.dtnascimento !== undefined &&  this.matrix.dtnascimento !== null;
    if (hasBirthDate) {
      this.matrix.dtnascimento = new Date(this.matrix.dtnascimento);
    } else {
      this.matrix.dtnascimento = undefined;
    }
  }

  onCloseClick() {
    if (this.hasChanges) {
      this.saveHandler(true);
    } else {
      this.closeHandler();
    }
  }

  closeHandler() {
    this.dialogRef.close();
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'X', {duration: 2500});
  }

  itemChanged() {
    this.hasChanges = true;
  }

  onSaveClick() {
    this.saveHandler(false);
  }

  saveHandler(closeDialog: boolean) {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja salvar as alterações?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.saveChanges(closeDialog);
      } else if (closeDialog) {
        this.closeHandler();
      }
    });
  }

  saveChanges(closeDialog: boolean) {
    const isNewColostragem =
      this.colostragem.cdColostragem === undefined || this.colostragem.cdColostragem === 0;

    if (isNewColostragem) {
      this.insert(closeDialog);
    } else {
      this.update(closeDialog);
    }
  }

  insert(closeDialog: boolean) {
    this.altaCriaService.insereColostragem([this.colostragem]).subscribe((response: Colostragem[]) => {
      if (response != null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'X', {
          duration: 2500,
        });
        this.hasChanges = false;
        if (closeDialog) {
          this.closeHandler();
        }
      }
    }, (error) => console.error(error));
  }

  update(closeDialog: boolean) {
    this.altaCriaService.atualizaColostragem([this.colostragem]).subscribe((response: Colostragem[]) => {
      if (response != null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'X', {
          duration: 2500,
        });
        this.hasChanges = false;
        if (closeDialog) {
          this.closeHandler();
        }
      }
    }, (error) => console.error(error));
  }

}
