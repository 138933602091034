import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DATE_FORMATS, MAT_DIALOG_DATA } from '@angular/material';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { ProducaoService } from 'src/app/_services/producao.service';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';
import { Secagem } from 'src/models/producao/secagem.model';

@Component({
  selector: 'app-secagem-cadastro',
  templateUrl: './secagem-cadastro.component.html',
  styleUrls: ['./secagem-cadastro.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class SecagemCadastroComponent implements OnInit {

  hasChanges = false;
  selectedMatrix: Secagem;
  itemsSource: Array<Secagem>;
  item: Secagem;
  persistedItem: Secagem;
  dtsecagem: Date;
  dtparto: Date;
  error = ``;
  controleLeiteiros: Array<any>;

  action = 'INSERT';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private producaoService: ProducaoService,
              public dialogRef: MatDialogRef<SecagemCadastroComponent>) {
    this.itemsSource = data.items;
    this.item = data.item;
  }

  ngOnInit() {
   this.setDadosSecagem();
   this.getControlesLeiteiros();
  }

  getControlesLeiteiros() {
    this.producaoService.getControlesLeiteirosLancados().subscribe(request => {
      this.controleLeiteiros = request;
    });
  }

  setDadosSecagem() {
    if (this.item.cdreproducao !== undefined && this.item.cdreproducao > 0) {
      this.selectedMatrix = this.itemsSource.find
      (
        cond => cond.cdmatriz === this.item.cdmatriz && this.item.cdreproducao === cond.cdreproducao
      );
    } else {
      this.selectedMatrix = this.itemsSource.find
      (
        cond => cond.cdmatriz === this.item.cdmatriz
      );
    }
    this.dtparto = this.selectedMatrix.dtparto;
    this.dtsecagem = this.selectedMatrix.dtsecagem;

    if (this.item.cdreproducao !== null && this.item.cdreproducao !== undefined) {
      this.itemsSource = [this.selectedMatrix];

      const newItem = new Secagem();
      newItem.cdfazenda = this.item.cdfazenda;
      newItem.cdreproducao = this.item.cdreproducao;
      newItem.cdmatriz = this.item.cdmatriz;

      newItem.dtsecagem = this.item.dtsecagem;
      newItem.dtparto = this.item.dtparto;
      newItem.nome = this.item.nome;
      newItem.numero = this.item.numero;

      this.persistedItem = this.item;
      this.item = newItem;
      this.action = 'UPDATE';
    }
  }

  onSaveClick() {
    this.saveHandler(true, false);
  }

  protected saveHandler(closeDialog: boolean, isOnCloseClick: boolean) {
    this.item.cdreproducao = this.selectedMatrix.cdreproducao;
    this.item.cdmatriz = this.selectedMatrix.cdmatriz;
    this.item.cdfazenda = this.selectedMatrix.cdfazenda;
    this.item.nome = this.selectedMatrix.nome;
    this.item.numero = this.selectedMatrix.numero;
    this.item.dtparto = this.selectedMatrix.dtparto;
    this.item.dtsecagem = this.dtsecagem;

    if (isOnCloseClick) {
      this.showConfirmationDialog(closeDialog);
    } else {
      if (this.validate()) {
        this.saveChanges(true);
      }
    }
  }

  showConfirmationDialog(closeDialog: boolean) {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja salvar as alterações?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (this.validaDataSecagem()) {
          this.saveChanges(true);
        }
      } else if (closeDialog) {
        this.closeHandler(null);
      }
    });
  }

  private saveChanges(closeDialog: boolean) {
    if (this.action === 'INSERT') {
      this.item.dtsecagem = this.dtsecagem;
      this.producaoService.insereSecagem(this.item).subscribe((response: Secagem) => {
        if (response != null) {
          this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
            duration: 2000,
          });

          if (closeDialog) {
            this.closeHandler(response);
          }
        }
      });
    } else if (this.action === 'UPDATE') {
      this.item.dtsecagem = this.dtsecagem;
      this.producaoService.atualizaSecagens([this.item]).subscribe((response: Array<Secagem>) => {
        if (response != null) {
          this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
            duration: 2000,
          });

          if (closeDialog) {
            this.closeHandler(response);
          }
        }
      });
    }
  }

  onCloseClick() {
    if (this.hasChanges === true) {
      this.saveHandler(true, true);
    } else {
      this.closeHandler(null);
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.hasChanges = true;
  }

  closeHandler(result: any) {
    this.dialogRef.close(result);
  }

  validate(): boolean {
    if (!this.validaDataSecagem()) { return false; }
    if (!this.validaLancamentoSecagemAnteriorLancamentoUltimoControleLeiteiro()) { return false; }
    return true;
  }

  validaDataSecagem(): boolean {
    this.error = ``;
    const existeDataParto = this.item.dtparto !== undefined;
    const existeDataSecagem = this.item.dtsecagem !== undefined;

    if (existeDataParto && existeDataSecagem) {
      const dtSecagem = new Date(this.item.dtsecagem).getTime();
      const dtParto = new Date(this.item.dtparto).getTime();
      const dataAtual = new Date().getTime();
      const ehDataSecagemValida = dtSecagem > dtParto && dtSecagem <= dataAtual;

      if (!ehDataSecagemValida) {
        this.error = `A data da secagem não pode ser menor que a data do parto e nem maior que a data atual.`;
        this.showAlertDialog(this.error);
        return false;
      }
    }
    return true;
  }

  validaLancamentoSecagemAnteriorLancamentoUltimoControleLeiteiro(): boolean {
    const controlesLancados = this.controleLeiteiros.filter(controle => controle.cdMatriz === this.item.cdmatriz);
    const existemControlesLancados = controlesLancados !== undefined && controlesLancados.length > 0;

    if (existemControlesLancados)
    {
      const ultimoControleLeiteiro = controlesLancados.sort((one, two) => (one.dataControle > two.dataControle ? -1 : 1))[0];
      const existeUltimoControleLeiteiro = ultimoControleLeiteiro !== undefined && ultimoControleLeiteiro !== null;

      if (existeUltimoControleLeiteiro)
      {
        const dtSecagem = new Date(this.item.dtsecagem).getTime();
        const dataControle = new Date(ultimoControleLeiteiro.dataControle).getTime();
        
        if (dtSecagem < dataControle)
        {
          this.error = `A data da secagem não pode ser menor que a data do último controle leiteiro lançado.`;
          this.showAlertDialog(this.error);
          return false;
        }
      }
    }
    return true;
  }

  showAlertDialog(msg: string) {
    this.dialog.open(DialogoAlertaComponent, {
      data: {
              message: msg,
              buttonText: {
              cancel: 'Ok'
              }
          },
      });
  }

}
