import { BaseTouroFiltro } from '../../../models/configuracoes/base-touros-filtro.model';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatRadioButton } from '@angular/material';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { ConfiguracoesService } from 'src/app/_services/configuracoes.service';
import { BaseTouro } from 'src/models/configuracoes/base-touros.model';
import { Raca } from 'src/models/configuracoes/raca.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Reprodutor } from 'src/models/configuracoes/reprodutor.model';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-reprodutor-cadastro',
    templateUrl: './reprodutor-cadastro.component.html',
    styleUrls: ['./reprodutor-cadastro.component.scss'],
  })

export class ReprodutorCadastroComponent implements OnInit {

    @ViewChild('tiporeprodutor0', { static: false }) tiporeprodutor0: MatRadioButton;
    @ViewChild('tiporeprodutor1', { static: false }) tiporeprodutor1: MatRadioButton;

    selectedValue: any;

    hasChanges = false;
    editBaseTouros = false;
    edited = false;
    loading = false;
    loadingBaseTouros = false;
    filterBaseTouros = faFilter;
    total = 0;
    codigoBaseUnif: number;
    regInternacional: string;
    codigoInternacional: string;
    nomeCompleto: string;
    nomeResumido: string;
    raca: any;

    reprodutorListAll: Array<Reprodutor>;
    baseTourosListAll: Array<BaseTouro>;
    baseTourosList: Array<BaseTouro>;
    resultFilter: Array<BaseTouro>;
    clickedRowItem: any;
    racaListAll: Array<Raca>;
    validReprodutor: any;
    message = '';
    baseTourosFiltrados: BaseTouro[];

    gridView: GridDataResult;
    pageSize = 100;
    skip = 0;

    myControl = new FormControl();
    filteredOptions: Observable<BaseTouroFiltro[]>;

    selectedCallback = (args: { dataItem: any; }) => args.dataItem

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ReprodutorCadastroComponent>,
        private reprodutorService: ConfiguracoesService,
        private dialog: MatDialog) { }

    ngOnInit() {
        this.gridView = {
            data: [],
            total: 0
        };
        this.selectedValue = -1;
        this.getAllReprodutor();
        // this.getBaseTouro();
        this.getRaca();
        // this.verificaCargaBaseDados();
    }

    getAllReprodutor() {
        this.loading = true;
        this.reprodutorService.getReprodutores().subscribe((response) => {
            this.reprodutorListAll = response;
        });
        this.loading = false;
    }

    cancel() {
        this.dialogRef.close({ data: 'cancelar' });
    }

    confirm() {
        if (this.validateReprodutor()) {
            this.save(this.tiporeprodutor0.checked);
            this.dialogRef.close({
                tiporeprodutor0: this.tiporeprodutor0.checked
                , semenTouroId: this.data.semenTouroId });
        }
    }

    filterResults() {
      this.loadingBaseTouros = true;
      const filter = this.createBaseTouroFilter();
      this.reprodutorService.getBaseTourosPorFiltro(filter).subscribe((response) => {
        this.baseTourosList = response;
        this.loadingBaseTouros = false;
      }, error => {
        this.loadingBaseTouros = false;
        this.openMessageAviso();
      });
    }

    getBaseTouro() {
        // if (this.validateFilters()) {
            // this.loadingBaseTouros = true;
            // const filter = this.createBaseTouroFilter();
            // this.reprodutorService.getBaseTourosPorFiltro(filter).subscribe((response) => {
            //     this.baseTourosListAll = response;
            //     this.baseTourosList = response;
            //     // this.filteringBaseTouros();
            //     this.setPagination();
            //     this.loadingBaseTouros = false;
            // });
        // }
    }

    createBaseTouroFilter(): BaseTouroFiltro  {
        const filter = new BaseTouroFiltro();
        filter.bullId = this.codigoBaseUnif;
        filter.interregnum = this.regInternacional;
        filter.semencode = this.codigoInternacional;
        filter.name = this.nomeCompleto;
        filter.shortname = this.nomeResumido;
        filter.breed = this.selectedValue.sigla;

        return filter;
    }

    validateFilters(): boolean {
        this.setFilterValues();

        let hasAnyFilter = false;
        this.message = ``;

        const hasCodigoUnif =
            this.codigoBaseUnif !== null && this.codigoBaseUnif !== undefined && this.codigoBaseUnif > 0;
        if (hasCodigoUnif) {
            hasAnyFilter = true;
        }

        const hasRegInternacional =
            this.regInternacional !== null && this.regInternacional !== undefined && this.regInternacional !== '';
        if (hasRegInternacional) {
            hasAnyFilter = true;
        }

        const hasCodigoInternacional =
            this.codigoInternacional !== null && this.codigoInternacional !== undefined && this.codigoInternacional !== '';
        if (hasCodigoInternacional) {
            hasAnyFilter = true;
        }

        const hasNome = this.nomeCompleto !== null && this.nomeCompleto !== undefined && this.nomeCompleto !== '';
        if (hasNome) {
            hasAnyFilter = true;
        }

        const hasNomeResumido = this.nomeResumido !== null && this.nomeResumido !== undefined && this.nomeResumido !== '';
        if (hasNomeResumido) {
            hasAnyFilter = true;
        }

        const hasRaca = this.raca !== null && this.raca !== undefined && this.raca !== '' && this.raca !== -1;
        if (hasRaca) {
            hasAnyFilter = true;
        }

        if (!hasAnyFilter) {
            this.message = `É necessário informar ao menos um filtro para listar os touros`;
            return false;
        }

        return true;
    }

    setFilterValues() {
        this.codigoBaseUnif         = Number(((document.getElementById('codigoBaseUnif') as HTMLInputElement).value));
        this.regInternacional       = ((document.getElementById('registroInternacional') as HTMLInputElement).value);
        this.codigoInternacional    = ((document.getElementById('codigoInternacionalSemenCode') as HTMLInputElement).value);
        this.nomeCompleto           = ((document.getElementById('nomeCompleto') as HTMLInputElement).value);
        this.nomeResumido           = ((document.getElementById('nomeResumido') as HTMLInputElement).value);
        this.raca                   = this.selectedValue;
    }

    getRaca() {
        this.loading = true;
        this.reprodutorService.getRacas().subscribe((response) => {
            this.racaListAll = response;
            this.loading = false;
      });
    }

    setPagination() {
        this.gridView = {
            data: this.baseTourosList.slice(this.skip, this.skip + this.pageSize),
            total: this.baseTourosList.length
            };
    }

    hasValue(statusValue) {
        if ( statusValue.trim() !== '' && statusValue !== undefined ) {
            this.hasChanges = true;
        } else {
            this.hasChanges = false;
        }
        this.setBreederType();
    }

    setBreederType() {
        const isBull = this.tiporeprodutor0.checked;
        const isSemen = this.tiporeprodutor1.checked;
        if (isBull) {
            this.data.semenTouroId.tipo = 'touro';
        } else if (isSemen) {
            this.data.semenTouroId.tipo = 'semen';
        }
    }

    onCellClick(e) {
        this.clickedRowItem = e.dataItem;
    }

    onDblClick() {
        if (this.clickedRowItem !== '' && this.clickedRowItem !== undefined) {
            this.data.semenTouroId.codigoBaseunif = this.clickedRowItem.bullId;
            this.data.semenTouroId.BullId1 = this.clickedRowItem.bullId;
            this.data.semenTouroId.regInternacional = this.clickedRowItem.interregnum;
            this.data.semenTouroId.codigoInternacional = this.clickedRowItem.semencode;

            this.editBaseTouros = false;
            this.edited = false;
        }
    }

    openBaseTouros() {
        this.editBaseTouros = true;
        this.edited = true;
        this.hasChanges = true;
    }

    closeBaseTouros() {
        this.editBaseTouros = false;
        this.edited = false;
    }

    saveBaseTouros() {
        if (this.clickedRowItem !== '' && this.clickedRowItem !== undefined) {
            this.hasChanges = false;
            this.editBaseTouros = false;
            this.edited = false;
            this.data.semenTouroId.codigoBaseunif = this.clickedRowItem.bullId;
            this.data.semenTouroId.BullId1 = this.clickedRowItem.bullId;
            this.data.semenTouroId.regInternacional = this.clickedRowItem.interregnum;
            this.data.semenTouroId.codigoInternacional = this.clickedRowItem.semencode;

            this.editBaseTouros = false;
            this.edited = false;
        }
    }

    filteringBaseTouros() {
        // this.getBaseTouro();
        // this.loadingBaseTouros = true;

        // this.resultFilter         = [];

        // const codigoBaseUnif      = ((document.getElementById('codigoBaseUnif') as HTMLInputElement).value);
        // const regInternacional    = ((document.getElementById('regInternacional') as HTMLInputElement).value);
        // const codigoInternacional = ((document.getElementById('codigoInternacional') as HTMLInputElement).value);
        // const nomeCompleto        = ((document.getElementById('nomeCompleto') as HTMLInputElement).value);
        // const nomeResumido        = ((document.getElementById('nomeResumido') as HTMLInputElement).value);
        // const raca: any = this.selectedValue;

        // const hasBaseTouros = this.baseTourosListAll !== undefined;
        // if (!hasBaseTouros) {
        //     if (this.data.baseTourosListAll === undefined) {
        //         this.openMessage();
        //     } else {
        //         this.baseTourosList = this.data.baseTourosListAll;
        //     }
        // } else {
        //     this.baseTourosList = this.baseTourosListAll;
        // }

        // this.resultFilter = this.baseTourosList;

        // const hasCodigoUnif =
        //     codigoBaseUnif !== null && codigoBaseUnif !== undefined && codigoBaseUnif !== '';
        // if (hasCodigoUnif) {
        //     const baseTouro = this.baseTourosList.filter(x => (x.bullId !== null));

        //     this.resultFilter = [];

        //     if (baseTouro.length > 0) {
        //         this.resultFilter = baseTouro;
        //     }

        //     const termo = parseFloat(codigoBaseUnif);
        //     const reprodutor1 = this.resultFilter.filter (y => y.bullId === termo);

        //     this.baseTourosList = [];

        //     if (reprodutor1.length > 0) {
        //         this.resultFilter = [];
        //         this.resultFilter = reprodutor1;
        //     } else {
        //         this.resultFilter = [];
        //     }
        // }

        // const hasRegInternacional =
        //     regInternacional !== null && regInternacional !== undefined && regInternacional !== '';
        // if (hasRegInternacional) {
        //     const baseTouro = this.resultFilter.filter (y => y.interregnum.toUpperCase().includes(regInternacional));

        //     if (baseTouro.length > 0) {
        //         this.resultFilter = [];
        //         this.resultFilter = baseTouro;
        //     } else {
        //         this.resultFilter = [];
        //     }
        // }

        // const hasCodigoInternacional =
        //     codigoInternacional !== null && codigoInternacional !== undefined && codigoInternacional !== '';
        // if (hasCodigoInternacional) {
        //     const baseTouro = this.resultFilter.filter (y => y.semencode.includes(codigoInternacional));

        //     if (baseTouro.length > 0) {
        //         this.resultFilter = [];
        //         this.resultFilter = baseTouro;
        //     } else {
        //         this.resultFilter = [];
        //     }
        // }

        // const hasNome = nomeCompleto !== null && nomeCompleto !== undefined && nomeCompleto !== '';
        // if (hasNome) {
        //     const baseTouro = this.resultFilter.filter (y => y.name.toUpperCase().includes(nomeCompleto.toUpperCase()));

        //     if (baseTouro.length > 0) {
        //         this.resultFilter = [];
        //         this.resultFilter = baseTouro;
        //     } else {
        //         this.resultFilter = [];
        //     }
        // }

        // const hasNomeResumido = nomeResumido !== null && nomeResumido !== undefined && nomeResumido !== '';
        // if (hasNomeResumido) {
        //     const baseTouro = this.resultFilter.filter (y => y.shortname.toUpperCase().includes(nomeResumido.toUpperCase()));

        //     if (baseTouro.length > 0) {
        //         this.resultFilter = [];
        //         this.resultFilter = baseTouro;
        //     } else {
        //         this.resultFilter = [];
        //     }
        // }

        // const hasRaca = raca !== null && raca !== undefined && raca !== '' && raca !== -1;
        // if (hasRaca) {
        //     const raca1 = this.resultFilter.filter(x => x.breed === raca.sigla);
        //     if (raca1.length > 0) {
        //         this.resultFilter = [];
        //         this.resultFilter = raca1;
        //     } else {
        //         this.resultFilter = [];
        //     }
        // }

        // this.baseTourosList = [];
        // this.baseTourosList = this.resultFilter;
        // this.total = this.baseTourosList.length;

        // this.gridView = {
        //     data: [],
        //     total: 0
        // };

        // this.gridView = {
        //     data: this.baseTourosList,
        //     total: this.total
        // };

        // this.loadingBaseTouros = false;
    }

    openMessage() {
        const dialogRef2 = this.dialog.open(DialogoAlertaComponent, {
        data: {
                message: 'A Base de Touros ainda está sendo carregada. Aguarde!',
                buttonText: {
                cancel: 'Ok'
                }
            },
        });
    }

    openMessageAviso() {
        const dialogRef3 = this.dialog.open(DialogoAlertaComponent, {
        data: {
                message: 'A Base de Touros é muito pesada para ser carregada. Preencha um dos campos e clique novamente em filtrar!',
                buttonText: {
                cancel: 'Ok'
                }
            },
        });
    }

    // verificaCargaBaseDados() {
    //     if (this.baseTourosListAll !== undefined || this.data.baseTourosListAll !== undefined) {
    //         this.loadingBaseTouros = false;
    //     } else {
    //         this.loading = true;
    //     }
    // }

    save(tiporeprodutor0: boolean) {
        const hasCdFarm =
            this.data.semenTouroId.cdFazenda !== null
            && this.data.semenTouroId.cdFazenda !== undefined;

        const hasValidBreeder =
            this.validReprodutor.cdreprodutor !== null
            && this.validReprodutor.cdreprodutor !== undefined
            && this.validReprodutor.cdreprodutor !== 0;

        this.data.semenTouroId.cdFazenda = hasCdFarm ? this.data.semenTouroId.cdFazenda : this.reprodutorService.getCdFazenda();
        this.data.semenTouroId.cdtiporeprodutor = tiporeprodutor0 === false ? 0 : 1;

        if (hasValidBreeder) {
            this.reprodutorService.atualizaReprodutor(this.data.semenTouroId).subscribe((response: Array<Reprodutor>) => {
            if (response != null) {
            }
            });
        } else {
            if (this.message !== '') {
            this.message = '';
            } else {
            this.reprodutorService.insereReprodutor(this.data.semenTouroId).subscribe((response) => {
            });
            }
        }
    }

    validateReprodutor(): boolean {
        let isValid = true;
        const hasBreeder =
            this.data.semenTouroId.numero !== null
            && this.data.semenTouroId.numero !== undefined
            && this.data.semenTouroId.numero !== '';

        if (hasBreeder) {
            this.validReprodutor = this.data.semenTouroId;
            for (const item of this.reprodutorListAll) {
                if (item.cdreprodutor !== this.data.semenTouroId.cdreprodutor
                    && item.numero.toUpperCase() === this.data.semenTouroId.numero.toUpperCase() ) {
                        this.validReprodutor = [];
                        isValid = false;
                        this.showAlertDialog(`Este número já existe no cadastro`);
                }
            }
        } else {
            this.showAlertDialog(`É necessário informar o número`);
            isValid = false;
        }

        return isValid;
    }

    showAlertDialog(msg: string) {
        this.dialog.open(DialogoAlertaComponent, {
          data: {
            message: msg,
            buttonText: {
              cancel: 'OK'
            }
          }
        });
    }
}
