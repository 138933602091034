import { Injectable } from '@angular/core';
import { Leite } from 'src/models/leite.model';
import { Matriz } from 'src/models/matriz/matriz.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';

@Injectable({
  providedIn: 'root'
})
export class ValidacaoSecagemReproducaoService {

  reproducao: any;
  matriz: Matriz;
  erro: string;
  reproducoes: Reproducao[];
  matrizes: Matriz[];
  controlesLeiteiros: Leite[];

  constructor() { }

  validaLancamentoSecagem(
    reproducoes: Reproducao[],
    reproducao: any,
    matriz: Matriz,
    controlesLeiteiros: Leite[]): boolean {
    this.reproducao = reproducao;
    this.matriz = matriz;
    this.erro = ``;
    this.reproducoes = reproducoes;
    this.controlesLeiteiros = controlesLeiteiros;

    if (!this.ehDataSecagemValida()) { return false; }

    return true;
  }

  private ehDataSecagemValida(): boolean {
    if (!this.ehDataSecagemMenorQueDataAtual()) { return false; }
    if (!this.ehDataSecagemAnteriorParto()) { return false; }
    if (!this.existeDataPartoLancada()) { return false; }
    if (!this.ehDataSecagemAnteriorProximoParto()) { return false; }
    if (!this.existemControlesLeiteirosDentroDoIntervaloDaLactacao()) { return false; }
    return true;
  }

  private ehDataSecagemMenorQueDataAtual(): boolean {
    if (this.existeDataSecagem()) {
      let ehDataSecagemValida = true;
      const dataAtual = new Date();
      const dataSecagem = new Date(this.reproducao.dtsecagem);
      ehDataSecagemValida = dataSecagem <= dataAtual;
      if (!ehDataSecagemValida) {
        this.erro = `A data de secagem não pode ser superior a data atual. Matriz: ${ this.matriz.numero }.`;
        return ehDataSecagemValida;
      }
    }
    return true;
  }

  private ehDataSecagemAnteriorParto(): boolean {
    if (this.existeDataSecagem() && this.existeDataParto()) {
      let ehDataSecagemValida = true;
      const dataParto = new Date(this.reproducao.dtparto);
      const dataSecagem = new Date(this.reproducao.dtsecagem);
      ehDataSecagemValida = dataSecagem > dataParto;
      if (!ehDataSecagemValida) {
        this.erro = `A data de secagem não pode ser anterior ao parto. Matriz: ${ this.matriz.numero }.`;
        return ehDataSecagemValida;
      }
    }
    return true;
  }

  private existeDataPartoLancada(): boolean {
    if (this.existeDataSecagem() && !this.existeDataParto()) {
      this.erro =
        `Não pode ser informada data de secagem para registros que não tenham data de parto informada. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private ehDataSecagemAnteriorProximoParto(): boolean {
    const reproducoes =
      this.reproducoes.filter(
          reproducao => reproducao.cdmatriz === this.reproducao.cdmatriz
          && reproducao.dtparto > this.reproducao.data
          && reproducao.cdreproducao !== this.reproducao.cdreproducao);
    const existemReproducoes = reproducoes !== undefined && reproducoes.length > 0;

    if (this.existeDataSecagem() && existemReproducoes) {
      const dtAux =
        reproducoes.sort((a, b) => (new Date(a.data).getTime() - new Date(b.data).getTime()))[reproducoes.length - 1].dtparto;
      if (this.reproducao.dtsecagem >= dtAux) {
        this.erro =
          `A data da secagem tem que ser anterior ao próximo parto caso exista. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  private existemControlesLeiteirosDentroDoIntervaloDaLactacao(): boolean {
    const controlesLeiteiros = this.controlesLeiteiros.filter(
      controleLeiteiro => controleLeiteiro.cdMatriz === this.reproducao.cdmatriz
      && controleLeiteiro.data > this.reproducao.dtsecagem
      && controleLeiteiro.cdReproducao === this.reproducao.cdreproducao);
    const existeControleLeiteiro = controlesLeiteiros !== undefined && controlesLeiteiros.length > 0;

    if (existeControleLeiteiro) {
      this.erro =
        `A data de secagem não pode ser gravada caso existam controles leiteiros fora do intervalo da lactação.
          Matriz: ${ this.matriz.numero }`;
      return false;
    }
    return true;
  }

  private existeDataSecagem() {
    const existeDataSecagem =
      this.reproducao.dtsecagem !== null
      && this.reproducao.dtsecagem !== undefined;
    return existeDataSecagem;
  }

  private existeDataParto(): boolean {
    const existeDataParto =
      this.reproducao.dtparto !== undefined
      && this.reproducao.dtparto !== null;
    return existeDataParto;
  }

  getErro(): string {
    return this.erro;
  }
}
