import { TipoTentativaEnum } from 'src/app/_enums/tipo-tentativa.enum';
import { TipoTentativa } from 'src/models/tipo-tentativa.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatSnackBar, MAT_DATE_FORMATS } from '@angular/material';
import { Inseminador } from 'src/models/configuracoes/inseminador.model';
import { Protocolo } from 'src/models/configuracoes/protocolo.model';
import { SemenTouro } from 'src/models/configuracoes/semen-touro.model';
import { ComposicaoRacial } from 'src/models/matriz/composicao-racial.model';
import { Genealogia } from 'src/models/matriz/genealogia.model';
import { Lote } from 'src/models/matriz/lote.model';
import { Matriz } from 'src/models/matriz/matriz.model';
import { MotivoBaixa } from 'src/models/matriz/motivo-baixa.model';
import { Reprodutor } from 'src/models/matriz/reprodutor.model';
import { InseminadorCadastroComponent } from '../../configurations/inseminador-cadastro/inseminador-cadastro.component';
import { ReprodutorCadastroComponent } from '../../configurations/reprodutor-cadastro/reprodutor-cadastro.component';
import { MatrizCadastroComponent } from '../../matrix/matriz-cadastro/matriz-cadastro.component';
import { ConfiguracoesService } from '../../_services/configuracoes.service';
import { ReproducaoService } from '../../_services/reproducao.service';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';
import { MatrizService } from 'src/app/_services/matriz.service';

@Component({
  selector: 'app-ia-cob-te-detalhe',
  templateUrl: './ia-cob-te-detalhe.component.html',
  styleUrls: ['./ia-cob-te-detalhe.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class IaCobTeDetalheComponent implements OnInit {

  @Input() item: any;
  @Output() hasChanges = new EventEmitter<string>();

  breeders: Reprodutor[];
  breedersFiltered: Reprodutor[];
  protocols: Protocolo[];
  inseminators: Inseminador[];
  matrix: Matriz[];
  semenTourosListAll: SemenTouro[];
  semenTourosList: SemenTouro[];
  lots: Lote[];
  motivoBaixa: MotivoBaixa[];
  racialCompositions: ComposicaoRacial[];
  genealogies: Genealogia[];
  tryTypes: TipoTentativa[];

  selectedBreeder: Reprodutor;
  selectedDoadora: Matriz;
  selectedInseminator: Inseminador;

  semenTouroId: any;

  constructor(
    public dialog: MatDialog,
    private reproducaoService: ReproducaoService,
    private matrizService: MatrizService,
    private configurationsService: ConfiguracoesService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.loadItems();
    this.setUsoDescongelador();
  }

  loadItems() {
    this.getTryTypes();
    this.getSemenTouro();
    this.getBreeders();
    this.getProtocols();
    this.getInseminators();
    this.getLots();
    this.getMatrix();
    this.getMotivoBaixa();
    this.getComposicaoRacial();
    this.getGenealogia();

    console.log(this.item)
  }

  getSemenTouro() {
      this.configurationsService.getSemenTouros().subscribe((response) => {
      this.semenTourosListAll = response;
      this.semenTourosList = response;
    }, (error) => console.error(error));
  }

  getBreeders() {
    this.reproducaoService.getReprodutores().subscribe((response) => {
      this.breeders = response;
      this.breedersFiltered = response;
      this.selectedBreeder =
        this.breeders.find(breeder => breeder.cdreprodutor === this.item.cdreprodutor);
      this.onTryTypeChange(this.item.cdtipotentativa);
    }, (error) => console.error(error));
  }

  getProtocols() {
    this.configurationsService.getProtocolos().subscribe((response) => {
      this.protocols = response;
    }, (error) => console.error(error));
  }

  getInseminators() {
    this.configurationsService.getInseminadores().subscribe((response) => {
      this.inseminators = response;
      this.selectedInseminator =
        this.inseminators.find(inseminator => inseminator.cdinseminador === this.item.cdinseminador);
    }, (error) => console.error(error));
  }

  getMatrix() {
    this.matrizService.getMatrizes().subscribe((response) => {
      this.matrix = response.filter(m => m.doadora === true);
      this.selectedDoadora =
        this.matrix.find(matrix => matrix.cdmatriz === this.item.cddoadora);
    }, (error) => console.error(error));
  }

  getMotivoBaixa() {
    this.matrizService.getMotivoBaixa().subscribe((response) => {
      this.motivoBaixa = response;
    }, (error) => console.error(error));
  }

  getLots() {
    this.matrizService.getLote().subscribe((response) => {
      this.lots = response;
    }, (error) => console.error(error));
  }

  getComposicaoRacial() {
    this.matrizService.getComposicaoRacial().subscribe((response) => {
      this.racialCompositions = response;
    }, (error) => console.error(error));
  }

  getGenealogia() {
    this.matrizService.getGenealogia().subscribe((response) => {
      this.genealogies = response;
    }, (error) => console.error(error));
  }

  getTryTypes() {
    this.reproducaoService.getTiposTentativa().subscribe((response) => {
    this.tryTypes = response;
    this.setTryType();
    }, (error) => console.error(error));
  }

  setTryType() {
    this.item.cdtipotentativa =
        this.item.cdtipotentativa === undefined || this.item.cdtipotentativa === null ? 0 : this.item.cdtipotentativa;
  }

  setUsoDescongelador() {
    this.item.usodescongelador =
      this.item.usodescongelador === undefined || this.item.usodescongelador === null ? 0 : this.item.usodescongelador;
  }

  getBullTypeBreeders() {
    const hasBreeders = this.breeders !== undefined && this.breeders.length > 0;
    if (hasBreeders) {
      this.breedersFiltered = this.breeders.filter(breeder => breeder.cdtiporeprodutor === 1);
    }
  }

  getSemenTypeBreeders() {
    const hasBreeders = this.breeders !== undefined && this.breeders.length > 0;
    if (hasBreeders) {
      this.breedersFiltered = this.breeders.filter(breeder => breeder.cdtiporeprodutor === 0);
    }
  }

  itemChanged(e: any) {
    this.itemHasChanged();
  }

  onSelectedBreederChange() {
    this.item.cdreprodutor = this.selectedBreeder.cdreprodutor;
  }

  onSelectedInseminatorChange() {
    this.item.cdinseminador = this.selectedInseminator.cdinseminador;
  }

  onSelectedDoadoraChange() {
    this.item.cddoadora = this.selectedDoadora.cdmatriz;
  }

  onTryTypeChange(value: any) {
    if (value === TipoTentativaEnum.IA || value === TipoTentativaEnum.COB) {
      this.item.cddoadora = null;
      this.selectedDoadora = null;
    }
    if (value === TipoTentativaEnum.IA) {
      this.getSemenTypeBreeders();
    }
    if (value === TipoTentativaEnum.COB) {
      this.getBullTypeBreeders();
      this.selectedInseminator = null;
    }
    if (value === TipoTentativaEnum.TE) {
      this.breedersFiltered = this.breeders;
      this.selectedInseminator = null;
    }
  }

  ehTipoLancamentoTE() {
    return this.item.cdtipotentativa === TipoTentativaEnum.TE;
  }

  ehTipoLancamentoMonta() {
    return this.item.cdtipotentativa === TipoTentativaEnum.COB;
  }

  editInseminator() {
    const hasSelectedInseminator = this.selectedInseminator !== undefined && this.selectedInseminator !== null;
    if (hasSelectedInseminator) {
      this.itemHasChanged();
      this.openInseminatorDialog(this.selectedInseminator);
    } else {
      this.showAlertDialog(`Selecione um inseminador para editar`);
    }
  }

  createInseminator() {
    this.itemHasChanged();
    const inseminator = new Inseminador();
    this.openInseminatorDialog(inseminator);
  }

  editBreeder() {
    const hasSelectedBreeder = this.selectedBreeder !== undefined && this.selectedBreeder !== null;
    if (hasSelectedBreeder) {
      this.itemHasChanged();
      this.semenTouroId = this.semenTourosListAll.find(o => o.cdreprodutor === this.selectedBreeder.cdreprodutor);
      this.openBreederDialog(this.semenTouroId);
    } else {
      this.showAlertDialog(`Selecione um reprodutor para editar`);
    }
  }

  createBreeder() {
    this.itemHasChanged();
    const breeder = new SemenTouro();
    this.openBreederDialog(breeder);
  }

  editMatrix() {
    const hasSelectedMatrix = this.selectedDoadora !== undefined && this.selectedDoadora !== null;
    if (hasSelectedMatrix) {
      this.itemHasChanged();
      this.openMatrixDialog(this.selectedDoadora);
    } else {
      this.showAlertDialog(`Selecione uma doadora para editar`);
    }
  }

  createMatrix() {
    this.itemHasChanged();
    const matrix = new Matriz();
    this.openMatrixDialog(matrix);
  }

  public openBreederDialog(semenTouro: SemenTouro) {
    const dialogRef = this.dialog.open(ReprodutorCadastroComponent, {
      disableClose: true,
          height: '440px',
          width: '600px',
      data: {
        semenTouroId: semenTouro
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const a = document.createElement('a');
      a.click();
      a.remove();
      if (res && res.data === 'cancelar') {
        this.showSnackBar('Operação Cancelada.');
      } else {
        this.getBreeders();
      }
    });
  }

  public openMatrixDialog(matrix: Matriz) {
    const dialogRef = this.dialog.open(MatrizCadastroComponent, {
      disableClose: true,
      height: '600px',
      width: '800px',
      data: {
        matrixId: matrix
        , motivoBaixa: this.motivoBaixa
        , composicaoRacial: this.racialCompositions
        , lote: this.lots
        , pai: this.breeders
        , mae: this.matrix
        , genealogia: this.genealogies
        // , numPartos: this.numPartos.length
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const a = document.createElement('a');
      a.click();
      a.remove();
      if (res && res.data === 'cancelar') {
        this.showSnackBar('Operação Cancelada.');
      } else {
        // this.showResult(res.infoRealNascer0, res.infoRealDesaleitamento0);
      }
    });
  }

  public openInseminatorDialog(inseminator: Inseminador) {
    const dialogRef = this.dialog.open(InseminadorCadastroComponent, {
      disableClose: true,
      height: '300px',
      width: '500px',
      data: {
        inseminatorId: inseminator
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const a = document.createElement('a');
      a.click();
      a.remove();
      if (res && res.data === 'cancelar') {
        this.showSnackBar('Operação cancelada.');
      } else {
        this.getInseminators();
      }
    });
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'X', {duration: 2500});
  }

  itemHasChanged() {
    this.hasChanges.emit(`true`);
  }

  showAlertDialog(msg: string) {
    this.dialog.open(DialogoAlertaComponent, {
      data: {
              message: msg,
              buttonText: {
              cancel: 'Ok'
              }
          },
      });
  }

}
