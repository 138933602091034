import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LogAcessoService } from 'src/app/_services/log-acesso.service';
import { Fazenda } from 'src/models/fazenda.model';
import { LogAcesso } from 'src/models/logacesso.model';
import { Pessoa } from 'src/models/pessoa.model';
import { UsuarioFazenda } from 'src/models/usuario-fazenda.model';
import { AltaGestaoService } from '../../_services/alta-gestao.service';
import { UsuarioService } from '../../_services/usuario.service';

@Component({
  selector: 'app-popup-alteracao-fazenda',
  templateUrl: './popup-alteracao-fazenda.component.html',
  styleUrls: ['./popup-alteracao-fazenda.component.scss']
})
export class PopUpAlteracaoFazendaComponent implements OnInit {

  myControl = new FormControl();
  listaFazendasFiltrada: Observable<Fazenda[]>;
  listaFazendas: Fazenda[];
  listaUsuariosFazenda: UsuarioFazenda[];
  fazendaSelecionada: Fazenda;
  isAdmin = false;
  isAdminTecnico = false;
  closed = `closed`;
  nenhumaFazendaSelecionada = `nenhumaFazendaSelecionada`;

  constructor(
    public dialogRef: MatDialogRef<PopUpAlteracaoFazendaComponent>,
    private usuarioService: UsuarioService,
    private altaGestaoService: AltaGestaoService,
    private logAcessoService: LogAcessoService) { }

  ngOnInit() {
    this.listaFazendas = [];
    this.isAdmin = this.altaGestaoService.ehUsuarioAdministrador();
    this.isAdminTecnico = this.altaGestaoService.ehUsuarioAdministradorTecnico();
    this.setFazendasUsuarioSessao();
  }

  displayFn(farm: Fazenda): string {
    return farm && farm.nome ? farm.nome + ' (' + farm.proprietario + ')' : '';
  }

  private _filter(nome: string): Fazenda[] {
    const filterValue = nome.toLowerCase();
    return this.listaFazendas.filter(farm => farm.nome.toLowerCase().includes(filterValue));
  }

  setFazendasFiltradas() {
    this.listaFazendasFiltrada = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nome),
        map(nome => nome ? this._filter(nome) : this.listaFazendas.slice())
    );
  }

  getUsuarioSessao(): Pessoa {
    const usuarioSessao = JSON.parse(sessionStorage.getItem('currentUser'));
    return usuarioSessao;
  }

  setFazendasUsuarioSessao() {
    const usuarioSessao = this.getUsuarioSessao();
    const existeUsuarioFazenda = usuarioSessao.usuario.usuariofazenda !== undefined;
    if (existeUsuarioFazenda) {
      this.listaUsuariosFazenda = usuarioSessao.usuario.usuariofazenda;
      this.setFazendas();
      this.setFazendasFiltradas();
    } else {
      this.usuarioService.getUsuariLogadoPorEmail(usuarioSessao.email).subscribe((response) => {
        this.listaUsuariosFazenda = response.usuario.usuariofazenda;
        this.setFazendas();
        this.setFazendasFiltradas();
      }, (error) => console.error(error));
    }
  }

  getUsuariosFazendas() {
    const person = JSON.parse(sessionStorage.getItem('currentUser'));
    this.listaUsuariosFazenda = person.usuario.usuariofazenda;
  }

  setFazendas() {
    this.listaUsuariosFazenda.forEach((userFarm) => {
      this.listaFazendas.push(userFarm.fazenda);
    });
  }

  onSelectFarmChange(farm: Fazenda) {
    this.fazendaSelecionada = farm;
  }

  onSelectFarm() {
    sessionStorage.removeItem(`currentFarm`);
    sessionStorage.setItem(`currentFarm`, JSON.stringify(this.fazendaSelecionada));
    this.insereLogAcesso();
  }

  onSelectNoFarm() {
    sessionStorage.removeItem(`currentFarm`);
  }

  insereLogAcesso() {
    const logAcesso = new LogAcesso();
    logAcesso.cdFazenda = this.fazendaSelecionada.cdfazenda;
    logAcesso.cdPessoa = this.getUsuarioSessao().cdpessoa;
    this.logAcessoService.insere(logAcesso).subscribe(response => {}, (error) => console.error(error));
  }
}
