import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { UF } from 'src/models/cadastro/uf.model';
import { PerfilRacialRebanho } from 'src/models/cadastro/perfil-racial-rebanho.model';
import { SistemaProducao } from 'src/models/cadastro/sistema-producao.model';
import { Grupo } from 'src/models/cadastro/grupo.model';
import { Municipio } from 'src/models/cadastro/municipio.model';
import { Fazenda } from 'src/models/fazenda.model';
import { GrupoFazenda } from 'src/models/cadastro/grupo-fazenda.model';
import { Pais } from 'src/models/cadastro/pais.model';

@Injectable({
  providedIn: 'root'
})
export class FazendaService {

  private apiUrl = `${environment.apiBaseUrl}/fazenda`;
  private NUMERO_TENTATIVAS_REQUEST = 0;

  constructor(private http: HttpClient) { }

  getFazendas(): Observable<Fazenda[]> {
    return this.http
      .get<Fazenda[]>(`${this.apiUrl}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getFazendasPorGrupo(cdgrupo: number): Observable<Fazenda[]> {
    return this.http
      .get<Fazenda[]>(`${this.apiUrl}/fazendas-por-grupo/${cdgrupo}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaFazenda(fazenda: any): Observable<object> {
    return this.http
      .put<any>(`${this.apiUrl}/atualiza`, fazenda)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeFazenda(fazenda: any[]): Observable<object> {
    return this.http
      .put<any>(`${this.apiUrl}/remove`, fazenda)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  limpaFazenda(fazenda: any): Observable<any> {
    return this.http
      .put<any>(`${this.apiUrl}/clear`, fazenda)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereFazenda(fazenda: Fazenda) {
    return this.http
      .post<any>(`${this.apiUrl}`, fazenda)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereGrupoFazenda(fazenda: Fazenda) {
    return this.http
      .post<any>(`${this.apiUrl}/insere/grupo-fazenda`, fazenda)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getPais(): Observable<Pais[]> {
    return this.http
      .get<Pais[]>(`${this.apiUrl}/paises`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getUf(): Observable<UF[]> {
    return this.http
      .get<UF[]>(`${this.apiUrl}/ufs`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getPerfilRacialRebanho(): Observable<PerfilRacialRebanho[]> {
    return this.http
      .get<PerfilRacialRebanho[]>(`${this.apiUrl}/perfis-raciais-rebanho`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getSistemaProducao(): Observable<SistemaProducao[]> {
    return this.http
      .get<SistemaProducao[]>(`${this.apiUrl}/sistemas-producao`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getGrupo(): Observable<Grupo[]> {
    return this.http
      .get<Grupo[]>(`${this.apiUrl}/grupos`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getGrupoFazenda(): Observable<GrupoFazenda[]> {
    return this.http
      .get<GrupoFazenda[]>(`${this.apiUrl}/grupos-fazenda`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getMunicipoPorCdUf(cdUf: number): Observable<Municipio[]> {
    return this.http
      .get<Municipio[]>(`${this.apiUrl}/municipios/${cdUf}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getMunicipios(): Observable<Municipio[]> {
    return this.http.get<Municipio[]>(`${this.apiUrl}/municipios`)
    .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getFazendasPlanilha(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/fazendas-planilha`)
    .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getFazendasPlanilhaGrupo(cdgrupo: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/fazendas-planilha-grupo/${cdgrupo}`)
    .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }
}
