import { Reproducao } from 'src/models/matriz/reproducao.models';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DATE_FORMATS, MAT_DIALOG_DATA } from '@angular/material';
import { ProducaoService } from 'src/app/_services/producao.service';
import { ReproducaoService } from 'src/app/_services/reproducao.service';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';
import { ControleLeiteiro } from 'src/models/producao/controle-leiteiro.model';

@Component({
  selector: 'app-controle-leiteiro-cadastro',
  templateUrl: './controle-leiteiro-cadastro.component.html',
  styleUrls: ['./controle-leiteiro-cadastro.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ControleLeiteiroCadastroComponent implements OnInit {

  hasChanges = false;
  selectedMatrix: ControleLeiteiro;
  itemsSource: Array<ControleLeiteiro>;
  item: ControleLeiteiro;
  dairyControlsRegistered: ControleLeiteiro[];
  reproductions: Reproducao[];
  persistedItem: ControleLeiteiro;
  action = 'INSERT';
  error = ``;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private producaoService: ProducaoService,
              private reproducaoService: ReproducaoService,
              private datePipe: DatePipe,
              public dialogRef: MatDialogRef<ControleLeiteiroCadastroComponent>) {

    this.itemsSource = data.items;
    this.item = data.item;
  }

  ngOnInit() {
    this.selectedMatrix = this.itemsSource.find(cond => cond.cdMatriz === this.item.cdMatriz);
    if (this.item.cdLeite != null) {
      this.itemsSource = [this.selectedMatrix];
      const newItem = new ControleLeiteiro();
      newItem.cdFazenda = this.item.cdFazenda;
      newItem.cdLeite = this.item.cdLeite;
      newItem.cdMatriz = this.item.cdMatriz;
      newItem.cdReproducao = this.item.cdReproducao;
      newItem.dataControle = this.item.dataControle;
      newItem.dataLactacao = this.item.dataLactacao;
      newItem.nomeMatriz = this.item.nomeMatriz;
      newItem.numeroMatriz = this.item.numeroMatriz;
      newItem.ordenha1 = this.item.ordenha1;
      newItem.ordenha2 = this.item.ordenha2;
      newItem.ordenha3 = this.item.ordenha3;
      newItem.pesoTotal = this.item.pesoTotal;
      newItem.ccs = this.item.ccs;
      this.persistedItem = this.item;
      this.item = newItem;
      this.action = 'UPDATE';
    }
    this.getDairyControlsRegistered();
    this.getReproductions();
  }

  getDairyControlsRegistered() {
    this.producaoService.getControlesLeiteirosLancados().subscribe((response: any) => {
      this.dairyControlsRegistered = response;
    });
  }

  getReproductions() {
    this.reproducaoService.getReproducoes().subscribe((response: any) => {
      this.reproductions = response;
    });
  }

  onSaveClick() {
    this.saveHandler(true);
  }

  protected saveHandler(closeDialog: boolean) {
    this.item.cdReproducao = this.selectedMatrix.cdReproducao;
    this.item.cdMatriz = this.selectedMatrix.cdMatriz;
    this.item.cdFazenda = this.selectedMatrix.cdFazenda;
    this.item.nomeMatriz = this.selectedMatrix.nomeMatriz;
    this.item.numeroMatriz = this.selectedMatrix.numeroMatriz;
    this.item.dataLactacao = this.selectedMatrix.dataLactacao;
    this.saveChanges(true);
  }

  private saveChanges(closeDialog: boolean) {
    this.error = ``;
    if (this.validateToSave()) {
      this.convertCcs();
      if (this.isInsert()) {
        this.insertItems(closeDialog);
      } else if (this.isUpdate()) {
        this.updateItems(closeDialog);
      }
    }
  }

  isInsert(): boolean {
    return (this.action === 'INSERT');
  }

  isUpdate(): boolean {
    return (this.action === 'UPDATE');
  }

  convertCcs() {
    const hasCcs = this.item.ccs !== undefined && this.item.ccs !== null;
    if (hasCcs) {
      this.item.ccs = Math.round(this.item.ccs);
    }
  }

  insertItems(closeDialog: boolean) {
    this.producaoService.insereControleLeiteiro(this.item).subscribe((response: ControleLeiteiro) => {
      if (response != null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
          duration: 2000,
        });
        if (closeDialog) {
          this.closeHandler(true);
        }
      }
    });
  }

  updateItems(closeDialog: boolean) {
    this.producaoService.atualizaControlesLeiteiros([this.item]).subscribe((response: Array<ControleLeiteiro>) => {
      if (response !== null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
          duration: 2000,
        });
        if (closeDialog) {
          this.closeHandler(true);
        }
      }
    });
  }

  onCloseClick() {
    if (this.hasChanges === true) {
      this.saveHandler(true);
    } else {
      this.closeHandler(false);
    }
  }

  onChanged() {
    this.item.pesoTotal = +this.item.ordenha1 + +this.item.ordenha2 + +this.item.ordenha3;
    this.hasChanges = true;
  }

  private closeHandler(reload: any) {
    this.dialogRef.close(reload);
  }

  validateToSave(): boolean {
    if (!this.validateHasDairyControleDate()) { return false; }
    if (!this.validaLancamentoDeControleLeiteiro()) { return false; }
    if (!this.validaLancamentoDeOrdenhas()) { return false; }
    return true;
  }

  validateHasDairyControleDate() {
    const hasControlDate =
      this.item.dataControle !== undefined && this.item.dataControle !== null;
    if (!hasControlDate) {
      this.error = `É necessário informar a data do controle`;
      this.hasChanges = false;
      return false;
    }
    return true;
  }

  validaLancamentoDeControleLeiteiro(): boolean {
    const dataControle = this.datePipe.transform(this.item.dataControle, `dd/MM/yyyy`);

    let controleLeiteirosLancados =
      this.dairyControlsRegistered.find(controleLeiteiro => controleLeiteiro.cdMatriz === this.item.cdMatriz
        && this.datePipe.transform(controleLeiteiro.dataControle, `dd/MM/yyyy`) === dataControle
        && controleLeiteiro.cdReproducao === this.item.cdReproducao);

    let existeControleLeiteiroLançado = controleLeiteirosLancados !== undefined && controleLeiteirosLancados.cdLeite > 0;
    if (existeControleLeiteiroLançado && this.isInsert()) {
      this.error = `Já existe um lançamento para esta matriz na mesma data`;
      this.hasChanges = false;
      return false;
    }

    controleLeiteirosLancados =
      this.dairyControlsRegistered.find(controleLeiteiro => controleLeiteiro.cdMatriz === this.item.cdMatriz
        && this.datePipe.transform(controleLeiteiro.dataControle, `dd/MM/yyyy`) === dataControle
        && controleLeiteiro.cdReproducao === this.item.cdReproducao
        && controleLeiteiro.cdLeite !== this.item.cdLeite);

    existeControleLeiteiroLançado = controleLeiteirosLancados !== undefined && controleLeiteirosLancados.cdLeite > 0;
    if (existeControleLeiteiroLançado && !this.isInsert()) {
      this.error = `Já existe um lançamento para esta matriz na mesma data`;
      this.hasChanges = false;
      return false;
    }

    const reproducao = this.reproductions.find(reproducao => reproducao.cdmatriz === this.item.cdMatriz
      && this.item.cdReproducao === reproducao.cdreproducao);

    const existeReproducao = reproducao !== undefined && reproducao.cdreproducao > 0;
    if (existeReproducao) {
      let ehControleLeiteiroValido = new Date(this.item.dataControle).getTime() > new Date(reproducao.dtparto).getTime();
      if (!ehControleLeiteiroValido) {
        this.error = `Não é permitido lançar um controle leiteiro com data
          anterior ao parto que deu início a lactação vigente`;
        this.hasChanges = false;
        return false;
      }

      ehControleLeiteiroValido = new Date(this.item.dataControle).getTime() <= new Date().getTime();
      if (!ehControleLeiteiroValido) {
        this.error = `Não é permitido lançar um controle leiteiro com data
          maior que a data atual`;
        this.hasChanges = false;
        return false;
      }

    }
    return true;
  }

  validaLancamentoDeOrdenhas() {
    const existeOrdenha1 =
      this.item.ordenha1 !== undefined && this.item.ordenha1 > 0;
    const existeOrdenha2 =
      this.item.ordenha2 !== undefined && this.item.ordenha2 > 0;
    const existeOrdenha3 =
      this.item.ordenha3 !== undefined && this.item.ordenha3 > 0;

    if (!existeOrdenha1 && !existeOrdenha2 && !existeOrdenha3) {
      this.error = `É necessário informar ao menos uma ordenha`;
      this.hasChanges = false;
      return false;
    }
    return true;
  }

}
