import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, zip } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FazendaParametro } from 'src/models/fazenda-parametro.model';
import { ParametroFiltro } from 'src/models/parametro-filtro.model';
import { AltaGestaoService } from './alta-gestao.service';

const itemIndex = (item: any, data: any[]): number => {
    for (let idx = 0; idx < data.length; idx++) {
        if (data[idx].cdFazendaParametro === item.cdFazendaParametro) {
            return idx;
        }
    }
    return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
  providedIn: 'root'
})
export class ParametrosEditaveisService extends BehaviorSubject<any[]> {

  private apiUrlCadastro = `${environment.apiBaseUrl}/cadastro`;
  private NUMERO_TENTATIVAS_REQUEST = 0;

  private data: FazendaParametro[] = [];
  private originalData: FazendaParametro[] = [];
  private updatedItems: FazendaParametro[] = [];

  constructor(
    private http: HttpClient,
    private altaGestaoService: AltaGestaoService) {
      super([]);
  }

  atualizaFazendaParametros(parameters: FazendaParametro[]): Observable<FazendaParametro[]> {
    return this.http
      .put<FazendaParametro[]>(`${this.apiUrlCadastro}/fazenda-parametro/atualiza`, parameters)
      .pipe(retry(3), catchError(this.handleError));
  }

  public read(parameterFilter: ParametroFiltro) {
    if (this.data.length) {
        return super.next(this.data);
    }

    this.fetch(parameterFilter)
        .subscribe(data => {
            this.convertValuesToNumber(data);
            this.data = data;
            this.originalData = cloneData(data);
            super.next(data);
        });
  }

  public update(item: any): void {
      if (!this.isNew(item)) {
          const index = itemIndex(item, this.updatedItems);
          if (index !== -1) {
              this.updatedItems.splice(index, 1, item);
          } else {
              this.updatedItems.push(item);
          }
      // } else {
      //     const index = this.createdItems.indexOf(item);
      //     this.createdItems.splice(index, 1, item);
      }
  }

  // public create(item: any): void {
  //   this.createdItems.push(item);
  //   this.data.unshift(item);
  //   super.next(this.data);
  // }

  public isNew(item: any): boolean {
      return !item.cdFazendaParametro;
  }

  public hasChanges(): boolean {
      return Boolean(this.updatedItems.length);
  }

  public saveChanges(parameterFilter: ParametroFiltro): void {
      if (!this.hasChanges()) {
          return;
      }

      const completed = [];
      if (this.updatedItems.length) {
          // completed.push(this.fetch(UPDATE_ACTION, this.updatedItems));
        this.convertValuesToString();
        completed.push(this.atualizaFazendaParametros(this.updatedItems));
      }

      this.reset();

      zip(...completed).subscribe(() => this.read(parameterFilter));
  }

  public cancelChanges(): void {
      this.reset();
      this.data = this.originalData;
      this.originalData = cloneData(this.originalData);
      super.next(this.data);
  }

  public assignValues(target: any, source: any): void {
      Object.assign(target, source);
  }

  private reset() {
      this.data = [];
      this.updatedItems = [];
  }

  fetch(parameterFilter: ParametroFiltro): Observable<any[]> {
      parameterFilter.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
      return this.http
        .post<FazendaParametro[]>(`${this.apiUrlCadastro}/fazenda-parametro`, parameterFilter)
        .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  convertValuesToString() {
    this.updatedItems.forEach((parameter) => {
      parameter.valor = parameter.valor.toString();
    });
  }

  convertValuesToNumber(data: FazendaParametro[]) {
    data.forEach((parameter) => {
      parameter.valorNumerico = Number(parameter.valor);
    });
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }

}
