export class Evento {
    public cdEventos: number;
    public cdMatriz: number;
    public cdFazenda: number;
    public cdTipoEvento: number;
    public dataEvento: Date;
    public evento: string;

    // utilizados somente para validações na tela
    public isValid: boolean;
    public dtNascimento: Date;
    public isRemoved: boolean;
}
