import { ParametrosEditaveisService } from 'src/app/_services/parametros-editaveis.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { ValidacaoReproducaoService } from 'src/app/_services/validacao-reproducao.service';
import { ReproducaoService } from 'src/app/_services/reproducao.service';
import { Matriz } from 'src/models/matriz/matriz.model';
import { DificuldadeDeParto } from 'src/models/reproducao/dificuldade-de-parto.model';
import { OcorrenciaDeReproducao } from 'src/models/reproducao/ocorrencia-de-reproducao.model';
import { TentativaDeParto } from 'src/models/reproducao/tentativa-de-parto.model';
import { TipoDeParto } from 'src/models/reproducao/tipo-de-parto.model';
import { OcorrenciaReproducaoCadastroComponent } from '../ocorrencia-reproducao-cadastro/ocorrencia-reproducao-cadastro.component';
import { MatrizService } from 'src/app/_services/matriz.service';
import { MatrizCadastroComponent } from 'src/app/matrix/matriz-cadastro/matriz-cadastro.component';
import { MotivoBaixa } from 'src/models/matriz/motivo-baixa.model';
import { ComposicaoRacial } from 'src/models/matriz/composicao-racial.model';
import { Reprodutor } from 'src/models/matriz/reprodutor.model';
import { Lote } from 'src/models/matriz/lote.model';
import { Genealogia } from 'src/models/matriz/genealogia.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';
import { HistoricoDeMatriz } from 'src/models/matriz/historico-matriz.model';
import { ParametroFiltro } from 'src/models/parametro-filtro.model';
import { FazendaParametro } from 'src/models/fazenda-parametro.model';
import { TipoPartoEnum } from 'src/app/_enums/tipo-parto.enum';

@Component({
  selector: 'app-parto-cadastro',
  templateUrl: './parto-cadastro.component.html',
  styleUrls: ['./parto-cadastro.component.scss']
})
export class PartoCadastroComponent implements OnInit {

  matrixPanelOpenState = true;
  ocurrencesPanelOpenState = true;
  parturitionPanelOpenState = true;

  gemelar = false;
  itemsSource: any;
  item: TentativaDeParto;
  oldItem: any;
  types: Array<TipoDeParto>;
  difficulties: Array<DificuldadeDeParto>;
  events: Array<OcorrenciaDeReproducao>;
  eventTypes: Array<string>;
  hasChanges = false;
  isFemale = false;
  reload = false;
  loading = false;
  matrixId: any;
  composicao: Array<ComposicaoRacial>;
  reprodutor: Array<Reprodutor>;
  motivoBaixa: Array<MotivoBaixa>;
  lote: Array<Lote>;
  genealogia: Array<Genealogia>;
  reproducao: Array<Reproducao>;
  numPartos: Array<Reproducao>;
  avoMaterno: any;
  historico: Array<HistoricoDeMatriz>;
  matrixListAll: Array<Matriz>;
  reproductionsList: Reproducao[];
  parametersList: FazendaParametro[];
  historicoMatriz: any;
  cria1: any;
  cria2: any;
  showMatrizEdit1Button = false;
  showMatrizEdit2Button = false;
  error = ``;

  public gridSelection: any[] = [];

  public selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private reproducaoService: ReproducaoService,
              public dialogRef: MatDialogRef<PartoCadastroComponent>,
              private reproducaoValidacaoService: ValidacaoReproducaoService,
              private matrizService: MatrizService,
              private parameterService: ParametrosEditaveisService) {
    this.itemsSource = data.items;
    this.item = data.item;
    this.item.dtparto = data.item.dataParto;
    this.item.data = data.item.dataServico;
    this.item.dtdiagnostico1 = data.item.dataDiagnostico1;
    this.item.dtdiagnostico2 = data.item.dataDiagnostico2;
    this.item.cdresultadodiagnostico1 = data.item.cdDiagnostico1;
    this.item.cdresultadodiagnostico2 = data.item.cdDiagnostico2;
    this.item.cdtipoparto = data.item.cdTipoParto;
    this.item.cddificuldadeparto = data.item.cdDificuldadeParto;
    this.item.deslocamentoabomaso = data.item.deslocamentoAbomaso;
    this.item.doencasmetabolicas = data.item.doencasMetabolicas;
    this.item.retencaoplacenta = data.item.retencaoPlacenta;
    this.item.sexocria1 = data.item.sexoCria1;
    this.item.sexocria2 = data.item.sexoCria2;
    this.item.natimortocria1 = data.item.natimortoCria1;
    this.item.natimortocria2 = data.item.natimortoCria2;
    this.types = data.types;
    this.difficulties = data.difficulties;
    this.oldItem = this.item;
  }

  ngOnInit() {
    this.historicoMatriz = [];
    this.LoadTiposDeOcorrencia();
    this.LoadEvents();
    this.getAllReproduction();
    this.getReproductionsList();
    this.getAllMatrix();
    this.getAllGenealogia();
    this.getAllHistory();
    this.getAllComposicaoRacial();
    this.getAllReprodutor();
    this.getAllLote();
    this.getAllMotivoBaixa();
    this.getParametersByFarm();
  }

  LoadTiposDeOcorrencia() {
    this.loading = true;
    this.reproducaoService.getTiposOcorrencia().subscribe((response: any) => {
      this.eventTypes = response;
      this.loading = false;
    });
  }

  LoadEvents() {
    this.loading = true;
    this.reproducaoService.getOcorrenciasDeReproducaoPorMatriz(this.item.cdMatriz).subscribe((response: any) => {
      this.events = response;
      this.loading = false;
    });
  }

  getReproductionsList() {
    this.reproducaoService.getReproducoes().subscribe((response: Reproducao[]) => {
      this.reproductionsList = response;
    }, (error) => console.error(error));
  }

  getAllComposicaoRacial() {
    this.matrizService.getComposicaoRacial().subscribe((response) => {
      this.composicao = response;
    });
  }

  getAllReprodutor() {
    this.matrizService.getReprodutor().subscribe((response) => {
      this.reprodutor = response;
    });
  }

  getAllMotivoBaixa() {
    this.matrizService.getMotivoBaixa().subscribe((response) => {
      this.motivoBaixa = response;
    });
  }

  getAllLote() {
    this.matrizService.getLote().subscribe((response) => {
      this.lote = response;
    });
  }

  getAllHistory() {
    this.matrizService.getHistoricoDeMatrizPorFazenda().subscribe((response) => {
      this.historico = response;
    });
  }

  getAllGenealogia() {
    this.matrizService.getGenealogia().subscribe((response) => {
      this.genealogia = response;
    });
  }

  getAllReproduction() {
    this.matrizService.getReproducao().subscribe((response) => {
      this.reproducao = response;
    });
  }

  getAllMatrix() {
    this.matrizService.getMatrizes().subscribe((response) => {
      this.matrixListAll = response;
    });
  }

  getParametersByFarm() {
    const parameterFilter = new ParametroFiltro();
    parameterFilter.editavelWeb = true;
    this.parameterService.fetch(parameterFilter).subscribe((response: FazendaParametro[]) => {
      this.parametersList = response;
    }, (error) => console.error(error));
  }

  onGridKeyDown(e: any) {
    if (e.code === 'F4') {
      this.showReproductionEventsWindow();
    }
  }

  onSaveClick() {
    this.saveHandler(false);
  }

  onCloseClick() {
    if (this.hasChanges) {
      this.saveHandler(true);
    } else {
      this.closeHandler(false);
    }
    this.isFemale = false;
  }

  saveHandler(isOnCloseClick: boolean) {
    if (isOnCloseClick) {
      this.showConfirmationDialog();
    } else {
      if (this.validateToSave()) {
        this.saveChanges();
      }
    }
  }

  showConfirmationDialog() {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja salvar as alterações ?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (this.item.dataParto === undefined) {
          this.snackBar.open('Não foi possível salvar as alterações. Necessário informar a data do parto!', 'Fechar', {
            duration: 2000,
          });
        } else {
          if (this.validateToSave()) {
            this.saveChanges();
          }
        }
      } else {
        this.closeHandler(false);
      }
    });
  }

  saveChanges() {
    this.item.dataParto = this.item.dtparto;
    this.item.cdTipoParto = this.item.cdtipoparto;
    this.item.cdDificuldadeParto = this.item.cddificuldadeparto;
    this.item.deslocamentoAbomaso = this.item.deslocamentoabomaso;
    this.item.doencasMetabolicas = this.item.doencasmetabolicas;
    this.item.retencaoPlacenta = this.item.retencaoplacenta;
    this.item.sexoCria1 = this.item.sexocria1;
    this.item.sexoCria2 = this.item.sexocria2;
    this.item.natimortoCria1 = this.item.natimortocria1;
    this.item.natimortoCria2 = this.item.natimortocria2;

    const isSexoCria1Female = this.item.sexoCria1 === 'F';
    const isSexoCria2Female = this.item.sexoCria2 === 'F';
    const isSexoCria1Male = this.item.sexoCria1 === 'M';
    const isSexoCria2Male = this.item.sexoCria2 === 'M';
    const isNatimortoCria1 = this.item.natimortocria1 === true || Number(this.item.natimortocria1) === 1;
    const isNatimortoCria2 = this.item.natimortocria2 === true || Number(this.item.natimortocria2) === 1;
    const isGemelar = this.item.gemelar === true || Number(this.item.gemelar) === 1;

    if (this.ehTipoDePartoAbortoComLactacao()) {
      this.item.gemelar = null;
      this.item.sexoCria1 = null;
      this.item.cdCria1 = null;
      this.item.numeroCria1 = null;
      this.item.natimortoCria1 = null;
      this.item.sexoCria2 = null;
      this.item.cdCria2 = null;
      this.item.numeroCria2 = null;
      this.item.natimortoCria2 = null;
      this.updateTentativasDeReproducao();
    } else if (isGemelar) {
      // Cria 1 macho e Cria 2 macho
      // Cria 1 macho e Cria 2 fêmea natimorto
      // Cria 1 fêmea natimorto e Cria 2 macho
      const salvarSemNumeroCria1ECria2 = (isSexoCria1Male && isSexoCria2Male)
                          || (isSexoCria1Male && isSexoCria2Female && isNatimortoCria2)
                          || (isSexoCria2Male && isSexoCria1Female && isNatimortoCria1)
                          || ((isSexoCria1Female && isNatimortoCria1) && (isSexoCria2Female && isNatimortoCria2));

      const salvarComOuSemNumeroCria1ECria2 = (isSexoCria1Male && (isSexoCria2Female && !this.hasCdCria2() && !isNatimortoCria1))
                        || (isSexoCria2Male && (isSexoCria1Female && !this.hasCdCria1() && !isNatimortoCria2))
                        || ((isSexoCria1Female && !this.hasCdCria1() && !isNatimortoCria1)
                              || (isSexoCria2Female && !this.hasCdCria2() && !isNatimortoCria2));

      const salvarComNumeroCria1ECria2 = (isSexoCria1Male && (this.hasCdCria2() && this.validateExistingMatrixNumber2()))
                          || (isSexoCria2Male && (this.hasCdCria1() && this.validateExistingMatrixNumber1()))
                          || ((isSexoCria1Female && isNatimortoCria1) && this.hasCdCria2())
                          || ((isSexoCria2Female && isNatimortoCria2) && this.hasCdCria1())
                          || ((this.hasCdCria1() && this.validateExistingMatrixNumber1())
                              && (this.hasCdCria2() && this.validateExistingMatrixNumber2()));

      if (salvarSemNumeroCria1ECria2) {
        this.updateTentativasDeReproducao();
      } else if (salvarComOuSemNumeroCria1ECria2) {
        this.editCriaReproducaoSemCria(this.item, true);
      } else if (salvarComNumeroCria1ECria2) {
          this.updateTentativasDeReproducao();
          const editDialogRef = this.dialog.open(DialogoConfirmacao, {
            data: {
              message: 'Deseja editar os dados da(s) cria(s) agora ?',
              buttonText: {
                ok: 'Sim',
                cancel: 'Não'
              }
            }
          });
          editDialogRef.afterClosed().subscribe((confirm: boolean) => {
            if (confirm) {
              if (this.hasCdCria1()) {
                this.showMatrizEdit1Button = true;
              }
              if (this.hasCdCria2()) {
                this.showMatrizEdit2Button = true;
              }
            } else {
              this.closeHandler(true);
            }
          });
        }
    } else {
      // Cria 1 macho
      // Cria 1 macho natimorto
      // Cria 1 fêmea natimorto
      const salvarSemNumeroCria1 = (isSexoCria1Male)
                          || (isSexoCria1Male && isNatimortoCria1)
                          || (isSexoCria1Female && isNatimortoCria1);

      const salvarComouSemNumeroCria1 = (isSexoCria1Female && !this.hasCdCria1() && !isNatimortoCria1);

      const salvarComNumeroCria1 = (this.hasCdCria1() && this.validateExistingMatrixNumber1());

      if (salvarSemNumeroCria1) {
        this.updateTentativasDeReproducao();
      } else if (salvarComouSemNumeroCria1) {
        this.editCriaReproducaoSemCria(this.item, true);
      } else if (salvarComNumeroCria1) {
        this.updateTentativasDeReproducao();
        const editDialogRef = this.dialog.open(DialogoConfirmacao, {
          data: {
            message: 'Deseja editar os dados da(s) cria(s) agora ?',
            buttonText: {
              ok: 'Sim',
              cancel: 'Não'
            }
          }
        });
        editDialogRef.afterClosed().subscribe((confirm: boolean) => {
          if (confirm) {
            if (this.hasCdCria1()) {
              this.showMatrizEdit1Button = true;
            }
            if (this.hasCdCria2()) {
              this.showMatrizEdit2Button = true;
            }
          } else {
            this.closeHandler(true);
          }
        });
      }
    }
  }

  updateTentativasDeReproducao() {
    this.reproducaoService.atualizaTentativasAtuaisDeReproducao([this.item]).subscribe((response: TentativaDeParto[]) => {
      if (response !== null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
          duration: 5000,
        });
        this.getAllMatrix();
        this.hasChanges = false;
      }
    });
  }

  validateExistingMatrixNumber1(): boolean {
    const hasExistingMatrixNumber =
      this.matrixListAll.find(matrix => matrix.numero.toUpperCase() === this.item.numeroCria1.toUpperCase());
    if (hasExistingMatrixNumber) {
      this.showAlertDialog(`Já existe uma matriz cadastrada no sistema com o mesmo número.`);
      return false;
    }
    return true;
  }

  validateExistingMatrixNumber2(): boolean {
    const hasExistingMatrixNumber =
      this.matrixListAll.find(matrix => matrix.numero.toUpperCase() === this.item.numeroCria2.toUpperCase());
    if (hasExistingMatrixNumber) {
      this.showAlertDialog(`Já existe uma matriz cadastrada no sistema com o mesmo número.`);
      return false;
    }
    return true;
  }

  closeHandler(reload: boolean) {
    this.dialogRef.close(reload);
  }

  showReproductionEventsWindow() {
    let dialogRef: MatDialogRef<OcorrenciaReproducaoCadastroComponent>;
    dialogRef = this.dialog.open(OcorrenciaReproducaoCadastroComponent, {
      disableClose: true,
      height: '400px',
      width: '500px',
      data: {
        items: this.events,
        numeroDaMatriz: this.item.numeroMatriz,
        nomeDaMatriz: this.item.nomeMatriz,
        cdReproducao: this.item.cdReproducao,
        cdMatriz: this.item.cdMatriz,
        dataServico: this.item.dataServico,
        tiposDeOcorrencia: this.eventTypes,
        snackBar: this.snackBar,
        service: this.reproducaoService,
        dialogRef
      },
    });
  }

  validateToSave(): boolean {
    let isValid = true;
    this.error = ``;
    const reproduction = this.item;
    const matrix = new Matriz();
    matrix.numero = reproduction.numeroMatriz;
    if (!this.reproducaoValidacaoService.validaLancamentoParto(reproduction, matrix, this.matrixListAll, this.parametersList)
        || !this.reproducaoValidacaoService.validaLancamentoDatas(this.reproductionsList, reproduction, this.oldItem, matrix)) {
      isValid = false;
      this.error = this.reproducaoValidacaoService.getErro();
      this.showErrorAlert();
      return isValid;
    }
    return isValid;
  }

  itemHasChanged(value: string) {
    const hasChanges = value !== undefined && value === `true`;
    if (hasChanges) {
      this.hasChanges = true;
    }
  }

  itemChanged(e: any) {
    this.hasChanges = true;
    this.reload = true;
  }

  showErrorAlert() {
    const dialogRef = this.dialog.open(DialogoAlertaComponent, {
      data: {
        message: `${this.error}`,
        buttonText: {
          cancel: 'OK'
        }
      }
    });
  }

  editCriaReproducaoSemCria(item: TentativaDeParto, closeDialog: boolean) {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'O número da Cria 1 e/ou Cria 2 precisa(m) ser preenchido(s). Deseja sair sem gravar a(s) cria(s) agora ?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.updateTentativasDeReproducao();
        this.closeHandler(true);
      }
    });
  }

  showMatrizEdit1() {
    if (this.hasCdCria1()) {
      this.matrixId = this.matrixListAll.find(x => x.numero.toUpperCase() === this.item.numeroCria1.toUpperCase());
      this.setHistoriesByMatrix();
      this.setGrandFatherByMatrix();
      this.calculoNumPartos();
      this.showMatrixRegisterEdit1Component();
    }
  }

  hasCdCria1(): boolean {
    const hasNumeroCria1 =
      this.item.numeroCria1 !== undefined
      && this.item.numeroCria1 !== null
      && this.item.numeroCria1 !== '';
    return hasNumeroCria1;
  }

  showMatrizEdit2() {
    if (this.hasCdCria2()) {
      this.matrixId = this.matrixListAll.find(x => x.numero.toUpperCase() === this.item.numeroCria2.toUpperCase());
      this.setHistoriesByMatrix();
      this.setGrandFatherByMatrix();
      this.calculoNumPartos();
      this.showMatrixRegisterEdit2Component();
    }
  }

  hasCdCria2(): boolean {
    const hasNumeroCria2 =
      this.item.numeroCria2 !== undefined
      && this.item.numeroCria2 !== null
      && this.item.numeroCria2 !== '';
    return hasNumeroCria2;
  }

  showMatrixRegisterEdit1Component() {
    const dialogRef = this.dialog.open(MatrizCadastroComponent, {
      disableClose: true,
      height: '922px',
      width: '900px',
      data: {
              matrixId: this.matrixId
              , motivoBaixa: this.motivoBaixa
              , composicaoRacial: this.composicao
              , lote: this.lote
              , pai: this.reprodutor
              , mae: this.matrixListAll
              , avoMat: this.avoMaterno
              , historyMatrix: this.historicoMatriz
              , genealogia: this.genealogia
              , numPartos: this.numPartos
          }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
        duration: 2500,
      });
      this.showMatrizEdit1Button = false;
    });
  }

  showMatrixRegisterEdit2Component() {
    const dialogRef = this.dialog.open(MatrizCadastroComponent, {
      disableClose: true,
      height: '922px',
      width: '900px',
      data: {
              matrixId: this.matrixId
              , motivoBaixa: this.motivoBaixa
              , composicaoRacial: this.composicao
              , lote: this.lote
              , pai: this.reprodutor
              , mae: this.matrixListAll
              , avoMat: this.avoMaterno
              , historyMatrix: this.historicoMatriz
              , genealogia: this.genealogia
              , numPartos: this.numPartos
          }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
        duration: 2500,
      });
      this.showMatrizEdit2Button = false;
    });
  }

  setGrandFatherByMatrix() {
    if (this.hasMatrixId()) {
      const hasCdMother = this.matrixId.cdmae !== null && this.matrixId.cdmae !== undefined;
      if (hasCdMother) {
        const maeMaterna = this.matrixListAll.find(o => o.cdmatriz === this.matrixId.cdmae);
        const hasCdGrandFather = maeMaterna.cdpai !== null && maeMaterna.cdpai !== undefined;
        if (hasCdGrandFather) {
          this.avoMaterno = this.reprodutor.find(o => o.cdreprodutor === maeMaterna.cdpai);
        }
      } else {
        this.avoMaterno = '';
      }
    }
  }

  setHistoriesByMatrix() {
    if (this.hasMatrixId()) {
      this.historicoMatriz = [];
      this.historicoMatriz = this.historico.filter(history => history.cdMatriz === this.matrixId.cdmatriz);
    }
  }

  calculoNumPartos() {
    if (this.hasMatrixId()) {
      this.numPartos = [];
      const hasReproductions =
        this.reproducao !== null && this.reproducao !== undefined && this.reproducao.length > 0;
      if (hasReproductions) {
        this.reproducao.forEach((reproduction) => {
          const hasCdMatrix =
            reproduction.cdmatriz !== null
            && reproduction.cdmatriz !== undefined
            && reproduction.cdmatriz === this.matrixId.cdmatriz;
          if (hasCdMatrix) {
            this.numPartos.push(reproduction);
          }
        });
      }
    }
  }

  hasMatrixId(): boolean {
    const hasMatrixId = this.matrixId !== null && this.matrixId !== undefined;
    return hasMatrixId;
  }

  showAlertDialog(msg: string) {
    this.dialog.open(DialogoAlertaComponent, {
      data: {
              message: msg,
              buttonText: {
              cancel: 'Ok'
              }
          },
      });
  }

  private ehTipoDePartoAbortoComLactacao(): boolean {
    return this.item.cdtipoparto === TipoPartoEnum.ABORTO_COM_LACTACAO;
  }

}
