import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { GridDataResult, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HistoricoDeMatriz } from 'src/models/matriz/historico-matriz.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { MatrizService } from 'src/app/_services/matriz.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';

@Component({
  selector: 'app-matriz-historico-cadastro',
  templateUrl: './matriz-historico-cadastro.component.html',
  styleUrls: ['./matriz-historico-cadastro.component.scss']
})
export class MatrizHistoricoCadastroComponent implements OnInit {

  @ViewChild('historyGrid', { static: false }) grid: GridComponent;

  public hasChanges = false;
  public itemsToUpdate: Array<HistoricoDeMatriz> = [];
  public itemToRemove: HistoricoDeMatriz;

  public formGroup: FormGroup;
  public previousEdit: any;
  public editedRowIndex: number;

  public nomeDaMatriz: string;
  public numeroDaMatriz: string;

  public removedItems: Array<HistoricoDeMatriz> = [];
  public allHistory: Array<HistoricoDeMatriz>;

  public historyList: Array<HistoricoDeMatriz>;
  public gridView: GridDataResult;
  public matrixSort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];
  public gridSelection: any[] = [];
  public loading = false;
  public addMatrixHistory = faPlus;
  public editIcon = faEdit;

  public selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private matrizService: MatrizService,
              public dialogRef: MatDialogRef<MatrizHistoricoCadastroComponent>) {
      if (data && data.histories) {
        this.historyList = data.histories;
      }
  }

  ngOnInit() { }

  validateItem(context: RowClassArgs) {
    const data: Date = new Date(context.dataItem.data);

    context.dataItem.isValid = true;

    const hasDateAndDescription =
      data !== undefined
      && context.dataItem.historico !== undefined
      && context.dataItem.historico !== '';

    const hasValidDate = data < new Date();

    if (!hasDateAndDescription || !hasValidDate) {
      context.dataItem.isValid = false;
    }

    return {
      invalid: !context.dataItem.isValid
    };
  }

  public clickTableRow() {
    const matrix = this.gridSelection[0];
  }

  public onCellClick({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    this.editHandler({ sender, rowIndex, columnIndex, dataItem, isEdited });
  }

  private createFormGroup(dataItem: HistoricoDeMatriz) {
    this.formGroup = new FormGroup({
      data: new FormControl(new Date(dataItem.data)),
      historico: new FormControl(dataItem.historico, Validators.required)
    },
    {
      updateOn: 'blur',
    });

    this.formGroup.valueChanges.subscribe(value => this.updateObjectHandler(dataItem));
  }

  private updateObjectHandler(dataItem: HistoricoDeMatriz) {
    dataItem.historico = this.formGroup.controls[`historico`].value;
    dataItem.data = this.formGroup.controls[`data`].value;

    const itemsToUpdate = this.itemsToUpdate.filter(cond => cond === dataItem);
    if (itemsToUpdate.length === 0) {
      this.itemsToUpdate.push(dataItem);
    }

    this.hasChanges = true;
  }

  protected closeRow({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  public removeClick({ dataItem }) {
    this.itemToRemove = this.historyList.find(hm => hm.cdHistorico === dataItem.cdHistorico);
    this.removeHandler();
  }

  public removeHandler() {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja realmente remover o registro selecionado?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.removeItem(dialogRef);
      }
    });
  }

  private removeItem(dialogRef) {
    this.historyList = this.historyList.filter(hm => hm.cdHistorico !== this.itemToRemove.cdHistorico);

    if (this.itemToRemove.cdHistorico !== 0) {

      this.matrizService.removeHistoricoDeMatriz([this.itemToRemove]).subscribe((response: Array<HistoricoDeMatriz>) => {
        if (response != null) {
          this.allHistory = response;
        }
      });
    } else {

      this.itemsToUpdate = this.itemsToUpdate.filter(hm => hm !== this.itemToRemove);

      if (this.itemsToUpdate.length === 0) {
        this.hasChanges = false;
      }
    }

    dialogRef.close();
  }

  saveClick() {
    this.saveHandler(false);
  }

  private getHistoryAndClose() {
    this.matrizService.getHistoricoDeMatrizPorFazenda().subscribe(response => {
      this.allHistory = response;
      this.closeHandler();
    });
  }

  protected saveHandler(closeDialog: boolean) {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja salvar as alterações?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const item = this.itemsToUpdate.find(cond => cond.isValid === false);
        const hasInvalidItem = item !== null && item !== undefined;
        if (hasInvalidItem) {
          this.dialog.open(DialogoAlertaComponent, {
            data: {
              message: `Não foi possível salvar. Existem registros inválidos`,
              buttonText: {
                cancel: 'OK'
              }
            }
          });
        } else {
          this.saveChanges(dialogRef, closeDialog);
        }
      } else if (closeDialog) {
        this.getHistoryAndClose();
      }
    });
  }

  private saveChanges(dialogRef, closeDialog: boolean) {
    console.log(this.itemsToUpdate);
    this.matrizService.atualizaHistoricoDeMatriz(this.itemsToUpdate).subscribe((response: Array<HistoricoDeMatriz>) => {

      if (response != null) {
        this.allHistory = response;

        this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
          duration: 2000,
        });

        if (closeDialog) {
          this.closeHandler();
        }
      }
    });

    this.hasChanges = false;
    dialogRef.close();
  }

  closeClick() {
    if (this.hasChanges === true) {
      this.saveHandler(true);
    } else {
      this.closeHandler();
    }
  }

  private closeHandler() {
    this.dialogRef.close(this.allHistory);
  }

  addClick() {
    this.addHandler();
  }

  addHandler() {
    this.createFormGroup(new HistoricoDeMatriz());

    const newHistory: HistoricoDeMatriz = new HistoricoDeMatriz();
    newHistory.cdMatriz = this.data.cdMatriz;
    newHistory.cdFazenda = this.matrizService.getCdFazenda();
    newHistory.data = new Date();
    newHistory.cdHistorico = 0;

    this.historyList.push(newHistory);

    this.editHandler({ sender: this.grid, rowIndex: this.historyList.length - 1, columnIndex: 0, dataItem: newHistory, isEdited: false });

    this.hasChanges = true;
  }

  editHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {

    if (this.previousEdit != null) {

      this.closeRow(this.previousEdit);
    }

    this.createFormGroup(dataItem);

    sender.editCell(rowIndex, columnIndex, this.formGroup);
    this.previousEdit = { dataItem, sender, rowIndex };
  }
}



