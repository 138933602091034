export class Colostragem {
    public cdColostragem: number;
    public cdMatriz: number;
    public cdFazenda: number;
    public tipoColostro: string;
    public tipoFornecimento: string;
    public qualidade: string;
    public valorCor: string;
    public quantLitros: number;
    public proteinaSanguinea: number;
    public avaliacaoProteina: string;
    public nomeFuncionario: string;
}
