import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { apiConfig } from '../_authorization/auth.config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: MsalService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve) => {
      this.authService.acquireTokenSilent({ scopes: apiConfig.b2cScopes })
        .then((tokenResponse) => {
          // console.log(tokenResponse.accessToken);
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          this.router.navigate(['/login']);
          resolve(false);
        });
    });
  }
}
