import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Iatf } from 'src/models/reproducao/iatf.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ReprodcutionIatfFilter } from 'src/models/reproducao/iatf-filter.model';
import { Protocolo } from 'src/models/protocolo.model';
import { OcorrenciaDeReproducao } from 'src/models/reproducao/ocorrencia-de-reproducao.model';
import { AltaGestaoService } from './alta-gestao.service';
import { TentativaDeParto } from 'src/models/reproducao/tentativa-de-parto.model';
import { TipoDeParto } from 'src/models/reproducao/tipo-de-parto.model';
import { DificuldadeDeParto } from 'src/models/reproducao/dificuldade-de-parto.model';
import { Reprodutor } from 'src/models/matriz/reprodutor.model';
import { ResultadoDiagnostico } from 'src/models/resultado-diagnostico.model';
import { TipoTentativa } from 'src/models/tipo-tentativa.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';
import { IaCobTeFilter } from 'src/models/reproducao/ia-cob-te-filter.model';
import { HistoricoReproducao } from 'src/models/reproducao/historico-reproducao.model';
import { HistoricoReproducaoFiltro } from 'src/models/reproducao/historico-reproducao-filtro.model';
import { Leite } from 'src/models/leite.model';

@Injectable({
  providedIn: 'root'
})
export class ReproducaoService {

  private apiUrlReproducao = `${environment.apiBaseUrl}/reproducao`;
  private NUMERO_TENTATIVAS_REQUEST = 0;

  constructor(
    private http: HttpClient,
    private altaGestaoService: AltaGestaoService) {
  }

  getDificuldadesDeParto(): Observable<DificuldadeDeParto[]> {
    return this.http
      .get<DificuldadeDeParto[]>(`${this.apiUrlReproducao}/parto/dificuldades-parto`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getTiposDeParto(): Observable<TipoDeParto[]> {
    return this.http
      .get<TipoDeParto[]>(`${this.apiUrlReproducao}/parto/tipos-parto`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getTentativasAtuaisDeReproducao(): Observable<TentativaDeParto[]> {
    return this.http
      .get<TentativaDeParto[]>(`${this.apiUrlReproducao}/parto/tentativa-reproducao/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaTentativasAtuaisDeReproducao(tentativasReproducao: Array<TentativaDeParto>): Observable<TentativaDeParto[]> {
    return this.http
      .post<TentativaDeParto[]>(`${this.apiUrlReproducao}/parto/tentativa-reproducao/atualiza`, tentativasReproducao)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getOcorrenciasDeReproducao(): Observable<OcorrenciaDeReproducao[]> {
    return this.http
      .get<OcorrenciaDeReproducao[]>(`${this.apiUrlReproducao}/ocorrencia-reproducao/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getOcorrenciasDeReproducaoPorMatriz(cdMatriz: number): Observable<OcorrenciaDeReproducao[]> {
    return this.http
      .get<OcorrenciaDeReproducao[]>(`${this.apiUrlReproducao}/ocorrencia-reproducao/matriz/${cdMatriz}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getTiposOcorrencia(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.apiUrlReproducao}/ocorrencia-reproducao/tipos-ocorrencia`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeOcorrenciasDeReproducao(ids: number[]): Observable<OcorrenciaDeReproducao[]> {
    return this.http
      .put<OcorrenciaDeReproducao[]>(`${this.apiUrlReproducao}/ocorrencia-reproducao/remove`, ids)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaOcorrenciasDeReproducao(ocorrenciasReproducao: Array<OcorrenciaDeReproducao>): Observable<OcorrenciaDeReproducao[]> {
    return this.http
      .post<OcorrenciaDeReproducao[]>(`${this.apiUrlReproducao}/ocorrencia-reproducao/atualiza`, ocorrenciasReproducao)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getLancamentosProtocolosIatf(filtro: ReprodcutionIatfFilter): Observable<Iatf[]> {
    filtro.cdFarm = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Iatf[]>(`${this.apiUrlReproducao}/iatfs/protocolos/lancamentos`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getLancamentosProtocolosIatfMatrizNoPev(filtro: ReprodcutionIatfFilter): Observable<Iatf[]> {
    filtro.cdFarm = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Iatf[]>(`${this.apiUrlReproducao}/iatfs/protocolos/lancamentos/matriz-pev`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getLancamentosProtocolosIatfMatrizPrenha(filtro: ReprodcutionIatfFilter): Observable<Iatf[]> {
    filtro.cdFarm = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Iatf[]>(`${this.apiUrlReproducao}/iatfs/protocolos/lancamentos/matriz-prenha`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getLancamentosProtocolosIatfMatrizNoPevEPrenha(filtro: ReprodcutionIatfFilter): Observable<Iatf[]> {
    filtro.cdFarm = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Iatf[]>(`${this.apiUrlReproducao}/iatfs/protocols/lancamentos/matriz-prenha-pev`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getProtocolosIatfLancados(filtro: ReprodcutionIatfFilter): Observable<Iatf[]> {
    filtro.cdFarm = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Iatf[]>(`${this.apiUrlReproducao}/iatfs/protocolos/lancados`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getProtocolosIatf(): Observable<Protocolo[]> {
    return this.http
      .get<Protocolo[]>(`${this.apiUrlReproducao}/iatfs/protocolos/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereIatfs(iatfs: Iatf[]) {
    return this.http
      .post<any>(`${this.apiUrlReproducao}`, iatfs)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaIatfs(iatfs: Iatf[]) {
    return this.http
      .put<Iatf[]>(`${this.apiUrlReproducao}`, iatfs)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeIatfs(iatfs: Iatf[]) {
    return this.http
      .put<Iatf[]>(`${this.apiUrlReproducao}/iatf/remove`, iatfs)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getTiposTentativa(): Observable<TipoTentativa[]> {
    return this.http
      .get<TipoTentativa[]>(`${this.apiUrlReproducao}/consulta/tipos-tentativa`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getReprodutores(): Observable<Reprodutor[]> {
    return this.http
      .get<Reprodutor[]>(`${this.apiUrlReproducao}/consulta/reprodutor/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getResultadosDiagnostico(): Observable<ResultadoDiagnostico[]> {
    return this.http
      .get<ResultadoDiagnostico[]>(`${this.apiUrlReproducao}/consulta/resultado-diagnostico`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getReproducoes(): Observable<Reproducao[]> {
    return this.http
      .get<Reproducao[]>(`${this.apiUrlReproducao}/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaReproducoes(reproducoes: Array<Reproducao>): Observable<Reproducao[]> {
    return this.http
      .put<Reproducao[]>(`${this.apiUrlReproducao}/consulta/atualiza`, reproducoes)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeReproducoes(cdsReproducao: number[]): Observable<Reproducao[]> {
    return this.http
      .put<Reproducao[]>(`${this.apiUrlReproducao}/consulta/remove`, cdsReproducao)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getIaCobTes(filtro: IaCobTeFilter): Observable<Reproducao[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Reproducao[]>(`${this.apiUrlReproducao}/ia-cob-te`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereIaCobTes(iacobtes: Reproducao[]) {
    iacobtes.forEach((ia) => ia.cdfazenda = this.altaGestaoService.getCdFazendaSelecionada());
    return this.http
      .post<any>(`${this.apiUrlReproducao}/ia-cob-te/insere`, iacobtes)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getHistoricosDeReproducao(filtro: HistoricoReproducaoFiltro): Observable<HistoricoReproducao[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<HistoricoReproducao[]>(`${this.apiUrlReproducao}/historico-reproducao`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereHistoricosDeReproducao(historicosReproducao: HistoricoReproducao[]): Observable<HistoricoReproducao[]> {
    return this.http
      .post<HistoricoReproducao[]>(`${this.apiUrlReproducao}/historico-reproducao/insere`, historicosReproducao)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaHistoricosDeReproducao(historicosReproducao: HistoricoReproducao[]): Observable<HistoricoReproducao[]> {
    return this.http
      .put<HistoricoReproducao[]>(`${this.apiUrlReproducao}/historico-reproducao/atualiza`, historicosReproducao)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeHistoricosDeReproducao(cdsHistoricoReproducao: number[]): Observable<HistoricoReproducao[]> {
    return this.http
      .put<HistoricoReproducao[]>(`${this.apiUrlReproducao}/historico-reproducao/remove`, cdsHistoricoReproducao)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getLeites(): Observable<Leite[]> {
    return this.http
      .get<Leite[]>(`${this.apiUrlReproducao}/leite/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }
}
