import { TipoEvento } from '../../models/alta-cria/tipo-evento.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ColostragemMortalidadeFiltro } from 'src/models/alta-cria/colostragem-mortalidade-filtro.model';
import { ColostragemMortalidade } from 'src/models/alta-cria/colostragem-mortalidade.model';
import { GpdQuantidadeDoencasFiltro } from 'src/models/alta-cria/gpd-quantidade-doencas-filtro.model';
import { GpdQuantidadeDoencas } from 'src/models/alta-cria/gpd-quantidade-doencas.model';
import { IdadeMediaDoencasFiltro } from 'src/models/alta-cria/idade-doencas-filtro.model';
import { IdadeMediaDoencas } from 'src/models/alta-cria/idade-doencas.model';
import { NumeroDoencasMesesFiltro } from 'src/models/alta-cria/numero-doencas-meses-filtro.model';
import { NumeroDoencasMeses } from 'src/models/alta-cria/numero-doencas-meses.model';
import { Detalhe } from 'src/models/detalhe.model';
import { AltaGestaoService } from './alta-gestao.service';
import { GanhoPesoMeta } from 'src/models/alta-cria/ganho-peso-meta.model';
import { GanhoPesoMetaFiltro } from 'src/models/alta-cria/ganho-peso-meta-filtro.model';
import { Colostragem } from 'src/models/alta-cria/colostragem.model';
import { Pesagem } from 'src/models/alta-cria/pesagem.model';
import { PesagemFiltro } from 'src/models/alta-cria/pesagem-filtro.model';
import { ColostragemFiltro } from 'src/models/alta-cria/colostragem-filtro.model';
import { Evento } from 'src/models/alta-cria/evento.model';
import { EventoFiltro } from 'src/models/alta-cria/evento-filtro.model';

@Injectable({
  providedIn: 'root'
})
export class AltaCriaService {

  private apiUrl = `${environment.apiBaseUrl}/alta-cria`;
  private NUMERO_TENTATIVAS_REQUEST = 0;

  colostragemMortalidadeUrl = `colostragem-mortalidade`;
  gpdQuantidadeDoencasUrl = `gpd-quantidade-doencas`;
  idadeDoencasUrl = `idade-doencas`;
  numeroDoencasMesesUrl = `numero-doencas-meses`;
  ganhoPesoMetaUrl = `ganho-peso-meta`;

  constructor(
    private http: HttpClient,
    private altaGestaoService: AltaGestaoService) {
  }

  getColostragemMortalidade(filtro: ColostragemMortalidadeFiltro): Observable<ColostragemMortalidade[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<ColostragemMortalidade[]>(`${this.apiUrl}/${this.colostragemMortalidadeUrl}`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getGpdQuantidadeDoencas(filtro: GpdQuantidadeDoencasFiltro): Observable<GpdQuantidadeDoencas[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<GpdQuantidadeDoencas[]>(`${this.apiUrl}/${this.gpdQuantidadeDoencasUrl}`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getIdadeDoencas(filtro: IdadeMediaDoencasFiltro): Observable<IdadeMediaDoencas[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<IdadeMediaDoencas[]>(`${this.apiUrl}/${this.idadeDoencasUrl}`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getNumeroDoencasMeses(filtro: NumeroDoencasMesesFiltro): Observable<NumeroDoencasMeses[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<NumeroDoencasMeses[]>(`${this.apiUrl}/${this.numeroDoencasMesesUrl}`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getAltaCriaGanhoPesoMeta(filtro: GanhoPesoMetaFiltro): Observable<GanhoPesoMeta[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<GanhoPesoMeta[]>(`${this.apiUrl}/${this.ganhoPesoMetaUrl}`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getDatalhes(chave: string): Observable<Detalhe> {
    return this.http
      .get<Detalhe>(`${this.apiUrl}/${chave}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getColostragem(filter: ColostragemFiltro): Observable<Colostragem[]> {
    filter.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Colostragem[]>(`${this.apiUrl}/colostragem`, filter)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereColostragem(colostragens: Colostragem[]): Observable<Colostragem[]> {
    return this.http
      .post<Colostragem[]>(`${this.apiUrl}/colostragem/insere`, colostragens)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaColostragem(colostragens: Colostragem[]): Observable<Colostragem[]> {
    return this.http
      .put<Colostragem[]>(`${this.apiUrl}/colostragem/atualiza`, colostragens)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeColostragem(ids: number[]): Observable<Colostragem[]> {
    return this.http
      .put<Colostragem[]>(`${this.apiUrl}/colostragem/remove`, ids)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getPesagem(filtro: PesagemFiltro): Observable<Pesagem[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Pesagem[]>(`${this.apiUrl}/pesagem`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  inserePesagem(pesagens: Pesagem[]): Observable<Pesagem[]> {
    return this.http
      .post<Pesagem[]>(`${this.apiUrl}/pesagem/insere`, pesagens)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaPesagem(pesagens: Pesagem[]): Observable<Pesagem[]> {
    return this.http
      .put<Pesagem[]>(`${this.apiUrl}/pesagem/atualiza`, pesagens)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removePesagem(ids: number[]): Observable<Pesagem[]> {
    return this.http
      .put<Pesagem[]>(`${this.apiUrl}/pesagem/remove`, ids)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getEventos(filtro: EventoFiltro): Observable<Evento[]> {
    filtro.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<Evento[]>(`${this.apiUrl}/eventos`, filtro)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereEvento(eventos: Evento[]): Observable<Evento[]> {
    return this.http
      .post<Evento[]>(`${this.apiUrl}/eventos/insere`, eventos)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaEvento(eventos: Evento[]): Observable<Evento[]> {
    return this.http
      .put<Evento[]>(`${this.apiUrl}/eventos/atualiza`, eventos)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeEvento(ids: number[]): Observable<Evento[]> {
    return this.http
      .put<Evento[]>(`${this.apiUrl}/eventos/remove`, ids)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getTiposEvento(): Observable<TipoEvento[]> {
    return this.http
      .get<TipoEvento[]>(`${this.apiUrl}/eventos/tipoevento`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }

}
