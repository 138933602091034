import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { GridComponent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { OcorrenciaReproducaoCadastroComponent } from 'src/app/reproduction/ocorrencia-reproducao-cadastro/ocorrencia-reproducao-cadastro.component';
import { AltaCriaService } from 'src/app/_services/alta-cria.service';
import { AltaGestaoService } from 'src/app/_services/alta-gestao.service';
import { EventoFiltro } from 'src/models/alta-cria/evento-filtro.model';
import { Evento } from 'src/models/alta-cria/evento.model';
import { Matriz } from 'src/models/matriz/matriz.model';

@Component({
  selector: 'app-eventos-cadastro',
  templateUrl: './eventos-cadastro.component.html',
  styleUrls: ['./eventos-cadastro.component.scss']
})
export class EventosCadastroComponent implements OnInit {

  @ViewChild('eventsGrid', { static: false }) grid: GridComponent;

  hasChanges = false;
  loading = false;
  isValid = true;

  itemsToUpdate: Evento[] = [];
  itemsToInsert: Evento[] = [];
  removedItems: Evento[] = [];
  itemsSource: Evento[] = [];
  invalidItems: Evento[] = [];
  eventTypes: string[] = [];

  formGroup: FormGroup;
  previousEdit: any;
  editedRowIndex: number;
  itemToRemove: Evento;
  matrix: Matriz;

  gridView: GridDataResult;
  itemsSort: SortDescriptor[] = [{ field: 'data', dir: 'asc' }];
  gridSelection: any[] = [];
  addIcon = faPlus;
  editIcon = faEdit;
  rowIndex: number;

  selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private altaCriaService: AltaCriaService,
    private dialogRef: MatDialogRef<OcorrenciaReproducaoCadastroComponent>,
    private altaGestaoService: AltaGestaoService) {
    this.matrix = data.matrix;
  }

  ngOnInit() {
    this.invalidItems = new Array<Evento>();
    this.getEvents();
    this.getEventTypes();
  }

  onCellClick({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    this.editHandler({ sender, rowIndex, columnIndex, dataItem, isEdited });
  }

  validateItem(context: RowClassArgs) {
    let dataEvento = new Date(context.dataItem.dataEvento);
    let ano = dataEvento.getFullYear().toString();
    if (ano.length === 2) {
      ano = '20' + ano;
      dataEvento.setFullYear(Number(ano));
    }

    const currentDate =  new Date();
    const birthDate = new Date(context.dataItem.dtNascimento);
    const event = context.dataItem.evento;

    const hasDateAndDescription =
    dataEvento !== undefined
      && event !== undefined
      && event !== '';

    const hasValidDate = dataEvento > birthDate && dataEvento <= currentDate;

    context.dataItem.isValid = true;

    if (!hasDateAndDescription || !hasValidDate) {
      context.dataItem.isValid = false;
    }
    return {
      invalid: !context.dataItem.isValid
    };
  }

  createFormGroup(dataItem: Evento) {
    this.formGroup = new FormGroup({
      dataEvento: new FormControl(new Date(dataItem.dataEvento), Validators.required),
      evento: new FormControl(dataItem.evento, Validators.required)
    },
    {
      updateOn: 'blur',
    });

    this.formGroup.valueChanges.subscribe(value => this.updateObjectHandler(dataItem));
  }

  updateObjectHandler(dataItem: Evento) {
    let dataEvento = new Date(this.formGroup.controls[`dataEvento`].value);
    let ano = dataEvento.getFullYear().toString();
    if (ano.length === 2) {
      ano = '20' + ano;
      dataEvento.setFullYear(Number(ano));
    }

    dataItem.evento = this.formGroup.controls[`evento`].value;
    dataItem.dataEvento = dataEvento;

    const itemsToUpdate = this.itemsToUpdate.filter(cond => cond === dataItem);
    if (itemsToUpdate.length === 0) {
      this.itemsToUpdate.push(dataItem);
    }

    this.hasChanges = true;
  }

  closeRow({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  onRemoveClick({ dataItem }) {
    this.itemToRemove = dataItem;
    this.itemToRemove.isRemoved = true;
    this.removeHandler();
  }

  removeHandler() {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: `Deseja realmente remover o registro ${ new Date(this.itemToRemove.dataEvento).toLocaleDateString() }
          - ${ this.itemToRemove.evento ? this.itemToRemove.evento : `Não informado`} ?`,
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.removeItem(dialogRef);
      } else {
        this.itemToRemove.isRemoved = false;
      }
    });
  }

  removeItem(dialogRef) {
    const hasCdEventos =
      this.itemToRemove.cdEventos !== undefined && this.itemToRemove.cdEventos > 0;

    if (hasCdEventos) {
      this.itemsSource = this.itemsSource.filter(hm => hm.cdEventos !== this.itemToRemove.cdEventos);
      this.itemsToUpdate = this.itemsToUpdate.filter(hm => hm.cdEventos !== this.itemToRemove.cdEventos);

      this.altaCriaService.removeEvento([this.itemToRemove.cdEventos]).subscribe((response: Array<Evento>) => {
        this.snackBar.open('Registro removido com sucesso.', 'X', {
          duration: 2500,
        });
      });
    } else {
      this.itemsSource = this.itemsSource.filter(hm => hm.isRemoved === false || hm.isRemoved === undefined);
      this.itemsToUpdate = this.itemsToUpdate.filter(hm => hm.isRemoved === false || hm.isRemoved === undefined);

      if (this.itemsToUpdate.length === 0) {
        this.hasChanges = false;
      }
    }

    dialogRef.close();
  }

  saveClick() {
    this.saveHandler(false);
  }

  getEvents() {
    this.loading = true;
    const filter = this.getFilter();
    this.altaCriaService.getEventos(filter).subscribe((response: Evento[]) => {
      this.itemsSource = response;
      this.setBirthDate();
      this.loading = false;
    });
  }

  getEventTypes() {
    this.eventTypes.push(
        `BEBE RUMINAL`,
        `CARRAPATICIDA`,
        `DIARREIA`,
        `MOCHACAO`,
        `PNEUMONIA`,
        `PREVENTIVO TRISTEZA`,
        `TRISTEZA PARASITARIA`,
        `ULCERA DE ABOMASO`,
        `UMBIGO INFLAMADO`,
        `VACINACAO`,
        `VERMIFUGACAO`);
  }

  getFilter(): EventoFiltro {
    const filter = new EventoFiltro();
    filter.cdMatriz = this.matrix.cdmatriz;
    filter.cdFazenda = this.matrix.cdfazenda;
    return filter;
  }

  saveHandler(closeDialog: boolean) {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja salvar as alterações?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const item = this.itemsToUpdate.find(cond => cond.isValid === false);
        const hasInvalidItem = item !== null && item !== undefined;

        if (hasInvalidItem) {
          this.showErrorAlert(`Não foi possível salvar. Existem registros inválidos`);
        } else if (!this.isValidSameEventTypeOnSameDate()) {
          this.showErrorAlert(`Não é permitido lançar o mesmo evento na mesma data`);
        } else {
          this.saveChanges(dialogRef, closeDialog);
        }
      } else if (closeDialog) {
        this.closeHandler();
      }
    });
  }

  saveChanges(dialogRef, closeDialog: boolean) {
    this.itemsToInsert = this.itemsToUpdate.filter(item => item.cdEventos === 0 || item.cdEventos === undefined);
    this.itemsToUpdate = this.itemsToUpdate.filter(item => item.cdEventos > 0);

    if (this.itemsToInsert.length > 0) {
      this.insert(closeDialog);
    }
    if (this.itemsToUpdate.length > 0) {
      this.update(closeDialog);
    }

    this.hasChanges = false;

    dialogRef.close();
  }

  insert(closeDialog: boolean) {
    this.altaCriaService.insereEvento(this.itemsToInsert).subscribe((response: Evento[]) => {
      if (response != null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'X', {
          duration: 2500,
        });
        this.itemsToInsert = [];
        this.getEvents();
        this.setBirthDate();
        if (closeDialog) {
          this.closeHandler();
        }
      }
    });
  }

  update(closeDialog: boolean) {
    this.altaCriaService.atualizaEvento(this.itemsToUpdate).subscribe((response: Evento[]) => {
      if (response != null) {
        this.snackBar.open('Alterações salvas com sucesso.', 'X', {
          duration: 2500,
        });
        this.itemsToUpdate = [];
        this.getEvents();
        this.setBirthDate();
        if (closeDialog) {
          this.closeHandler();
        }
      }
    });
  }

  closeClick() {
    if (this.hasChanges === true) {
      this.saveHandler(true);
    } else {
      this.closeHandler();
    }
  }

  closeHandler() {
    this.dialogRef.close();
  }

  addClick() {
    this.addHandler();
  }

  addHandler() {
    const newItem: Evento = new Evento();
    newItem.cdMatriz = this.matrix.cdmatriz;
    newItem.dataEvento = new Date();
    newItem.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();
    newItem.dtNascimento = this.matrix.dtnascimento;

    this.itemsSource.push(newItem);
    this.itemsToUpdate.push(newItem);

    this.editHandler({ sender: this.grid, rowIndex: this.itemsSource.length - 1, columnIndex: 0, dataItem: newItem, isEdited: false });

    this.hasChanges = true;
  }

  editHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (this.previousEdit != null) {
      this.closeRow(this.previousEdit);
    }

    this.createFormGroup(dataItem);
    sender.editCell(rowIndex, columnIndex, this.formGroup);
    this.previousEdit = { dataItem, sender, rowIndex };
  }

  onDropDownChange(eventType: string, event: Evento) {
    const hasEventType = eventType !== undefined && eventType !== null;
    const hasEvent = event !== undefined && event !== null;

    if (hasEventType && hasEvent) {
      event.evento = eventType;

      if (this.itemsToUpdate.filter(cond => cond === event).length === 0) {
        this.itemsToUpdate.push(event);
      }

      this.hasChanges = true;
    }
  }

  setBirthDate() {
    this.itemsSource.forEach((event) => { event.dtNascimento = this.matrix.dtnascimento; });
  }

  isValidSameEventTypeOnSameDate(): boolean {
    const itemsToUpdateAux = this.itemsToUpdate;
    let count = 0;

    itemsToUpdateAux.forEach((itemToUpdateAux) => {
      this.itemsToUpdate.forEach((itemToUpdate) => {
        const isSameEvent = itemToUpdateAux.evento === itemToUpdate.evento;
        const isSameDate = new Date(itemToUpdateAux.dataEvento).getDate() === new Date(itemToUpdate.dataEvento).getDate();
        if (isSameEvent && isSameDate) {
          count++;
        }
      });
      if (count > 1) {
        return;
      }
      count = 0;
    });

    if (count > 1) {
      return false;
    }

    count = 0;

    this.itemsToUpdate.forEach((itemToUpdate) => {
      this.itemsSource.forEach((itemSource) => {
        const isSameEvent = itemToUpdate.evento === itemSource.evento;
        const isSameDate = new Date(itemToUpdate.dataEvento).getDate() === new Date(itemSource.dataEvento).getDate();
        if (isSameEvent && isSameDate) {
          count++;
        }
      });
      if (count > 1) {
        return;
      }
      count = 0;
    });

    if (count > 1) {
      return false;
    }

    return true;

  }

  showErrorAlert(msg: string) {
    this.dialog.open(DialogoAlertaComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'OK'
        }
      }
    });
  }

}
