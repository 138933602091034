import { Injectable } from '@angular/core';
import { Matriz } from 'src/models/matriz/matriz.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { ComposicaoRacial } from 'src/models/matriz/composicao-racial.model';
import { Reprodutor } from 'src/models/matriz/reprodutor.model';
import { MotivoBaixa } from 'src/models/matriz/motivo-baixa.model';
import { Lote } from 'src/models/matriz/lote.model';
import { Genealogia } from 'src/models/matriz/genealogia.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';
import { HistoricoDeMatriz } from 'src/models/matriz/historico-matriz.model';
import { Raca } from 'src/models/configuracoes/raca.model';
import { AltaGestaoService } from './alta-gestao.service';

@Injectable({
  providedIn: 'root'
})
export class MatrizService {

  private apiUrlMatriz = `${environment.apiBaseUrl}/matriz`;
  private apiUrlConfiguracoes = `${environment.apiBaseUrl}/configuracoes`;
  private NUMERO_TENTATIVAS_REQUEST = 0;

  constructor(
    private http: HttpClient,
    private altaGestaoService: AltaGestaoService) {
  }

  getMatrizes(): Observable<Matriz[]> {
    return this.http
      .get<Matriz[]>(`${this.apiUrlMatriz}/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaMatriz(matriz: any): Observable<object> {
    return this.http
      .put(`${this.apiUrlMatriz}/atualiza`, matriz)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeMatriz(matriz: any): Observable<object> {
    return this.http
      .put(`${this.apiUrlMatriz}/remove`, matriz)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getComposicaoRacial(): Observable<ComposicaoRacial[]> {
    return this.http
      .get<ComposicaoRacial[]>(`${this.apiUrlMatriz}/composicao-racial/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getReprodutor(): Observable<Reprodutor[]> {
    return this.http
      .get<Reprodutor[]>(`${this.apiUrlMatriz}/reprodutor/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getMotivoBaixa(): Observable<MotivoBaixa[]> {
    return this.http.get<MotivoBaixa[]>(`${this.apiUrlMatriz}/motivo-baixa`).pipe(retry(3), catchError(this.handleError));
  }

  getLote(): Observable<Lote[]> {
    return this.http
      .get<Lote[]>(`${this.apiUrlMatriz}/lote/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getGenealogia(): Observable<Genealogia[]> {
    return this.http
      .get<Genealogia[]>(`${this.apiUrlMatriz}/genealogia/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getReproducao(): Observable<Reproducao[]> {
    return this.http
      .get<Reproducao[]>(`${this.apiUrlMatriz}/reproducao/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getHistoricoDeMatrizPorFazenda(): Observable<HistoricoDeMatriz[]> {
    return this.http
      .get<HistoricoDeMatriz[]>(`${this.apiUrlMatriz}/historico-matriz/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getRaca(): Observable<Raca[]> {
    return this.http
      .get<Raca[]>(`${this.apiUrlConfiguracoes}/raca`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getConfiguracoesComposicaoRacial(): Observable<ComposicaoRacial[]> {
    return this.http
      .get<ComposicaoRacial[]>(`${this.apiUrlConfiguracoes}/composicao-racial/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  updateComposition(value: any): Observable<object> {
    return this.http
      .put(`${this.apiUrlConfiguracoes}/composicao-racial/atualiza`, value)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insertComposition(racialComposition: ComposicaoRacial) {
    return this.http
      .post<any>(`${this.apiUrlConfiguracoes}/composicao-racial`, racialComposition)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getLot(): Observable<Lote[]> {
    return this.http
      .get<Lote[]>(`${this.apiUrlConfiguracoes}/lote/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  updateLot(value: any): Observable<object> {
    return this.http
      .put(`${this.apiUrlConfiguracoes}/lote/atualiza`, value)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insertLot(lote: Lote) {
    return this.http
      .post<any>(`${this.apiUrlConfiguracoes}`, lote)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereMatriz(matriz: Matriz) {
    matriz.cdfazenda = this.altaGestaoService.getCdFazendaSelecionada();
    return this.http
      .post<any>(`${this.apiUrlMatriz}`, matriz)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getCdFazenda() {
    return this.altaGestaoService.getCdFazendaSelecionada();
  }

  removeHistoricoDeMatriz(historicoMatriz: Array<HistoricoDeMatriz>): Observable<HistoricoDeMatriz[]> {
    return this.http
      .put<HistoricoDeMatriz[]>(`${this.apiUrlMatriz}/historico-matriz/remove`, historicoMatriz)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaHistoricoDeMatriz(historicoMatriz: HistoricoDeMatriz[]): Observable<HistoricoDeMatriz[]> {
    return this.http
      .post<HistoricoDeMatriz[]>(`${this.apiUrlMatriz}/historico-matriz/atualiza`, historicoMatriz)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }
}
