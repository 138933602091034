import { Component, OnInit, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { GridComponent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { OcorrenciaDeReproducao } from 'src/models/reproducao/ocorrencia-de-reproducao.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SortDescriptor } from '@progress/kendo-data-query';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { ReproducaoService } from 'src/app/_services/reproducao.service';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { AltaGestaoService } from 'src/app/_services/alta-gestao.service';

@Component({
  selector: 'app-ocorrencia-reproducao-cadastro',
  templateUrl: './ocorrencia-reproducao-cadastro.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./ocorrencia-reproducao-cadastro.component.scss']
})
export class OcorrenciaReproducaoCadastroComponent implements OnInit {

  @ViewChild('eventsGrid', { static: false }) grid: GridComponent;

  public hasChanges = false;
  public itemsToUpdate: Array<OcorrenciaDeReproducao> = [];
  public itemToRemove: OcorrenciaDeReproducao;

  public formGroup: FormGroup;
  public previousEdit: any;
  public editedRowIndex: number;

  public nomeDaMatriz: string;
  public numeroDaMatriz: string;

  public removedItems: Array<OcorrenciaDeReproducao> = [];
  public allItems: Array<OcorrenciaDeReproducao>;

  public itemsSource: Array<OcorrenciaDeReproducao>;
  public gridView: GridDataResult;
  public itemsSort: SortDescriptor[] = [{ field: 'dataOcorrencia', dir: 'asc' }];
  public gridSelection: any[] = [];
  public loading = false;
  public addIcon = faPlus;
  public editIcon = faEdit;

  public invalidItems: Array<OcorrenciaDeReproducao> = new Array<OcorrenciaDeReproducao>();
  public isValid = true;

  public rowIndex: number;

  public selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public reproducaoService: ReproducaoService,
              public dialogRef: MatDialogRef<OcorrenciaReproducaoCadastroComponent>,
              public altaGestaoService: AltaGestaoService) {

    this.itemsSource = data.items;
  }

  ngOnInit() {
    this.invalidItems = new Array<OcorrenciaDeReproducao>();
  }

  public clickTableRow() {
    const matrix = this.gridSelection[0];
  }

  public onCellClick({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    this.editHandler({ sender, rowIndex, columnIndex, dataItem, isEdited });
  }

  public validateItem(context: RowClassArgs) {
    const dataOcorrencia: Date = new Date(context.dataItem.dataOcorrencia);

    context.dataItem.isValid = true;

    if (context.dataItem.dataOcorrencia == null || context.dataItem.descricao == null || context.dataItem.descricaoCompl == null) {
      context.dataItem.isValid = false;
    } else if (context.dataItem.dataReproducao != null && dataOcorrencia < new Date(context.dataItem.dataReproducao)) {
      context.dataItem.isValid = false;
    } else if (dataOcorrencia > new Date()) {
      context.dataItem.isValid = false;
    }

    return {
      invalid: !context.dataItem.isValid
    };
  }

  public onDropDownChange(dataItem: any) {
    if (dataItem != null) {
      if (this.itemsToUpdate.filter(cond => cond === dataItem).length === 0) {
        this.itemsToUpdate.push(dataItem);
      }
      this.hasChanges = true;
    }
  }

  private createFormGroup(dataItem: OcorrenciaDeReproducao) {
    this.formGroup = new FormGroup({
      dataOcorrencia: new FormControl(new Date(dataItem.dataOcorrencia)),
      descricao: new FormControl(dataItem.descricao, Validators.required),
      descricaoCompl: new FormControl(dataItem.descricaoCompl, [Validators.required, Validators.maxLength(100)])
    },
      {
        updateOn: 'blur',
      });
    this.formGroup.valueChanges.subscribe(value => this.updateObjectHandler(dataItem));
  }

  private updateObjectHandler(dataItem: OcorrenciaDeReproducao) {
    dataItem.descricao = this.formGroup.controls.descricao.value;
    dataItem.descricaoCompl = this.formGroup.controls.descricaoCompl.value;
    dataItem.dataOcorrencia = this.formGroup.controls.dataOcorrencia.value;
    if (this.itemsToUpdate.filter(cond => cond === dataItem).length === 0) {
      this.itemsToUpdate.push(dataItem);
    }
    this.hasChanges = true;
  }

  protected closeRow({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
  }

  public onRemoveClick({ dataItem }) {
    this.itemToRemove = dataItem;
    this.itemToRemove.isRemoved = true;
    this.removeHandler();
  }

  public removeHandler() {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: `Deseja realmente remover o registro do dia ${ new Date(this.itemToRemove.dataOcorrencia).toLocaleDateString() }
        - ${ this.itemToRemove.descricao ? this.itemToRemove.descricao : `Não informado`} ?`,
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.removeItem(dialogRef);
      } else {
        this.itemToRemove.isRemoved = false;
      }
    });
  }

  private removeItem(dialogRef) {
    const hasCdOcorrencia =
      this.itemToRemove.cdOcorrencia !== undefined && this.itemToRemove.cdOcorrencia > 0;

    if (hasCdOcorrencia) {
      this.itemsSource = this.itemsSource.filter(hm => hm.cdOcorrencia !== this.itemToRemove.cdOcorrencia);
      this.itemsToUpdate = this.itemsToUpdate.filter(hm => hm.cdOcorrencia !== this.itemToRemove.cdOcorrencia);

      this.reproducaoService.removeOcorrenciasDeReproducao([this.itemToRemove.cdOcorrencia]).subscribe((response: Array<OcorrenciaDeReproducao>) => {
        if (response != null) {
          this.allItems = response;
        }
      });
    } else {
      this.itemsSource = this.itemsSource.filter(hm => hm.isRemoved === false || hm.isRemoved === undefined);
      this.itemsToUpdate = this.itemsToUpdate.filter(hm => hm.isRemoved === false || hm.isRemoved === undefined);

      if (this.itemsToUpdate.length === 0) {
        this.hasChanges = false;
      }
    }

    dialogRef.close();
  }

  saveClick() {
    this.saveHandler(true, false);
  }

  private getHistoryAndClose() {
    this.reproducaoService.getOcorrenciasDeReproducao().subscribe(response => {
      this.allItems = response;
      this.closeHandler();
    });
  }

  protected saveHandler(closeDialog: boolean, isOnCloseClick: boolean) {
    if (isOnCloseClick) {
      this.showConfirmationDialog(closeDialog);
    } else {
      if (this.itemsToUpdate.filter(cond => cond.isValid === false).length > 0) {
        this.snackBar.open('Não foi possível salvar as alterações. Alguns items possuem campos inválidos!', 'Fechar', {
          duration: 2000,
        });
      } else {
        this.saveChanges(closeDialog);
      }
    }
  }

  showConfirmationDialog(closeDialog: boolean) {
    const dialogRef = this.dialog.open(DialogoConfirmacao, {
      data: {
        message: 'Deseja salvar as alterações?',
        buttonText: {
          ok: 'Sim',
          cancel: 'Não'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (this.itemsToUpdate.filter(cond => cond.isValid === false).length > 0) {
          this.snackBar.open('Não foi possível salvar as alterações. Alguns items possuem campos inválidos!', 'Fechar', {
            duration: 2000,
          });
        } else {
          this.saveChanges(closeDialog);
        }
      } else if (closeDialog) {
        this.getHistoryAndClose();
      }
    });
  }

  private saveChanges(closeDialog: boolean) {
    this.reproducaoService.atualizaOcorrenciasDeReproducao(this.itemsToUpdate).subscribe((response: Array<OcorrenciaDeReproducao>) => {
      if (response != null) {
        this.allItems = response;
        this.snackBar.open('Alterações salvas com sucesso.', 'Fechar', {
          duration: 2000,
        });
        if (closeDialog) {
          this.closeHandler();
        }
      }
    });
    this.hasChanges = false;
  }

  closeClick() {
    if (this.hasChanges === true) {
      this.saveHandler(true, true);
    } else {
      this.closeHandler();
    }
  }

  private closeHandler() {
    this.dialogRef.close(this.allItems);
  }

  addClick() {
    this.addHandler();
  }

  private addHandler() {
    const newItem: OcorrenciaDeReproducao = new OcorrenciaDeReproducao();
    newItem.cdMatriz = this.data.cdMatriz;
    newItem.CdReproducao = this.data.cdReproducao;
    newItem.dataOcorrencia = new Date();
    newItem.cdFazenda = this.altaGestaoService.getCdFazendaSelecionada();

    this.itemsSource.push(newItem);
    this.itemsToUpdate.push(newItem);

    this.editHandler({ sender: this.grid, rowIndex: this.itemsSource.length - 1, columnIndex: 0, dataItem: newItem, isEdited: false });

    this.hasChanges = true;
  }

  private editHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (this.previousEdit != null) {
      this.closeRow(this.previousEdit);
    }

    this.createFormGroup(dataItem);

    sender.editCell(rowIndex, columnIndex, this.formGroup);
    this.previousEdit = { dataItem, sender, rowIndex };
  }

}




