import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DATE_FORMATS, MAT_DIALOG_DATA } from '@angular/material';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';

@Component({
  selector: 'app-ia-cob-te-cadastro',
  templateUrl: './ia-cob-te-cadastro.component.html',
  styleUrls: ['./ia-cob-te-cadastro.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class IaCobTeCadastroComponent implements OnInit {

  @ViewChild('errorAlert', { static: false })
  errorAlert: ElementRef;

  matrixPanelOpenState = true;
  iaCobTePanelOpenState = true;
  touchPanelOpenState = true;
  ocurrencesPanelOpenState = false;
  hasChanges = false;
  reload = false;
  isNewIA = false;
  item: any;

  gridSelection: any[] = [];
  selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<IaCobTeCadastroComponent>) {
    this.setItems(data);
  }

  ngOnInit() {
  }

  setItems(data: any) {
    this.item = data.item;
    this.isNewIA = data.isNewIA;
  }

  onCloseClick() {
    this.closeHandler();
  }

  closeHandler() {
    this.dialogRef.close(this.reload);
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'X', { duration: 2500 });
  }

  itemHasChanged(value: string) {
    const hasChanges = value !== undefined && value === `true`;
    if (hasChanges) {
      this.hasChanges = true;
    }
  }

}
