import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LogAcesso } from 'src/models/logacesso.model';

@Injectable({
  providedIn: 'root'
})
export class LogAcessoService {
  
  private apiUrl = `${environment.apiBaseUrl}/logacesso`;
  private NUMERO_TENTATIVAS_REQUEST = 0;

  constructor(private http: HttpClient) { }

  insere(logAcesso: LogAcesso) {
    return this.http
      .post<any>(`${this.apiUrl}`, logAcesso)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }
}
