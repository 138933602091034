import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';

@Component({
  selector: 'app-toque-detalhe',
  templateUrl: './toque-detalhe.component.html',
  styleUrls: ['./toque-detalhe.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ToqueDetalheComponent implements OnInit {

  @Input() item: any;
  @Output() hasChanges = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  itemChanged(e: any) {
    this.itemHasChanged();
  }

  itemHasChanged() {
    this.hasChanges.emit(`true`);
  }

}
