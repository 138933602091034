import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SortDescriptor } from '@progress/kendo-data-query';
import { OcorrenciaDeReproducao } from 'src/models/reproducao/ocorrencia-de-reproducao.model';
import { OcorrenciaReproducaoCadastroComponent } from '../ocorrencia-reproducao-cadastro/ocorrencia-reproducao-cadastro.component';
import { ReproducaoService } from '../../_services/reproducao.service';

@Component({
  selector: 'app-ocorrencia-reproducao-detalhe',
  templateUrl: './ocorrencia-reproducao-detalhe.component.html',
  styleUrls: ['./ocorrencia-reproducao-detalhe.component.scss']
})
export class OcorrenciaReproducaoDetalheComponent implements OnInit {

  @Input() item: any;
  @Output() hasChanges = new EventEmitter<string>();

  itemsSource: any;
  events: OcorrenciaDeReproducao[];
  eventTypes: string[];
  cdMatrix: number;
  cdReproduction: number;
  gridKeyDownCode = `F4`;
  ocurrenceSort: SortDescriptor[] = [{ field: 'dataOcorrencia', dir: 'asc' }];
  gridSelection: any[] = [];

  selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(
    private reproducaoService: ReproducaoService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.setCdMatrix();
    this.setCdReproduction();
    this.getOcurrenceTypes();
    this.getEvents();
  }

  setCdMatrix() {
    this.cdMatrix =
      this.item.cdmatriz !== undefined ? this.item.cdmatriz :
      this.item.cdMatrix !== undefined ? this.item.cdMatrix : this.item.cdMatriz;
  }

  setCdReproduction() {
    this.cdReproduction =
      this.item.cdreproducao !== undefined ? this.item.cdreproducao : this.item.cdReproducao;
  }

  getOcurrenceTypes() {
    this.reproducaoService.getTiposOcorrencia().subscribe((response: any) => {
      this.eventTypes = response;
    }, (error) => console.error(error));
  }

  getEvents() {
    this.reproducaoService.getOcorrenciasDeReproducaoPorMatriz(this.cdMatrix).subscribe((response: any) => {
      response = response.filter(ocurrence => ocurrence.cdReproducao === this.cdReproduction);
      this.events = response;
      this.itemsSource = response;
    }, (error) => console.error(error));
  }

  onGridKeyDown(e: any) {
    if (e.code === this.gridKeyDownCode) {
      this.showReproductionEventsWindow();
      this.itemHasChanged();
    }
  }

  onDblClick() {
    this.showReproductionEventsWindow();
    this.itemHasChanged();
  }

  showReproductionEventsWindow() {
    const items: Array<OcorrenciaDeReproducao> =
      this.itemsSource.filter(m => m.cdMatriz === this.cdMatrix && m.cdReproducao === this.cdReproduction);

    const dialogRef = this.dialog.open(OcorrenciaReproducaoCadastroComponent, {
      disableClose: true,
      height: '300px',
      width: '500px',
      data: {
        items,
        numeroDaMatriz: this.item.numeroMatriz,
        nomeDaMatriz: this.item.nomeMatriz,
        cdReproducao: this.cdReproduction,
        cdMatriz: this.cdMatrix,
        dataServico: this.item.data,
        tiposDeOcorrencia: this.eventTypes,
        snackBar: this.snackBar,
        service: this.reproducaoService,
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.getEvents();
      }
    });
  }

  itemChanged(e: any) {
    this.itemHasChanged();
  }

  itemHasChanged() {
    this.hasChanges.emit(`true`);
  }

}
