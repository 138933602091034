import { ProducaoService } from './../../_services/producao.service';
import { DatePipe } from '@angular/common';
import { MatrizMaeCadastroComponent } from '../matriz-mae-cadastro/matriz-mae-cadastro.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatInput, MatRadioButton, MatSnackBar, MatSnackBarConfig, MAT_DATE_FORMATS } from '@angular/material';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import { GridDataResult, DataItem } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DialogoConfirmacao } from 'src/app/_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { MatrizService } from 'src/app/_services/matriz.service';
import { ConfiguracoesService } from 'src/app/_services/configuracoes.service';
import { Raca } from 'src/models/configuracoes/raca.model';
import { BaseTouro } from 'src/models/configuracoes/base-touros.model';
import { ComposicaoRacial } from 'src/models/matriz/composicao-racial.model';
import { Lote } from 'src/models/matriz/lote.model';
import { DialogoAlertaComponent } from 'src/app/_utils/dialogo-alerta/dialogo-alerta.component';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { SemenTouro } from 'src/models/configuracoes/semen-touro.model';
import { Reprodutor } from 'src/models/configuracoes/reprodutor.model';
import { Matriz } from 'src/models/matriz/matriz.model';
import { PesagemCadastroComponent } from 'src/app/alta-cria/pesagem-cadastro/pesagem-cadastro.component';
import { ColostragemCadastroComponent } from 'src/app/alta-cria/colostragem-cadastro/colostragem-cadastro.component';
import { EventosCadastroComponent } from 'src/app/alta-cria/eventos-cadastro/eventos-cadastro.component';
import { MotivoBaixa } from 'src/models/matriz/motivo-baixa.model';
import { Genealogia } from 'src/models/matriz/genealogia.model';
import { HistoricoDeMatriz } from 'src/models/matriz/historico-matriz.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';
import { MatrizHistoricoCadastroComponent } from '../matriz-historico-cadastro/matriz-historico-cadastro.component';
import { MY_DATE_FORMATS } from 'src/app/_utils/calendar-date-format.utils';
import * as moment from 'moment';
import { AltaGestaoService } from 'src/app/_services/alta-gestao.service';
import { BaseTouroFiltro } from 'src/models/configuracoes/base-touros-filtro.model';
import { ReproducaoService } from 'src/app/_services/reproducao.service';
import { ControleLeiteiro } from 'src/models/producao/controle-leiteiro.model';

@Component({
  selector: 'app-matriz-cadastro',
  templateUrl: './matriz-cadastro.component.html',
  styleUrls: ['./matriz-cadastro.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class MatrizCadastroComponent implements OnInit {

  @ViewChild('infoRealNascer0', { static: false }) infoRealNascer0: MatRadioButton;
  @ViewChild('infoRealNascer1', { static: false }) infoRealNascer1: MatRadioButton;
  @ViewChild('infoRealDesaleitamento0', { static: false }) infoRealDesaleitamento0: MatRadioButton;
  @ViewChild('infoRealDesaleitamento1', { static: false }) infoRealDesaleitamento1: MatRadioButton;
  @ViewChild('tipo0', { static: false }) tipo0: MatRadioButton;
  @ViewChild('tipo1', { static: false }) tipo1: MatRadioButton;
  @ViewChild('ativo0', { static: false }) ativo0: MatRadioButton;
  @ViewChild('ativo1', { static: false }) ativo1: MatRadioButton;
  @ViewChild('desc', { static: false }) private desc: MatInput;
  @ViewChild('tiporeprodutor0', { static: false }) tiporeprodutor0: MatRadioButton;
  @ViewChild('tiporeprodutor1', { static: false }) tiporeprodutor1: MatRadioButton;

  matrixPanelOpenState = true;
  altaCriaPanelOpenState = false;
  historyPanelOpenState = false;
  hasChangesComp = false;
  hasChangesLot = false;
  hasChanges = false;
  hasChangesPai = false;
  hasChangesMother = false;
  hasError = false;
  desabilita = false;
  loading = false;
  loadingHistories = false;
  editComp = false;
  editLote = false;
  editPai = false;
  editMother = false;
  edited = false;
  editBaseTouros = false;
  carregado = false;

  raca: Array<Raca>;
  // racaList: Array<Raca>;
  compositionList: Array<ComposicaoRacial>;
  composicoesRaciais: Array<ComposicaoRacial>;
  lotes: Array<Lote>;
  // racas = [];
  reprodutorListAll: Array<Reprodutor>;
  reprodutorList: Array<Reprodutor>;
  semenTourosListAll: Array<SemenTouro>;
  semenTourosList: Array<SemenTouro>;
  baseTourosListAll: Array<BaseTouro>;
  baseTourosList: Array<BaseTouro>;
  racaListAll: Array<Raca>;
  resultFilter: Array<BaseTouro>;
  composicao: Array<ComposicaoRacial>;
  reprodutor: Array<Reprodutor>;
  motivosBaixa: Array<MotivoBaixa>;
  lote: Array<Lote>;
  genealogias: Array<Genealogia>;
  historicos: Array<HistoricoDeMatriz>;
  reproducao: Array<Reproducao>;
  matrizes: Array<Matriz>;
  historicoMatriz: HistoricoDeMatriz[];
  controlesLeiteiros: ControleLeiteiro[];

  clickedRowItem: any;
  racialCompositionId: any;
  validComposition: any;
  lotId: any;
  motherId: any;
  grandfatherId: any;
  validLot: any;
  validReprodutor: any;
  semenTouroId: any;
  reprodutorId: any;
  validMatrix: any;
  matrixNumberTemp: any;

  message = '';
  perc1: string;
  perc2: string;
  perc3: string;
  perc4: string;
  perc5: string;
  rac1: string;
  rac2: string;
  rac3: string;
  rac4: string;
  rac5: string;
  percTotal = 0;
  msg = '';
  msgPercentual = '';
  msgTotal: string;
  msgPreenchimento = '';
  msgValidFill = '';
  msgRaca = '';

  gridView: GridDataResult;
  matrixSort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];
  gridSelection: any[] = [];

  avoSelecionado: number;
  paiSelecionado: number;
  semenCodePai: string;
  totalPartos = 0;
  numPartos = 0;
  total = 0;

  selectedValue = 0;
  selectedHistory: HistoricoDeMatriz;

  filterBaseTouros = faFilter;

  F4 = `F4`;

  snackBarConfig: MatSnackBarConfig = {
    panelClass: ['mat-snack-bar-container'],
    duration: 2500
  };

  selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<MatrizCadastroComponent>,
    private matrizService: MatrizService,
    private configuracoesService: ConfiguracoesService,
    private reproducaoService: ReproducaoService,
    private producaoService: ProducaoService,
    private altaGestaoService: AltaGestaoService,
    private datePipe: DatePipe) {
      const hasMatrixNumber = this.data.matrixId.numero !== undefined && this.data.matrixId.numero !== null;
      if (hasMatrixNumber) {
        this.matrixNumberTemp = this.data.matrixId.numero;
      }
    }

  cancel() {
    this.dialogRef.close({ data: 'cancelar' });
  }

  confirm() {
    if (this.validaMatriz()) {
      this.save();
      this.dialogRef.close({ infoRealNascer0: this.infoRealNascer0.checked
        , infoRealNascer1: this.infoRealNascer1.checked
        , infoRealDesaleitamento0: this.infoRealDesaleitamento0.checked
        , infoRealDesaleitamento1: this.infoRealDesaleitamento1.checked
        , matrixId: this.data.matrixId
      });
    }
  }

  ngOnInit() {
      // this.racaList = [];
      this.compositionList = [];
      this.totalPartos = this.data.numPartos !== undefined ? this.data.numPartos : this.totalPartos;
      this.numPartos = this.totalPartos;
      this.selectedValue = -1;
      this.onPartosTotais(this.data.matrixId.numpartoinicio);
      this.getRacas();
      this.getAllComposition();
      this.getAllLot();
      this.getAllReprodutor();
      this.getSemenTouro();
      this.getRaca();
      this.getGenealogia();
      this.getHistoricosDeMatriz();
      this.getComposicoesRaciais();
      this.getReprodutores();
      this.getLotes();
      this.getMotivoBaixa();
      this.getMatrizes();
      this.getReproducoes();
      this.getControlesLeiteiros();
  }

  getMatrizes() {
    this.loading = true;
    this.matrizService.getMatrizes().subscribe((response) => {
      this.matrizes = response;
      this.loading = false;
    });
  }

  getComposicoesRaciais() {
    this.loading = true;
    this.matrizService.getComposicaoRacial().subscribe((response) => {
      this.composicao = response;
      this.loading = false;
    });
  }

  getReprodutores() {
    this.loading = true;
    this.matrizService.getReprodutor().subscribe((response) => {
      this.reprodutor = response;
      this.loading = false;
    });
  }

  getMotivoBaixa() {
    this.loading = true;
    this.matrizService.getMotivoBaixa().subscribe((response) => {
      this.motivosBaixa = response;
      this.loading = false;
    });
  }

  getLotes() {
    this.loading = true;
    this.matrizService.getLote().subscribe((response) => {
      this.lote = response;
      this.loading = false;
    });
  }

  getHistoricosDeMatriz() {
    this.loadingHistories = true;
    this.matrizService.getHistoricoDeMatrizPorFazenda().subscribe((response) => {
      this.historicos = response;
      this.historicoMatriz = [];
      const itemHist =
        this.historicos.filter(history => history.cdMatriz === this.data.matrixId.cdmatriz);
      this.historicoMatriz.push(...itemHist);
      this.loadingHistories = false;
    });
  }

  getGenealogia() {
    this.loading = true;
    this.matrizService.getGenealogia().subscribe((response) => {
      this.genealogias = response;
      this.onBuscaAvo(this.data.matrixId.cdmae);
      this.loading = false;
    });
  }

  // getReproducoes() {
  //   this.loading = true;
  //   this.matrizService.getReproducao().subscribe((response) => {
  //     this.reproducao = response;
  //     this.loading = false;
  //   });
  // }

  getReproducoes() {
    this.loading = true;
    this.reproducaoService.getReproducoes().subscribe((response) => {
      this.reproducao = response;
      this.loading = false;
    });
  }

  getControlesLeiteiros() {
    this.loading = true;
    this.producaoService.getControlesLeiteirosLancados().subscribe((response) => {
      this.controlesLeiteiros = response;
      this.loading = false;
    });
  }

  getRacas() {
    this.loading = true;
    this.matrizService.getRaca().subscribe((response) => {
        this.raca = response;
        // this.racaList = response;
        this.loading = false;
    });
  }

  getAllComposition() {
    this.loading = true;
    this.matrizService.getConfiguracoesComposicaoRacial().subscribe((response) => {
        this.composicoesRaciais = response;
        this.loading = false;
    });
  }

  getAllLot() {
    this.loading = true;
    this.matrizService.getLot().subscribe((response) => {
        this.lotes = response;
        this.data.lote = [];
        this.data.lote = response;
        this.loading = false;
    });
  }

  getAllReprodutor() {
    this.loading = true;
    this.configuracoesService.getReprodutores().subscribe((response) => {
        this.reprodutorListAll = response;
        this.data.pai = [];
        this.reprodutorList = [];
        this.reprodutorList = this.reprodutorListAll;
        this.data.pai = this.reprodutorListAll;
        this.loading = false;
    });
  }

  getSemenTouro() {
    this.loading = true;
    this.configuracoesService.getSemenTouros().subscribe((response) => {
      this.semenTourosListAll = response;
      this.semenTourosList = response;
      const hasCdPai = this.data.matrixId !== undefined && this.data.matrixId.cdpai !== undefined;
      if (hasCdPai) {
        this.onBuscaSemenCode(this.data.matrixId.cdpai);
      }
      this.loading = false;
    });
  }

getBaseTouro() {
  if (this.validateFilters()) {
      const filter = this.createBaseTouroFilter();
      this.loading = true;
      this.configuracoesService.getBaseTourosPorFiltro(filter).subscribe((response) => {
      this.baseTourosListAll = response;
      this.baseTourosList = response;
      this.carregado = true;
      this.loading = false;
    });
  }
}

createBaseTouroFilter(): BaseTouroFiltro  {
  const codigoBaseUnif      = ((document.getElementById('codigoBaseUnif') as HTMLInputElement).value);
  const regInternacional    = ((document.getElementById('regInternacional') as HTMLInputElement).value);
  const codigoInternacional = ((document.getElementById('codigoInternacional') as HTMLInputElement).value);
  const nomeCompleto        = ((document.getElementById('nomeCompleto') as HTMLInputElement).value);
  const nomeResumido        = ((document.getElementById('nomeResumido') as HTMLInputElement).value);
  const raca: any = this.selectedValue;

  const filter = new BaseTouroFiltro();
  filter.bullId = Number(codigoBaseUnif);
  filter.interregnum = regInternacional;
  filter.semencode = codigoInternacional;
  filter.name = nomeCompleto;
  filter.shortname = nomeResumido;
  filter.breed = raca[`sigla`];

  return filter;
}

validateFilters(): boolean {
  const codigoBaseUnif      = ((document.getElementById('codigoBaseUnif') as HTMLInputElement).value);
  const regInternacional    = ((document.getElementById('regInternacional') as HTMLInputElement).value);
  const codigoInternacional = ((document.getElementById('codigoInternacional') as HTMLInputElement).value);
  const nomeCompleto        = ((document.getElementById('nomeCompleto') as HTMLInputElement).value);
  const nomeResumido        = ((document.getElementById('nomeResumido') as HTMLInputElement).value);
  const raca: any = this.selectedValue;

  let hasAnyFilter = false;
  this.message = ``;

  const hasCodigoUnif = codigoBaseUnif !== null && codigoBaseUnif !== undefined && codigoBaseUnif !== '';
  if (hasCodigoUnif) {
    hasAnyFilter = true;
  }

  const hasRegInternacional = regInternacional !== null && regInternacional !== undefined && regInternacional !== '';
  if (hasRegInternacional) {
    hasAnyFilter = true;
  }

  const hasCodigoInternacional = codigoInternacional !== null && codigoInternacional !== undefined && codigoInternacional !== '';
  if (hasCodigoInternacional) {
    hasAnyFilter = true;
  }

  const hasNome = nomeCompleto !== null && nomeCompleto !== undefined && nomeCompleto !== '';
  if (hasNome) {
    hasAnyFilter = true;
  }

  const hasNomeResumido = nomeResumido !== null && nomeResumido !== undefined && nomeResumido !== '';
  if (hasNomeResumido) {
    hasAnyFilter = true;
  }

  const hasRaca = raca !== null && raca !== undefined && raca !== '' && raca !== -1;
  if (hasRaca) {
    hasAnyFilter = true;
  }

  if (!hasAnyFilter) {
    this.message = `É necessário informar ao menos um filtro para listar os touros`;
    return false;
  }

  return true;
}

  getRaca() {
      this.loading = true;
      this.configuracoesService.getRacas().subscribe((response) => {
      this.racaListAll = response;
      this.loading = false;
    });
  }

  onPartosTotais(obj) {
    const sel = obj;
    if (sel !== null && sel !== undefined) {
      this.totalPartos = parseFloat(sel) + this.numPartos;
    } else {
      this.totalPartos = this.numPartos;
    }
  }

  onBuscaAvo(cdmae) {
    const hasCdMae = cdmae !== undefined && cdmae !== null;
    if (hasCdMae) {
      const mae = this.genealogias.find(genealogia => genealogia.cdmatriz === cdmae);
      const hasMae = mae !== undefined && mae !== null;
      if (hasMae) {
        this.avoSelecionado = mae.codigoPai;
      }
    }
  }

  onBuscaPai(ob) {
    const hasOb = ob !== undefined && ob !== null;
    if (hasOb) {
      this.paiSelecionado = ob.value;
    }
  }

  onBuscaSemenCode(ob) {
    const hasOb = ob !== undefined && ob !== null;
    if (hasOb) {
      const semenCode = this.semenTourosListAll.filter(x => (x.cdreprodutor === ob.value));
      for (const item of semenCode) {
        this.semenCodePai = item.codigoInternacional;
      }
    } else {
      const semenCode = this.semenTourosListAll.filter(x => (x.cdreprodutor === ob));
      for (const item of semenCode) {
        this.semenCodePai = item.codigoInternacional;
      }
    }
  }

  hasValue(statusValue) {
    if (this.data.matrixId.numero !== null && this.data.matrixId.numero !== undefined && this.data.matrixId.numero !== '') {
      if (statusValue !== '' && statusValue !== undefined) {
        this.hasChanges = true;
      }
    } else {
      this.hasChanges = false;
    }
  }

  // cadastro/alteracao composicao racial
  openNewRacialComposition() {
    this.racialCompositionId = new ComposicaoRacial();
    this.editComp = true;
    this.edited = true;
  }

  openEditRacialComposition() {
    const hasRacialComposition =
      this.data.matrixId.cdcomposicaoracial !== undefined
        && this.data.matrixId.cdcomposicaoracial > 0;

    if (hasRacialComposition) {
      this.getAllComposition();
      const id = this.data.matrixId.cdcomposicaoracial;
      for (const item of this.composicoesRaciais) {
        if (item.cdcomposicaoracial === id) {
          this.racialCompositionId = item;
        }
      }
      this.editComp = true;
      this.edited = true;
    } else {
      this.showAlertDialog(`Selecione uma composição racial para editar`);
    }
  }

  closeRacialComposition() {
    this.clearAllFields();
    this.editComp = false;
    this.edited = false;
  }

  saveNewComposition() {
    this.msgValidFill = '';

    let validacao = this.validFill();

    if (validacao) {
        validacao = this.validateComposition();
    }

    if (validacao) {
      this.saveComp(this.tipo0.checked, this.ativo0.checked);
      this.hasChangesComp = false;
      this.editComp = false;
      this.edited = false;
    } else {
        this.openConfirmationDialog();
    }
  }

  public openConfirmationDialog() {

    const dialogRef1 = this.dialog.open(DialogoConfirmacao, {
    data: {
        // message: 'Existem campos para serem preenchidos! ',
        message: this.msgValidFill !== '' ? this.msgValidFill : 'Existem campos para serem preenchidos! ',
        buttonText: {
            ok: 'Cancelar Tudo',
            cancel: 'Corrigir'
        }
    }
    });
    dialogRef1.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
            this.clearAllFields();
            this.editComp = false;
            this.edited = false;
        }
    });

  }

  hasValueComp(statusValueComp) {
    if ( statusValueComp.trim() !== '' && statusValueComp !== undefined ) {
        this.hasChangesComp = true;
    } else {
        this.hasChangesComp = false;
    }
  }

  saveComp(tipo0: boolean, ativo0: boolean) {
    this.racialCompositionId.tipo = tipo0 === true ? true : false;
    this.racialCompositionId.ativo = ativo0 === true ? true : false;

    this.racialCompositionId.padrao = this.racialCompositionId.PADRAO > 0 ? this.racialCompositionId.PADRAO : null;
    this.racialCompositionId.cdraca1 = this.racialCompositionId.cdraca1 === undefined ? null : this.racialCompositionId.cdraca1;
    this.racialCompositionId.percentual1 = this.racialCompositionId.percentual1 === undefined
          ? null : this.racialCompositionId.percentual1;
    this.racialCompositionId.cdraca2 = this.racialCompositionId.cdraca2 === undefined ? null : this.racialCompositionId.cdraca2;
    this.racialCompositionId.percentual2 = this.racialCompositionId.percentual2 === undefined
          ? null : this.racialCompositionId.percentual2;
    this.racialCompositionId.cdraca3 = this.racialCompositionId.cdraca3 === undefined ? null : this.racialCompositionId.cdraca3;
    this.racialCompositionId.percentual3 = this.racialCompositionId.percentual3 === undefined
          ? null : this.racialCompositionId.percentual3;
    this.racialCompositionId.cdraca4 = this.racialCompositionId.cdraca4 === undefined ? null : this.racialCompositionId.cdraca4;
    this.racialCompositionId.percentual4 = this.racialCompositionId.percentual4 === undefined
          ? null : this.racialCompositionId.percentual4;
    this.racialCompositionId.cdraca5 = this.racialCompositionId.cdraca5 === undefined ? null : this.racialCompositionId.cdraca5;
    this.racialCompositionId.percentual5 = this.racialCompositionId.percentual5 === undefined
          ? null : this.racialCompositionId.percentual5;
    this.racialCompositionId.removido = this.racialCompositionId.removido === undefined ? false : this.racialCompositionId.removido;

    this.validateComposition();

    this.racialCompositionId.cdfazenda = this.racialCompositionId.cdfazenda !== null && this.racialCompositionId.cdfazenda !== undefined
                           ? this.racialCompositionId.cdfazenda : this.matrizService.getCdFazenda();

    if (this.validComposition.cdcomposicaoracial !== null && this.validComposition.cdcomposicaoracial !== undefined
      && this.validComposition.cdcomposicaoracial !== 0) {
      this.matrizService.updateComposition(this.racialCompositionId).subscribe((response: Array<ComposicaoRacial>) => {
        if (response != null) {
          this.compositionList = [];
          this.atualizaListagemComp();
          this.showSnackBar('Alterações salvas com sucesso.');
        }
      });
    } else {
      if ( this.message !== '' ) {
        this.showSnackBar(this.message);
        this.compositionList = [];
        this.atualizaListagemComp();
        this.message = '';
      } else {
        this.matrizService.insertComposition(this.racialCompositionId).subscribe((response) => {
          this.compositionList = [];
          this.atualizaListagemComp();
          this.showSnackBar('Registros armazenados com sucesso.');
        }, (error) => this.showSnackBar(error));
      }
    }
  }

  public validTotalPerc() {
    let percItem1 = 0;
    let percItem2 = 0;
    let percItem3 = 0;
    let percItem4 = 0;
    let percItem5 = 0;
    let percItem = 0;

    percItem1 = parseFloat(this.racialCompositionId.percentual1 !== null && this.racialCompositionId.percentual1 !== undefined
                && this.racialCompositionId.percentual1 !== '' ? this.racialCompositionId.percentual1 : 0);
    percItem2 = parseFloat(this.racialCompositionId.percentual2 !== null && this.racialCompositionId.percentual2 !== undefined
                && this.racialCompositionId.percentual2 !== '' ? this.racialCompositionId.percentual2 : 0);
    percItem3 = parseFloat(this.racialCompositionId.percentual3 !== null && this.racialCompositionId.percentual3 !== undefined
                && this.racialCompositionId.percentual3 !== '' ? this.racialCompositionId.percentual3 : 0);
    percItem4 = parseFloat(this.racialCompositionId.percentual4 !== null && this.racialCompositionId.percentual4 !== undefined
                && this.racialCompositionId.percentual4 !== '' ? this.racialCompositionId.percentual4 : 0);
    percItem5 = parseFloat(this.racialCompositionId.percentual5 !== null && this.racialCompositionId.percentual5 !== undefined
                && this.racialCompositionId.percentual5 !== '' ? this.racialCompositionId.percentual5 : 0);

    if (percItem1 > 0) { this.validPerc(percItem1, 'Percentual1'); }
    if (percItem2 > 0) { this.validPerc(percItem2, 'Percentual2'); }
    if (percItem3 > 0) { this.validPerc(percItem3, 'Percentual3'); }
    if (percItem4 > 0) { this.validPerc(percItem4, 'Percentual4'); }
    if (percItem5 > 0) { this.validPerc(percItem5, 'Percentual5'); }

    this.validBreed();

    percItem = (percItem1 + percItem2 + percItem3 + percItem4 + percItem5);

    if (percItem > 100) {
        this.msgTotal = 'Erro! Soma dos Percentuais acima de 100. ';
        if (!this.hasError) { this.hasError = true; }
        return false;
    } else {
        this.msgTotal = '';
        return true;
    }
  }

  validateComposition() {
    if (this.racialCompositionId.descricao !== null && this.racialCompositionId.descricao !== undefined) {

      this.validComposition = this.racialCompositionId;

      const description = this.racialCompositionId.descricao.toUpperCase();
      const array = new Array();
      const raca = description.split(', ');

      for (let i = 0; i < 4; i++) {
        if (raca[i] !== undefined) {
          array.push(raca[i]);
        }
      }

      let total = 0;
      let tem = false;

      for (const item of this.composicoesRaciais) {
        const desc = item.descricao.toUpperCase();
        const arrayLista = new Array();
        const racas = desc.split(', ');

        if (item.cdcomposicaoracial !== this.racialCompositionId.cdcomposicaoracial
            && item.descricao.toUpperCase() === this.racialCompositionId.descricao.toUpperCase() ) {
                this.validComposition = [];
                this.message = 'Esta descrição já existe no cadastro.';
                return false;
        }

        if (racas.length === raca.length) {
          for (let i = 0; i < 4; i++) {
            tem = item.descricao.toUpperCase().includes(array[i], i) ? true : false;
            if (tem) {
              total = total + 1;
            }

            if (total === raca.length) {
              if ( item.cdcomposicaoracial === this.racialCompositionId.cdcomposicaoracial) {
                    return true;
                } else {
                  this.validComposition = [];
                  this.message = 'A composição racial informada já existe! Escolha outra.';
                  return false;
                }
            }
          }
        }
      }
      return true;
    }
  }

  public atualizaListagemComp() {
    this.matrizService.getConfiguracoesComposicaoRacial().subscribe((response) => {
      this.composicoesRaciais = response;
      this.compositionList = response;
      this.composicao = response;
    });
  }

  public validPerc(value: number, field: string) {
    const num = value / 0.390625;
    const setField = field;

    // Parte inteira:
    const int = Math.trunc(num);

    // Parte decimal ('0' por padrão).
    const decimal = num - int;

    if (decimal > 0) {
        this.hasError = true;
        const indice = this.msgPercentual.indexOf(setField);
        if (indice === 0 && this.msgPercentual.length > 43) { this.msgPercentual = this.msgPercentual.substring(43); }
        if (indice === 0 && this.msgPercentual.length === 43) { this.msgPercentual = ''; }
        if (indice >= 43) { this.msgPercentual = this.msgPercentual.substring(indice - 1, 0)
            + this.msgPercentual.substring(this.msgPercentual.length, indice + 43); }
        this.msgPercentual += setField + ' está com valor lançado errado! ';

        return false;
    } else {
        const indice = this.msgPercentual.indexOf(setField);
        if (indice === 0 ) { this.msgPercentual = this.msgPercentual.substring(43); }
        if (indice >= 43) { this.msgPercentual = this.msgPercentual.substring(indice - 1, 0)
                    + this.msgPercentual.substring(this.msgPercentual.length, indice + 43); }
        if (this.msgPercentual === '') { this.hasError = false; }

        this.getDescription();

        return true;
    }
  }

  public validBreed() {
    const erro = this.hasError;
    const racas = [];

    if (this.racialCompositionId.cdraca1 !== null && this.racialCompositionId.cdraca1 !== undefined ) {
        const tem = racas.includes(this.racialCompositionId.cdraca1);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            racas.push(this.racialCompositionId.cdraca1);
        }
    }

    if (this.racialCompositionId.cdraca2 !== null && this.racialCompositionId.cdraca2 !== undefined ) {
        const tem = racas.includes(this.racialCompositionId.cdraca2);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            racas.push(this.racialCompositionId.cdraca2);
        }
    }

    if (this.racialCompositionId.cdraca3 !== null && this.racialCompositionId.cdraca3 !== undefined ) {
        const tem = racas.includes(this.racialCompositionId.cdraca3);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            racas.push(this.racialCompositionId.cdraca3);
        }
    }

    if (this.racialCompositionId.cdraca4 !== null && this.racialCompositionId.cdraca4 !== undefined ) {
        const tem = racas.includes(this.racialCompositionId.cdraca4);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            racas.push(this.racialCompositionId.cdraca4);
        }
    }

    if (this.racialCompositionId.cdraca5 !== null && this.racialCompositionId.cdraca5 !== undefined ) {
        const tem = racas.includes(this.racialCompositionId.cdraca5);
        if (tem) {
            this.msgRaca = 'Erro! Existe(m) raça(s) repetida(s). ';
            this.hasError = true;
            return false;
        } else {
            racas.push(this.racialCompositionId.cdraca5);
        }
    }

    this.msgRaca = '';

    if (!erro) { this.hasError = false; }

    return true;
  }

  public getDescription() {
    const sigla1 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca1);
    const sigla2 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca2);
    const sigla3 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca3);
    const sigla4 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca4);
    const sigla5 = this.raca.find(x => x.cdraca === this.racialCompositionId.cdraca5);

    let total = 0;
    total += parseFloat(this.racialCompositionId.cdraca1) > 0 ? 1 : 0;
    total += parseFloat(this.racialCompositionId.cdraca2) > 0 ? 1 : 0;
    total += parseFloat(this.racialCompositionId.cdraca3) > 0 ? 1 : 0;
    total += parseFloat(this.racialCompositionId.cdraca4) > 0 ? 1 : 0;
    total += parseFloat(this.racialCompositionId.cdraca5) > 0 ? 1 : 0;

    const fracao1 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual1));
    const fracao2 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual2));
    const fracao3 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual3));
    const fracao4 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual4));
    const fracao5 = this.fracaoRaca(parseFloat(this.racialCompositionId.percentual5));

    let nome = '';

    if ( total === 1 ) {
        nome = sigla1.descricao;
    } else {
        if ( total >= 2 ) {
            nome = fracao1 +  ' ' + sigla1.sigla;
            nome = nome + ', ' + fracao2 + ' ' + sigla2.sigla;
            if ( total >= 3 ) {
                nome = nome + ', ' + fracao3 + ' ' + sigla3.sigla;
                if ( total >= 4 ) {
                    nome = nome + ', ' + fracao4 + ' ' + sigla4.sigla;
                    if ( total === 5 ) {
                        nome = nome + ', ' + fracao5 + ' ' + sigla5.sigla;
                    }
                }
            }
        }
    }
    this.racialCompositionId.descricao = nome;
  }

  // converte o valor percentual em fracao para incluir na descricao
  public fracaoRaca(APercentual: number) {
    let num: number;
    let den: number;

    den = 1;

    while (den <= 256) {
        num = (APercentual * den) / 100;
        if ( (num < den) && (Math.trunc(num) === num) ) {
            return num + '/' + den;
        }
        den = den * 2;
    }
  }

  public clearAllFields() {

    this.racialCompositionId.cdraca1 = null;
    this.racialCompositionId.cdraca2 = null;
    this.racialCompositionId.cdraca3 = null;
    this.racialCompositionId.cdraca4 = null;
    this.racialCompositionId.cdraca5 = null;

    this.racialCompositionId.percentual1 = null;
    this.racialCompositionId.percentual2 = null;
    this.racialCompositionId.percentual3 = null;
    this.racialCompositionId.percentual4 = null;
    this.racialCompositionId.percentual5 = null;

    this.racialCompositionId.descricao = '';

    this.clearMessages();

    this.hasChangesComp = false;
  }

  public clearMessages() {
    this.msgTotal = '';
    this.msgPercentual = '';
    this.hasError = false;
  }

  public validFill() {
    if ( this.tipo1.checked === true ) {
        if (this.racialCompositionId.cdraca1 !== null
            && this.racialCompositionId.cdraca1 !== ''
            && this.racialCompositionId.cdraca1 !== undefined) {
                if (this.racialCompositionId.cdraca2 !== null
                    && this.racialCompositionId.cdraca2 !== ''
                    && this.racialCompositionId.cdraca2 !== undefined) {
                        if (this.racialCompositionId.percentual1 !== null
                            && this.racialCompositionId.percentual1 !== ''
                            && this.racialCompositionId.percentual1 !== undefined) {
                                if (this.racialCompositionId.percentual2 !== null
                                    && this.racialCompositionId.percentual2 !== ''
                                    && this.racialCompositionId.percentual2 !== undefined) {
                                        return this.validFillOthers();
                                    } else {
                                        this.msgValidFill = 'Preencha o campo Percentual 2.';
                                        return false;
                                    }
                            } else {
                                this.msgValidFill = 'Preencha o campo Percentual 1.';
                                return false;
                            }
                    } else {
                        this.msgValidFill = 'Preencha o campo Raca 2.';
                        return false;
                    }
            } else {
                this.msgValidFill = 'Preecha o campo Raca 1.';
                return false;
            }
    } else {
        if ( this.tipo1.checked === false ) {
            if (this.racialCompositionId.cdraca1 !== null
                && this.racialCompositionId.cdraca1 !== ''
                && this.racialCompositionId.cdraca1 !== undefined) {
                    if (this.racialCompositionId.percentual1 !== null
                        && this.racialCompositionId.percentual1 !== ''
                        && this.racialCompositionId.percentual1 !== undefined) {
                            if (this.racialCompositionId.percentual1 === 100) {
                                return true;
                            } else {
                                this.msgValidFill = 'O percentual da Raça deve ser 100.';
                                return false;
                            }
                        } else {
                            this.msgValidFill = 'Preencha o campo percentual da Raça.';
                            return false;
                        }
                } else {
                    this.msgValidFill = 'Escolha uma raça e preencha o campo Percentual 1.';
                    return false;
                }
        } else { return false; }
    }
  }

  public validFillOthers() {
    if ( this.tipo1.checked === true ) {
        if (this.racialCompositionId.cdraca3 !== null
            && this.racialCompositionId.cdraca3 !== ''
            && this.racialCompositionId.cdraca3 !== undefined) {
                if (this.racialCompositionId.percentual3 === null
                    || this.racialCompositionId.percentual3 === ''
                    || this.racialCompositionId.percentual3 === undefined) {
                        this.msgValidFill = 'O percentual da Raça 3 deve ser preenchido.';
                        return false;
                    }
            }
        if (this.racialCompositionId.cdraca4 !== null
            && this.racialCompositionId.cdraca4 !== ''
            && this.racialCompositionId.cdraca4 !== undefined) {
                if (this.racialCompositionId.percentual4 === null
                    || this.racialCompositionId.percentual4 === ''
                    || this.racialCompositionId.percentual4 === undefined) {
                        this.msgValidFill = 'O percentual da Raça 4 deve ser preenchido.';
                        return false;
                    }
            }

        if (this.racialCompositionId.cdraca5 !== null
            && this.racialCompositionId.cdraca5 !== ''
            && this.racialCompositionId.cdraca5 !== undefined) {
                if (this.racialCompositionId.percentual5 === null
                    || this.racialCompositionId.percentual5 === ''
                    || this.racialCompositionId.percentual5 === undefined) {
                        this.msgValidFill = 'O percentual da Raça 3 deve ser preenchido.';
                        return false;
                    }
            }

        if (this.racialCompositionId.percentual3 !== null
            && this.racialCompositionId.percentual3 !== ''
            && this.racialCompositionId.percentual3 !== undefined) {
                if (this.racialCompositionId.cdraca3 === null
                    || this.racialCompositionId.cdraca3 === ''
                    || this.racialCompositionId.cdraca3 === undefined) {
                        this.msgValidFill = 'O campo Raca 3 deve ser preenchido.';
                        return false;
                    }
            }

        if (this.racialCompositionId.percentual4 !== null
            && this.racialCompositionId.percentual4 !== ''
            && this.racialCompositionId.percentual4 !== undefined) {
                if (this.racialCompositionId.cdraca4 === null
                    || this.racialCompositionId.cdraca4 === ''
                    || this.racialCompositionId.cdraca4 === undefined) {
                        this.msgValidFill = 'O campo Raca 4 deve ser preenchido.';
                        return false;
                    }
            }

        if (this.racialCompositionId.percentual5 !== null
            && this.racialCompositionId.percentual5 !== ''
            && this.racialCompositionId.percentual5 !== undefined) {
                if (this.racialCompositionId.cdraca5 === null
                    || this.racialCompositionId.cdraca5 === ''
                    || this.racialCompositionId.cdraca5 === undefined) {
                        this.msgValidFill = 'O campo Raca 5 deve ser preenchido.';
                        return false;
                    }
            }


        // verifica totais percentuais
        let percItem1 = 0;
        let percItem2 = 0;
        let percItem3 = 0;
        let percItem4 = 0;
        let percItem5 = 0;
        let percItem = 0;

        percItem1 = parseFloat(this.racialCompositionId.percentual1 !== null
                    && this.racialCompositionId.percentual1 !== undefined
                    && this.racialCompositionId.percentual1 !== '' ? this.racialCompositionId.percentual1 : 0);
        percItem2 = parseFloat(this.racialCompositionId.percentual2 !== null
                    && this.racialCompositionId.percentual2 !== undefined
                    && this.racialCompositionId.percentual2 !== '' ? this.racialCompositionId.percentual2 : 0);
        percItem3 = parseFloat(this.racialCompositionId.percentual3 !== null
                    && this.racialCompositionId.percentual3 !== undefined
                    && this.racialCompositionId.percentual3 !== '' ? this.racialCompositionId.percentual3 : 0);
        percItem4 = parseFloat(this.racialCompositionId.percentual4 !== null
                    && this.racialCompositionId.percentual4 !== undefined
                    && this.racialCompositionId.percentual4 !== '' ? this.racialCompositionId.percentual4 : 0);
        percItem5 = parseFloat(this.racialCompositionId.percentual5 !== null
                    && this.racialCompositionId.percentual5 !== undefined
                    && this.racialCompositionId.percentual5 !== '' ? this.racialCompositionId.percentual5 : 0);

        percItem = (percItem1 + percItem2 + percItem3 + percItem4 + percItem5);

        if (percItem < 100) {
            this.msgValidFill = 'A soma dos percentuais não Totalizam 100.';
            return false;
        } else {
            return true;
        }
    }
  }

  validacaoTipos() {
    if (this.racialCompositionId.padrao === 0
        || this.racialCompositionId.padrao === undefined
        || this.racialCompositionId.padrao === null
       ) {

        // verifica se o tipo é PURA
        if ( this.tipo0 === undefined ) {
            this.validGeral();
        } else {
            if ( this.tipo0.checked === true ) {
                this.getValuesFields();
                this.clearFields();
                this.desabilita = true;
                this.clearMessages();
            } else {
                this.clearMessages();
                this.setValuesFields();
                this.desabilita = false;
            }
        }
    }
  }

  validGeral() {
    // valida percentuais - se o valor lançado como percentual é válido
    this.perc1 = this.racialCompositionId.percentual1;
    this.perc2 = this.racialCompositionId.percentual2;
    this.perc3 = this.racialCompositionId.percentual3;
    this.perc4 = this.racialCompositionId.percentual4;
    this.perc5 = this.racialCompositionId.percentual5;

    this.validPerc(parseFloat(this.perc1), 'Percentual1');
    this.validPerc(parseFloat(this.perc2), 'Percentual2');
    this.validPerc(parseFloat(this.perc3), 'Percentual3');
    this.validPerc(parseFloat(this.perc4), 'Percentual4');
    this.validPerc(parseFloat(this.perc5), 'Percentual5');

    // preenche o campo descrição
    this.getDescription();

    // verifica se total percentuais não ultrapassou 100%
    this.validTotalPerc();
  }

  public getValuesFields() {
    this.desc = this.racialCompositionId.descricao;

    this.rac1 = this.racialCompositionId.cdraca1;
    this.rac2 = this.racialCompositionId.cdraca2;
    this.rac3 = this.racialCompositionId.cdraca3;
    this.rac4 = this.racialCompositionId.cdraca4;
    this.rac5 = this.racialCompositionId.cdraca5;

    this.perc1 = this.racialCompositionId.percentual1;
    this.perc2 = this.racialCompositionId.percentual2;
    this.perc3 = this.racialCompositionId.percentual3;
    this.perc4 = this.racialCompositionId.percentual4;
    this.perc5 = this.racialCompositionId.percentual5;
}

setValuesFields() {

    this.racialCompositionId.descricao   = this.desc;

    this.racialCompositionId.cdraca1 = this.rac1 !== '' ? this.rac1 : '';
    this.racialCompositionId.cdraca2 = this.rac2 !== '' ? this.rac2 : '';
    this.racialCompositionId.cdraca3 = this.rac3 !== '' ? this.rac3 : '';
    this.racialCompositionId.cdraca4 = this.rac4 !== '' ? this.rac4 : '';
    this.racialCompositionId.cdraca5 = this.rac5 !== '' ? this.rac5 : '';

    if (this.perc1 !== '') {
        this.racialCompositionId.percentual1 = this.perc1;
        this.validPerc(parseFloat(this.perc1), 'Percentual1');
    }
    if (this.perc2 !== '') {
        this.racialCompositionId.percentual2 = this.perc2;
        this.validPerc(parseFloat(this.perc2), 'Percentual2');
    }
    if (this.perc3 !== '') {
        this.racialCompositionId.percentual3 = this.perc3;
        this.validPerc(parseFloat(this.perc3), 'Percentual3');
    }
    if (this.perc4 !== '') {
        this.racialCompositionId.percentual4 = this.perc4;
        this.validPerc(parseFloat(this.perc4), 'Percentual4');
    }
    if (this.perc5 !== '') {
        this.racialCompositionId.percentual5 = this.perc5;
        this.validPerc(parseFloat(this.perc5), 'Percentual5');
    }
  }

  public clearFields() {
    this.racialCompositionId.cdraca2 = null;
    this.racialCompositionId.cdraca3 = null;
    this.racialCompositionId.cdraca4 = null;
    this.racialCompositionId.cdraca5 = null;
    this.racialCompositionId.percentual1 = null;
    this.racialCompositionId.percentual2 = null;
    this.racialCompositionId.percentual3 = null;
    this.racialCompositionId.percentual4 = null;
    this.racialCompositionId.percentual5 = null;
    this.racialCompositionId.descricao = '';
    this.getDescription();
  }

  // cadastro/alteracao lote
  openEditLote() {
    const hasLot =
    this.data.matrixId.cdlote !== undefined
    && this.data.matrixId.cdlote > 0;

    if (hasLot) {
      this.getAllLot();
      const id = this.data.matrixId.cdlote;
      for (const item of this.lotes) {
        if (item.cdlote === id) {
          this.lotId = item;
        }
      }
      this.editLote = true;
      this.edited = true;
    } else {
      this.showAlertDialog(`Selecione um lote para editar`);
    }
  }

  openNewLote() {
    this.lotId = new Lote();
    this.editLote = true;
    this.edited = true;
  }

  closeLote() {
    this.editLote = false;
    this.edited = false;
  }

  saveLot() {
    this.hasChangesLot = false;
    this.editLote = false;
    this.edited = false;
    this.saveLotAll();
  }

  saveLotAll() {
    this.validateLot();

    this.lotId.cdfazenda = this.lotId.cdfazenda !== null && this.lotId.cdfazenda !== undefined
                           ? this.lotId.cdfazenda : this.matrizService.getCdFazenda();

    if (this.validLot.cdlote !== null && this.validLot.cdlote !== undefined && this.validLot.cdlote !== 0) {
      this.matrizService.updateLot(this.lotId).subscribe((response: Array<Lote>) => {
        if (response != null) {
          this.atualizaListagemLot('update');
          this.showSnackBar('Alterações salvas com sucesso.');
        }
      });
    } else {
      if (this.message !== '' ) {
        this.showSnackBar(this.message);
        this.atualizaListagemLot('null');
        this.message = '';
      } else {
        this.matrizService.insertLot(this.lotId).subscribe((response) => {
          this.atualizaListagemLot('insert');
          this.showSnackBar('Registros armazenados com sucesso.');
        }, (error) => this.showSnackBar(error));
      }
    }
  }

  savePai() {
    this.validateReprodutor();

    this.semenTouroId.cdtiporeprodutor =  this.tiporeprodutor0.checked ? 1 : 0;
    this.semenTouroId.cdtiporeprodutor1 =  parseFloat(this.tiporeprodutor1.value);
    this.semenTouroId.cdFazenda =
      this.semenTouroId.cdFazenda !== null && this.semenTouroId.cdFazenda !== undefined
      ? this.semenTouroId.cdFazenda : this.configuracoesService.getCdFazenda();
    this.reprodutorId = this.semenTouroId;
    this.reprodutorId.BullId1 = this.semenTouroId.codigoBaseunif;

    const hasExistBreeder = this.validReprodutor !== null
                              && this.validReprodutor !== undefined
                              && this.validReprodutor.cdreprodutor !== undefined
                              && this.validReprodutor.cdreprodutor !== 0;

    if (hasExistBreeder) {
      this.configuracoesService.atualizaReprodutor(this.reprodutorId).subscribe((response: Array<Reprodutor>) => {
        if (response != null) {
          this.showSnackBar('Alterações salvas com sucesso.');
          this.atualizaListagemPai('update');
          this.closePai();
          this.hasChanges = true;
        }
      });
    } else {
      if (this.message !== '') {
        this.showSnackBar(this.message);
        this.message = '';
      } else {
        this.configuracoesService.insereReprodutor(this.semenTouroId).subscribe((response) => {
          this.showSnackBar('Registros armazenados com sucesso.');
          this.atualizaListagemPai('insert');
          this.closePai();
          this.hasChanges = true;
        }, (error) => this.showSnackBar(error));
      }
    }
  }

  validateLot() {
    if (this.lotId.descricao !== null && this.lotId.descricao !== undefined) {

        this.validLot = this.lotId;

        for (const item of this.lotes) {
            if ( item.cdlote !== this.lotId.cdlote
                && item.descricao.toUpperCase() === this.lotId.descricao.toUpperCase() ) {
                    this.validLot = [];
                    this.message = 'Esta descrição já existe no cadastro.';
            }
        }
    }
  }

  validateReprodutor() {
    const hasSemenTouroId = this.semenTouroId.numero !== null && this.semenTouroId.numero !== undefined;
    if (hasSemenTouroId) {
        this.validReprodutor = this.semenTouroId;
        const existsBreeder =
          this.reprodutorListAll.find(breeder => breeder.cdreprodutor !== this.semenTouroId.cdreprodutor
            && breeder.numero.toUpperCase() === this.semenTouroId.numero.toUpperCase());
        const hasExistsBreeder = existsBreeder !== undefined && existsBreeder !== null;
        if (hasExistsBreeder) {
          this.validReprodutor = undefined;
          this.message = `O número ${ this.semenTouroId.numero } já existe no cadastro.`;
        }
    }
  }

  public atualizaListagemLot(tipo: string) {
    this.matrizService.getLot().subscribe((response) => {
      this.lotes = response;
      if (tipo === 'update') {
        for (const item of this.data.lote) {
          if (item.cdlote === this.validLot.cdlote) {
            item.descricao = this.validLot.descricao;
          }
        }
      } else if (tipo === 'insert') {
        this.data.lote = [];
        this.data.lote = this.lotes;
        this.lote = this.lotes;
      }
    });
  }

  public atualizaListagemPai(tipo: string) {
    this.configuracoesService.getReprodutores().subscribe((response) => {
      this.reprodutorListAll = response;
      this.data.pai = [];
      this.data.pai = this.reprodutorListAll;
      this.reprodutor = this.reprodutorListAll;
      let father;
      const isUpdate = tipo === 'update';
      if (isUpdate) {
        father =
          this.data.pai.find((breeder: { cdreprodutor: any; }) => breeder.cdreprodutor === this.validReprodutor.cdreprodutor);
      } else {
        father =
          this.data.pai.find((breeder: { numero: string; }) => breeder.numero.toUpperCase() === this.semenTouroId.numero.toUpperCase());
      }
      const hasFather = father !== undefined && father !== null;
      if (hasFather) {
        this.data.matrixId.cdpai = father.cdreprodutor;
      }
    });
  }

  hasValueLot(statusValueLot) {
    if ( statusValueLot.trim() !== '' && statusValueLot !== undefined ) {
        this.hasChangesLot = true;
    } else {
        this.hasChangesLot = false;
    }
  }

  // cadastrar/alterar pai
  public openEditPai() {
    const hasFather =
      this.data.matrixId.cdpai !== undefined
      && this.data.matrixId.cdpai > 0;

    if (hasFather) {
      this.semenTouroId = new SemenTouro();
      if (this.semenTourosList === undefined) {
        this.semenTourosList = [];
        this.getSemenTouro();
      }
      const id = this.data.matrixId.cdpai;
      for (const item of this.semenTourosList) {
        if (item.cdreprodutor === id) {
          this.semenTouroId = item;
        }
      }
      this.editPai = true;
      this.edited = true;
    } else {
      this.showAlertDialog(`Selecione um touro para editar`);
    }
  }

  openNewPai() {
    this.semenTouroId = new SemenTouro();
    this.editPai = true;
    this.edited = true;
  }

  closePai() {
    this.editPai = false;
    this.edited = false;
  }

  // consulta base de touros
  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  public onDblClick() {
      if (this.clickedRowItem !== '' && this.clickedRowItem !== undefined) {
          this.semenTouroId.codigoBaseunif = this.clickedRowItem.bullId;
          // this.semenTouroId.cdreprodutor = this.clickedRowItem.bullId;
          this.semenTouroId.regInternacional = this.clickedRowItem.interregnum;
          this.semenTouroId.codigoInternacional = this.clickedRowItem.semencode;

          this.editBaseTouros = false;
          this.hasChangesPai = true;
          this.editPai = true;
          // this.edited = false;
      }
  }

  public filteringBaseTouros() {
    this.getBaseTouro();
    // this.resultFilter         = [];
    // const numero              = ((document.getElementById('codigoBaseUnif') as HTMLInputElement).value);
    // const regInternacional    = ((document.getElementById('regInternacional') as HTMLInputElement).value);
    // const codigoInternacional = ((document.getElementById('codigoInternacional') as HTMLInputElement).value);
    // const nomeCompleto        = ((document.getElementById('nomeCompleto') as HTMLInputElement).value);
    // const nomeResumido        = ((document.getElementById('nomeResumido') as HTMLInputElement).value);

    // const raca: any = this.selectedValue;

    // if (this.selectedValue !== -1) {
    //     const rac = raca.descricao.toUpperCase();
    // }

    // if ((numero === null || numero === '') && (regInternacional === null || regInternacional === '') &&
    //     (codigoInternacional === null || codigoInternacional === '') &&
    //     (nomeCompleto === null || nomeCompleto === '') && (nomeResumido === null || nomeResumido === '') &&
    //     (this.selectedValue === -1 || this.selectedValue === -1)) {
    //         this.openMessageAviso();
    // } else {

    //     if (this.baseTourosListAll === undefined) {
    //         if (this.data.baseTourosListAll === undefined) {
    //             this.openMessage();
    //         } else {
    //             this.baseTourosList = this.data.baseTourosListAll;
    //         }
    //     } else {
    //         this.baseTourosList = this.baseTourosListAll;
    //     }

    //     this.resultFilter = this.baseTourosList;

    //     if (numero !== null && numero !== '') {

    //         const baseTouro = this.baseTourosList.filter(x => (x.bullId !== null));

    //         this.resultFilter = [];

    //         if (baseTouro.length > 0) {
    //             for (const item of baseTouro) {
    //                 this.resultFilter.push(item);
    //             }
    //         }

    //         const termo = parseFloat(numero);

    //         const reprodutor1 = this.resultFilter.filter (y => y.bullId === termo);

    //         this.baseTourosList = [];

    //         if (reprodutor1.length > 0) {
    //             this.resultFilter = [];
    //             for (const item of reprodutor1) {
    //                 this.resultFilter.push(item);
    //             }
    //         } else {
    //             this.resultFilter = [];
    //         }

    //     }

    //     if (regInternacional !== null && regInternacional !== '') {
    //         const baseTouro = this.resultFilter.filter (y => y.interregnum.toUpperCase().includes(regInternacional));

    //         if (baseTouro.length > 0) {
    //             this.resultFilter = [];
    //             for (const item of baseTouro) {
    //                 this.resultFilter.push(item);
    //             }
    //         } else {
    //             this.resultFilter = [];
    //         }
    //     }

    //     if (codigoInternacional !== null && codigoInternacional !== '') {
    //         const baseTouro1 = this.resultFilter.filter(x => (x.semencode !== null));

    //         this.resultFilter = [];

    //         if (baseTouro1.length > 0) {
    //             for (const item of baseTouro1) {
    //                 this.resultFilter.push(item);
    //             }
    //         }

    //         const baseTouro = this.resultFilter.filter (y => y.semencode.toUpperCase().includes(codigoInternacional.toUpperCase()));

    //         if (baseTouro.length > 0) {
    //             this.resultFilter = [];
    //             for (const item of baseTouro) {
    //                 this.resultFilter.push(item);
    //             }
    //         } else {
    //             this.resultFilter = [];
    //         }
    //     }

    //     if (nomeCompleto !== null && nomeCompleto !== '') {
    //         const baseTouro = this.resultFilter.filter (y => y.name.toUpperCase().includes(nomeCompleto.toUpperCase()));

    //         if (baseTouro.length > 0) {
    //             this.resultFilter = [];
    //             for (const item of baseTouro) {
    //                 this.resultFilter.push(item);
    //             }
    //         } else {
    //             this.resultFilter = [];
    //         }
    //     }

    //     if (nomeResumido !== null && nomeResumido !== '') {
    //         const baseTouro = this.resultFilter.filter (y => y.shortname.toUpperCase().includes(nomeResumido.toUpperCase()));

    //         if (baseTouro.length > 0) {
    //             this.resultFilter = [];
    //             for (const item of baseTouro) {
    //                 this.resultFilter.push(item);
    //             }
    //         } else {
    //             this.resultFilter = [];
    //         }
    //     }

    //     if (raca !== null && raca !== undefined && raca !== '' && raca !== -1) {
    //         const raca1 = this.resultFilter.filter (x => x.raca === raca.descricao.toUpperCase());

    //         if (raca1.length > 0) {
    //             this.resultFilter = [];
    //             for (const item of raca1) {
    //                 this.resultFilter.push(item);
    //             }
    //         } else {
    //             this.resultFilter = [];
    //         }
    //     }

    //     this.baseTourosList = this.resultFilter;

    //     this.total = this.baseTourosList.length;
    // }
  }

  public openBaseTouros() {
    this.editBaseTouros = true;
    this.edited = true;
    this.editPai = false;
    this.hasChanges = true;
  }

  closeBaseTouros() {
    this.editBaseTouros = false;
    this.editPai = true;
    this.edited = true;
  }

  saveBaseTouros() {
    if (this.clickedRowItem !== '' && this.clickedRowItem !== undefined) {
        this.hasChangesPai = true;
        this.editBaseTouros = false;
        this.editPai = true;
        this.semenTouroId.codigoBaseunif = this.clickedRowItem.bullId;
        this.semenTouroId.cdreprodutor = this.clickedRowItem.bullId;
        this.semenTouroId.regInternacional = this.clickedRowItem.interregnum;
        this.semenTouroId.codigoInternacional = this.clickedRowItem.semencode;
    }
  }

  public openMessage() {
    const dialogRef2 = this.dialog.open(DialogoAlertaComponent, {
    data: {
            message: 'A Base de Touros ainda está sendo carregada. Aguarde!',
            buttonText: {
            cancel: 'Ok'
            }
        },
    });
  }

  public openMessageAviso() {
    const dialogRef3 = this.dialog.open(DialogoAlertaComponent, {
    data: {
            message: 'A Base de Touros é muito pesada para ser carregada. Preencha um dos campos e clique novamente em filtrar!',
            buttonText: {
            cancel: 'Ok'
            }
        },
    });
  }

  hasValuePai(statusValuePai) {
    if ( statusValuePai.trim() !== '' && statusValuePai !== undefined ) {
        this.hasChangesPai = true;
    } else {
        this.hasChangesPai = false;
    }
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, 'X', this.snackBarConfig);
  }

  save() {
    const hasValidMatrix =
      this.validMatrix.cdmatriz !== null
      && this.validMatrix.cdmatriz !== undefined
      && this.validMatrix.cdmatriz !== 0;

    if (hasValidMatrix) {
      this.matrizService.atualizaMatriz(this.data.matrixId).subscribe((response: Array<Matriz>) => {
        this.showSnackBar(`Alterações salvas com sucesso.`);
      }, (error) => this.showSnackBar(error));
    } else {
      this.matrizService.insereMatriz(this.data.matrixId).subscribe((response) => {
        this.showSnackBar(`Alterações salvas com sucesso.`);
      }, (error) => this.showSnackBar(error));
    }
  }

  validaMatriz(): boolean {
    if (!this.validaNumeroMatriz()) { return false; }
    if (!this.validaNumeroMatrizRepetido()) { return false; }
    if (!this.validaDataNascimento()) { return false; }
    if (!this.validaDataBaixa()) { return false; }
    if (!this.validaMotivoBaixa()) { return false; }
    if (!this.validaDescarte()) { return false; }
    if (!this.validaDataDescarte()) { return false; }
    if (!this.validaDesaleitamento()) { return false; }
    if (!this.validaDataNascimentoNumeroPartos()) { return false; }
    return true;
  }

  validaNumeroMatriz(): boolean {
    const hasMatrixNumber = this.data.matrixId.numero !== null && this.data.matrixId.numero !== undefined;
    if (hasMatrixNumber) {
      this.validMatrix = this.data.matrixId;
      return true;
    }
    return false;
  }

  validaNumeroMatrizRepetido(): boolean {
    const hasExistingMatrixNumber =
      this.matrizes.find(matrix => matrix.numero.toUpperCase() === this.data.matrixId.numero.toUpperCase()
        && matrix.cdmatriz !== this.data.matrixId.cdmatriz);
    if (hasExistingMatrixNumber) {
      this.data.matrixId.numero = this.matrixNumberTemp;
      this.showAlertDialog(`Já existe uma matriz cadastrada no sistema com o mesmo número.`);
      return false;
    }
    return true;
  }

  validaDataNascimento() {
    const hasBirthDate = this.data.matrixId.dtnascimento !== undefined
      && this.data.matrixId.dtnascimento !== null;
    if (hasBirthDate) {
      const birthDate = moment(this.data.matrixId.dtnascimento).toDate();
      const isValidBirthDate = birthDate <= new Date();
      if (!isValidBirthDate) {
        this.showAlertDialog(`A data de nascimento do animal não pode ser maior que a data atual.`);
        return false;
      }
    }
    return true;
  }

  validaDataBaixa() {
    const existeDataBaixa = this.data.matrixId.dtbaixa !== undefined
      && this.data.matrixId.dtbaixa !== null;
    const existeMotivoBaixa = this.data.matrixId.cdmotivobaixa !== undefined
      && this.data.matrixId.cdmotivobaixa !== null;
    if (existeDataBaixa) {
      const dataBaixa = moment(this.data.matrixId.dtbaixa).toDate();
      const ehDataBaixaValida = dataBaixa <= new Date();
      if (!ehDataBaixaValida) {
        this.showAlertDialog(`A data de baixa do animal não pode ser maior que a data atual.`);
        return false;
      }
      if (!this.validaDataBaixaAnteriorLancamentosReproducao()) {
        return false;
      }
      if (!this.validaDataBaixaAnteriorLancamentosProducao()) {
        return false;
      }
      if (!existeMotivoBaixa) {
        this.showAlertDialog(`É obrigatório informar o motivo baixa quando possui a data da baixa.`);
        return false;
      }
      const dataNascimento = this.data.matrixId.dtnascimento;
      const existeDataNascimento = dataNascimento !== null && dataNascimento !== undefined;
      if (existeDataNascimento) {
        const ehDataBaixaValida = dataBaixa > new Date(this.data.matrixId.dtnascimento);
        if (!ehDataBaixaValida) {
          this.showAlertDialog(`A data da baixa não pode ser menor que a data de nascimento do animal.`);
          return false;
        }
      }
    }
    return true;
  }

  validaDataBaixaAnteriorLancamentosReproducao() {
    let dataLancamento: Date;
    let evento: string;
    let ultimLancamentoReproducao: Reproducao;

    let reproducoes =
      this.reproducao.filter(reproducao => reproducao.cdmatriz === this.validMatrix.cdmatriz);

    const exsitemLancamentosReproducao = reproducoes !== undefined && reproducoes.length > 0;

    if (exsitemLancamentosReproducao) {
      reproducoes = reproducoes.sort
      (
        (a, b) => (new Date(b.data).getTime() - new Date(a.data).getTime())
      );

      ultimLancamentoReproducao = reproducoes[0];

      const dataSecagem = ultimLancamentoReproducao.dtsecagem;
      const dataParto = ultimLancamentoReproducao.dtparto;
      const dataDiagnostico4 = ultimLancamentoReproducao.dtdiagnostico4;
      const dataDiagnostico3 = ultimLancamentoReproducao.dtdiagnostico3;
      const dataDiagnostico2 = ultimLancamentoReproducao.dtdiagnostico2;
      const dataDiagnostico1 = ultimLancamentoReproducao.dtdiagnostico1;
      const dataServico = ultimLancamentoReproducao.data;

      const existeDataSecagem = dataSecagem !== null && dataSecagem !== undefined;
      const existeDataParto = dataParto !== null && dataParto !== undefined;
      const existeDataDiagnostico4 = dataDiagnostico4 !== null && dataDiagnostico4 !== undefined;
      const existeDataDiagnostico3 = dataDiagnostico3 !== null && dataDiagnostico3 !== undefined;
      const existeDataDiagnostico2 = dataDiagnostico2 !== null && dataDiagnostico2 !== undefined;
      const existeDataDiagnostico1 = dataDiagnostico1 !== null && dataDiagnostico1 !== undefined;

      if (existeDataSecagem) {
        dataLancamento = new Date(dataSecagem);
        evento = `secagem`;
      } else if (existeDataParto) {
        dataLancamento = new Date(dataParto);
        evento = `parto`;
      } else if (existeDataDiagnostico4) {
        dataLancamento = new Date(dataDiagnostico4);
        evento = `4º diagnóstico`;
      } else if (existeDataDiagnostico3) {
        dataLancamento = new Date(dataDiagnostico3);
        evento = `3º diagnóstico`;
      } else if (existeDataDiagnostico2) {
        dataLancamento = new Date(dataDiagnostico2);
        evento = `2º diagnóstico`;
      } else if (existeDataDiagnostico1) {
        dataLancamento = new Date(dataDiagnostico1);
        evento = `1º diagnóstico`;
      } else {
        dataLancamento = new Date(dataServico);
        evento = `serviço`;
      }

      if (this.data.matrixId.dtbaixa <= dataLancamento) {
        this.showAlertDialog( `A data de baixa do animal não pode ser anterior
          a data do(a) ${ evento } (${ this.datePipe.transform(dataLancamento, 'dd/MM/yy') }).`);
        return false;
      }
    }
    return true;
  }

  validaDataBaixaAnteriorLancamentosProducao() {
    let ultimLancamentoControleLeiteiro: ControleLeiteiro;

    let controlesLeiteiros =
      this.controlesLeiteiros.filter(controleLeiteiro => controleLeiteiro.cdMatriz === this.validMatrix.cdmatriz);

    const existemControlesLeiteiros = controlesLeiteiros !== undefined && controlesLeiteiros.length > 0;

    if (existemControlesLeiteiros) {
      controlesLeiteiros = controlesLeiteiros.sort
      (
        (a, b) => (new Date(b.dataControle).getTime() - new Date(a.dataControle).getTime())
      );

      ultimLancamentoControleLeiteiro = controlesLeiteiros[0];

      const dataControleLeiteiro = new Date(ultimLancamentoControleLeiteiro.dataControle);

      const existeControleLeiteiro = dataControleLeiteiro !== null && dataControleLeiteiro !== undefined;

      if (existeControleLeiteiro) {
        if (this.data.matrixId.dtbaixa <= dataControleLeiteiro) {
          this.showAlertDialog( `A data de baixa do animal não pode ser anterior
            a data de controle leiteiro (${ this.datePipe.transform(dataControleLeiteiro, 'dd/MM/yy') }).`);
          return false;
        }
      }
    }
    return true;
  }

  validaMotivoBaixa() {
    const hasDataBaixa = this.data.matrixId.dtbaixa !== undefined
      && this.data.matrixId.dtbaixa !== null;
    const hasMotivoBaixa = this.data.matrixId.cdmotivobaixa !== undefined
      && this.data.matrixId.cdmotivobaixa !== null;
    if (hasMotivoBaixa) {
      if (!hasDataBaixa) {
        this.showAlertDialog(`É obrigatório informar a data da baixa quando possui o motivo da baixa.`);
        return false;
      }

    }
    return true;
  }

  validaDescarte() {
    const hasDescarte =
      this.data.matrixId.descarte !== undefined
      && this.data.matrixId.descarte !== null
      && this.data.matrixId.descarte !== false;
    const hasDataDescarte = this.data.matrixId.dtdescarte !== undefined && this.data.matrixId.dtdescarte !== null;
    if (hasDescarte) {
      if (!hasDataDescarte) {
        this.showAlertDialog(`É obrigatório informar a data quando é descarte.`);
        return false;
      }
    }
    return true;
  }

  validaDataDescarte() {
    const marcadoComoDescarte =
      this.data.matrixId.descarte !== undefined
      && this.data.matrixId.descarte !== null
      && this.data.matrixId.descarte !== false;
    const existeDataDescarte =
      this.data.matrixId.dtdescarte !== undefined && this.data.matrixId.dtdescarte !== null;
    if (marcadoComoDescarte && existeDataDescarte) {
      const dataDescarte = new Date(this.data.matrixId.dtdescarte);
      const dataAtual = new Date();
      const ehDataDescarteValida = dataDescarte <= dataAtual;
      if (!ehDataDescarteValida) {
        this.showAlertDialog(`A data do descarte não pode ser maior que a data atual.`);
        return false;
      }
      const dataNascimento = this.data.matrixId.dtnascimento;
      const existeDataNascimento = dataNascimento !== null && dataNascimento !== undefined;
      if (existeDataNascimento) {
        const ehDataDescarteValida = dataDescarte > new Date(this.data.matrixId.dtnascimento);
        if (!ehDataDescarteValida) {
          this.showAlertDialog(`A data do descarte não pode ser menor que a data de nascimento do animal.`);
          return false;
        }
      }
    }
    return true;
  }

  validaDataNascimentoNumeroPartos() {
    const hasNumeroPartos =
      this.data.matrixId.numpartoinicio !== undefined
        && this.data.matrixId.numpartoinicio !== null
        && this.data.matrixId.numpartoinicio > 0;
    if (hasNumeroPartos) {
      const dtnascimento = new Date(this.data.matrixId.dtnascimento);
      const dataHoje = new Date();
      const dias = this.altaGestaoService.calculaDiferencaEntreDatas(dtnascimento, dataHoje);
      if (dias < 400) {
        this.showAlertDialog(`Não é permitido lançar partos para o animal com menos de 400 dias.`);
        return false;
      }
    }
    return true;
  }

  validaDesaleitamento() {
    const hasPesoDesaleitamento = this.data.matrixId.pesodesaleitamento !== undefined
          && this.data.matrixId.pesodesaleitamento !== null;
    const hasDataDesaleitamento = this.data.matrixId.dtdesaleitamento !== undefined
          && this.data.matrixId.dtdesaleitamento !== null;
    if (hasPesoDesaleitamento) {
      if (!hasDataDesaleitamento) {
        this.showAlertDialog(`É obrigatório informar a data desaleitamento quando informa o peso desaleitamento. `);
        return false;
      }
    }
    return true;
  }

  showAlertDialog(msg: string) {
    this.dialog.open(DialogoAlertaComponent, {
      data: {
              message: msg,
              buttonText: {
              cancel: 'Ok'
              }
          },
      });
  }

  openColostragemForm() {
    const dialogRef = this.dialog.open(ColostragemCadastroComponent, {
      disableClose: true,
          height: '450px',
          width: '700px',
      data: {
        matrix: this.data.matrixId
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const a = document.createElement('a');
      a.click();
      a.remove();
      if (res && res.data === 'cancelar') {
        this.showSnackBar('Operação Cancelada.');
      }
    });
  }

  openPesagemForm() {
    const hasPesoAoNascer =
      this.data.matrixId.pesonascer !== undefined
      && this.data.matrixId.pesonascer !== null
      && this.data.matrixId.pesonascer > 0;

    if (hasPesoAoNascer) {
      const dialogRef = this.dialog.open(PesagemCadastroComponent, {
        disableClose: true,
            height: '400px',
            width: '600px',
        data: {
          matrix: this.data.matrixId
        }
      });

      dialogRef.afterClosed().subscribe(res => {
        const a = document.createElement('a');
        a.click();
        a.remove();
        if (res && res.data === 'cancelar') {
          this.showSnackBar('Operação Cancelada.');
        }
      });
    } else {
      this.showAlertDialog(`Informe o peso ao nascer do animal para prosseguir`);
    }
  }

  openEventosForm() {
    const dialogRef = this.dialog.open(EventosCadastroComponent, {
      disableClose: true,
          height: '400px',
          width: '600px',
      data: {
        matrix: this.data.matrixId
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const a = document.createElement('a');
      a.click();
      a.remove();
      if (res && res.data === 'cancelar') {
        this.showSnackBar('Operação Cancelada.');
      }
    });
  }

  onMatrixHistoryCellClick(e: any) {
    this.selectedHistory = e.dataItem;
  }

  onMatrixHistoryDblClick() {
    this.editHandler({ dataItem: this.selectedHistory });
  }

  public editHandler(e: any) {
    const matrixHistory: Array<HistoricoDeMatriz> = this.historicoMatriz.filter(m => m.cdMatriz === e.dataItem.cdMatriz);

    let dialogRef: MatDialogRef<MatrizHistoricoCadastroComponent>;

    dialogRef = this.dialog.open(MatrizHistoricoCadastroComponent, {
      disableClose: true,
      height: '300px',
      width: '500px',
      data: {
        histories: matrixHistory,
        numeroDaMatriz: this.data.matrixId.numero,
        nomeDaMatriz: this.data.matrixId.nome,
        cdMatriz: this.data.matrixId.cdmatriz
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.getHistoricosDeMatriz();
      }
    });
  }

  onGridKeyDown(e: any) {
    if (e.code === this.F4) {
      this.onMatrixHistoryDblClick();
    }
  }

   openEditMother() {
    const hasMother =
      this.data.matrixId.cdmae !== undefined
      && this.data.matrixId.cdmae > 0;

    if (hasMother) {
      const id = this.data.matrixId.cdmae;
      this.motherId = this.matrizes.find(matrix => matrix.cdmatriz === id);
      this.hasChangesMother = true;
      this.editMother = true;
      this.edited = true;
    } else {
      this.showAlertDialog(`Selecione uma mãe para editar`);
    }
  }

  openNewMother() {
    this.motherId = new Matriz();
    this.hasChangesMother = true;
    this.editMother = true;
    this.edited = true;
  }

  openMotherDialog() {
    const dialogRef = this.dialog.open(MatrizMaeCadastroComponent, {
      disableClose: true,
      height: '400px',
      width: '800',
      data: {
        matrixId: this.motherId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.getMatrizes();
        this.getGenealogia();
      }
    });
  }

  closeMotherRegisterDialog(closed: any) {
    this.editMother = false;
    this.edited = false;
    this.hasChangesMother = false;
  }

  updateMotherId(cdmatriz: any) {
    this.data.matrixId.cdmae = cdmatriz;
    this.getMatrizes();
    this.getGenealogia();
    this.closeMotherRegisterDialog(true);
    this.hasChanges = true;
  }
}
