export const environment = {
  production: false,

  // local
  // baseUrl: 'https://localhost:5001',
  // apiBaseUrl: 'https://localhost:5001/api',
  // b2cRedirectUri: 'http://localhost:4200/',
  // b2cPostLogoutRedirectUri: 'http://localhost:4200/',
  // b2cClientId: '003feb18-91a9-4c12-ae47-e283a6314847',
  // b2cLogin: 'urusb2c.b2clogin.com',
  // b2cDomain: 'urusb2c.onmicrosoft.com',
  // b2cApiClientId: '4d78276a-0b48-4418-be59-0ddc963068b0',
  // b2cSignUpSignIn: 'B2C_1_AltaGestao_SignupSigninV7',
  // b2cResetPassword: 'B2C_1_AltaGestao_PasswordReset'

  // dev
  // baseUrl: 'https://altagestaodev.altagenetics.com.br',
  // apiBaseUrl: 'https://altagestaodev.altagenetics.com.br/api',
  // b2cRedirectUri: 'https://altagestaodev.altagenetics.com.br',
  // b2cPostLogoutRedirectUri: 'https://altagestaodev.altagenetics.com.br',
  // b2cClientId: '003feb18-91a9-4c12-ae47-e283a6314847',
  // b2cLogin: 'urusb2c.b2clogin.com',
  // b2cDomain: 'urusb2c.onmicrosoft.com',
  // b2cApiClientId: '4d78276a-0b48-4418-be59-0ddc963068b0',
  // b2cSignUpSignIn: 'B2C_1_AltaGestao_SignupSigninV7',
  // b2cResetPassword: 'B2C_1_AltaGestao_PasswordReset'

  // test
  // baseUrl: 'https://altagestaotest.altagenetics.com.br',
  // apiBaseUrl: 'https://altagestaotest.altagenetics.com.br/api',
  // b2cRedirectUri: 'https://altagestaotest.altagenetics.com.br',
  // b2cPostLogoutRedirectUri: 'https://altagestaotest.altagenetics.com.br',
  // b2cClientId: '62d3ea76-021d-424a-a402-556d8f859a18',
  // b2cLogin: 'urusb2c.b2clogin.com',
  // b2cDomain: 'urusb2c.onmicrosoft.com',
  // b2cApiClientId: '6508db8c-ed32-48c8-b8ba-f8fd3793fe9b',
  // b2cSignUpSignIn: 'B2C_1_AltaGestao_SignupSigninV7',
  // b2cResetPassword: 'B2C_1_AltaGestao_PasswordReset'

  // prod
  baseUrl: 'https://altagestao.altagenetics.com.br',
  apiBaseUrl: 'https://altagestao.altagenetics.com.br/api',
  b2cRedirectUri: 'https://altagestao.altagenetics.com.br',
  b2cPostLogoutRedirectUri: 'https://altagestao.altagenetics.com.br',
  b2cClientId: 'cde4a017-f5af-4eeb-b718-c243d5aa8319',
  b2cLogin: 'urusb2c.b2clogin.com',
  b2cDomain: 'urusb2c.onmicrosoft.com',
  b2cApiClientId: 'b8cd7f3d-08ae-4725-971c-b755ab4dda75',
  b2cSignUpSignIn: 'B2C_1_AltaGestao_SignupSigninV7',
  b2cResetPassword: 'B2C_1_AltaGestao_PasswordReset'
};
