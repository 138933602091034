import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-lote-cadastro',
    templateUrl: './lote-cadastro.component.html',
    styleUrls: ['./lote-cadastro.component.scss'],
  })

export class LoteCadastroComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<LoteCadastroComponent>) { }

    public hasChanges = false;

    cancel() {
        this.dialogRef.close({ data: 'cancelar' });
    }

    confirm() {
        this.dialogRef.close({ lotId: this.data.lotId });
    }

    ngOnInit() {}

    hasValue(statusValue) {
        if (statusValue.trim() !== '' && statusValue !== undefined ) {
            this.hasChanges = true;
        } else {
            this.hasChanges = false;
        }
    }
}
