import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AltaGestaoService } from './alta-gestao.service';
import { Pessoa } from 'src/models/pessoa.model';
import { Papel } from 'src/models/papel.model';
import { Usuario} from 'src/models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class CadastroService {

  private apiUrl = `${environment.apiBaseUrl}/cadastro`;
  private NUMERO_TENTATIVAS_REQUEST = 0;

  constructor(
    private http: HttpClient,
    private altaGestaoService: AltaGestaoService) {
  }

  getUsuarioPorFazenda(): Observable<Pessoa[]> {
    return this.http
      .get<Pessoa[]>(`${this.apiUrl}/fazenda-usuario/${this.altaGestaoService.getCdFazendaSelecionada()}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  GetUsuarioPorCdPessoa(cdPessoa: number): Observable<Usuario> {
    return this.http
      .get<Usuario>(`${this.apiUrl}/usuario/${cdPessoa}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getUsuarios(): Observable<Pessoa[]> {
    return this.http
      .get<Pessoa[]>(`${this.apiUrl}/usuario`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getUsuariosPorGrupoFazenda(cdgrupo: number): Observable<Pessoa[]> {
    return this.http
      .get<Pessoa[]>(`${this.apiUrl}/usuario-por-grupo-fazenda/${cdgrupo}`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  getPapeis(): Observable<Papel[]> {
    return this.http
      .get<Papel[]>(`${this.apiUrl}/perfil`)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereUsuarioCadastradoNaAzure(usuario: Usuario): Observable<Usuario> {
    return this.http
      .post<Usuario>(`${this.apiUrl}/usuario/insere/azure`, usuario)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  insereUsuario(usuario: Usuario) {
    return this.http
      .post<any>(`${this.apiUrl}/usuario/insere`, usuario)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  atualizaUsuario(usuario: Usuario) {
    return this.http
      .put<any>(`${this.apiUrl}/usuario/atualiza`, usuario)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  removeUsuario(usuarios: Pessoa[]): Observable<Pessoa[]> {
    return this.http
      .put<Pessoa[]>(`${this.apiUrl}/usuario/remove`, usuarios)
      .pipe(retry(this.NUMERO_TENTATIVAS_REQUEST), catchError(this.handleError));
  }

  handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // if (error.status === 404) {
      //     this.router.navigateByUrl('/404', { skipLocationChange: true });
      // }
      console.log(error);
    }
    // return an observable with a user-facing error message
    return throwError('Erro ao executar operação');
  }

}
