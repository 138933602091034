import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, UploadInterceptor } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import '@progress/kendo-angular-intl/locales/pt/all';
import { DatePipe, registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { Configuration } from 'msal';
import { msalConfig, msalAngularConfig } from './_authorization/auth.config';
import { LoaderComponent } from './_utils/loader/loader.component';
import { MsalModule, MsalInterceptor, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalService, MsalAngularConfiguration } from '@azure/msal-angular';
import { MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './_utils/CustomPaginatorConfiguration';

import { MatAutocompleteModule, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule, MatIconModule, MatButtonModule } from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogoAlertaComponent } from './_utils/dialogo-alerta/dialogo-alerta.component';
import { DialogoConfirmacao } from './_utils/dialogo-confirmacao/dialogo-confirmacao.component';
import { MatrizHistoricoCadastroComponent } from './matrix/matriz-historico-cadastro/matriz-historico-cadastro.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { IatfCadastroComponent } from './reproduction/iatf-cadastro/iatf-cadastro.component';
import { IaCobTeCadastroComponent } from './reproduction/ia-cob-te-cadastro/ia-cob-te-cadastro.component';
import { PartoCadastroComponent } from './reproduction/parto-cadastro/parto-cadastro.component';
import { ConsultaCadastroComponent } from './reproduction/consulta-cadastro/consulta-cadastro.component';
import { OcorrenciaReproducaoCadastroComponent } from './reproduction/ocorrencia-reproducao-cadastro/ocorrencia-reproducao-cadastro.component';
import { LoteCadastroComponent } from './configurations/lote-cadastro/lote-cadastro.component';
import { InseminadorCadastroComponent } from './configurations/inseminador-cadastro/inseminador-cadastro.component';
import { ProtocoloCadastroComponent } from './configurations/protocolo-cadastro/protocolo-cadastro.component';
import { ComposicaoRacialCadastroComponent } from './configurations/composicao-racial-cadastro/composicao-racial-cadastro.component';
import { ReprodutorCadastroComponent } from './configurations/reprodutor-cadastro/reprodutor-cadastro.component';

import 'hammerjs';
import { ControleLeiteiroCadastroComponent } from './production/controle-leiteiro-cadastro/controle-leiteiro-cadastro.component';
import { IaCobTeDetalheComponent } from './reproduction/ia-cob-te-detalhe/ia-cob-te-detalhe.component';
import { ToqueDetalheComponent } from './reproduction/toque-detalhe/toque-detalhe.component';
import { OcorrenciaReproducaoDetalheComponent } from './reproduction/ocorrencia-reproducao-detalhe/ocorrencia-reproducao-detalhe.component';
import { PartoDetalheComponent } from './reproduction/parto-detalhe/parto-detalhe.component';
import { HistoricoReproducaoCadastroComponent } from './reproduction/historico-reproducao-cadastro/historico-reproducao-cadastro.component';
import { ColostragemCadastroComponent } from './alta-cria/colostragem-cadastro/colostragem-cadastro.component';
import { PesagemCadastroComponent } from './alta-cria/pesagem-cadastro/pesagem-cadastro.component';
import { EventosCadastroComponent } from './alta-cria/eventos-cadastro/eventos-cadastro.component';
import { SecagemCadastroComponent } from './production/secagem-cadastro/secagem-cadastro.component';
import { MatrizMaeCadastroComponent } from './matrix/matriz-mae-cadastro/matriz-mae-cadastro.component';
import { ProtocoloDetalheCadastroComponent } from './configurations/protocolo-detelhe-cadastro/protocolo-detelhe-cadastro.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { PopUpAlteracaoFazendaComponent } from './_utils/popup-alteracao-fazenda/popup-alteracao-fazendacomponent';
import { MatrizCadastroComponent } from './matrix/matriz-cadastro/matriz-cadastro.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogLimparFazendaComponent } from './_utils/dialog-limpar-fazenda/dialog-limpar-fazenda.component';

registerLocaleData(pt, 'pt');

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const protectedResourceMap: [string, string[]][] = [];

export function MSALConfigFactory(): Configuration {
  return msalConfig;
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return msalAngularConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MatrizCadastroComponent,
    LoaderComponent,
    DialogoAlertaComponent,
    DialogoConfirmacao,
    MatrizHistoricoCadastroComponent,
    IatfCadastroComponent,
    IaCobTeCadastroComponent,
    PartoCadastroComponent,
    ConsultaCadastroComponent,
    LoteCadastroComponent,
    InseminadorCadastroComponent,
    ProtocoloCadastroComponent,
    ComposicaoRacialCadastroComponent,
    OcorrenciaReproducaoCadastroComponent,
    PopUpAlteracaoFazendaComponent,
    ControleLeiteiroCadastroComponent,
    ReprodutorCadastroComponent,
    IaCobTeDetalheComponent,
    ToqueDetalheComponent,
    OcorrenciaReproducaoDetalheComponent,
    PartoDetalheComponent,
    HistoricoReproducaoCadastroComponent,
    ColostragemCadastroComponent,
    PesagemCadastroComponent,
    EventosCadastroComponent,
    SecagemCadastroComponent,
    MatrizMaeCadastroComponent,
    ProtocoloDetalheCadastroComponent,
    DialogLimparFazendaComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    GridModule,
    DropDownsModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MsalModule,
    MatMenuModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatSelectModule,
    MatSnackBarModule,
    MatListModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatFormFieldModule,
    MomentDateModule,
    DragDropModule,
    // NgxScrollTopModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService,
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    DatePipe,
    {
      provide: MatPaginatorIntl,
      useValue: CustomPaginator()
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'pt-BR'
    }
  ],
  entryComponents: [
    DialogoConfirmacao,
    DialogoAlertaComponent,
    MatrizCadastroComponent,
    MatrizHistoricoCadastroComponent,
    IatfCadastroComponent,
    OcorrenciaReproducaoCadastroComponent,
    LoteCadastroComponent,
    InseminadorCadastroComponent,
    ProtocoloCadastroComponent,
    ProtocoloDetalheCadastroComponent,
    ComposicaoRacialCadastroComponent,
    ControleLeiteiroCadastroComponent,
    PopUpAlteracaoFazendaComponent,
    PartoCadastroComponent,
    ReprodutorCadastroComponent,
    ConsultaCadastroComponent,
    IaCobTeCadastroComponent,
    HistoricoReproducaoCadastroComponent,
    ColostragemCadastroComponent,
    PesagemCadastroComponent,
    EventosCadastroComponent,
    SecagemCadastroComponent,
    MatrizMaeCadastroComponent,
    DialogLimparFazendaComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('angular-material-router-app-theme');
  }
}
