import { Usuario } from './usuario.model';

export class Pessoa {
    public cdpessoa: number;
    public nome: string;
    public email: string;
    public cpf: string;
    public cnpj: string;
    public cdUsuarioAltera: number;
    public dtAltera: Date;
    public tipoPessoa: number;
    public usuario: Usuario;

    // Para Tela Usuário
    public nomeFazenda: string;
}
