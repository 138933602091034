import { Injectable } from '@angular/core';
import { Matriz } from 'src/models/matriz/matriz.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';
import { Reprodutor } from 'src/models/configuracoes/reprodutor.model';
import { FazendaParametro } from 'src/models/fazenda-parametro.model';
import { Leite } from 'src/models/leite.model';
import { ValidacaoIaCobTeReproducaoService } from './validacao-ia-cob-te-reproducao.service';
import { ValidacaoDiagnosticoReproducaoService } from './validacao-diagnostico-reproducao.service';
import { ValidaCaoPartoReproducaoService } from './validacao-parto-reproducao.service';
import { ValidacaoSecagemReproducaoService } from './validacao-secagem-reproducao.service';

@Injectable({
  providedIn: 'root'
})
export class ValidacaoReproducaoService {

  private reproducao: any;
  private reproducaoAnterior: any;
  private matriz: Matriz;
  private erro: string;
  private reproducoes: Reproducao[];

  constructor(
    private reproductionIaCobTeValidationService: ValidacaoIaCobTeReproducaoService,
    private reproductionDiagnosticValidationService: ValidacaoDiagnosticoReproducaoService,
    private reproductionParturitionValidationService: ValidaCaoPartoReproducaoService,
    private reproductionDryingValidationService: ValidacaoSecagemReproducaoService) {}

  validaLancamentoIaCobTe(
    reproducoes: Reproducao[],
    reprodutores: Reprodutor[],
    reproducao: any,
    matriz: Matriz,
    parametros: FazendaParametro[]): boolean {

    const ehLancamentoIaCobTeValido = this.reproductionIaCobTeValidationService.validaLancamentoIaCobTe(
      reproducoes,
      reprodutores,
      reproducao,
      matriz,
      parametros
    );

    if (!ehLancamentoIaCobTeValido) { this.erro = this.reproductionIaCobTeValidationService.getErro(); return false; }

    return true;
  }

  validaLancamentoDiagnostico(reproducao: any, matriz: Matriz): boolean {
    this.reproducao = reproducao;
    this.matriz = matriz;
    this.erro = ``;

    const ehLancamentoDiagnosticoValido = this.reproductionDiagnosticValidationService.validaLancamentoDiagnostico(
      reproducao,
      matriz
    );

    if (!ehLancamentoDiagnosticoValido) { this.erro = this.reproductionDiagnosticValidationService.getErro(); return false; }

    return true;
  }

  validaLancamentoParto(
    reproducao: any,
    matriz: Matriz,
    matrizes: Matriz[],
    parametros: FazendaParametro[]): boolean {

    const ehLancamentoPartoValido = this.reproductionParturitionValidationService.validaLancamentoParto(
      reproducao,
      matriz,
      matrizes,
      parametros
    );

    if (!ehLancamentoPartoValido) { this.erro = this.reproductionParturitionValidationService.getErro(); return false; }

    return true;
  }

  validaLancamentoSecagem(
    reproducoes: Reproducao[],
    reproducao: any,
    matriz: Matriz,
    controlesLeiteiros: Leite[]): boolean {

    const ehLancamentoSecagemValido = this.reproductionDryingValidationService.validaLancamentoSecagem(
      reproducoes,
      reproducao,
      matriz,
      controlesLeiteiros
    );

    if (!ehLancamentoSecagemValido) { this.erro = this.reproductionDryingValidationService.getErro(); return false; }

    return true;
  }

  validaLancamentoDatas(
    reproducoes: Reproducao[],
    reproducao: any,
    reproducaoAnterior: any,
    matriz: Matriz): boolean {
    this.reproducao = reproducao;
    this.reproducaoAnterior = reproducaoAnterior;
    this.matriz = matriz;
    this.erro = ``;
    this.reproducoes = reproducoes;

    const ehInsercao =
      this.reproducao.cdreproducao === 0
      || this.reproducao.cdreproducao === undefined
      || this.reproducao.cdreproducao === null;

    const ehAtualizacao = this.reproducao.cdreproducao > 0;

    if (ehInsercao) {
      if (!this.validaInsercaoDatas()) { return false; }
    } else if (ehAtualizacao) {
      if (!this.validaAtualizacaoDatas()) { return false; }
    }

    return true;
  }

  validaInsercaoDatas() {
    let dtAux: Date;

    let reproducoes =
      this.reproducoes.filter(
          reproducao => reproducao.cdmatriz === this.reproducao.cdmatriz
          && reproducao.cdreproducao !== this.reproducao.cdreproducao);

    const existemReproducao = reproducoes !== undefined && reproducoes.length > 0;

    if (existemReproducao) {
      reproducoes = reproducoes.sort((a, b) => (new Date(b.data).getTime() - new Date(a.data).getTime()));

      const dataParto = reproducoes[0].dtparto;
        // reproductions.sort((a, b) => (new Date(b.dtparto).getTime() - new Date(a.dtparto).getTime()))[0].dtparto;

      const dataDiagnostico4 = reproducoes[0].dtdiagnostico4;
        // reproductions.sort((a, b) => (new Date(b.dtdiagnostico4).getTime() - new Date(a.dtdiagnostico4).getTime()))[0].dtdiagnostico4;

      const dataDiagnostico3 = reproducoes[0].dtdiagnostico3;
        // reproductions.sort((a, b) => (new Date(b.dtdiagnostico3).getTime() - new Date(a.dtdiagnostico3).getTime()))[0].dtdiagnostico3;

      const dataDiagnostico2 = reproducoes[0].dtdiagnostico2;
        // reproductions.sort((a, b) => (new Date(b.dtdiagnostico2).getTime() - new Date(a.dtdiagnostico2).getTime()))[0].dtdiagnostico2;

      const dataDiagnostico1 = reproducoes[0].dtdiagnostico1;
        // reproductions.sort((a, b) => (new Date(b.dtdiagnostico1).getTime() - new Date(a.dtdiagnostico1).getTime()))[0].dtdiagnostico1;

      const dataServico = reproducoes[0].data;
        // reproductions.sort((a, b) => (new Date(b.data).getTime() - new Date(a.data).getTime()))[0].data;

      const existeDataParto = dataParto !== null && dataParto !== undefined;
      const existeDataDiagnostico4 = dataDiagnostico4 !== null && dataDiagnostico4 !== undefined;
      const existeDataDiagnostico3 = dataDiagnostico3 !== null && dataDiagnostico3 !== undefined;
      const existeDataDiagnostico2 = dataDiagnostico2 !== null && dataDiagnostico2 !== undefined;
      const existeDataDiagnostico1 = dataDiagnostico1 !== null && dataDiagnostico1 !== undefined;

      if (existeDataParto) {
        dtAux = new Date(dataParto);
      } else if (existeDataDiagnostico4) {
        dtAux = new Date(dataDiagnostico4);
      } else if (existeDataDiagnostico3) {
        dtAux = new Date(dataDiagnostico3);
      } else if (existeDataDiagnostico2) {
        dtAux = new Date(dataDiagnostico2);
      } else if (existeDataDiagnostico1) {
        dtAux = new Date(dataDiagnostico1);
      } else {
        dtAux = new Date(dataServico);
      }
    } else {
      dtAux = new Date(`01/01/1900`);
    }

    if (this.reproducao.data < dtAux) {
      this.erro =
        `A inclusão de novos registros não pode ser em datas inferiores à outros registros reprodutivos. Matriz: ${ this.matriz.numero }`;
      return false;
    }
    return true;
  }

  validaAtualizacaoDatas(): boolean {
    const dtAuxMin = this.getDtAuxMin();
    const dtAux = this.getDtAux();

    if (!this.ehDataTentativaPosteriorReproducaoAnterior(dtAuxMin))     { return false; }
    if (!this.ehDataDiagnostico1PosteriorReproducaoAnterior(dtAuxMin))  { return false; }
    if (!this.ehDataDiagnostico2PosteriorReproducaoAnterior(dtAuxMin))  { return false; }
    if (!this.ehDataDiagnostico3PosteriorReproducaoAnterior(dtAuxMin))  { return false; }
    if (!this.ehDataDiagnostico4PosteriorReproducaoAnterior(dtAuxMin))  { return false; }
    if (!this.ehDataPartoPosteriorReproducaoAnterior(dtAuxMin))         { return false; }
    if (!this.ehDataTentativaMenorQueReproducaoPosterior(dtAux))        { return false; }
    if (!this.ehDataDiagnostico1MenorQueReproducaoPosterior(dtAux))     { return false; }
    if (!this.ehDataDiagnostico2MenorQueReproducaoPosterior(dtAux))     { return false; }
    if (!this.ehDataDiagnostico3MenorQueReproducaoPosterior(dtAux))     { return false; }
    if (!this.ehDataDiagnostico4MenorQueReproducaoPosterior(dtAux))     { return false; }
    if (!this.ehDataPartoMenorQueReproducaoPosterior(dtAux))            { return false; }

    return true;
  }

  getDtAux(): Date {
    let dtAux: Date;

    const reproducoes =
      this.reproducoes.filter(
          reproducao => reproducao.cdmatriz === this.reproducao.cdmatriz
          && reproducao.data >= this.reproducao.data
          && reproducao.cdreproducao !== this.reproducao.cdreproducao);

    const existemReproducoes = reproducoes !== undefined && reproducoes.length > 0;

    if (existemReproducoes) {
      const dataServico =
        reproducoes.sort((a, b) => (new Date(a.data).getTime() - new Date(b.data).getTime()))[reproducoes.length - 1].data;
      dtAux = new Date(dataServico);
    } else {
      dtAux = new Date(`01/01/2999`);
    }
    return dtAux;
  }

  getDtAuxMin(): Date {
    let dtAuxMin: Date;

    const reproducoes =
      this.reproducoes.filter(
          reproducao => reproducao.cdmatriz === this.reproducao.cdmatriz
          && reproducao.data < this.reproducao.data
          && reproducao.cdreproducao !== this.reproducao.cdreproducao);

    const existemReproducoes = reproducoes !== undefined && reproducoes.length > 0;

    if (existemReproducoes) {
      const dataParto =
        reproducoes.sort((a, b) => (new Date(b.dtparto).getTime() - new Date(a.dtparto).getTime()))[0].dtparto;

      const dtDiagnostico1 =
        reproducoes.sort((a, b) => (new Date(b.dtdiagnostico1).getTime() - new Date(a.dtdiagnostico1).getTime()))[0].dtdiagnostico1;

      const dtDiagnostico2 =
        reproducoes.sort((a, b) => (new Date(b.dtdiagnostico2).getTime() - new Date(a.dtdiagnostico2).getTime()))[0].dtdiagnostico2;

      const dtDiagnostico3 =
        reproducoes.sort((a, b) => (new Date(b.dtdiagnostico3).getTime() - new Date(a.dtdiagnostico3).getTime()))[0].dtdiagnostico3;

      const dtDiagnostico4 =
        reproducoes.sort((a, b) => (new Date(b.dtdiagnostico4).getTime() - new Date(a.dtdiagnostico4).getTime()))[0].dtdiagnostico4;

      const date =
        reproducoes.sort((a, b) => (new Date(b.data).getTime() - new Date(a.data).getTime()))[0].data;

      const existeDataParto = dataParto !== null && dataParto !== undefined;
      const existeDtDiagnostico1 = dtDiagnostico1 !== null && dtDiagnostico1 !== undefined;
      const existeDtDiagnostico2 = dtDiagnostico2 !== null && dtDiagnostico2 !== undefined;
      const existeDtDiagnostico3 = dtDiagnostico3 !== null && dtDiagnostico3 !== undefined;
      const existeDtDiagnostico4 = dtDiagnostico4 !== null && dtDiagnostico4 !== undefined;

      if (existeDataParto) {
        dtAuxMin = new Date(dataParto);
      } else if (existeDtDiagnostico4) {
        dtAuxMin = new Date(dtDiagnostico4);
      } else if (existeDtDiagnostico3) {
        dtAuxMin = new Date(dtDiagnostico3);
      } else if (existeDtDiagnostico2) {
        dtAuxMin = new Date(dtDiagnostico2);
      } else if (existeDtDiagnostico1) {
        dtAuxMin = new Date(dtDiagnostico1);
      } else {
        dtAuxMin = new Date(date);
      }
    } else {
      dtAuxMin = new Date(`01/01/1900`);
    }
    return dtAuxMin;
  }

  ehDataTentativaPosteriorReproducaoAnterior(dtAuxMin: Date): boolean {
    const existeDataServico =
      this.reproducao.data !== null && this.reproducao.data !== undefined;

    if (existeDataServico) {
      const dataServico = new Date(this.reproducao.data);
      const isDiferentReproductionDate = dataServico.getTime() !== new Date(this.reproducaoAnterior.data).getTime();
      if (isDiferentReproductionDate && dataServico < dtAuxMin) {
        this.erro =
          `A data da tentativa não pode ser anterior à data do registro reprodutivo anterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataDiagnostico1PosteriorReproducaoAnterior(dtAuxMin: Date): boolean {
    if (this.existeDataDiagnostico1()) {
      const dataDiagnostico1 = new Date(this.reproducao.dtdiagnostico1);
      const isDiferentDiagnostic1Date = dataDiagnostico1.getTime() !== new Date(this.reproducaoAnterior.dtdiagnostico1).getTime();
      if (isDiferentDiagnostic1Date && dataDiagnostico1 < dtAuxMin) {
        this.erro =
          `A data do diagnóstico1 não pode ser anterior à data do registro reprodutivo anterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataDiagnostico2PosteriorReproducaoAnterior(dtAuxMin: Date): boolean {
    if (this.existeDataDiagnostico2()) {
      const dataDiagnostico2 = new Date(this.reproducao.dtdiagnostico2);
      const isDiferentDiagnostic2Date = dataDiagnostico2.getTime() !== new Date(this.reproducaoAnterior.dtdiagnostico2).getTime();
      if (isDiferentDiagnostic2Date && dataDiagnostico2 < dtAuxMin) {
        this.erro =
          `A data do diagnóstico2 não pode ser anterior à data do registro reprodutivo anterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataDiagnostico3PosteriorReproducaoAnterior(dtAuxMin: Date): boolean {
    if (this.existeDataDiagnostico3()) {
      const dataDiagnostico3 = new Date(this.reproducao.dtdiagnostico3);
      const isDiferentDiagnostic3Date = dataDiagnostico3.getTime() !== new Date(this.reproducaoAnterior.dtdiagnostico3).getTime();
      if (isDiferentDiagnostic3Date && dataDiagnostico3 < dtAuxMin) {
        this.erro =
          `A data do diagnóstico3 não pode ser anterior à data do registro reprodutivo anterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataDiagnostico4PosteriorReproducaoAnterior(dtAuxMin: Date): boolean {
    if (this.existeDataDiagnostico4()) {
      const dataDiagnostico4 = new Date(this.reproducao.dtdiagnostico4);
      const isDiferentDiagnostic4Date = dataDiagnostico4.getTime() !== new Date(this.reproducaoAnterior.dtdiagnostico4).getTime();
      if (isDiferentDiagnostic4Date && dataDiagnostico4 < dtAuxMin) {
        this.erro =
          `A data do diagnóstico4 não pode ser anterior à data do registro reprodutivo anterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataPartoPosteriorReproducaoAnterior(dtAuxMin: Date): boolean {
    if (this.existeDataParto()) {
      const dataParto = new Date(this.reproducao.dtparto);
      const isDiferentParturitionDate = dataParto.getTime() !== new Date(this.reproducaoAnterior.dtparto).getTime();
      if (isDiferentParturitionDate && dataParto < dtAuxMin) {
        this.erro =
          `A data do parto não pode ser anterior à data do registro reprodutivo anterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataTentativaMenorQueReproducaoPosterior(dtAux: Date): boolean {
    const existeDataServico =
      this.reproducao.data !== null && this.reproducao.data !== undefined;

    if (existeDataServico) {
      const dataServico = new Date(this.reproducao.data);
      const isDiferentIaCobTeDate = dataServico.getTime() !== new Date(this.reproducaoAnterior.data).getTime();
      if (isDiferentIaCobTeDate && dataServico > dtAux) {
        this.erro =
          `A data da tentativa não pode ser superior à data do registro reprodutivo posterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataDiagnostico1MenorQueReproducaoPosterior(dtAux: Date): boolean {
    if (this.existeDataDiagnostico1()) {
      const dataDiagnostico1 = new Date(this.reproducao.dtdiagnostico1);
      const isDiferentDiagnostic1Date = dataDiagnostico1.getTime() !== new Date(this.reproducaoAnterior.dtdiagnostico1).getTime();
      if (isDiferentDiagnostic1Date && dataDiagnostico1 > dtAux) {
        this.erro =
          `A data do diagnóstico1 não pode ser superior à data do registro reprodutivo posterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataDiagnostico2MenorQueReproducaoPosterior(dtAux: Date): boolean {
    if (this.existeDataDiagnostico2()) {
      const dataDiagnostico2 = new Date(this.reproducao.dtdiagnostico2);
      const isDiferentDiagnostic2Date = dataDiagnostico2.getTime() !== new Date(this.reproducaoAnterior.dtdiagnostico2).getTime();
      if (isDiferentDiagnostic2Date && dataDiagnostico2 > dtAux) {
        this.erro =
          `A data do diagnóstico2 não pode ser superior à data do registro reprodutivo posterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataDiagnostico3MenorQueReproducaoPosterior(dtAux: Date): boolean {
    if (this.existeDataDiagnostico3()) {
      const dataDiagnostico3 = new Date(this.reproducao.dtdiagnostico3);
      const isDiferentDiagnostic3Date = dataDiagnostico3.getTime() !== new Date(this.reproducaoAnterior.dtdiagnostico3).getTime();
      if (isDiferentDiagnostic3Date && dataDiagnostico3 > dtAux) {
        this.erro =
          `A data do diagnóstico3 não pode ser superior à data do registro reprodutivo posterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataDiagnostico4MenorQueReproducaoPosterior(dtAux: Date): boolean {
    if (this.existeDataDiagnostico4()) {
      const dataDiagnostico4 = new Date(this.reproducao.dtdiagnostico4);
      const isDiferentDiagnostic4Date = dataDiagnostico4.getTime() !== new Date(this.reproducaoAnterior.dtdiagnostico4).getTime();
      if (isDiferentDiagnostic4Date && dataDiagnostico4 > dtAux) {
        this.erro =
          `A data do diagnóstico4 não pode ser superior à data do registro reprodutivo posterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  ehDataPartoMenorQueReproducaoPosterior(dtAux: Date): boolean {
    if (this.existeDataParto()) {
      const dataParto = new Date(this.reproducao.dtparto);
      const ehDataPartoDiferente = dataParto.getTime() !== new Date(this.reproducaoAnterior.dtparto).getTime();
      if (ehDataPartoDiferente && dataParto > dtAux) {
        this.erro =
          `A data do parto não pode ser superior à data do registro reprodutivo posterior. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  existeDataParto(): boolean {
    const existeDataParto =
      this.reproducao.dtparto !== undefined
      && this.reproducao.dtparto !== null;
    return existeDataParto;
  }

  existeDataDiagnostico1(): boolean {
    const existeDataDiagnostico1 =
      this.reproducao.dtdiagnostico1 !== undefined
      && this.reproducao.dtdiagnostico1 !== null;
    return existeDataDiagnostico1;
  }

  existeDataDiagnostico2(): boolean {
    const existeDataDiagnostico2 =
      this.reproducao.dtdiagnostico2 !== undefined
      && this.reproducao.dtdiagnostico2 !== null;
    return existeDataDiagnostico2;
  }

  existeDataDiagnostico3(): boolean {
    const existeDataDiagnostico3 =
      this.reproducao.dtdiagnostico3 !== undefined
      && this.reproducao.dtdiagnostico3 !== null;
    return existeDataDiagnostico3;
  }

  existeDataDiagnostico4(): boolean {
    const existeDataDiagnostico4 =
      this.reproducao.dtdiagnostico4 !== undefined
      && this.reproducao.dtdiagnostico4 !== null;
    return existeDataDiagnostico4;
  }

  getErro(): string {
    return this.erro;
  }
}
