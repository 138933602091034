import { TipoPartoEnum } from './../_enums/tipo-parto.enum';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FazendaParametro } from 'src/models/fazenda-parametro.model';
import { Matriz } from 'src/models/matriz/matriz.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';
import { ResultadoDiagnosticoEnum as ResultadoDiagnosticoEnum } from '../_enums/resultado-diagnostico.enum';
import { TipoTentativaEnum } from '../_enums/tipo-tentativa.enum';

@Injectable({
  providedIn: 'root'
})
export class ValidaCaoPartoReproducaoService {

  reproducao: any;
  matriz: Matriz;
  erro: string;
  reproducoes: Reproducao[];
  matrizes: Matriz[];
  parametros: FazendaParametro[];

  constructor() { }

  validaLancamentoParto(
    reproducao: any,
    matriz: Matriz,
    matrizes: Matriz[],
    parametros: FazendaParametro[]): boolean {
    this.reproducao = reproducao;
    this.matrizes = matrizes;
    this.parametros = parametros;
    this.matriz = matriz;
    this.erro = ``;

    if (!this.validaDataParto())      { return false; }
    if (!this.validaParto())          { return false; }
    if (!this.validaDetalhesParto())  { return false; }
    if (!this.validaTipoParto())      { return false; }
    if (!this.validaGrauAuxilio())    { return false; }
    if (!this.existeCrias())          { return false; }
    if (this.criaPossuiNatimorto())   { return false; }
    if (!this.validaNumeroCria1())    { return false; }
    if (!this.validaNumeroCria2())    { return false; }
    if (!this.validaDiasGestacao())   { return false; }

    return true;
  }

  private validaDataParto(): boolean {
    let ehDataPartoValida = true;

    if (this.existeDataParto()) {
      const dataAtual = new Date();
      const dataIaCobTe = new Date(this.reproducao.data);
      const dataDiagnostico1 = new Date(this.reproducao.dtdiagnostico1);
      const dataDiagnostico2 = new Date(this.reproducao.dtdiagnostico2);
      const dataParto = new Date(this.reproducao.dtparto);
      const existeDataDiagnostico3 = this.reproducao.dtdiagnostico3 !== null && this.reproducao.dtdiagnostico3 !== undefined;
      const existeDataDiagnostico4 = this.reproducao.dtdiagnostico4 !== null && this.reproducao.dtdiagnostico4 !== undefined;

      ehDataPartoValida =
        dataParto > dataIaCobTe
          && dataParto > dataDiagnostico1
          && dataParto > dataDiagnostico2
          && dataParto <= dataAtual;

      if (existeDataDiagnostico3) {
        const dataDiagnostico3 = new Date(this.reproducao.dtdiagnostico3);
        ehDataPartoValida =
          dataParto > dataIaCobTe
            && dataParto > dataDiagnostico1
            && dataParto > dataDiagnostico2
            && dataParto > dataDiagnostico3
            && dataParto <= dataAtual;
      }

      if (existeDataDiagnostico4) {
        const dataDiagnostico3 = new Date(this.reproducao.dtdiagnostico3);
        const dataDiagnoscito4 = new Date(this.reproducao.dtdiagnostico4);
        ehDataPartoValida =
          dataParto > dataIaCobTe
            && dataParto > dataDiagnostico1
            && dataParto > dataDiagnostico2
            && dataParto > dataDiagnostico3
            && dataParto > dataDiagnoscito4
            && dataParto <= dataAtual;
      }

      if (!ehDataPartoValida) {
        this.erro = `
          A data do parto deve ser superior à data da inseminação, a data do diagnóstico 1,
            a data do diagnóstico 2, diagnóstico 3 e diagnóstico 4 e inferior a data atual. Matriz: ${ this.matriz.numero }.`;
        return ehDataPartoValida;
      }
    }
    return ehDataPartoValida;
  }

  private validaParto(): boolean {
    if (this.existeDataParto()) {
      if (!this.validaMatrizEstaPrenha()) { return false; }
      if (!this.validaLancamentoDiagnosticos()) { return false; }
      if (!this.validaLancamentoRetoques()) { return false; }
    }
    return true;
  }

  private validaMatrizEstaPrenha(): boolean {
    const estaPrenha =
        this.reproducao.cdresultadodiagnostico1 !== ResultadoDiagnosticoEnum.N
          && this.reproducao.cdresultadodiagnostico2 !== ResultadoDiagnosticoEnum.N
          && this.reproducao.cdresultadodiagnostico3 !== ResultadoDiagnosticoEnum.N
          && this.reproducao.cdresultadodiagnostico4 !== ResultadoDiagnosticoEnum.N;
    if (!estaPrenha) {
      this.erro = `
        Somente deve ser permitido informar um parto caso a matriz tenha ficado prenha. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private validaLancamentoDiagnosticos(): boolean {
    if (!this.existeResultadoDiagnostico1()
          && !this.existeResultadoDiagnostico2()
          && !this.existeResultadoDiagnostico3()
          && !this.existeResultadoDiagnostico4()) {
        this.erro = `
          Não pode ser informado um parto sem ser informado ao menos um diagnóstico. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    return true;
  }

  private validaLancamentoRetoques(): boolean {
    const existeRetoque1 =
    this.reproducao.cdresultadodiagnostico1 === ResultadoDiagnosticoEnum.R
      && (this.reproducao.cdresultadodiagnostico2 !== undefined
        && this.reproducao.cdresultadodiagnostico2 !== null);

    const existeRetoque2 =
      this.reproducao.cdresultadodiagnostico2 === ResultadoDiagnosticoEnum.R
        && (this.reproducao.cdresultadodiagnostico3 !== undefined
          && this.reproducao.cdresultadodiagnostico3 !== null);

    const existeRetoque3 =
      this.reproducao.cdresultadodiagnostico3 === ResultadoDiagnosticoEnum.R
        && (this.reproducao.cdresultadodiagnostico4 !== undefined
          && this.reproducao.cdresultadodiagnostico4 !== null);

    if (existeRetoque1) {
      const dataParto = this.reproducao.dtparto;
      const ehDataPartoValida = moment(dataParto).toDate() > moment(this.reproducao.dtdiagnostico2).toDate();
      if (!ehDataPartoValida) {
        this.erro = `Se o animal foi marcado para retoque,
          a reconfirmação deve ser informada antes do lançamento do parto. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }

    if (existeRetoque2) {
      const dataParto = this.reproducao.dtparto;
      const ehDataPartoValida = moment(dataParto).toDate() > moment(this.reproducao.dtdiagnostico3).toDate();
      if (!ehDataPartoValida) {
        this.erro = `Se o animal foi marcado para retoque,
          a reconfirmação deve ser informada antes do lançamento do parto. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }

    if (existeRetoque3) {
      const dataParto = this.reproducao.dtparto;
      const ehDataPartoValida = moment(dataParto).toDate() > moment(this.reproducao.dtdiagnostico4).toDate();
      if (!ehDataPartoValida) {
        this.erro = `Se o animal foi marcado para retoque,
          a reconfirmação deve ser informada antes do lançamento do parto. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }

    return true;
  }

  private existeCrias(): boolean {
    if (this.existeDataParto() && !this.ehTipoDePartoAbortoComLactacao()) {
      const ehGemelar = this.reproducao.gemelar === 1;
      const naoTemCria1Femea = this.reproducao.sexocria1 === null || this.reproducao.sexocria1 === undefined;
      const naoTemCria2Femea = this.reproducao.sexocria2 === null || this.reproducao.sexocria2 === undefined;

      if (ehGemelar) {
        if (naoTemCria1Femea || naoTemCria2Femea) {
          this.erro = `Informe o sexo das crias. Matriz: ${ this.matriz.numero }.`;
          return false;
        }
      }

      if (naoTemCria1Femea) {
        this.erro = `Informe o sexo das crias. Matriz: ${ this.matriz.numero }.`;
        return false;
      }

      if ((this.existeNumeroCria1() && !this.existeSexoCria1())
        || (this.existeNumeroCria2() && !this.existeSexoCria2())) {
        this.erro = `Não é permitido informar uma cria sem sexo. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  private validaDetalhesParto(): boolean {
    const existeEccParto =
      this.reproducao.eccParto !== undefined
      && this.reproducao.eccParto !== null
      && this.reproducao.eccParto > 0;

    const existeRetencaoPlacenta = this.reproducao.retencaoplacenta === 1 ? true : false;
    const existeDoencasMetabolicas = this.reproducao.doencasmetabolicas === 1 ? true : false;
    const existeDeslocamentoAbomaso = this.reproducao.deslocamentoabomaso === 1 ? true : false;
    const existeNatimortocria1 = this.reproducao.natimortocria1 === 1 ? true : false;
    const existeNatimortocria2 = this.reproducao.natimortocria2 === 1 ? true : false;
    const ehGemelar = this.reproducao.gemelar === 1 ? true : false;

    const existeAlgumDetalheDeLancamentoDeParto =
    (
      this.existeSexoCria1()
        || this.existeSexoCria2()
        || existeEccParto
        || existeRetencaoPlacenta
        || existeDoencasMetabolicas
        || existeDeslocamentoAbomaso
        || existeNatimortocria1
        || existeNatimortocria2
        || this.existeCdCria1()
        || this.existeCdCria2()
        || ehGemelar
    );

    if (this.existeResultadoDiagnostico2()
        && this.getResultadoDiagnostico2() === 1
        && existeAlgumDetalheDeLancamentoDeParto
        && !this.existeDataParto()) {
      this.erro = `É necessário informar a data do parto. Matriz: ${ this.matriz.numero }.`;
      return false;
    }

    if (existeAlgumDetalheDeLancamentoDeParto
        && !this.existeDataParto()) {
      this.erro = `É necessário informar a data do parto. Matriz: ${ this.matriz.numero }.`;
      return false;
    }

    return true;
  }

  private validaNumeroCria1(): boolean {
    if (this.existeNumeroCria1()) {
      const matrizes =
        this.matrizes.filter(
          matriz => matriz.numero.toUpperCase() === this.reproducao.numeroCria1.toUpperCase());
      const existeMatriz = matrizes !== undefined && matrizes.length > 0;

      if (!this.existeCdCria1() && existeMatriz) {
        this.erro =
          `A cria 1 informada não pode ter um número já existente no cadastro de matrizes. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  private validaNumeroCria2() {
    if (this.existeNumeroCria2()) {
      const matrixList =
        this.matrizes.filter(
          matriz => matriz.numero.toUpperCase() === this.reproducao.numeroCria2.toUpperCase());
      const existeMatriz = matrixList !== undefined && matrixList.length > 0;

      if (!this.existeCdCria2() && existeMatriz) {
        this.erro =
          `A cria 2 informada não pode ter um número já existente no cadastro de matrizes. Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  private validaDiasGestacao(): boolean {
    if (this.existeDataParto()) {
      let dtAuxMin: Date;
      const parametroGestacaoMinima = this.parametros.find(p => p.parametro.chave === `MinimoGestacao`);
      const parametroGestacaoMaxima = this.parametros.find(p => p.parametro.chave === `MaximoGestacao`);
      const valorGestacaoMinima = Number(parametroGestacaoMinima.valor);
      const valorGestacaoMaxima = Number(parametroGestacaoMaxima.valor);
      const dataReproducao = new Date(this.reproducao.data);
      const dataParto = new Date(this.reproducao.dtparto);

      if (this.isTE()) {
        dtAuxMin = dataReproducao;
        dtAuxMin.setDate(dtAuxMin.getDate() - 7);
      } else {
        dtAuxMin = dataReproducao;
      }

      const diferenca = Math.abs(dataParto.getTime() - dtAuxMin.getTime());
      const diferencaDias = Math.ceil(diferenca / (1000 * 3600 * 24));

      if (diferencaDias < valorGestacaoMinima) {
        this.erro =
          `A quantidade de dias de gestação não pode ser inferior à quantidade mínima (${ valorGestacaoMinima }) informada nos parâmetros do sistema.
            Matriz: ${ this.matriz.numero }.`;
        return false;
      }

      if (diferencaDias > valorGestacaoMaxima) {
        this.erro =
          `A quantidade de dias de gestação não pode ser superior à quantidade máxima (${ valorGestacaoMaxima }) informada nos parâmetros do sistema.
            Matriz: ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  private validaTipoParto(): boolean {
    if (!this.existeTipoParto() && this.existeDataParto()) {
      this.erro = `É necessário informar o tipo do parto. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private validaGrauAuxilio(): boolean {
    if (!this.existeGrauAuxilio()) {
      this.erro = `É necessário informar o grau de auxílio do parto. Matriz: ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private criaPossuiNatimorto(): boolean {
    const cria1PossuiNatimorto = this.existeNumeroCria1() && this.reproducao.natimortocria1 === 1;
    const cria2PossuiNatimorto = this.existeNumeroCria2() && this.reproducao.natimortocria2 === 1;
    if (cria1PossuiNatimorto || cria2PossuiNatimorto) {
      this.erro = `Não é permitido informar cria com natimorto. Matriz: ${ this.matriz.numero }.`;
      return true;
    }
    return false;
  }

  private existeDataParto(): boolean {
    const existeDataParto =
      this.reproducao.dtparto !== undefined
      && this.reproducao.dtparto !== null;
    return existeDataParto;
  }

  private existeTipoParto(): boolean {
    const existeTipoParto =
      this.reproducao.cdtipoparto !== null
      && this.reproducao.cdtipoparto !== undefined
      && this.reproducao.cdtipoparto > 0;
    return existeTipoParto;
  }

  private existeNumeroCria1(): boolean {
    const existeNumeroCria1 = this.reproducao.numeroCria1 !== undefined
      && this.reproducao.numeroCria1 !== null
      && this.reproducao.numeroCria1 !== ``;
    return existeNumeroCria1;
  }

  private existeNumeroCria2(): boolean {
    const existeNumeroCria2 = this.reproducao.numeroCria2 !== undefined
      && this.reproducao.numeroCria2 !== null
      && this.reproducao.numeroCria2 !== ``;
    return existeNumeroCria2;
  }

  private existeSexoCria1(): boolean {
    const hasCria1 = this.reproducao.sexocria1 !== undefined
      && this.reproducao.sexocria1 !== null
      && this.reproducao.sexocria1 !== ``;
    return hasCria1;
  }

  private existeSexoCria2(): boolean {
    const hasCria2 = this.reproducao.sexocria2 !== undefined
      && this.reproducao.sexocria2 !== null
      && this.reproducao.sexocria2 !== ``;
    return hasCria2;
  }

  private existeCdCria1(): boolean {
    const existeCdCria1 = this.reproducao.cdcria1 !== undefined
      && this.reproducao.cdcria1 !== null
      && this.reproducao.cdcria1 > 0;
    return existeCdCria1;
  }

  private existeCdCria2(): boolean {
    const existeCdCria2 = this.reproducao.cdcria2 !== undefined
      && this.reproducao.cdcria2 !== null
      && this.reproducao.cdcria2 > 0;
    return existeCdCria2;
  }

  private existeGrauAuxilio(): boolean {
    const existeGrauAuxilio =
      this.reproducao.cddificuldadeparto !== null
      && this.reproducao.cddificuldadeparto !== undefined
      && this.reproducao.cddificuldadeparto > 0;
    return existeGrauAuxilio;
  }

  private existeResultadoDiagnostico1(): boolean {
    const existeResultadoDiagnostico1 =
      this.reproducao.cdresultadodiagnostico1 !== null
      && this.reproducao.cdresultadodiagnostico1 !== undefined;
    return existeResultadoDiagnostico1;
  }

  private existeResultadoDiagnostico2(): boolean {
    const existeResultadoDiagnostico2 =
      this.reproducao.cdresultadodiagnostico2 !== null
      && this.reproducao.cdresultadodiagnostico2 !== undefined;
    return existeResultadoDiagnostico2;
  }

  private existeResultadoDiagnostico3(): boolean {
    const existeResultadoDiagnostico3 =
      this.reproducao.cdresultadodiagnostico3 !== null
      && this.reproducao.cdresultadodiagnostico3 !== undefined;
    return existeResultadoDiagnostico3;
  }

  private existeResultadoDiagnostico4(): boolean {
    const existeResultadoDiagnostico4 =
      this.reproducao.cdresultadodiagnostico4 !== null
      && this.reproducao.cdresultadodiagnostico4 !== undefined;
    return existeResultadoDiagnostico4;
  }

  private getResultadoDiagnostico2(): any {
    return this.reproducao.cdresultadodiagnostico2;
  }

  private isTE(): boolean {
    const isTE = this.reproducao.cdtipotentativa === TipoTentativaEnum.TE;
    return isTE;
  }

  private ehTipoDePartoAbortoComLactacao() {
    return this.reproducao.cdtipoparto === TipoPartoEnum.ABORTO_COM_LACTACAO;
  }

  getErro(): string {
    return this.erro;
  }
}
