import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Reprodutor } from 'src/models/configuracoes/reprodutor.model';
import { FazendaParametro } from 'src/models/fazenda-parametro.model';
import { Matriz } from 'src/models/matriz/matriz.model';
import { Reproducao } from 'src/models/matriz/reproducao.models';
import { TipoTentativaEnum } from '../_enums/tipo-tentativa.enum';

@Injectable({
  providedIn: 'root'
})
export class ValidacaoIaCobTeReproducaoService {

  private reproducao: any;
  private matriz: Matriz;
  private erro: string;
  private reproducoes: Reproducao[];
  private reprodutores: Reprodutor[];
  private parametros: FazendaParametro[];

  constructor() { }

  validaLancamentoIaCobTe(
    reproducoes: Reproducao[],
    reprodutores: Reprodutor[],
    reproducao: any,
    matriz: Matriz,
    parametros: FazendaParametro[]): boolean {
    this.reproducao = reproducao;
    this.matriz = matriz;
    this.erro = ``;
    this.reproducoes = reproducoes;
    this.reprodutores = reprodutores;
    this.parametros = parametros;

    if (!this.existeDataIaCobTe())                              { return false; }
    if (!this.ehDataIaCobTeValida())                            { return false; }
    if (!this.validaDataAptidao())                              { return false; }
    if (!this.existeTipoTentativa())                            { return false; }
    if (!this.existeUsoDescongelador())                         { return false; }
    if (!this.existeReprodutor())                               { return false; }
    if (!this.validaDoadora())                                  { return false; }
    if (this.existeReproducaoLancadaNaMesmaData())              { return false; }
    if (!this.validaLancamentoIaUsandoTouroComoReprodutor())    { return false; }
    if (!this.validaLancamentoCobUsandoSemenComoReprodutor())   { return false; }

    return true;
  }

  private validaDataAptidao(): boolean {
    const idadeAptidao = this.parametros.find(fazendaparametro => fazendaparametro.parametro.chave === `IdadeParaAptidao`);
    const existeIdadeAptidao = idadeAptidao !== undefined && idadeAptidao !== null;
    const existeDataNascimento = this.matriz.dtnascimento !== undefined && this.matriz.dtnascimento !== null;

    if (existeIdadeAptidao && existeDataNascimento) {
      const dataNascimento = new Date(this.matriz.dtnascimento).getTime();
      const dataServico = new Date(this.reproducao.data).getTime();
      const diasAptidao = Math.abs(dataNascimento - dataServico);
      const diasAptidaoConvertido = Math.ceil(diasAptidao / (1000 * 3600 * 24));

      if (diasAptidaoConvertido < Number(idadeAptidao.valor)) {
        this.erro = `
        A data de inseminação, cobertura ou transferência não pode ser anterior à
          data de nascimento somado aos dias para aptidão. Matriz ${ this.matriz.numero }.`;
        return false;
      }
    }

    return true;
  }

  private validaDoadora(): boolean {
    if (this.isTE()) {
      if (!this.existeDoadora()) {
        this.erro = `
        É obrigatório informar a doadora quando o tipo
          escolhido for transferência de embrião. Matriz ${ this.matriz.numero }.`;
        return false;
      }
    }
    if (!this.isTE()) {
      if (this.existeDoadora()) {
        this.erro = `
          Não é permitido informar a doadora para uma inseminação ou cobertura. Matriz ${ this.matriz.numero }.`;
        return false;
      }
    }
    return true;
  }

  private existeDoadora(): boolean {
    const existeDoadora =
        this.reproducao.cddoadora !== undefined
          && this.reproducao.cddoadora !== null
          && this.reproducao.cddoadora > 0;
    return existeDoadora;
  }

  private existeUsoDescongelador(): boolean {
    const existeUsoDescongelador =
      this.reproducao.usodescongelador !== undefined
      && this.reproducao.usodescongelador !== null;
    if (!existeUsoDescongelador) {
      this.erro = `
        O uso de descongelador é de preenchimento obrigatório. Matriz ${ this.matriz.numero }.`;
    }
    return existeUsoDescongelador;
  }

  private existeTipoTentativa(): boolean {
    const existeTipoTentativa =
      this.reproducao.cdtipotentativa !== undefined
      && this.reproducao.cdtipotentativa !== null;

    if (!existeTipoTentativa) {
      this.erro = `É necessário informar o tipo de tentativa. Matriz ${ this.matriz.numero }.`;
    }
    return existeTipoTentativa;
  }

  private existeReprodutor(): boolean {
    const existeReprodutor =
      this.reproducao.cdreprodutor !== undefined
      && this.reproducao.cdreprodutor !== null
      && this.reproducao.cdreprodutor > 0;

    if (!existeReprodutor) {
      this.erro = `É necessário informar o reprodutor. Matriz ${ this.matriz.numero }.`;
    }
    return existeReprodutor;
  }

  private existeDataIaCobTe(): boolean {
    const existeDataIaCobTe = this.reproducao.data !== undefined && this.reproducao.data !== null;
    if (!existeDataIaCobTe) {
      this.erro = `É necessário informar a data de ia/cob/te. Matriz ${ this.matriz.numero }.`;
    }
    return existeDataIaCobTe;
  }

  private ehDataIaCobTeValida(): boolean {
    let ehDataIaCobTeValida = true;
    if (this.existeDataIaCobTe()) {
      const dataAtual = new Date();
      const dataNascimento = new Date(this.matriz.dtnascimento);
      const dataIaCobTe = new Date(this.reproducao.data);

      ehDataIaCobTeValida = dataIaCobTe > dataNascimento && dataIaCobTe <= dataAtual;

      if (!ehDataIaCobTeValida) {
        this.erro = `A data não pode ser anterior à data de nascimento
          e nem superior à data atual. Matriz ${ this.matriz.numero }.`;
      }
    }
    return ehDataIaCobTeValida;
  }

  private existeReproducaoLancadaNaMesmaData(): boolean {
    const reproducoes =
      this.reproducoes
        .filter(reproducao => reproducao.cdmatriz === this.reproducao.cdmatriz
            && new Date(reproducao.data).getTime() === moment(this.reproducao.data).toDate().getTime()
            && reproducao.cdreproducao !== this.reproducao.cdreproducao);

    const existeReproducaoLancadaNaMesmaData =
      reproducoes !== undefined
      && reproducoes !== null
      && reproducoes.length > 0;

    if (existeReproducaoLancadaNaMesmaData) {
      this.erro = `
        Não é permitido o cadastramento de uma tentativa na mesma
          data de outra tentativa. Matriz ${ this.matriz.numero }.`;
    }
    return existeReproducaoLancadaNaMesmaData;
  }

  private validaLancamentoIaUsandoTouroComoReprodutor(): boolean {
    const reprodutores =
      this.reprodutores.filter(
        reprodutor => reprodutor.cdreprodutor === this.reproducao.cdreprodutor
        && reprodutor.cdtiporeprodutor === 1);

    const existeReprodutor = reprodutores !== undefined && reprodutores.length > 0;

    if (this.isIA() && existeReprodutor) {
      this.erro = `Não pode informar inseminação utilizando touro como reprodutor. Matriz ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private validaLancamentoCobUsandoSemenComoReprodutor(): boolean {
    const reprodutores =
      this.reprodutores.filter(
        reprodutor => reprodutor.cdreprodutor === this.reproducao.cdreprodutor
        && reprodutor.cdtiporeprodutor === 0);

    const existeReprodutor = reprodutores !== undefined && reprodutores.length > 0;

    if (this.isCob() && existeReprodutor) {
      this.erro = `Não pode informar cobertura utilizando sêmen como reprodutor. Matriz ${ this.matriz.numero }.`;
      return false;
    }
    return true;
  }

  private isTE(): boolean {
    const isTE = this.reproducao.cdtipotentativa === TipoTentativaEnum.TE;
    return isTE;
  }

  private isIA(): boolean {
    const isIA = this.reproducao.cdtipotentativa === TipoTentativaEnum.IA;
    return isIA;
  }

  private isCob(): boolean {
    const isCob = this.reproducao.cdtipotentativa === TipoTentativaEnum.COB;
    return isCob;
  }

  getErro(): string {
    return this.erro;
  }
}
