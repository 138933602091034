export class HistoricoDeMatriz {
    public cdHistorico: number;
    public cdMatriz: number;
    public cdFazenda: number;
    public numero: string;
    public nome: string;
    public historico: string;
    public data: Date;

    // atributo utilizado para validações na tela
    public isValid: boolean;
  }
