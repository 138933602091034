import { Pessoa } from './pessoa.model';
import { Fazenda } from './fazenda.model';
import { UsuarioFazenda } from './usuario-fazenda.model';

export class Usuario {
    public cdpessoa: number;
    public senha: string;
    public login: string;
    public cnpj: string;
    public bloqueado: boolean;
    public dtaltera: Date;
    public apelido: string;
    public datavalidadeacesso: Date;
    public pendente: boolean;
    public dtcadastro: Date;
    public dtultimoacesso: Date;
    public indicacao: string;
    public usuariofazenda: UsuarioFazenda[];
    public isAdmin: boolean;
    public lembretesenha: string;
    public perfilAcesso: number;
    public pessoa: Pessoa;
    public trocasenha: boolean;
    public cdusuarioaltera: number;
    public usuarioGrupo: any;

    // Dados para crud Pessoa
    public nome: string;
    public email: string;
    public numeroidentificacao: string;
    public fazendas: Fazenda[];
}
